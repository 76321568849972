export const hideIntercom = () => {
  document
    .querySelectorAll('.intercom-lightweight-app')
    .forEach((item) => item.classList.add('hide_intercom'));
};

export const showIntercom = () => {
  document
    .querySelectorAll('.intercom-lightweight-app')
    .forEach((item) => item.classList.remove('hide_intercom'));
};
