import styled from 'styled-components';
import { Button, theme } from '@klover/attain-design-system';

export const Wrapper = styled.div`
  background: ${theme.colors.backgroundPrimary};
  min-height: 100vh;
  min-width: 100%;
  overflow-x: hidden;
`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 ${theme.spacing.md};
  width: 100%;
`;

export const Flex = styled.div`
  display: flex;
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex-grow: 1;
  gap: ${theme.spacing.xl};
  text-transform: uppercase;
`;

export const HeaderRight = styled.div`
  display: flex;
  gap: ${theme.spacing.md};
`;

export const HeaderButton = styled(Button)`
  &&& {
    color: ${theme.colors.textInverted};
  }
`;

export const HeaderButtonTalk = styled(HeaderButton)`
  &&& {
    border-color: #7a7397;
    position: relative;
    top: -3px;

    &:hover,
    &:active {
      border-color: ${theme.colors.backgroundPrimary};
      color: ${theme.colors.textBrand};
    }
  }
`;

export const HeaderButtonSignUp = styled(HeaderButton)`
  &&& {
    background-color: #b8f4f1;
    color: ${theme.colors.text};

    &:hover,
    &:active {
      background-color: ${theme.colors.CHART_TERTIARY};
    }
  }
`;

export const LoginButton = styled.a`
  && {
    ${theme.typography.headingXS};
    cursor: pointer;
    line-height: 42px;
  }
`;

export const Logo = styled.img`
  margin-inline-end: ${theme.spacing.xxl};
  scale: 1.3333;
  transform-origin: top left;

  g {
    fill: ${theme.colors.textInverted};
  }
`;

export const Header = styled.header`
  align-items: center;
  background: #4d4176;
  color: ${theme.colors.textInverted};
  display: flex;
  padding-block-start: ${theme.spacing.xl};

  a {
    ${theme.typography.headingSM};
    color: ${theme.colors.textInverted};
    line-height: 42px;
  }
`;

export const Hero = styled.main`
  background: linear-gradient(to bottom, #4d4176, #3f3760);
  color: ${theme.colors.textInverted};
  padding-block-start: ${theme.spacing.xl};

  @media ${theme.mq.tabletPortraitUp} {
    padding-block-start: ${theme.spacing.xxxl};
  }
`;

export const HeroV3 = styled(Hero)`
  padding-block-end: ${theme.spacing.xl};

  @media ${theme.mq.tabletPortraitUp} {
    padding-block-end: ${theme.spacing.xxxl};
  }
`;

export const HeroDescription = styled.p`
  ${theme.typography.headingMD};
  margin: 0;

  @media ${theme.mq.tabletPortraitUp} {
    font-size: 56px;
    line-height: 1.2em;
  }

  span {
    font-family: 'Calibre-Regular', sans-serif !important;
  }
`;

export const HeroDescriptionGradient = styled.span`
  background: linear-gradient(to right, #ff8166, #b880a1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const VideoMobile = styled.div`
  background: #473d63;
  border: 4px solid #222132;
  border-radius: ${theme.spacing.xs};
  height: 194px;
  margin-block-start: ${theme.spacing.md};
  overflow: hidden;
  width: 100%;

  video {
    left: -1px;
    position: relative;
    top: -2px;
    width: calc(100% + 2px);
  }
`;

export const MoveVideoMobile = styled.div`
  transform: translate(-2%);
`;

export const VideoSkewedMobile = styled(VideoMobile)`
  box-shadow: 3px 3px 12px hsl(0 0% 0% / 33%);
  height: 186px;
  transform: perspective(910px) rotateY(-15deg) rotateX(10deg) scaleX(0.9);
`;

export const HeroList = styled.ul`
  && {
    margin-block-start: ${theme.spacing.xl};
  }
`;

export const HeroListItem = styled.li`
  ${theme.typography.bodyLG};
  align-items: center;
  display: flex;
  gap: ${theme.spacing.sm};

  @media ${theme.mq.tabletPortraitUp} {
    line-height: 32px;
  }

  &:not(:first-child) {
    margin-block-start: ${theme.spacing.sm};

    @media ${theme.mq.tabletPortraitUp} {
      margin-block-start: 0;
    }
  }

  svg {
    width: 20px;
  }
`;

export const HeroCTAs = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.sm};
  margin-block-start: ${theme.spacing.xl};

  @media ${theme.mq.tabletPortraitUp} {
    flex-direction: row;
    gap: ${theme.spacing.lg};
  }
`;

export const HeroCTA = styled(Button)`
  &&& {
    background-color: #b8f4f1;
    color: ${theme.colors.text};
    width: 100%;

    @media ${theme.mq.tabletPortraitUp} {
      ${theme.typography.headingSM};
      height: 64px;
      width: auto;
    }

    &:hover,
    &:active {
      background-color: ${theme.colors.CHART_TERTIARY};
    }
  }
`;

export const HeroCTAOutline = styled(Button)`
  &&& {
    border-color: #7a7397;
    color: ${theme.colors.textInverted};
    width: 100%;

    @media ${theme.mq.tabletPortraitUp} {
      ${theme.typography.headingSM};
      height: 64px;
      width: auto;
    }

    &:hover,
    &:active {
      border-color: ${theme.colors.backgroundPrimary};
      color: ${theme.colors.textBrand};
    }
  }
`;

export const HeroDetails = styled.div`
  margin-block-start: ${theme.spacing.lg};

  @media ${theme.mq.tabletPortraitUp} {
    margin-block-start: ${theme.spacing.xl};
  }
`;

export const Video = styled.div`
  background: #473d63;
  border: 8px solid #222132;
  border-radius: ${theme.spacing.md};
  height: 476px;
  overflow: hidden;
  width: 840px;

  video {
    height: 464px;
    left: -1px;
    position: relative;
    top: -4px;
  }
`;

export const MoveVideo = styled.div`
  transform: translate(-61px, -20px);
`;

export const VideoSkewed = styled(Video)`
  box-shadow: 6px 6px 24px hsl(0 0% 0% / 33%);
  transform: perspective(910px) rotateY(-15deg) rotateX(10deg) scaleX(0.9);
`;

export const Clients = styled.div`
  background: #3f3760;
  color: ${theme.colors.textInverted};
  fill: ${theme.colors.textInverted} !important;
  padding-block-start: ${theme.spacing.xl};
  text-align: center;

  @media ${theme.mq.tabletPortraitUp} {
    padding-block-start: ${theme.spacing.xxxl};
  }

  path {
    fill: ${theme.colors.textInverted} !important;
  }
`;

export const ClientsLogos = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.xxxl};
  justify-content: center;
  margin-block-start: ${theme.spacing.lg};
  margin-inline: ${theme.spacing.xl};
`;

export const ClientsV3 = styled(Clients)`
  background: #ecfeff;
  color: ${theme.colors.text};
  fill: #9591a3 !important;
  text-align: left;

  @media ${theme.mq.tabletPortraitUp} {
    text-align: center;
  }

  path {
    fill: #9591a3 !important;
  }
`;

export const Quotes = styled.div`
  background: #ecfeff;
  padding-block-start: ${theme.spacing.xl};
  overflow: hidden;
  position: relative;

  @media ${theme.mq.tabletPortraitUp} {
    padding-block-start: ${theme.spacing.xxxl};
  }

  .glide__slides {
    position: relative;
    z-index: 3;
  }

  .glide__bullets {
    display: flex;
    gap: ${theme.spacing.md};
    justify-content: center;
    margin-block-start: ${theme.spacing.xl};
    position: relative;
    z-index: 3;
  }

  .glide__bullet {
    background: ${theme.colors.textBrand};
    border-radius: 50%;
    height: 12px;
    opacity: 0.3;
    width: 12px;

    &.glide__bullet--active {
      opacity: 1;
    }
  }
`;

export const QuoteBackground = styled.div`
  background: #3f3760;
  height: 218px;
  left: 0;
  position: absolute;
  top: 0;
  transform: skew(0, -5deg);
  transform-origin: left bottom;
  width: 100%;
  z-index: 0;
`;

export const Quote = styled.div`
  background: ${theme.colors.backgroundPrimary};
  border: 1px solid ${theme.colors.CHART_PRIMARY};
  border-radius: ${theme.spacing.xs};
  color: ${theme.colors.buttonBackgroundActive};
  height: 100%;
  padding: ${theme.spacing.xxl} ${theme.spacing.xl};
  position: relative;
  z-index: 3;
`;

export const QuoteText = styled.p`
  ${theme.typography.headingSM};
  margin-block: ${theme.spacing.lg} calc(${theme.spacing.xxl} + 76px);
`;

export const QuoteCredit = styled.div`
  bottom: ${theme.spacing.xxl};
  left: ${theme.spacing.xl};
  position: absolute;
`;

export const QuoteAuthor = styled.div`
  ${theme.typography.headingXS};
`;

export const QuoteRole = styled.div`
  ${theme.typography.bodyLG};
  color: ${theme.colors.textTertiary};
`;

export const Partners = styled(Clients)`
  background: #ecfeff;
  color: ${theme.colors.buttonBackgroundActive};
  fill: ${theme.colors.buttonBackgroundActive} !important;
  padding-block: ${theme.spacing.xl};

  @media ${theme.mq.tabletPortraitUp} {
    padding-block: ${theme.spacing.xxxl};
  }

  svg {
    height: 56px;

    @media ${theme.mq.tabletPortraitUp} {
      height: 78px;
    }
  }

  path {
    fill: ${theme.colors.buttonBackgroundActive} !important;
  }
`;

export const ClientsTitle = styled.h3`
  ${theme.typography.headingSM};
  text-transform: uppercase;
`;

export const ClientsTitleV3 = styled.h3`
  ${theme.typography.headingLG};

  @media ${theme.mq.tabletPortraitUp} {
    ${theme.typography.headingXL};
  }
`;

export const PartnerLogos = styled(ClientsLogos)`
  column-gap: ${theme.spacing.lg};
  flex-wrap: wrap;
  row-gap: 0;
  margin-inline: 0;

  img {
    height: 80px;
  }

  @media ${theme.mq.tabletPortraitUp} {
    column-gap: ${theme.spacing.xxxl};
  }
`;

export const PartnersV3 = styled(Clients)`
  background: ${theme.colors.backgroundPrimary};
  color: ${theme.colors.text};
  fill: #9591a3 !important;
  margin-block-start: -200px;
  padding-block: calc(${theme.spacing.xl} + 200px) ${theme.spacing.xl};
  position: relative;
  text-align: left;
  z-index: 2;

  @media ${theme.mq.tabletPortraitUp} {
    padding-block: calc(${theme.spacing.xxxl} + 200px) ${theme.spacing.xxxl};
    text-align: center;
  }

  svg {
    height: 56px;

    @media ${theme.mq.tabletPortraitUp} {
      height: 78px;
    }
  }

  path {
    fill: #9591a3 !important;
  }
`;

export const PartnerLogosV3 = styled(ClientsLogos)`
  column-gap: ${theme.spacing.lg};
  flex-wrap: wrap;
  row-gap: 0;
  margin-inline: 0;

  @media ${theme.mq.tabletPortraitUp} {
    column-gap: ${theme.spacing.xxxl};
  }
`;

export const Mission = styled.div`
  padding-block: ${theme.spacing.xl};

  @media ${theme.mq.tabletPortraitUp} {
    padding-block: ${theme.spacing.xxxl};
  }
`;

export const MissionV3 = styled(Mission)`
  background: #faf9fa;
`;

export const MissionDescription = styled.p`
  ${theme.typography.headingLG};
  font-family: 'Calibre-Regular', sans-serif !important;
  text-align: center;

  @media ${theme.mq.tabletPortraitUp} {
    ${theme.typography.headingXL};
    line-height: 78px;
    text-align: left;
  }

  span {
    ${theme.typography.headingLG};
    border-bottom: 4px dashed black;
    border-image: linear-gradient(to right, #ff8166, #4d4176) 1;

    @media ${theme.mq.tabletPortraitUp} {
      ${theme.typography.headingXL};
      margin-block-start: ${theme.spacing.xxl};
    }
  }
`;

export const MissionDescriptionV3 = styled(MissionDescription)`
  text-align: left;
`;

export const MissionCTA = styled(Button)`
  &&& {
    width: 100%;

    @media ${theme.mq.tabletPortraitUp} {
      ${theme.typography.headingSM};
      height: 64px;
      width: auto;
    }
  }
`;

export const Attain = styled.a`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.md};
  line-height: 32px;
  margin-block-start: ${theme.spacing.xl};
  text-decoration: underline;

  img {
    height: 40px;
  }

  @media ${theme.mq.tabletPortraitUp} {
    margin-block-start: ${theme.spacing.xxxl};
  }

  svg {
    fill: ${theme.colors.textBrand};
    height: 32px;
    width: 32px;
  }
`;
