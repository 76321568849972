import MeasurementAdRecall from './MeasurementAdRecall';
import MeasurementAdRecallByMerchant from './MeasurementAdRecallByMerchant';
import MeasurementAdRecallOverTime from './MeasurementAdRecallOverTime';
import MeasurementBrandFavorability from './MeasurementBrandFavorability';
import MeasurementBrandRecall from './MeasurementBrandRecall';
import MeasurementBrandRecallAge from './MeasurementBrandRecallAge';
import MeasurementBrandRecallGender from './MeasurementBrandRecallGender';
import MeasurementBrandRecallIncome from './MeasurementBrandRecallIncome';
import MeasurementInterestSkews from './MeasurementInterestSkews';
import MeasurementPurchaseLikelihood from './MeasurementPurchaseLikelihood';
import { Grid, theme } from '@klover/attain-design-system';
import { ScaleBandSpec, ScaleSpec } from '@nivo/scales';
import { attainColorPalette } from 'constants/attainColorPalette';
import { linearGradientDef } from '@nivo/core';

const createGradientDef = (index) =>
  linearGradientDef(`gradient${index}`, [
    { offset: 0, color: attainColorPalette[index] },
    { offset: 100, color: attainColorPalette[index], opacity: 0.33 },
  ]);

const createFillMatch = (index) => ({
  match: (d) => d.color === attainColorPalette[index],
  id: `gradient${index}`,
});

const gradients = attainColorPalette.map((_, index) =>
  createGradientDef(index)
);
const fills = attainColorPalette.map((_, index) => createFillMatch(index));

const getBarLabel = (bar) =>
  bar.id === 'empty' ? '' : `${bar.value.toFixed(2)}%`;
const getBarColor = (bar) =>
  bar.id === 'empty' ? 'transparent' : bar.data.color;

export const chartCommonProps = {
  defs: [
    ...gradients,
    linearGradientDef(
      'gradientSuccess',
      [
        { offset: 0, color: theme.colors.successForeground, opacity: 0.4 },
        { offset: 100, color: theme.colors.successForeground, opacity: 0.33 },
      ],
      { gradientTransform: 'rotate(90 0.5 0.5)' }
    ),
    linearGradientDef(
      'gradientError',
      [
        { offset: 0, color: theme.colors.errorForeground, opacity: 0.4 },
        { offset: 100, color: theme.colors.errorForeground, opacity: 0.33 },
      ],
      { gradientTransform: 'rotate(-90 0.5 0.5)' }
    ),
  ],
  fill: [
    ...fills,
    {
      match: (d) => d.color === theme.colors.successBackground,
      id: 'gradientSuccess',
    },
    {
      match: (d) => d.color === theme.colors.errorBackground,
      id: 'gradientError',
    },
  ],
  isInteractive: true,
  indexBy: 'answer',
  margin: { top: 4, right: 0, bottom: 21, left: 40 },
  padding: 0.5,
  layout: 'vertical' as const,
  valueScale: { type: 'linear' } as ScaleSpec,
  indexScale: { type: 'band', round: true } as ScaleBandSpec,
  axisTop: null,
  axisRight: null,
  axisBottom: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    truncateTickAt: 0,
  },
  axisLeft: {
    format: (value) => `${Math.round(value)}%`,
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    truncateTickAt: 0,
  },
  labelSkipWidth: 12,
  labelSkipHeight: 12,
  labelTextColor: theme.colors.text,
  colors: getBarColor,
  maxValue: 100,
  label: getBarLabel,
};

export const MeasurementBrandSurvey = () => {
  return (
    <>
      <Grid item xs={12}>
        <MeasurementBrandRecall />
      </Grid>

      <Grid item xs={12}>
        <MeasurementAdRecall />
      </Grid>

      <Grid item xs={12}>
        <MeasurementBrandRecallGender />
      </Grid>

      <Grid item xs={12}>
        <MeasurementBrandRecallAge />
      </Grid>

      <Grid item xs={12}>
        <MeasurementBrandRecallIncome />
      </Grid>

      <Grid item xs={12} md={6}>
        <MeasurementPurchaseLikelihood />
      </Grid>

      <Grid item xs={12} md={6}>
        <MeasurementBrandFavorability />
      </Grid>

      <Grid item xs={12}>
        <MeasurementAdRecallOverTime />
      </Grid>

      <Grid item xs={12}>
        <MeasurementAdRecallByMerchant />
      </Grid>

      <Grid item xs={12}>
        <MeasurementInterestSkews />
      </Grid>
    </>
  );
};

export default MeasurementBrandSurvey;
