import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

export const AlignCenter = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.xs};
`;

export const Logo = styled.img<{ src?: string; alt?: string }>`
  border: 1px solid ${theme.colors.borderDark};
  border-radius: ${theme.spacing.xxs};
  height: 24px;
  width: 24px;
`;

export const Segment = styled.div`
  ${theme.typography.bodyLG};
`;
