import ChartSkew from './BrandSurveyCharts/ChartSkew';
import DataTable from 'components/DataTable/DataTable';
import { Card, Grid, theme } from '@klover/attain-design-system';
import * as Styled from './MeasurementBrandSurvey.styles';

const data = [
  {
    title: 'Plays Soccer',
    skew: 0.49,
  },
  { title: 'Eats At Family Restaurants', skew: 0.41 },
  { title: 'Eats At Fast Food Restaurants', skew: 0.41 },
  { title: 'NBA Enthusiast', skew: 0.39 },
  { title: 'Carrentals Frequent', skew: 0.38 },
  { title: 'Zoo Visitors', skew: 0.35 },
  { title: 'Impulse Buyer', skew: 0.33 },
  {
    title: 'Digital Magazine Newspapers Buyers',
    skew: 0.31,
  },
  { title: 'Dog Owners', skew: 0.31 },
  { title: 'Carrentals High', skew: 0.31 },
  { title: 'Do It Yourselfers', skew: -0.31 },
  { title: 'Sweepstakes Lottery', skew: -0.31 },
  { title: 'Dept Store Instore', skew: -0.31 },
  { title: 'Lexus', skew: -0.32 },
  { title: 'Listens To Classical Music', skew: -0.32 },
  { title: 'Video Gamer', skew: -0.33 },
  { title: 'Cadillac', skew: -0.34 },
  { title: 'Listens To Music', skew: -0.34 },
  { title: 'Furn Decor Deal Afford High', skew: -0.35 },
  { title: 'Chrysler', skew: -0.36 },
  { title: 'Volvo', skew: -0.36 },
  { title: 'Lincoln', skew: -0.43 },
  { title: 'BMW', skew: -0.46 },
  { title: 'Mercedes Benz', skew: -0.64 },
];

const chartRows = data.map((row) => {
  return {
    name: row.title,
    value: row.skew,
  };
});

const chart = {
  type: 'absolute',
  values: chartRows,
};

const columns = [
  {
    title: 'Attribute',
    sort: 'atrribute',
  },
  {
    title: 'Very likely buyer skew',
    sort: 'skew',
  },
];

const rows = data?.map((row) => ({
  sortValues: {
    attribute: row.title,
    skew: row.skew,
  },
  component: () => (
    <>
      <td>{row.title}</td>
      <td>{row.skew}</td>
      <td></td>
    </>
  ),
}));

export const MeasurementInterestSkews = () => {
  return (
    <Card>
      <Grid
        container
        columnSpacing={theme.spacing.xxl}
        rowSpacing={theme.spacing.lg}
      >
        <Grid item xs={12}>
          <Styled.CardHeader>
            Interest skews for &quot;very likely&quot; next 30 day buyers
          </Styled.CardHeader>
        </Grid>

        <Grid item xs={12} md={6}>
          <ChartSkew chart={chart} />
        </Grid>

        <Grid item xs={12} md={6}>
          <DataTable
            id="interest-skews"
            columns={columns}
            rows={rows}
            noFooter
            noBorder
            noPagination
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default MeasurementInterestSkews;
