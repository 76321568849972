import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

export const Wrapper = styled.div`
  background: ${theme.colors.backgroundPrimary};
  border: 1px solid ${theme.colors.borderDark};
  border-radius: ${theme.spacing.xs};
  margin-block: ${theme.spacing.md};
  padding: ${theme.spacing.md};
`;

export const WrapperOld = styled.div`
  margin-block: ${theme.spacing.md};
`;

export const Header = styled.div`
  ${theme.typography.headingXS};
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Bar = styled.div`
  background: ${theme.colors.backgroundLight};
  border-radius: 2px;
  height: 4px;
  margin-block-start: ${theme.spacing.md};
  position: relative;
  width: 100%;
`;

export const Filled = styled.div<{ $percent: number }>`
  background: ${theme.colors.backgroundBrand};
  border-radius: 2px;
  height: 4px;
  position: absolute;
  width: ${({ $percent }) => `${$percent * 100}%`};
`;
