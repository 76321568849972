import SkewsTable from '../MetricComponents/Skews/SkewsTable';
import TableLoader from '../MetricComponents/SkeletonLoaders/tableLoaders';
import brandLiftSurveyInsightsInterestSkewsQuery from 'operations/queries/brandLiftSurveyInsightsInterestSkews.query';
import { Base64ID } from 'types/models';
import {
  BlquestionType,
  BrandLiftBreakdownSkew,
  BrandLiftSurveyInsightsInterestSkewsQuery,
  BrandLiftSurveyInsightsInterestSkewsQueryVariables,
  SurveyQuestionTemplate,
} from 'types/graphql/generated';
import { useQuery } from '@apollo/client';

interface Props {
  questionType: BlquestionType | null;
  campaignId: Base64ID;
  liftType: 'absoluteLift' | 'relativeLift';
  isBrand: boolean;
}

export const InterestSkews = ({
  questionType,
  campaignId,
  liftType,
  isBrand,
}: Props) => {
  const { data, loading } = useQuery<
    BrandLiftSurveyInsightsInterestSkewsQuery,
    BrandLiftSurveyInsightsInterestSkewsQueryVariables
  >(brandLiftSurveyInsightsInterestSkewsQuery, {
    variables: {
      input: {
        campaignId: campaignId,
        questionType: isBrand
          ? ({ brand: questionType?.templateId } as SurveyQuestionTemplate)
          : ({ merchant: questionType?.templateId } as SurveyQuestionTemplate),
      },
    },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <TableLoader />;
  }

  const skewData = data?.brandLiftSurveyInsightsInterestSkews;
  return (
    <SkewsTable
      skewTables={skewData ? [skewData as BrandLiftBreakdownSkew] : undefined} //todo: remove unnessesary cast once types are updated
      liftType={liftType}
    />
  ); //todo: not any apollo needs to update type
};

export default InterestSkews;
