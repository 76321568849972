import { gql } from '@apollo/client';

export const measurementOverviewQuery = gql`
  query MeasurementOverview($input: MeasurementOverviewInput!) {
    measurementOverview(input: $input) {
      ... on MeasurementOverviewSuccess {
        id
        metrics {
          conversionRate {
            value
            title
          }
          roas {
            value
            title
          }
          projectedConversions {
            value
            title
          }
          salesDriven {
            value
            title
          }
          cpm {
            value
            title
          }
          cpa {
            value
            title
          }
          frequency {
            value
            title
          }
          mediaSpend {
            value
            title
          }
        }
        impressions {
          heroMetric {
            value
            title
          }
          time
          daily
          dailyAverage {
            value
            title
          }
          cumulative
        }
        reach {
          time
          daily
          cumulative
          heroMetric {
            value
            title
          }
          dailyAverage {
            value
            title
          }
        }
        dateRange {
          startDate
          endDate
        }
        campaignDateRange {
          startDate
          endDate
        }
      }
      ... on MeasurementOverviewError {
        message
      }
    }
  }
`;

export default measurementOverviewQuery;
