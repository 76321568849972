import Back from './BackButton/BackButton';
import OnboardingMerchantBrandMultiple from './OnboardingMerchantBrandSearchMultiple/OnboardingMerchantBrandMultiple';
import { Control, FieldValues, UseFieldArrayReturn } from 'react-hook-form';
import { InputLabel } from '@klover/attain-design-system';
import * as Styled from './Step.styles';

interface Props {
  prevPage: () => void;
  nextPage: () => void;
  step: number;
  fieldArray: UseFieldArrayReturn<FieldValues, 'brandOrMerchant', 'id'>;
  control: Control;
  watchValue: string | null;
}

export const MerchantsAndBrandsStep = ({
  prevPage,
  nextPage,
  step,
  fieldArray,
  watchValue,
}: Props) => {
  const { replace, fields, remove } = fieldArray;

  return (
    <Styled.Block>
      <Back prevPage={prevPage} />

      <Styled.Step>Step {step} / 6</Styled.Step>

      <Styled.Header>Which brand(s) do you care about?</Styled.Header>

      <Styled.InputWrapper fullWidth>
        <Styled.Instructions>
          Start typing to begin your search. Select{' '}
          <span>one or more brands</span> to see recommended insights &amp;
          audiences on the home page.
        </Styled.Instructions>
        <InputLabel required>Brands</InputLabel>

        <OnboardingMerchantBrandMultiple
          label="Start typing to begin your search"
          replace={replace}
          values={(fields ?? []) as any}
          remove={remove}
          localStorageName="onboardingBrands"
        />
      </Styled.InputWrapper>

      <Styled.Submit
        variant="contained"
        fullWidth
        onClick={nextPage}
        disabled={!Boolean(fields.length) && !Boolean(watchValue)}
      >
        Next
      </Styled.Submit>
    </Styled.Block>
  );
};

export default MerchantsAndBrandsStep;
