import MeasurementEmptyImg from 'assets/measurements-empty.svg';
import React from 'react';
import trackEvent from 'utils/trackingEvents';
import useMe from 'hooks/useMe/useMe';
import { Button } from '@klover/attain-design-system';
import * as Styled from './MeasurementNotPermissioned.styles';

export const MeasurementNotPermissioned = () => {
  const me = useMe();

  React.useEffect(() => {
    window.analytics.page('Page view: Measurement > Not Permissioned');
  }, []);

  return (
    <Styled.Empty>
      <img src={MeasurementEmptyImg} alt="" />

      <Styled.Header>
        Unlock measurement to see how your campaigns are doing in real time
      </Styled.Header>

      <Styled.Link
        onClick={() => {
          trackEvent('Request Demo', {
            ...me,
            from: 'Measurement - Not permissioned',
          });
          window.open('https://attaindata.io/contact-us/', '_blank');
        }}
      >
        <Button variant="contained">Request Demo</Button>
      </Styled.Link>
      {/* </Link> */}
    </Styled.Empty>
  );
};

export default MeasurementNotPermissioned;
