import styled, { keyframes } from 'styled-components';
import { TextField, theme } from '@klover/attain-design-system';

export const Retention = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.xs};
`;

export const RetentionInput = styled(TextField)`
  appearance: textfield;
  width: 60px;
`;

export const Amount = styled.div`
  display: flex;
  gap: ${theme.spacing.lg};
`;

const scaleLeftToRight = ($width) => keyframes`
  from {
    opacity: 0;
    width: 0;

  }
  to {
    opacity: 1;
    width: ${$width * 100}%;
  }
`;

export const DataTableBar = styled.div<{ $value: number }>`
  animation: ${({ $value }) => scaleLeftToRight($value)} 0.5s ease-out forwards;
  background: ${({ $value }) =>
    `hsl(251, ${(1 - $value) * 73 + 19}%, ${(1 - $value) * 46 + 44}%)`};
  border-radius: ${theme.spacing.xxs};
  display: none;
  height: 24px;
  width: ${({ $value }) => `${$value * 100}%`};

  @media ${theme.mq.tabletPortraitUp} {
    display: block;
  }
`;
