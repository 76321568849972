import styled from 'styled-components';
import { theme } from '@klover/attain-design-system';

export const CardHeader = styled.h3`
  ${theme.typography.headingSM};
  align-items: center;
  display: flex;
  gap: ${theme.spacing.lg};
`;

export const Badges = styled.div<{ $stacked?: boolean }>`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.xs};
  justify-content: center;
  flex-direction: ${({ $stacked }) => ($stacked ? 'column' : 'row')};
`;

export const Badge = styled.div<{ $borderColor?: string; $color?: string }>`
  ${theme.typography.bodyLG};
  background: ${({ $color }) => $color ?? theme.colors.infoBackground};
  border-radius: 4px;
  border: 1px solid
    ${({ $borderColor }) => $borderColor ?? theme.colors.borderDark};
  padding-inline: ${theme.spacing.xs};
  text-align: center;
  width: 100%;

  span {
    ${theme.typography.headingXS};
  }
`;

export const CardDescription = styled.p`
  ${theme.typography.bodyLG};
`;

export const ChartHeader = styled.h4`
  ${theme.typography.headingXS};
  text-align: center;
`;

export const ChartWrapper = styled.div<{ $height: number }>`
  height: ${({ $height }) => `${$height}px`};
  margin-top: ${theme.spacing.lg};
  position: relative;
`;

export const ChartWrapperTall = styled.div`
  height: 1541px;
  position: relative;
`;

export const MoeLayer = styled.div`
  position: absolute;
  right: 0;
  top: 4px;
  left: 45px;
  bottom: 21px;
  pointer-events: none;
`;

export const Moe = styled.div<{
  $position: number;
  $lift: number;
  $confidenceInterval: number;
}>`
  position: absolute;
  width: 8px;
  height: ${({ $confidenceInterval }) => `${$confidenceInterval}%`};
  translate: -50% 50%;
  background: ${theme.colors.backgroundBrand};
  bottom: ${({ $lift }) => `${$lift}%`};
  left: ${({ $position }) => `${$position}%`};
`;

export const Tooltip = styled.div`
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  background: ${theme.colors.backgroundPrimary};
  border: 1px solid;
  border-color: ${theme.colors.borderDark};
  border-radius: ${theme.spacing.xs};
`;

export const TooltipTitle = styled.div`
  ${theme.typography.headingXS};
  margin: 0;
`;
