import ComingSoonBadge from 'components/ComingSoonBadge/ComingSoonBadge';
import DataCellHeatMap from 'components/DataCellHeatMap/DataCellHeatMap';
import MenuItemExport from 'components/MenuItemExport/MenuItemExport';
import Pagination from '../../../Pagination/Pagination';
import React from 'react';
import useFeatureFlags from 'hooks/useFeatureFlags/useFeatureFlags';
import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import { Card, Tooltip } from '@klover/attain-design-system';
import { DealConversion } from 'types/graphql/generated';
import { OptimizationToggles } from '../../OptimizationsFilters/OptimizationsFilters';
import { SortingColumn } from '../OptimizationTable/OptimizationTable';
import { removeCharsAndSpaces } from 'utils/format';
import * as Styled from '../../MeasurementOptimization/MeasurementOptimization.styles';

interface Props {
  campaignTitle: string;
  data: DealConversion[] | undefined;
  decimalPlaces: number;
  columnToggles: OptimizationToggles;
}

export const TableOverall = ({
  campaignTitle,
  data,
  decimalPlaces,
  columnToggles,
}: Props) => {
  const [sortAsc, setSortAsc] = React.useState(true);
  const [sortedData, setSortedData] = React.useState(data);
  const [sortingColumn, setSortingColumn] = React.useState(
    SortingColumn.CONVERSIONRATE
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [offset, setOffset] = React.useState(0);

  const featureflags = useFeatureFlags();
  const indexAllowed =
    featureflags.isReady &&
    featureflags.flags['MEASUREMENT_OPTIMIZATIONS_ANY_TOUCH'];
  const roasCS = useUiFlag('ui_optimizations_roas_coming_soon');
  const roasFF = useUiFlag('ui_optimizations_roas');
  const volumeFF = useUiFlag('ui_optimizations_volume');
  const allowRoas = roasFF.isReady && roasFF.enabled;
  const allowVolume = volumeFF.isReady && volumeFF.enabled;
  const showRoasCS = roasCS.isReady && roasCS.enabled;
  const footerColSPan =
    (columnToggles.CVR ? 1 : 0) +
    (columnToggles.impVol ? 1 : 0) +
    (allowRoas || showRoasCS ? 1 : 0) +
    (allowVolume ? 1 : 0);

  const handleSort = (
    fieldAccess: keyof DealConversion,
    sortCol: SortingColumn
  ) => {
    const newSort = sortedData?.sort((a: any, b: any) => {
      const aVal = a[fieldAccess]?.value || 0;
      const bVal = b[fieldAccess]?.value || 0;
      return sortAsc ? aVal - bVal : bVal - aVal;
    });
    if (sortCol !== sortingColumn) {
      setSortingColumn(sortCol);
      newSort?.reverse();
    } else {
      setSortAsc(!sortAsc);
    }
    setSortedData(newSort);
  };

  let maxIndex;
  if (indexAllowed) {
    maxIndex = data
      ? data.reduce((acc, curr) => {
          const totalIndex = curr.dealIndex?.value || 0;
          return totalIndex > acc ? totalIndex : acc;
        }, 0)
      : 100;
  } else {
    maxIndex = data
      ? data?.reduce((acc, curr) => {
          const totalConversionRate = curr.dealConversionRate?.value || 0;
          return totalConversionRate > acc ? totalConversionRate : acc;
        }, 0)
      : 100;
  }

  const maxIndexVolume = data
    ? data.reduce((acc, curr) => {
        const totalVolumeValue = curr?.dealImpressionVolume?.value ?? 0;
        return totalVolumeValue > acc ? totalVolumeValue : acc;
      }, 0)
    : 100;

  const displayData = sortedData?.slice(
    offset * rowsPerPage,
    offset * rowsPerPage + rowsPerPage
  );

  const rowsExportData = data?.map((row, idx) => [
    idx + 1,
    row.dealName,
    row.dealConversionRate.title,
    row.dealImpressionVolume?.title,
  ]);

  const exportData = [
    [`Campaign Name: ${campaignTitle}`],
    [`Table Name: Conversion rate by deal`],
    [`Date: ${new Date()}`],
    [''],
    ['Rank', 'Deal name', 'Conversion rate', 'Impression Volume'],
    ...(rowsExportData as []),
  ];

  const menu = [
    <MenuItemExport
      data={exportData}
      filename={`${removeCharsAndSpaces(
        campaignTitle
      )}_conversion-rate-by-deal_${new Date()}`}
      key="export"
    />,
  ];

  return (
    <Card menu={menu}>
      <Styled.TableHeader>Conversion rate by deal</Styled.TableHeader>

      <Styled.Table>
        <thead>
          <tr>
            <Styled.HeaderFull>Deal name</Styled.HeaderFull>
            {columnToggles.CVR && (
              <Styled.HeaderCVR
                onClick={() => {
                  handleSort(
                    indexAllowed ? 'dealIndex' : 'dealConversionRate',
                    SortingColumn.CONVERSIONRATE
                  );
                }}
              >
                Conversion rate {indexAllowed && 'index'}
                {sortingColumn === SortingColumn.CONVERSIONRATE && (
                  <Styled.RankArrow weight="bold" $sortAsc={sortAsc} />
                )}
              </Styled.HeaderCVR>
            )}
            {allowVolume && columnToggles.impVol && (
              <Styled.HeaderCVR
                onClick={() => {
                  handleSort(
                    'dealImpressionVolume',
                    SortingColumn.IMPRESSIONVOLUME
                  );
                }}
              >
                Impression volume{' '}
                {sortingColumn === SortingColumn.IMPRESSIONVOLUME && (
                  <Styled.RankArrow weight="bold" $sortAsc={sortAsc} />
                )}
              </Styled.HeaderCVR>
            )}
            {columnToggles.ROAS && (allowRoas || showRoasCS) && (
              <Styled.HeaderROAS>
                ROAS
                {showRoasCS && (
                  <Tooltip
                    title={
                      <div>
                        <h3>Action required to view</h3>
                        <div>
                          Send us your media spend data & we’ll populate this
                          column
                        </div>
                      </div>
                    }
                    placement="top"
                    arrow
                  >
                    <span>
                      <ComingSoonBadge />
                    </span>
                  </Tooltip>
                )}
              </Styled.HeaderROAS>
            )}
          </tr>
        </thead>

        <tbody>
          {displayData?.map((row, idx) => {
            let rowValue;
            if (indexAllowed) {
              rowValue = ((row.dealIndex?.value || 0) / maxIndex).toFixed(
                decimalPlaces
              );
            } else {
              rowValue = (
                (row.dealConversionRate?.value || 0) / maxIndex
              ).toFixed(decimalPlaces);
            }
            const rowValueNumber = Number(rowValue);
            const rowVolumeNumber = Number(
              (row?.dealImpressionVolume?.value || 0) / maxIndexVolume
            );

            return (
              <tr key={idx}>
                <td>{row.dealName}</td>
                {columnToggles.CVR && (
                  <td>
                    <Styled.CellRate>
                      <DataCellHeatMap
                        indexValue={rowValueNumber}
                        text={
                          (indexAllowed
                            ? (row.dealIndex?.value * 100).toFixed(
                                decimalPlaces
                              )
                            : (row.dealConversionRate?.value * 100).toFixed(
                                decimalPlaces
                              )) + '%'
                        }
                      />
                    </Styled.CellRate>
                  </td>
                )}
                {allowVolume && columnToggles.impVol && (
                  <td>
                    <Styled.CellRate>
                      {row?.dealImpressionVolume?.value ? (
                        <DataCellHeatMap
                          text={`${(
                            row?.dealImpressionVolume?.value * 100
                          ).toFixed(decimalPlaces)}%`}
                          indexValue={rowVolumeNumber}
                        />
                      ) : (
                        '--'
                      )}
                    </Styled.CellRate>
                  </td>
                )}
                {columnToggles.ROAS && (allowRoas || showRoasCS) && (
                  <td>
                    <Styled.CellRate>
                      {allowRoas && row?.dealRoas?.title
                        ? row.dealRoas.title
                        : '--'}
                    </Styled.CellRate>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>

        {data?.length && data?.length > 5 && (
          <tfoot>
            <td colSpan={footerColSPan}>
              <Pagination
                total={data?.length ?? 0}
                setRowsPerPage={setRowsPerPage}
                rowsPerPage={rowsPerPage}
                setOffset={setOffset}
                offset={offset}
              />
            </td>
          </tfoot>
        )}
      </Styled.Table>
    </Card>
  );
};

export default TableOverall;
