import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

export const Slider = styled.div<{ $activeSlide: number; children?: any }>`
  display: flex;
  margin: -${theme.spacing.lg};
  transform: ${({ $activeSlide }) =>
    `translateX(calc(${$activeSlide} * -25%))`};
  transition: transform 0.33s ease-out;
  width: calc(400% + (${theme.spacing.lg} * 8));
`;
export const Slide = styled.div`
  padding: ${theme.spacing.lg};
  width: 25%;
`;

export const TabHeader = styled.div`
  ${theme.typography.headingMD};
`;

export const ColHeader = styled.div`
  ${theme.typography.label};
  color: ${theme.colors.textSecondary};
  flex: 2;
  font-size: 14px;

  @media ${theme.mq.tabletPortraitUp} {
    font-size: 16px;

    &:nth-child(2) {
      flex: 4;
    }
  }
`;

export const RowCell = styled(ColHeader)`
  ${theme.typography.headingXS};
  align-items: center;
  display: flex;
  font-size: 16px;
  gap: ${theme.spacing.xs};

  @media ${theme.mq.tabletPortraitUp} {
    font-size: 18px;
  }
`;

export const CategoryIcon = styled.div`
  height: 18px;
  width: 18px;

  @media ${theme.mq.tabletPortraitUp} {
    height: 24px;
    width: 24px;
  }

  svg {
    height: 18px;
    width: 18px;

    @media ${theme.mq.tabletPortraitUp} {
      height: 24px;
      width: 24px;
    }

    line,
    path {
      stroke: ${theme.colors.textTertiary};
    }
  }
`;

export const RowCellMerchant = styled(RowCell)`
  ${theme.typography.bodyMD};
`;

export const MerchantImage = styled.div`
  background: ${theme.colors.backgroundLight};
  display: block;
  height: 18px;
  width: 18px;

  @media ${theme.mq.tabletPortraitUp} {
    height: 24px;
    width: 24px;
  }
`;

export const Negative = styled.div`
  margin: -${theme.spacing.lg};
  margin-inline-start: -${theme.spacing.xs};
`;

export const IndexWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  gap: ${theme.spacing.xl};
  padding: ${theme.spacing.lg} ${theme.spacing.lg} ${theme.spacing.lg} 0;
`;

export const IndexWrapperChild = styled(IndexWrapper)`
  margin-inline: 40px ${theme.spacing.lg};
  padding-inline-end: 0;

  &:first-child {
    border-top: 1px solid ${theme.colors.borderLight};
    padding-block-end: 0;
  }
`;

export const Grow = styled.div`
  flex-grow: 1;
`;

export const IndexGeneral = styled.div`
  ${theme.typography.headingXS};
  font-size: 16px;
  margin-top: ${theme.spacing.xxs};

  @media ${theme.mq.tabletPortraitUp} {
    font-size: 18px;
  }
`;

export const IndexSlider = styled.div`
  margin-top: ${theme.spacing.xxs};
`;

export const IndexDetails = styled.div`
  ${theme.typography.bodySM};
  color: ${theme.colors.textTertiary};
  margin-top: ${theme.spacing.xxs};

  @media ${theme.mq.tabletPortraitUp} {
    ${theme.typography.bodyMD};
  }
`;

export const Arrow = styled.span<{ $isPositive: boolean }>`
  ${theme.typography.bodyMD};
  align-items: center;
  color: ${({ $isPositive }) =>
    $isPositive
      ? theme.colors.successForeground
      : theme.colors.errorForeground};
  display: flex;
  gap: ${theme.spacing.xxs};
  white-space: nowrap;

  @media ${theme.mq.tabletPortraitUp} {
    ${theme.typography.bodyLG};
  }
`;

export const CategoryHeader = styled.div`
  ${theme.typography.headingMD};
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: ${theme.spacing.sm};
  padding-block-end: ${theme.spacing.md};
`;

export const SegmentHeader = styled.div`
  ${theme.typography.headingXS};
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing.sm} ${theme.spacing.md};
`;

export const TimePeriod = styled.span`
  ${theme.typography.bodySM};
  color: ${theme.colors.textTertiary};
  justify-self: flex-end;
`;

export const YoY = styled.div`
  display: flex;
  gap: ${theme.spacing.md};
`;

export const Text = styled.span`
  ${theme.typography.bodyMD};
  align-items: center;
  display: flex;
  gap: ${theme.spacing.xxs};
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
`;

export const Logo = styled.div`
  background: ${theme.colors.backgroundLight};
  border-radius: ${theme.spacing.xs};
  height: 24px;
  margin-inline-end: ${theme.spacing.xxs};
  overflow: hidden;
  width: 24px;

  img {
    height: 24px;
    width: 24px;
  }

  svg {
    margin: 3px;
  }
`;

export const BreakdownTitleCaret = styled.div<{
  $collapsed?: boolean;
  children?: any;
}>`
  cursor: pointer;
  height: 18px;
  transform: ${({ $collapsed }) => ($collapsed ? 'none' : 'rotate(90deg)')};
  transition: ${({ $collapsed }) =>
    $collapsed ? 'all 0.25s ease-out' : 'all 0.25s ease-in'};
`;

export const HighestIndexing = styled.td<{ children?: any; colSpan: number }>`
  && {
    ${theme.typography.label};
    background: ${theme.colors.backgroundLight};
    padding-block: ${theme.spacing.xs};
  }
`;
