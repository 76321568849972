import { gql } from '@apollo/client';

export const streamBrandInsightChatSubscription = gql`
  subscription StreamBrandInsightChat($input: StreamBrandInsightChatInput!) {
    streamBrandInsightChat(input: $input) {
      ... on StreamBrandInsightChatSuccess {
        context
        message
        messageId
        messageDone
      }
    }
  }
`;

export default streamBrandInsightChatSubscription;
