import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useCallback } from 'react';

export const useExport = () => {
  const exportDivToPdf = useCallback(
    async (divId, pdfFileName = 'sample.pdf') => {
      const element = document.getElementById(divId);

      if (!element) {
        console.error(`Element with id "${divId}" not found.`);
        return;
      }

      // Convert the element to a canvas using html2canvas
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL('image/png');

      const margin = 0.5; // Margin in inches
      const pdfWidth = 8.5; // Standard US letter width in inches

      // Calculate image width and height in inches
      const imgWidth = pdfWidth - 2 * margin; // Image width, accounting for margins
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Scale image height based on width

      // Convert the canvas height to inches and add margins
      const pdfHeight = imgHeight + 2 * margin;

      // Create a PDF with the exact height to fit the content
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'in', // Use inches for dimensions
        format: [pdfWidth, pdfHeight], // Custom size based on content and margin
      });

      // Add the image to the PDF with margins
      pdf.addImage(imgData, 'PNG', margin, margin, imgWidth, imgHeight);

      // Save the generated PDF
      pdf.save(pdfFileName);
    },
    []
  );

  const exportDivToImage = useCallback(
    async (divId, imageFileName = 'sample.png') => {
      const element = document.getElementById(divId);
      if (!element) {
        console.error(`Element with id "${divId}" not found.`);
        return;
      }

      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL('image/png');

      const link = document.createElement('a');
      link.href = imgData;
      link.download = imageFileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    []
  );

  return { exportDivToPdf, exportDivToImage };
};
