import { TextFieldProps, theme } from '@klover/attain-design-system';
import * as Styled from './TextFieldSearch.styles';

export const TextFieldSearch = ({ ...props }: TextFieldProps) => {
  delete props.InputProps?.startAdornment;

  return (
    <Styled.Wrapper
      {...props}
      InputProps={{
        ...props.InputProps,
        startAdornment: (
          <Styled.SearchIcon weight="bold" size={theme.icons.lg} />
        ),
      }}
    />
  );
};

export default TextFieldSearch;
