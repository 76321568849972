import { gql } from '@apollo/client';

export const uiFlagsQuery = gql`
  query UiFlags($input: UiBooleanFeatureFlagsInput!) {
    me {
      uiBooleanFeatureFlags(input: $input) {
        boolean
      }
    }
  }
`;
