import styled from 'styled-components';
import { theme } from '@klover/attain-design-system';

export const DataCell = styled.div<{ $value: number }>`
  ${theme.typography.bodyLG}
  background-color: ${({ $value }) =>
    `hsl(252, ${30 - $value * 5}%, ${98 - $value * 26}%)`};
  border-radius: 8px;
  width: 120px;
  text-align: center;
  padding: ${theme.spacing.sm} ${theme.spacing.lg};
`;
