import { gql } from '@apollo/client';

export const measurementNewBuyerLiftQuery = gql`
  query MeasurementNewBuyerLift($input: MeasurementNewBuyerLiftInput!) {
    measurementNewBuyerLift(input: $input) {
      ... on MeasurementNewBuyerLiftSuccess {
        id
        metrics {
          penetraion {
            value
            title
          }
          penetrationLift {
            value
            title
          }
          roas {
            value
            title
          }
          salesDriven {
            value
            title
          }
          cpa {
            value
            title
          }
          avgTransactionAmount {
            value
            title
          }
          projectedNewBuyers {
            value
            title
          }
        }
        impressions {
          heroMetric {
            value
            title
          }
          time
          daily
          dailyAverage {
            value
            title
          }
          cumulative
        }
        reach {
          heroMetric {
            value
            title
          }
          time
          daily
          dailyAverage {
            value
            title
          }
          cumulative
        }
        dateRange {
          startDate
          endDate
        }
      }
      ... on MeasurementNewBuyerLiftError {
        message
      }
    }
  }
`;

export default measurementNewBuyerLiftQuery;
