import { gql } from '@apollo/client';

export const measurementWeekOverWeekQuery = gql`
  query MeasurementWeekOverWeek($input: MeasurementWeekOverWeekInput!) {
    measurementWeekOverWeek(input: $input) {
      ... on MeasurementWeekOverWeekSuccess {
        id
        metrics {
          conversionRate {
            value
            title
          }
          roas {
            value
            title
          }
          projectedConversions {
            value
            title
          }
          salesDriven {
            value
            title
          }
          cpm {
            value
            title
          }
          cpa {
            value
            title
          }
          frequency {
            value
            title
          }
          newBuyerPenetration {
            value
            title
          }
          newBuyerPenetrationLift {
            value
            title
          }
          projectedNewBuyers {
            value
            title
          }
          newBuyerRoas {
            value
            title
          }
          newBuyerSalesDriven {
            value
            title
          }
          newBuyerCpa {
            value
            title
          }
          newBuyerAvgTransactionAmount {
            value
            title
          }
          avgTotalSpend {
            value
            title
          }
          avgTransactionAmount {
            value
            title
          }
          avgTransactionFrequency {
            value
            title
          }
          costPerTransaction {
            value
            title
          }
          avgImpressionFrequency {
            value
            title
          }
        }
      }
      ... on MeasurementWeekOverWeekError {
        message
      }
    }
  }
`;

export default measurementWeekOverWeekQuery;
