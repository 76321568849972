import { Card, TextField } from '@klover/attain-design-system';
import { useEffect, useState } from 'react';
import * as Styled from './PostPeriodSlider.styles';
interface Props {
  max: number;
  value: number;
  onChange: (value: number) => void;
  loading: boolean;
}

interface Label {
  value: number;
  label: string;
}

export const PostPeriodSelect = ({ max, value, loading, onChange }: Props) => {
  const marks: Label[] = [];

  if (max > 7) {
    marks.push({ value: 7, label: '7 d' });
  }
  if (max > 14) {
    marks.push({ value: 14, label: '14 d' });
  }
  if (max > 30) {
    marks.push({ value: 30, label: '30 d' });
  }
  if (max > 45) {
    marks.push({ value: 45, label: '45 d' });
  }

  marks.push({ value: max, label: `${max} d` });

  const [showSlider, setShowSlider] = useState(false);
  const [sliderValue, setSliderValue] = useState(value);
  const [bounce, setBounce] = useState<NodeJS.Timeout | null>();

  useEffect(() => {
    if (bounce) clearTimeout(bounce);
    setBounce(
      setTimeout(() => {
        onChange(sliderValue);
      }, 1000)
    );
  }, [sliderValue]);

  return (
    <>
      <Styled.Wrapper>
        <TextField
          onClick={(e) => {
            e.preventDefault;
            setShowSlider((prev) => !prev);
          }}
          value={sliderValue + ' days'}
          InputProps={{
            readOnly: true,
          }}
        />
      </Styled.Wrapper>
      <Styled.SliderWrapper visible={showSlider}>
        <Card>
          <Styled.Title>Adjust post period</Styled.Title>
          <Styled.SliderPostion>
            <Styled.StyledSlider
              disabled={loading}
              value={sliderValue}
              aria-label="post period"
              defaultValue={30}
              getAriaValueText={(value) => `${value} days`}
              step={1}
              valueLabelDisplay="off"
              marks={marks}
              track={false}
              max={max}
              onChange={(_, value) => {
                setSliderValue(value as number);
              }}
              onBlur={() => {
                setShowSlider(false);
              }}
            />
          </Styled.SliderPostion>
        </Card>
      </Styled.SliderWrapper>
    </>
  );
};

export default PostPeriodSelect;
