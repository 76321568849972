import GhostLoader from 'react-ghost-loader';
import measurementAudienceInsightsHeroMetricsQuery from 'operations/queries/measurementAudienceInsightsHeroMetrics.query';
import useSearchParams from 'hooks/useSearchParams';
import { CampaignInsightCard } from './CampaignInsightCard';
import { Card, CardTypes, Grid, theme } from '@klover/attain-design-system';
import {
  DEFAULT_MEASUREMENT_ATTRIBUTION_WINDOW_ID,
  DEFAULT_MEASUREMENT_NEW_CUSTOMER_WINDOW_ID,
} from 'utils/Measurement/measurementPaths';
import {
  MeasurementAudienceInsightsHeroMetricsQuery,
  MeasurementAudienceInsightsHeroMetricsQueryVariables,
  MeasurementOverviewCustomerType,
} from 'types/graphql/generated';
import { MeasurementSearchParams } from 'views/Measurement/MeasurementInsightsDashboard';
import { StatCardLoading } from 'components/StatCard/StatCard';
import { useQuery } from '@apollo/client';
import * as Styled from '../../Measurement.styles';

export const AudienceInsights = () => {
  const [searchParams] = useSearchParams<MeasurementSearchParams>();
  const { data, loading } = useQuery<
    MeasurementAudienceInsightsHeroMetricsQuery,
    MeasurementAudienceInsightsHeroMetricsQueryVariables
  >(measurementAudienceInsightsHeroMetricsQuery, {
    variables: {
      input: {
        campaignId: searchParams.campaignId ?? '',
        attributionWindow:
          searchParams.attributionwindow ??
          DEFAULT_MEASUREMENT_ATTRIBUTION_WINDOW_ID,
        dealIds: searchParams.deals ?? [],
        newCustomersWindow: DEFAULT_MEASUREMENT_NEW_CUSTOMER_WINDOW_ID,
      },
    },
  });

  const heroMetrics =
    data?.measurementNewBuyerLift.__typename ===
    'MeasurementNewBuyerLiftSuccess'
      ? data.measurementNewBuyerLift.metrics
      : undefined;

  if (loading) {
    return (
      <Card type={CardTypes.Subtle}>
        <GhostLoader height="35px" heightRandom={0} width="30%" />
        <Grid
          container
          columnSpacing={theme.spacing.lg}
          rowSpacing={theme.spacing.lg}
          marginBlockStart={0}
        >
          <Grid item xs={12} lg={4}>
            <StatCardLoading />
          </Grid>

          <Grid item xs={12} lg={4}>
            <StatCardLoading />
          </Grid>

          <Grid item xs={12} lg={4}>
            <StatCardLoading />
          </Grid>
        </Grid>
      </Card>
    );
  }

  return (
    <Card type={CardTypes.Subtle}>
      <Styled.CardTitle>Audience insights</Styled.CardTitle>

      <Grid
        container
        columnSpacing={theme.spacing.lg}
        rowSpacing={theme.spacing.lg}
        marginBlockStart={0}
      >
        <Grid item xs={12} lg={4}>
          <CampaignInsightCard
            title="All Converters"
            description="All people who both interacted with an ad in this campaign and also converted"
            metric={heroMetrics?.totalConverters?.title ?? 'N/A'}
            customerType={MeasurementOverviewCustomerType.ALL_CUSTOMERS}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <CampaignInsightCard
            title="Net-New Converters"
            description="People who interacted with an ad in this campaign and converted for the first time in the last 12 months"
            metric={heroMetrics?.netNewConverters?.title ?? 'N/A'}
            customerType={MeasurementOverviewCustomerType.NET_NEW_CUSTOMERS}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <CampaignInsightCard
            title="Recurring Converters"
            description="People who interacted with an ad in this campaign, converted, and had at least one other transaction in the last 12 months"
            metric={heroMetrics?.recurringBuyers?.title ?? 'N/A'}
            customerType={MeasurementOverviewCustomerType.RECURRING_CUSTOMERS}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
