import styled from 'styled-components';
import { MenuItem } from '@klover/attain-design-system';

export const Row = styled(MenuItem)`
  span {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
`;

export const RowTitle = styled.div`
  flex-grow: 1;
`;
