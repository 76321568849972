import StatCard, { StatCardLoading } from 'components/StatCard/StatCard';
import measurementInsightsOptimalFrequencyQuery from 'operations/queries/measurementInsightsOptimalFrequency';
import { Alert } from '@klover/attain-design-system';
import {
  MeasurementInsightsOptimalFrequencyQuery,
  MeasurementInsightsOptimalFrequencyQueryVariables,
} from 'types/graphql/generated';
import { ResponsiveBar } from '@nivo/bar';
import { useQuery } from '@apollo/client';
import * as Styled from './OptimalFrequency.styles';

export const OptimalFrequency = ({
  campaignId,
  attributionWindow,
  startDate,
  dealIds,
  endDate,
  newCustomerWindow,
}) => {
  const { data, loading } = useQuery<
    MeasurementInsightsOptimalFrequencyQuery,
    MeasurementInsightsOptimalFrequencyQueryVariables
  >(measurementInsightsOptimalFrequencyQuery, {
    variables: {
      input: {
        campaignId: campaignId,
        dealIds: dealIds,
        attributionWindow: attributionWindow,
        dateRange: {
          startDate: startDate,
          endDate: endDate,
        },
        newCustomersWindow: newCustomerWindow,
      },
    },
  });

  if (loading) {
    return <StatCardLoading />;
  }

  const formattedData =
    data?.measurementInsightsOptimalFrequency.__typename ===
    'MeasurementInsightsOptimalFrequencySuccess'
      ? data.measurementInsightsOptimalFrequency.metrics?.map((row) => ({
          x: row.ntile.title,
          y: row.conversionRate.value * 100,
        }))
      : null;

  const maxYEntry =
    formattedData &&
    formattedData.reduce(
      (max, entry) => (entry.y > max.y ? entry : max),
      formattedData[0]
    );
  const maxXValue = maxYEntry && maxYEntry.x;

  return (
    <StatCard
      title="Optimal Frequency"
      detail={maxYEntry && maxYEntry.y > 0 ? maxXValue : null}
    >
      {formattedData &&
      formattedData.length > 1 &&
      maxYEntry &&
      maxYEntry.y > 0 ? (
        <Styled.ChartWrapper>
          <Styled.YTitle>Conversion rate</Styled.YTitle>
          <Styled.XTitle>Impression frequency</Styled.XTitle>

          <ResponsiveBar
            data={formattedData}
            keys={['y']}
            indexBy="x"
            colors={['#67579E']}
            margin={{ top: 31, right: 0, bottom: 48, left: 36 }}
            padding={0.5}
            label={() => ''}
            tooltip={(props) => (
              <Styled.ChartTooltip>
                {props.indexValue} - {props.value.toFixed(2)}%
              </Styled.ChartTooltip>
            )}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 8,
              format: (value) => `${value}`,
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 8,
              format: (value) => `${value}%`,
            }}
          />
        </Styled.ChartWrapper>
      ) : (
        <Alert severity="info">Not enough data for this chart</Alert>
      )}
    </StatCard>
  );
};

export default OptimalFrequency;
