import styled from 'styled-components';
import { theme } from '@klover/attain-design-system';

export const Wrapper = styled.div`
  // padding-block: ${theme.spacing.lg};
  div {
    display: flex;
    border-block-end: 1px solid ${theme.colors.borderDark};
    padding: ${theme.spacing.xs} ${theme.spacing.lg};
    margin-inline-start: -${theme.spacing.lg};
    width: calc(100% + ${theme.spacing.lg} * 2);
  }
`;
export const HeadRow = styled.div`
  background-color: ${theme.colors.backgroundLight};
  color: ${theme.colors.textSecondary};
`;

export const GraphWrapper = styled.div`
  height: 400px;
  margin-block-start: ${theme.spacing.lg};
`;
