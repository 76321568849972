import IndexScore from '../IndexScore/IndexScore';
import MetricBar100 from 'components/MetricBar100/MetricBar100';
import React from 'react';
import YearOverYear from '../YearOverYear/YearOverYear';
import { CaretRight, Image } from '@phosphor-icons/react';
import {
  SmartPersonaPfm1CategoryBreakdown,
  SmartPersonaPfm2CategoryBreakdown,
  SmartPersonaPfm2MerchantBreakdown,
} from 'types/graphql/generated';
import { theme } from '@klover/attain-design-system';
import * as Styled from './BreakdownRow.styles';
import * as StyledCommon from '../Tab.styles';

interface Props {
  row:
    | SmartPersonaPfm1CategoryBreakdown
    | SmartPersonaPfm2CategoryBreakdown
    | SmartPersonaPfm2MerchantBreakdown;
  drillDown: () => any;
}

const BreakdownRow = ({ row, drillDown }: Props) => {
  const [collapsed, setCollapsed] = React.useState(true);

  const isMerchant = row.__typename === 'SmartPersonaPfm2MerchantBreakdown';

  return (
    <>
      <tr>
        <td>
          <Styled.BreakdownTitle
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            <StyledCommon.BreakdownTitleCaret $collapsed={collapsed}>
              <CaretRight size={theme.icons.md} />
            </StyledCommon.BreakdownTitleCaret>

            {isMerchant && (
              <StyledCommon.Logo>
                {row.entity && row.entity.logo ? (
                  <img src={row.entity.logo} alt="" />
                ) : (
                  <Image size={theme.icons.md} />
                )}
              </StyledCommon.Logo>
            )}

            <Styled.BreakdownTitleName title={row.entity.title}>
              {row.entity.title}
            </Styled.BreakdownTitleName>
          </Styled.BreakdownTitle>
        </td>

        <td>
          <IndexScore index={row.index} />
        </td>

        <td>
          <MetricBar100 index={row.audiencePercentage.value as number} />
        </td>

        <Styled.FullBreakdown
          onClick={() => {
            drillDown();
          }}
        >
          <Styled.VertCenter>
            <CaretRight size={theme.icons.md} />
          </Styled.VertCenter>
        </Styled.FullBreakdown>
      </tr>

      <tr>
        <Styled.BreakdownCell colSpan={4} $collapsed={collapsed}>
          <Styled.Wrapper $collapsed={collapsed}>
            <StyledCommon.YoY>
              <YearOverYear
                internal
                title="Avg Total Spend"
                amount={row.averageSpend}
              />
              <YearOverYear
                internal
                title="Avg Spend/Transaction"
                amount={row.averageSpendPerTransaction}
              />
              <YearOverYear
                internal
                title="Avg Frequency"
                amount={row.averageFrequency}
              />
            </StyledCommon.YoY>
          </Styled.Wrapper>
        </Styled.BreakdownCell>
      </tr>
    </>
  );
};

export default BreakdownRow;
