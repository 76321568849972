import React, { useState } from 'react';
import submitMessageReviewMutation from 'operations/mutations/submitMessageReview.mutation';
import {
  Copy,
  PaperPlaneRight,
  PencilSimple,
  ThumbsDown,
  ThumbsUp,
} from '@phosphor-icons/react';
import { TextField, Tooltip, theme } from '@klover/attain-design-system';
import { useMutation } from '@apollo/client';
import * as Styled from './OutcomeAiMessageActions.styles';

export const OutcomeAiMessageActions = ({ message, onMessageReviewed }) => {
  const CLICK_FEEDBACK_MESSAGE = {
    SUCCESS: { feedback: 'Thank you for the feedback!', primaryColor: true },
    MORE: { feedback: 'Tell me more so I can learn!', primaryColor: false },
    DEFAULT: { feedback: '', primaryColor: false },
  };

  const [copied, setCopied] = useState(false);
  const [showReviewButtons, setShowReviewButtons] = useState(!message.rated);
  const [showEditButton, setShowEditButton] = useState(message.rated);
  const [showFeedbackInput, setShowFeedbackInput] = useState(false);
  const [loading, setLoading] = useState(false);

  const [messageActionFeedback, setMessageActionFeedback] = React.useState(
    message.rated
      ? CLICK_FEEDBACK_MESSAGE.SUCCESS
      : CLICK_FEEDBACK_MESSAGE.DEFAULT
  );

  const [feedbackInput, setFeedbackInput] = useState(
    message.feedbackText || ''
  );

  const [submitMessageReview] = useMutation(submitMessageReviewMutation);

  const handleCopyButtonClick = (content) => {
    setCopied(true);
    navigator.clipboard.writeText(content);
    setTimeout(() => {
      setCopied(false);
    }, 1200);
  };

  const handleReviewButtonClick = (message, rating) => {
    onMessageReviewed(message, rating);
    setShowFeedbackInput(true);
    setMessageActionFeedback(CLICK_FEEDBACK_MESSAGE.MORE);
    submitMessageReviewApi(rating);
  };

  const submitMessageReviewApi = (rating?: boolean, feedbackInput?: string) => {
    setLoading(true);
    return submitMessageReview({
      variables: {
        input: {
          messageId: message.id,
          rating,
          feedback: feedbackInput || '',
        },
      },
    })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleFeedbackFormSubmit = (e) => {
    e.preventDefault();
    onMessageReviewed(message, message.rating, feedbackInput.trim());
    submitMessageReviewApi(message.rating, feedbackInput).then(() => {
      setShowFeedbackInput(false);
      setShowReviewButtons(false);
      setShowEditButton(true);
      setMessageActionFeedback(CLICK_FEEDBACK_MESSAGE.SUCCESS);
    });
  };

  const handleEditClick = () => {
    setShowReviewButtons(true);
    setShowEditButton(false);
    setMessageActionFeedback(CLICK_FEEDBACK_MESSAGE.MORE);
    setShowFeedbackInput(true);
    setFeedbackInput(message.feedbackText || '');
  };

  const handleFeedbackInputChange = (e) => {
    setFeedbackInput(e.target.value);
  };

  return (
    <Styled.MessageActions>
      <Styled.MessageActionButtons>
        <Tooltip title={copied ? 'Copied!' : 'Copy'} placement="bottom" arrow>
          <Styled.MessageReviewButton
            onClick={() => handleCopyButtonClick(message.content)}
          >
            <Copy size={theme.icons.md} color={theme.colors.textTertiary} />
          </Styled.MessageReviewButton>
        </Tooltip>
        {showReviewButtons && (
          <>
            <Tooltip title={'Helpful'} placement="bottom" arrow>
              <Styled.MessageReviewButton
                $selected={message.rating === true}
                onClick={() => handleReviewButtonClick(message, true)}
              >
                <ThumbsUp
                  size={theme.icons.md}
                  color={
                    message.rating === true
                      ? theme.colors.CHART_PRIMARY
                      : theme.colors.textTertiary
                  }
                />
              </Styled.MessageReviewButton>
            </Tooltip>
            <Tooltip title={'Not helpful'} placement="bottom" arrow>
              <Styled.MessageReviewButton
                $selected={message.rating === false}
                onClick={() => handleReviewButtonClick(message, false)}
              >
                <ThumbsDown
                  size={theme.icons.md}
                  color={
                    message.rating === false
                      ? theme.colors.CHART_PRIMARY
                      : theme.colors.textTertiary
                  }
                />
              </Styled.MessageReviewButton>
            </Tooltip>
          </>
        )}
        {showEditButton && (
          <Tooltip title={'Edit feedback'} placement="bottom" arrow>
            <Styled.MessageReviewButton
              $selected={showEditButton}
              onClick={() => handleEditClick()}
            >
              <PencilSimple
                size={theme.icons.md}
                color={theme.colors.textTertiary}
              />
            </Styled.MessageReviewButton>
          </Tooltip>
        )}
        {!!messageActionFeedback.feedback.length && (
          <Styled.ActionFeedback $primary={messageActionFeedback.primaryColor}>
            {messageActionFeedback.feedback}
          </Styled.ActionFeedback>
        )}
      </Styled.MessageActionButtons>
      {showFeedbackInput && (
        <Styled.FeedbackForm onSubmit={handleFeedbackFormSubmit}>
          <TextField
            fullWidth
            disabled={loading}
            placeholder={`Enter feedback...`}
            value={feedbackInput}
            InputProps={{
              endAdornment: (
                <Styled.FeedbackButtonWrapper position="end">
                  <Styled.FeedbackButton
                    $loading={loading}
                    $disabled={loading || feedbackInput.trim().length < 1}
                    onClick={handleFeedbackFormSubmit}
                  >
                    <PaperPlaneRight />
                  </Styled.FeedbackButton>
                </Styled.FeedbackButtonWrapper>
              ),
            }}
            onChange={handleFeedbackInputChange}
          />
        </Styled.FeedbackForm>
      )}
    </Styled.MessageActions>
  );
};

export default OutcomeAiMessageActions;
