import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { theme } from '@klover/attain-design-system';

export const Wrapper = styled.div<{ $open: boolean; children: any }>`
  background: #4b4173;
  color: ${theme.colors.textInverted};
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  opacity: ${({ $open }) => ($open ? '1' : '0')};
  transform: ${({ $open }) => ($open ? 'none' : 'translateX(-100%)')};
  transition: opacity 0.25s ease, transform 0.25s ease;
  z-index: 9999999;

  @media ${theme.mq.tabletPortraitUp} {
    opacity: 1;
    overflow: hidden;
    overscroll-behavior-y: none;
    transform: none;
    transition: none;
    z-index: 0;
  }
`;

export const List = styled.ul`
  && {
    margin: ${theme.spacing.md} ${theme.spacing.sm};
  }
`;

export const ListLink = styled(NavLink)`
  ${theme.typography.bodyMD}
  align-items: center;
  border-radius: ${theme.spacing.xxs};
  color: ${theme.colors.textInverted};
  cursor: pointer;
  display: flex;
  gap: ${theme.spacing.sm};
  padding: ${theme.spacing.sm};
  text-decoration: none;

  &:hover {
    background: ${theme.colors.buttonBackgroundHover};
  }

  &.active {
    background: ${theme.colors.buttonBackgroundActive};
    cursor: default;
  }
`;

export const Label = styled.div`
  flex-grow: 1;
`;

export const SubList = styled.ul`
  && {
    background: ${theme.colors.buttonBackgroundActive};
    border-radius: 4px;
    margin-block: -12px 0;
    padding: 12px;
  }
`;

export const SubListLink = styled(ListLink)`
  &.active {
    background: #67579e;
  }
`;
