import { ResponsiveBar } from '@nivo/bar';
import { attainColorPalette } from 'constants/attainColorPalette';
import { chartCommonProps } from '../MeasurementBrandSurvey';
import * as Styled from '../MeasurementBrandSurvey.styles';

interface Props {
  chart: any;
  type: 'absolute' | 'relative' | 'headroom';
  colors?: string[];
  maxValue?: 'auto' | number;
  layout?: 'vertical' | 'horizontal';
  height?: number;
}

export const ChartAbsoluteLift = ({
  chart,
  type,
  colors,
  maxValue = 100,
  layout = 'vertical',
  height = 400,
}: Props) => {
  const isRelative = type === 'relative';
  const minValue = chart.values.reduce(
    (min, obj) => (obj.value < min ? obj.value : min),
    chart.values[0].value
  );

  const formattedData = chart.values.map((row, i) => ({
    answer: row.name,
    color: colors
      ? colors[i + 1]
      : i === 0
      ? attainColorPalette[2]
      : attainColorPalette[8],
    confidenceInterval: row.confidenceInterval
      ? (row.confidenceInterval[1] - row.confidenceInterval[0]) * 100
      : 0,
    empty: (!isRelative || minValue === row.value
      ? 0
      : (row.value - (row.value - minValue)) * 100
    ).toFixed(2),
    lift: (!isRelative || minValue === row.value
      ? row.value * 100
      : (row.value - minValue) * 100
    ).toFixed(2),
  }));

  const formattedDataHeadroom = chart.values.map((row, i) => ({
    answer: row.name,
    lift: 100 - row.value * 100,
    empty: 0,
    color: i === 0 ? attainColorPalette[2] : attainColorPalette[8],
  }));

  const total = formattedData.length;

  return (
    <>
      {type === 'absolute' && (
        <Styled.ChartWrapper $height={height}>
          <ResponsiveBar
            {...chartCommonProps}
            maxValue={maxValue}
            layout={layout}
            data={formattedData}
            keys={['lift']}
            tooltip={({ value, indexValue, index }) => (
              <Styled.Tooltip>
                <Styled.TooltipTitle>{indexValue}</Styled.TooltipTitle>
                <strong>
                  {index === 0 ? 'Baseline recall' : 'Absolute lift'}:
                </strong>{' '}
                {value.toFixed(2)}%
              </Styled.Tooltip>
            )}
          />
          <Styled.MoeLayer>
            {formattedData.map((row, i) => {
              const segments = total * 4 + 2;
              const increment = 100 / segments;
              const position = (i * 4 + 3) * increment;

              return (
                <Styled.Moe
                  key={i}
                  $position={position}
                  $lift={row.lift * (100 / Number(maxValue))}
                  $confidenceInterval={row.confidenceInterval}
                />
              );
            })}
          </Styled.MoeLayer>
        </Styled.ChartWrapper>
      )}

      {type === 'relative' && (
        <Styled.ChartWrapper $height={height}>
          <ResponsiveBar
            {...chartCommonProps}
            data={formattedData}
            keys={['empty', 'lift']}
            markers={[
              {
                axis: 'y',
                value: formattedData[0]?.lift || 0,
                lineStyle: {
                  stroke: attainColorPalette[0],
                  strokeWidth: 2,
                  strokeDasharray: '8, 8',
                },
              },
            ]}
            tooltip={({ value, indexValue, index }) => (
              <Styled.Tooltip>
                <Styled.TooltipTitle>{indexValue}</Styled.TooltipTitle>
                <strong>
                  {index === 0 ? 'Baseline recall' : 'Relative lift'}:
                </strong>{' '}
                {value.toFixed(2)}%
              </Styled.Tooltip>
            )}
          />
        </Styled.ChartWrapper>
      )}

      {type === 'headroom' && (
        <Styled.ChartWrapper $height={height}>
          <ResponsiveBar
            {...chartCommonProps}
            data={formattedDataHeadroom}
            keys={['lift']}
            tooltip={({ value, indexValue }) => (
              <Styled.Tooltip>
                <Styled.TooltipTitle>{indexValue}</Styled.TooltipTitle>
                <strong>Headroom lift:</strong> {value.toFixed(2)}%
              </Styled.Tooltip>
            )}
          />
        </Styled.ChartWrapper>
      )}
    </>
  );
};

export default ChartAbsoluteLift;
