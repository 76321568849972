import styled from 'styled-components';
import { theme } from '@klover/attain-design-system';

export const Wrapper = styled.div`
  ${theme.mixins.card};
  aspect-ratio: 1.85/1;
  position: relative;
  width: 100%;
`;

export const CardHeader = styled.header`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.xxs};
`;

export const CardTitle = styled.h3`
  ${theme.typography.headingSM};
  flex-grow: 1;
`;

export const CardDesc = styled.p`
  ${theme.typography.bodyLG};
  color: ${theme.colors.textTertiary};
  margin-block: ${theme.spacing.xxs} 0;
`;

export const AlignCenter = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.md};
`;

const chooseColor = (name) => {
  switch (name) {
    case 'South Atlantic':
      return theme.colors.CHART_4;
    case 'Middle Atlantic':
      return theme.colors.CHART_6;
    case 'East North Central':
      return theme.colors.CHART_TERTIARY;
    case 'West South Central':
      return theme.colors.CHART_4;
    case 'East South Central':
      return theme.colors.CHART_4;
    case 'New England':
      return theme.colors.CHART_6;
    case 'West North Central':
      return theme.colors.CHART_TERTIARY;
    default:
      return theme.colors.CHART_PRIMARY;
  }
};

export const Circle = styled.div<{ $name: string }>`
  background: ${({ $name }) => chooseColor($name)};
  border-radius: 50%;
  height: 12px;
  width: 12px;
`;
