import GhostLoader from 'react-ghost-loader';
import * as Styled from './tableLoader.styles';

export const GraphLoader = () => {
  return (
    <Styled.GraphWrapper>
      <GhostLoader
        height="100%"
        heightRandom={0}
        width="100%"
        widthRandom={0}
      />
    </Styled.GraphWrapper>
  );
};

export default GraphLoader;
