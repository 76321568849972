import paths from 'constants/paths';
import { Base64ID, base64ID, toBase64ID } from 'types/models';
import {
  CampaignBasic,
  MeasurementOverviewCustomerType,
  PartnerEnum,
} from 'types/graphql/generated';
// import { MeasurementSearchParams } from 'views/Measurement/MeasurementDashboard';
import { convertObjectToSearchString } from 'utils/convertObjectToSearchString';
import { generatePath } from 'react-router-dom';

//30D window id;
export const DEFAULT_MEASUREMENT_ATTRIBUTION_WINDOW_ID = base64ID(
  'eyIkIjoiTUlBVyIsIl8iOjMwfQ'
);

//12M window id;
export const DEFAULT_MEASUREMENT_NEW_CUSTOMER_WINDOW_ID = base64ID(
  'eyIkIjoiTUlOQ1ciLCJfIjoxMn0'
);

interface InitMeasurementPathParams {
  campaignId: Base64ID;
  deals?: string;
  attributionwindow?: Base64ID;
  customertype?: MeasurementOverviewCustomerType;
}

export const getMeasurementPath = (campaign: CampaignBasic) => {
  const isOpenX = campaign.partner === PartnerEnum.OPEN_X;
  const attributionWindow =
    toBase64ID(localStorage.getItem(`aw|${campaign.b64Id}`)) ??
    DEFAULT_MEASUREMENT_ATTRIBUTION_WINDOW_ID;

  const params: InitMeasurementPathParams = {
    // Like MeasurementSearchParams
    campaignId: campaign.b64Id,
    customertype: MeasurementOverviewCustomerType.ALL_CUSTOMERS,
    attributionwindow: attributionWindow,
  };

  if (isOpenX) {
    params.deals = encodeURIComponent(JSON.stringify(campaign.dealIds));
  }

  const paramsString = convertObjectToSearchString(params);
  const path = generatePath(
    paths.MEASUREMENT_DASHBOARD + paths.MEASUREMENT_DASHBOARD_OVERVIEW
  );
  return `${path}${paramsString}`;
};
