import TableLoader from '../MetricComponents/SkeletonLoaders/tableLoaders';
import brandLiftSurveyInsightsAudienceQuery from 'operations/queries/brandLiftSurveyInsightsAudience.query';
import { Base64ID } from 'types/models';
import {
  BlquestionType,
  BrandLiftBreakdown,
  BrandLiftSurveyInsightsAudienceQuery,
  BrandLiftSurveyInsightsAudienceQueryVariables,
  SurveyQuestionTemplate,
} from 'types/graphql/generated';
import { LiftTable } from '../MetricComponents/Lifts/LiftTable';
import { useQuery } from '@apollo/client';

interface Props {
  questionType: BlquestionType | null;
  campaignId: Base64ID;
  liftType: 'absoluteLift' | 'relativeLift';
  isBrand: boolean;
}

export const Audiences = ({
  questionType,
  campaignId,
  liftType,
  isBrand,
}: Props) => {
  const { data, loading } = useQuery<
    BrandLiftSurveyInsightsAudienceQuery,
    BrandLiftSurveyInsightsAudienceQueryVariables
  >(brandLiftSurveyInsightsAudienceQuery, {
    variables: {
      input: {
        campaignId: campaignId,
        questionType: isBrand
          ? ({ brand: questionType?.templateId } as SurveyQuestionTemplate)
          : ({ merchant: questionType?.templateId } as SurveyQuestionTemplate),
      },
    },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <TableLoader />;
  }

  const liftData = data?.brandLiftSurveyInsightsAudience;
  return (
    <LiftTable
      liftTables={liftData?.audiences as BrandLiftBreakdown[]} //TODO: remove cast once type is updated in apollo.
      liftType={liftType}
    />
  );
};
