import { UiFlagsQuery } from 'types/graphql/generated';
import { uiFlagsQuery } from 'operations/queries/uiFlags.query';
import { useQuery } from '@apollo/client';

const useUiFlag = (id: string) => {
  const { data, loading, error } = useQuery<UiFlagsQuery>(uiFlagsQuery, {
    fetchPolicy: 'cache-first',
    variables: {
      input: {
        ids: [id],
      },
    },
  });

  return {
    enabled: data?.me.uiBooleanFeatureFlags[0].boolean,
    isReady: !loading && !error,
    error,
  };
};

export default useUiFlag;
