import { ResponsiveBarCanvas } from '@nivo/bar';
import { SkewChartDatum } from './SkewsTable';
import { chartCommonProps } from '../Lifts/LiftTable';

interface Props {
  skewData: SkewChartDatum[] | null;
}

export const SkewsChart = ({ skewData }: Props) => {
  return (
    <ResponsiveBarCanvas
      {...chartCommonProps}
      isInteractive={false}
      enableLabel={false}
      enableGridX
      axisBottom={null}
      enableGridY={false}
      maxValue={25}
      minValue={-25}
      data={skewData || []}
      keys={['skew']}
      label={''}
      padding={0.6}
      layers={[
        'grid',
        'axes',
        (ctx, { bars, xScale }) => {
          bars.forEach((bar) => {
            const x = xScale(-100);
            const y = bar.y;
            const width = xScale(0) - xScale(-100);
            const height = bar.height;
            ctx.fillStyle = 'rgba(232, 48, 74, 0.05)'; // Equivalent to '#E8304A0D'
            ctx.fillRect(x, y, width, height);
          });
        },
        (ctx, { bars, xScale }) => {
          bars.forEach((bar) => {
            const xStart = xScale(0);
            const rectWidth = xScale(100) - xStart;
            ctx.fillStyle = '#2B7D510D';
            ctx.fillRect(xStart, bar.y, rectWidth, bar.height);
          });
        },
        'bars',
        // 'markers',
        'legends',
      ]}
    />
  );
};
