import { FieldValues } from 'react-hook-form';
import { UpdateOnboardingInput } from 'types/graphql/generated';

export const formatOnboardingInput = (data: FieldValues) => {
  const input: UpdateOnboardingInput = Object.keys(data).reduce(
    (reducer, key) => {
      if (data[key] && key !== 'teamEmails' && data[key] !== 'other') {
        if (key === 'customIndustry' && !reducer['industry']) {
          return { ...reducer, industry: data[key] };
        } else if (key === 'customJobRole' && !reducer['jobRole']) {
          return { ...reducer, jobRole: data[key] };
        } else if (key === 'brandOrMerchant') {
          return data[key].length
            ? {
                ...reducer,
                [key]: data.brandOrMerchant.map((item) => ({
                  brandOrMerchantId: item.realId,
                  brandOrMerchantName: item.title,
                })),
              }
            : reducer;
        } else if (!reducer[key]) {
          return { ...reducer, [key]: data[key] };
        }
      }
      return reducer;
    },
    {}
  );

  return input;
};

export default formatOnboardingInput;
