import styled from 'styled-components';
import { theme } from '@klover/attain-design-system';

export const Wrapper = styled.div`
  margin: 0 0 0 ${theme.spacing.md};
  width: 100%;
  max-width: 1600px;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block-end: ${theme.spacing.md};
`;

export const QuestionTypeInputWrapper = styled.div`
  display: flex;
`;

export const TabsWrapper = styled.div`
  overflow: hidden;
  margin-inline: -${theme.spacing.lg};
  padding-inline: ${theme.spacing.lg};
`;

export const CardBody = styled.div``;
