import { gql } from '@apollo/client';

export const smartPersonaPurchasesQuery = gql`
  query SmartPersonaPurchases($input: SmartPersonaPurchasesInput!) {
    smartPersonaPurchases(input: $input) {
      id
      data {
        id
        title
        ... on SmartPersonaEnrichedTable {
          id
          title
          categoryColumnTitle
          data {
            id
            category {
              title
              logo
            }
            index {
              value
              title
              description
              kind
            }
            audiencePercentage {
              value
              title
            }
            topMerchant {
              title
              logo
            }
            data {
              id
              category {
                title
                logo
              }
              index {
                value
                title
                description
                kind
              }
              audiencePercentage {
                value
                title
              }
              topMerchant {
                title
                logo
              }
            }
          }
        }
        ... on SmartPersonaCategoryBreakdownTable {
          id
          title
          data {
            id
            entity {
              title
              logo
            }
            index {
              value
              title
              description
              kind
            }
            averageSpend {
              value
              title
            }
            averageFrequency {
              value
              title
            }
            averageSpendPerTransaction {
              value
              title
            }
            audiencePercentage {
              value
              title
            }
            pfm2Categories {
              id
              entity {
                title
                logo
              }
              index {
                value
                title
                description
                kind
              }
              averageSpend {
                value
                title
              }
              averageFrequency {
                value
                title
              }
              averageSpendPerTransaction {
                value
                title
              }
              audiencePercentage {
                value
                title
              }
              merchants {
                id
                entity {
                  title
                  logo
                }
                index {
                  value
                  title
                  description
                  kind
                }
                averageSpend {
                  value
                  title
                }
                averageFrequency {
                  value
                  title
                }
                averageSpendPerTransaction {
                  value
                  title
                }
                audiencePercentage {
                  value
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default smartPersonaPurchasesQuery;
