export const generateYTicksCustomInterval = (
  interval: number,
  lowestY: number,
  highestY: number,
  minLow: number,
  minHigh: number,
  customAllowableDelta?: number
) => {
  const yTicks: number[] = [minLow];
  const allowableDelta = customAllowableDelta || Math.floor(interval / 2);
  let i = minLow;
  while (i - allowableDelta > lowestY) {
    i -= interval;
    yTicks.push(i);
  }
  i = minLow;
  while (i < minHigh || i + allowableDelta < highestY) {
    i += interval;
    yTicks.push(i);
  }
  return yTicks.sort((a, b) => b - a);
};

export const generateYTicksArray = (
  low: number,
  high: number,
  count: number
) => {
  const result: number[] = [Number(high.toFixed(2))];
  const remaining = count - result.length;
  const step = (high - low) / (count - 1);
  const zeroSpacing = step * 0.25;
  for (let i = 0; i < remaining; i++) {
    const value = low + step * i;
    if (
      (value >= zeroSpacing || value <= -zeroSpacing) &&
      !result.includes(Number(value.toFixed(2)))
    ) {
      result.push(Number(value.toFixed(2)));
    }
  }
  if (low <= 0 && high >= 0) {
    result.push(Number(0));
  }
  return result.sort((a, b) => b - a);
};

export default generateYTicksArray;
