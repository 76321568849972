import App from './App';
import ErrorMsg from 'ErrorMsg';
import GlobalStyle from 'styles/globalStyles';
import SecureApolloProvider from '@klover/ksaas-common-web/services/SecureApolloProvider';
import { AuthProvider } from '@klover/ksaas-common-web/services/AuthProvider';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CssBaseline } from '@klover/attain-design-system';
import { DndProvider } from 'react-dnd';
import { ErrorBoundary } from 'react-error-boundary';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IntercomProvider } from 'react-use-intercom';
import { MathJax3Config, MathJaxContext } from 'better-react-mathjax';
import { createRoot } from 'react-dom/client';
import './index.css';
import 'react-day-picker/dist/style.css';

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

const mathjaxConfig: MathJax3Config = {
  tex: {
    inlineMath: [
      ['(', ')'],
      ['[', ']'],
    ],
  },
};

root.render(
  <BrowserRouter>
    <AuthProvider
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID as string}
      domain={import.meta.env.VITE_AUTH0_DOMAIN as string}
      authorizationParams={{
        audience: import.meta.env.VITE_AUTH0_AUDIENCE as string,
      }}
    >
      <SecureApolloProvider
        url={import.meta.env.DEV ? '' : import.meta.env.VITE_GRAPHQL_URL}
        ws={import.meta.env.VITE_GRAPHQL_URL_SUBSCRIPTION}
      >
        <IntercomProvider appId="ssmf88uq">
          <DndProvider backend={HTML5Backend}>
            <CssBaseline />
            <GlobalStyle />
            <ErrorBoundary fallback={<ErrorMsg />}>
              <MathJaxContext config={mathjaxConfig}>
                <Routes>
                  <Route path="*" element={<App />} />
                </Routes>
              </MathJaxContext>
            </ErrorBoundary>
          </DndProvider>
        </IntercomProvider>
      </SecureApolloProvider>
    </AuthProvider>
  </BrowserRouter>
);
