import styled from 'styled-components';
import { CaretRight } from '@phosphor-icons/react';

import { Link } from 'react-router-dom';
import { theme } from '@klover/attain-design-system';

interface Props {
  $transparent?: boolean;
  children?: any;
}

export const StoryBookPadding = styled.div`
  padding-inline: ${theme.spacing.xl};
`;

export const Wrapper = styled.header<Props>`
  align-items: center;
  background: ${({ $transparent }) =>
    $transparent ? 'transparent' : theme.colors.backgroundBrand};
  display: flex;
  gap: ${theme.spacing.xs};
  margin-inline: -${theme.spacing.md};
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  position: relative;
  z-index: 1;

  @media ${theme.mq.tabletPortraitUp} {
    background: ${({ $transparent }) =>
      $transparent ? 'transparent' : theme.colors.backgroundPrimary};
    border-bottom: ${({ $transparent }) =>
      $transparent
        ? '1px solid transparent'
        : `1px solid ${theme.colors.borderDark}`};
    margin-inline: -${theme.spacing.xl};
    padding: ${theme.spacing.md} ${theme.spacing.xl};
  }
`;

export const Hamburger = styled.div`
  border-radius: ${theme.spacing.xs};
  cursor: pointer;
  height: 42px;
  width: 75px;

  @media ${theme.mq.tabletPortraitUp} {
    display: none;
  }

  &:hover,
  &:active {
    background: #5a4c8a;
  }
`;

export const Text = styled.span`
  align-items: center;
  display: flex;
  flex-grow: 1;
  margin: 0;
`;

export const Parent = styled(Link)<Props>`
  ${theme.typography.headingXS};
  color: ${theme.colors.textInverted};

  @media ${theme.mq.tabletPortraitUp} {
    color: ${({ $transparent }) =>
      $transparent ? theme.colors.textInverted : theme.colors.textBrand};
  }
`;

export const Caret = styled(CaretRight)<Props>`
  color: ${theme.colors.textInverted};
  margin-inline: ${theme.spacing.xs};
  position: relative;
  top: 1px;

  @media ${theme.mq.tabletPortraitUp} {
    color: ${({ $transparent }) =>
      $transparent ? theme.colors.textInverted : theme.colors.textTertiary};
  }
`;

export const Current = styled.span<Props>`
  ${theme.typography.bodyLG};
  color: ${theme.colors.textInverted};
  font-size: 20px;
  line-height: 24px;

  @media ${theme.mq.tabletPortraitUp} {
    color: ${({ $transparent }) =>
      $transparent ? theme.colors.textInverted : theme.colors.textSecondary};
  }
`;

export const CurrentBold = styled(Current)<Props>`
  ${theme.typography.headingXS};
  color: ${theme.colors.textInverted};

  @media ${theme.mq.tabletPortraitUp} {
    color: ${({ $transparent }) =>
      $transparent ? theme.colors.textInverted : theme.colors.text};
  }
`;
