import RegionBubble from './RegionBubble';
import RegionsMapWhite from 'assets/regions-white.svg';
import { theme } from '@klover/attain-design-system';
import * as Styled from './Regions.styles';

export const Regions = ({ loading, divisions }) => {
  const regionData = (divisions || []).reduce((acc, item) => {
    if (!acc[item.division])
      acc[item.division] = Math.round(item.percent.value * 100);
    return acc;
  }, {});

  let minValue = 0;
  let maxValue = 0;

  if (divisions) {
    function findMinMaxPercent() {
      divisions.forEach((division) => {
        if (division.percent.value < minValue) {
          minValue = division.percent.value;
        }
        if (division.percent.value > maxValue) {
          maxValue = division.percent.value;
        }
      });

      return {
        min: minValue,
        max: maxValue,
      };
    }

    minValue = findMinMaxPercent().min * 100;
    maxValue = findMinMaxPercent().max * 100;
  }

  if (loading) {
    return (
      <Styled.WrapperLoading>
        <Styled.CenterBoth>
          <Styled.Loader />
        </Styled.CenterBoth>
      </Styled.WrapperLoading>
    );
  }

  return (
    <Styled.Wrapper>
      <Styled.Center>
        <img src={RegionsMapWhite} alt="" />

        <Styled.RegionTitle>West</Styled.RegionTitle>
        <Styled.RegionTitleMidwest>Midwest</Styled.RegionTitleMidwest>
        <Styled.RegionTitleNortheast>Northeast</Styled.RegionTitleNortheast>
        <Styled.RegionTitleSouth>South</Styled.RegionTitleSouth>

        <RegionBubble
          name="Pacific"
          percent={regionData['Pacific'] || 0}
          top={27}
          left={9}
          color={theme.colors.CHART_PRIMARY}
          delay={0}
          minValue={minValue}
          maxValue={maxValue}
        />

        <RegionBubble
          name="Mountain"
          percent={regionData['Mountain'] || 0}
          top={40}
          left={27}
          color={theme.colors.CHART_PRIMARY}
          delay={1}
          minValue={minValue}
          maxValue={maxValue}
        />

        <RegionBubble
          name="West South Central"
          percent={regionData['West South Central'] || 0}
          top={69}
          left={47}
          color={theme.colors.CHART_4}
          delay={2}
          minValue={minValue}
          maxValue={maxValue}
        />

        <RegionBubble
          name="West North Central"
          percent={regionData['West North Central'] || 0}
          top={35}
          left={48}
          color={theme.colors.CHART_TERTIARY}
          delay={3}
          minValue={minValue}
          maxValue={maxValue}
        />

        <RegionBubble
          name="East North Central"
          percent={regionData['East North Central'] || 0}
          top={25}
          left={67}
          color={theme.colors.CHART_TERTIARY}
          delay={4}
          minValue={minValue}
          maxValue={maxValue}
        />

        <RegionBubble
          name="East South Central"
          percent={regionData['East South Central'] || 0}
          top={64}
          left={62}
          color={theme.colors.CHART_4}
          delay={5}
          minValue={minValue}
          maxValue={maxValue}
        />

        <RegionBubble
          name="South Atlantic"
          percent={regionData['South Atlantic'] || 0}
          top={76}
          left={78}
          color={theme.colors.CHART_4}
          delay={6}
          minValue={minValue}
          maxValue={maxValue}
        />

        <RegionBubble
          name="Middle Atlantic"
          percent={regionData['Middle Atlantic'] || 0}
          top={40}
          left={79}
          color={theme.colors.CHART_6}
          delay={7}
          minValue={minValue}
          maxValue={maxValue}
        />

        <RegionBubble
          name="New England"
          percent={regionData['New England'] || 0}
          top={20}
          left={87}
          color={theme.colors.CHART_6}
          delay={8}
          minValue={minValue}
          maxValue={maxValue}
        />
      </Styled.Center>
    </Styled.Wrapper>
  );
};

export default Regions;
