import styled from 'styled-components';
import { theme } from '@klover/attain-design-system';

export const ViewAudienceBtn = styled.div`
  ${theme.typography.bodyLG};
  margin-block-start: ${theme.spacing.sm};
  display: flex;
  align-items: center;
  color: ${theme.colors.CHART_PRIMARY};
  span {
    margin-inline: ${theme.spacing.xxs};
  }
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${theme.spacing.xxs};
`;
