import AutocompleteBrandMerchant from '../AutocompleteBrandMerchant/AutocompleteBrandMerchant';
import CustomDateField from 'components/Insights/CustomDateField/CustomDateField';
import React from 'react';
import insightRfmOptionsQuery from 'operations/queries/insightRfmOptions.query';
import paths from 'constants/paths';
import { BrandOrMerchant } from 'components/Outcome/Campaign/BrandOrMerchantSelect/brandOrMerchantSearch';
import {
  Button,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@klover/attain-design-system';
import {
  InsightRfmOptionsQuery,
  InsightTimePeriodEnum,
} from 'types/graphql/generated';
import { generatePath, useNavigate } from 'react-router-dom';
import { setRecentResults } from 'hooks/useRecentResults/useRecentResults';
import { useForm, useWatch } from 'react-hook-form';
import { useGlobalState } from 'state';
import { useQuery } from '@apollo/client';
import * as Styled from 'components/InsightsCard.styles';

interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
}

export const InsightsSearchCard = ({ open, onClose, title }: Props) => {
  const [, actions] = useGlobalState();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isValid },
    control,
    setValue,
    watch,
  } = useForm();

  const timePeriodValue = useWatch({
    control,
    name: 'timePeriod',
  });

  const { data } = useQuery<InsightRfmOptionsQuery>(insightRfmOptionsQuery, {
    fetchPolicy: 'cache-first',
  });

  const onSubmit = React.useCallback(
    (form) => {
      const isBrand =
        form.brandOrMerchant[0].description === BrandOrMerchant.Brand;

      const params = {
        paramSegmentID: form.brandOrMerchant[0].b64Id,
        // paymentChannel: selectedPaymentChannel,
        timePeriod: form.timePeriod,
        customTimePeriodEnd: form.customTimePeriodEnd,
      };

      if (form.customTimePeriodStart && form.customTimePeriodEnd) {
        params.timePeriod = Math.floor(
          form?.customTimePeriodStart?.toDate()?.getTime() / 1000
        );
        params.customTimePeriodEnd = Math.floor(
          form?.customTimePeriodEnd?.toDate()?.getTime() / 1000
        );
      }

      setRecentResults('InsightsSearchCard', {
        id: form.brandOrMerchant.id,
        title: form.brandOrMerchant.title,
      });

      // actions.setPaymentChannel(params.paymentChannel);
      actions.setTimePeriod(params.timePeriod);
      onClose();

      return navigate(
        generatePath(
          isBrand ? paths.INSIGHTS_BRANDS : paths.INSIGHTS_MERCHANTS,
          params
        )
      );
    },
    [data]
  );

  const { ref: _ } = register('brandOrMerchant', { required: true });
  const { ref: selectRef, ...selectFields } = register('timePeriod', {
    required: true,
  });

  // const { data: paymentChannelData } = useQuery<InsightGlobalFiltersQuery>(
  //   insightGlobalFiltersQuery
  // );

  // const paymentChannels =
  //   paymentChannelData?.insightGlobalFilters.paymentChannel;

  // const [selectedPaymentChannel, setSelectedPaymentChannel] = React.useState(
  //   state.globalFiltersPaymentChannel
  // );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      headerID="insightCardHeader"
      header={title}
      bodyID="insightCardBody"
      body={
        <Styled.DialogBody>
          <FormControl fullWidth>
            <InputLabel id="brandLabel">Brand</InputLabel>

            <AutocompleteBrandMerchant
              value={watch('brandOrMerchant') || []}
              onSelect={(value) => {
                setValue('brandOrMerchant', value);
              }}
              localStorageName="ohq-brandOrMerchant"
            />
          </FormControl>

          {/* <FormControl fullWidth>
            <InputLabel id="paymentChannelLabel">Payment channel</InputLabel>

            <Styled.Values>
              {paymentChannels?.map((channel) => (
                <React.Fragment key={channel.id}>
                  <input
                    type="radio"
                    id={channel.id}
                    name="paymentChannel"
                    value={channel.id}
                    checked={selectedPaymentChannel === channel.id}
                    onChange={(e) => {
                      setSelectedPaymentChannel(base64ID(e.target.value));
                      setValue('paymentChannel', e.target.value);
                    }}
                  />
                  <label htmlFor={channel.id}>{channel.title}</label>
                </React.Fragment>
              ))}
            </Styled.Values>
          </FormControl> */}

          <FormControl fullWidth>
            <InputLabel id="timePeriodLabel">Time period</InputLabel>

            <Select
              variant="outlined"
              labelId="timePeriodLabel"
              id="timePeriod"
              defaultValue=""
              inputRef={selectRef}
              {...selectFields}
            >
              {data?.insightRfmOptions?.timePeriods &&
                data.insightRfmOptions.timePeriods.map((period) => (
                  <MenuItem key={period.id} value={period.id}>
                    {period.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          {(data?.insightRfmOptions?.timePeriods?.find(
            (i) => i.id === timePeriodValue
          )?.kind as any) === InsightTimePeriodEnum.CUSTOM && (
            <CustomDateField control={control} />
          )}
        </Styled.DialogBody>
      }
      footer={
        <>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid}
          >
            Explore
          </Button>
        </>
      }
    />
  );
};

export default InsightsSearchCard;
