import styled from 'styled-components';

import { X } from '@phosphor-icons/react';
import { theme } from '@klover/attain-design-system';

export const DrawerHeader = styled.header`
  background: ${theme.colors.backgroundPrimary};
  padding-inline: ${theme.spacing.md};

  @media ${theme.mq.tabletPortraitUp} {
    padding-inline: ${theme.spacing.lg};
  }

  > div {
    padding-top: ${theme.spacing.lg};
  }
`;

export const DrawerActions = styled.div`
  align-items: center;
  display: flex;
`;

export const DrawerGrow = styled.div`
  flex-grow: 1;
`;

export const Close = styled(X)`
  cursor: pointer;
`;

export const DrawerCategory = styled.div`
  ${theme.typography.bodyMD};
  color: ${theme.colors.textTertiary};
  text-transform: uppercase;
`;

export const DrawerTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NarrationWrapper = styled.div`
  margin-top: 10px;
`;

export const NarrationBox = styled.div`
  background: ${theme.colors.backgroundPrimary};
  border-radius: ${theme.spacing.xxs};
  margin-inline: ${theme.spacing.lg};
  min-height: 250px;
  padding: ${theme.spacing.sm} ${theme.spacing.lg};
`;

export const AlertWrapper = styled.div`
  margin-block-start: ${theme.spacing.md};
`;

export const DrawerTitle = styled.div`
  ${theme.typography.headingLG};
  padding-top: ${theme.spacing.xs};
`;

export const DrawerBadges = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
  margin-block-start: ${theme.spacing.sm};
`;

export const CensusBalanced = styled.div`
  ${theme.typography.bodyMD};
  align-items: center;
  color: ${theme.colors.textBrand};
  display: flex;
  gap: ${theme.spacing.xxs};
`;

export const DrawerContent = styled.div`
  padding: ${theme.spacing.md};

  @media ${theme.mq.tabletPortraitUp} {
    padding: ${theme.spacing.lg};
  }
`;

export const Search = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.lg};
  justify-content: flex-end;
`;

export const TabTitle = styled.div`
  ${theme.typography.headingMD};
  flex-grow: 1;
`;

export const Loader = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.lg};
  padding-block: ${theme.spacing.md};
`;

export const Segment = styled.div`
  background: ${theme.colors.backgroundPrimary};
  border: 1px solid ${theme.colors.borderDark};
  border-radius: ${theme.spacing.xs};
  margin-block-start: ${theme.spacing.md};
  overflow: hidden;

  .MuiAccordion-root {
    background: transparent;
  }

  .MuiAccordionSummary-expandIconWrapper {
    margin-left: ${theme.spacing.sm};
  }
`;

export const SegmentThirdParty = styled(Segment)<{ $collapsed: boolean }>`
  max-height: ${({ $collapsed }) => ($collapsed ? '137px' : '657px')};
  transition: ${({ $collapsed }) =>
    $collapsed ? 'height 0.25s ease-in' : 'height 0.25s ease-out'};
`;

export const SegmentHeader = styled.div`
  align-items: center;
  display: flex;
`;

export const SegmentHeaderText = styled.div`
  ${theme.typography.headingXS};
  flex-grow: 1;
  padding-block: ${theme.spacing.xs};
`;

export const IndexTable = styled.table`
  border-collapse: collapse;
  margin: 0;
  width: 100%;

  th,
  td {
    padding: ${theme.spacing.xs} ${theme.spacing.md};
    text-align: left;
  }

  th {
    ${theme.typography.label};
    white-space: nowrap;
  }

  td {
    ${theme.typography.bodyLG};
    background: ${theme.colors.backgroundPrimary};
    border-block-start: 1px solid ${theme.colors.borderDark};
    padding-block: ${theme.spacing.sm};
  }
`;

export const Title = styled.span`
  ${theme.typography.headingXS};
  border-right: 1px solid ${theme.colors.borderDark};
  margin-right: ${theme.spacing.sm};
  padding-right: ${theme.spacing.sm};
`;

export const ContentWrapper = styled.div`
  padding: 0 ${theme.spacing.md} ${theme.spacing.lg};

  @media ${theme.mq.tabletPortraitUp} {
    padding-inline: ${theme.spacing.lg};
  }
`;
