import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

export const Wrapper = styled.div`
  cursor: pointer;
  height: 100%;
  transition: transform 0.25s ease;

  &:hover {
    @media ${theme.mq.tabletPortraitUp} {
      transform: scale(1.05);
    }
  }
`;

export const Title = styled.div`
  align-items: center;
  display: flex;
`;

export const Avatar = styled.div`
  height: 24px;
  margin-right: ${theme.spacing.sm};

  svg {
    height: 24px;
    width: 24px;
  }
`;

export const TitleText = styled.h4`
  ${theme.typography.headingSM}
  flex-grow: 1;
`;

export const Description = styled.p`
  ${theme.typography.bodyLG};
  color: ${theme.colors.textTertiary};
  margin-block: ${theme.spacing.sm} 0;
`;

export const DialogBody = styled.div`
  ${theme.mixins.stack(theme.spacing.lg)};
`;

export const Values = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.xs};
  margin-block-start: ${theme.spacing.xs};
`;
