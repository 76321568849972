import { gql } from '@apollo/client';

export const lookupIdentityQuery = gql`
  query LookupIdentity($input: LookupIdentityInput!) {
    lookupIdentity(input: $input) {
      ... on LookupIdentitySuccess {
        email
        ipInfo {
          ipAddress
          country
          regionName
          city
          zip
          lat
          lon
          timezone
          isp
        }
        userAgentInfo {
          userAgent
          browser
          engine
          operatingSystem
          device
          cpuArchitecture
        }
        households {
          luid
          homeAddress {
            addressLine1
            addressLine2
            city
            state
            zipCode
          }
          individuals {
            firstName
            lastName
            gender
          }
        }
      }
      ... on LookupIdentityError {
        title
        message
      }
    }
  }
`;
