import { gql } from '@apollo/client';

export const measurementInsightsCltvQuery = gql`
  query MeasurementInsightsCltv($input: MeasurementInsightsCltvInput!) {
    measurementInsightsCltv(input: $input) {
      ... on MeasurementInsightsCltvSuccess {
        id
        lowCltv {
          value
          title
        }
        medCltv {
          value
          title
        }
        highCltv {
          value
          title
        }
      }
      ... on MeasurementInsightsCltvError {
        message
      }
    }
  }
`;

export default measurementInsightsCltvQuery;
