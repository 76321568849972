import React from 'react';
import {
  useSearchParams as useLocationSearchParams,
  useNavigate,
} from 'react-router-dom';

export const useSearchParams = <T>(): [
  T,
  {
    push(key: string, value: string): void;
    pop(key: string): void;
    replace(key: string, value: string): void;
  }
] => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useLocationSearchParams();

  const searchParamsObject = React.useMemo(() => {
    const params = new URLSearchParams(searchParams);
    const obj: T = {} as T;
    params.forEach((value, key) => {
      try {
        obj[key] = JSON.parse(decodeURIComponent(value));
      } catch (e) {
        obj[key] = value;
      }
    });
    return obj;
  }, [searchParams]);

  const push = React.useCallback(
    (key: string, value: string) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(key, value);
      setSearchParams(newSearchParams.toString());
      navigate(`?${searchParams}`);
    },
    [navigate, searchParams, setSearchParams]
  );

  const pop = React.useCallback(
    (key: string) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(key);
      setSearchParams(newSearchParams.toString());
      navigate(`?${searchParams}`);
    },
    [navigate, searchParams, setSearchParams]
  );

  const replace = React.useCallback(
    (key: string, newValue: string) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(key, newValue);
      setSearchParams(newSearchParams.toString());
      navigate(`?${searchParams}`);
    },
    [navigate, searchParams, setSearchParams]
  );

  return [searchParamsObject, { push, pop, replace }];
};

export default useSearchParams;
