import Error from 'components/Error/Error';
import OptimizationTable from '../Tables/OptimizationTable/OptimizationTable';
import React from 'react';
import TableOverall from '../Tables/TableOverall/TableOverall';
import TableSkeletonLoader from '../Tables/TableSkeletonLoader';
import measurementOptimizationQuery from 'operations/queries/measurementOptimizations.query';
import useMe from 'hooks/useMe/useMe';
import useSearchParams from 'hooks/useSearchParams';
import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import { Alert, Card, Grid } from '@klover/attain-design-system';
import {
  MeasurementOptimizationsQuery,
  MeasurementOptimizationsQueryVariables,
  PartnerEnum,
} from 'types/graphql/generated';
import { MeasurementSearchParams } from 'views/Measurement/MeasurementDashboard';
import { OptimizationToggles } from '../OptimizationsFilters/OptimizationsFilters';
import { getMeasurementOptimizationInputFromUrlParams } from 'utils/Measurement/measurementInputFromUrlParams';
import { useLazyQuery } from '@apollo/client';
import * as Styled from './MeasurementOptimization.styles';

interface Props {
  campaignName: string;
  decimalPlaces: string;
  columnToggles: OptimizationToggles;
}

export const MeasurementOptimizationResults = ({
  campaignName,
  decimalPlaces,
  columnToggles,
}: Props) => {
  const me = useMe();
  const [searchParams] = useSearchParams<MeasurementSearchParams>();
  const isOpenX = searchParams.type === PartnerEnum.OPEN_X;

  const [getOptimizationsData, { data, loading }] = useLazyQuery<
    MeasurementOptimizationsQuery,
    MeasurementOptimizationsQueryVariables
  >(measurementOptimizationQuery, { fetchPolicy: 'no-cache' });

  const metrics =
    data &&
    data.measurementOptimizations.__typename ===
      'MeasurementOptimizationSuccess'
      ? data?.measurementOptimizations.metrics
      : null;

  React.useEffect(() => {
    window.analytics.page('Page view: Measurement > Optimization', {
      ...me,
    });
  }, []);

  React.useEffect(() => {
    const optimizationsInput = getMeasurementOptimizationInputFromUrlParams(
      searchParams.campaignId,
      searchParams
    );
    if (optimizationsInput) {
      getOptimizationsData({ variables: { input: optimizationsInput } });
    }
  }, [searchParams]);

  const hasNoMetrics =
    metrics?.data.length === 0 && metrics?.totalDealConversions.length === 0;
  const domainsFf = useUiFlag('ui_measurement_optimization_domain');

  if (loading) {
    return (
      <Styled.ShowingSkeleton item xs={12}>
        <TableSkeletonLoader />
      </Styled.ShowingSkeleton>
    );
  }
  if (
    data?.measurementOptimizations.__typename === 'MeasurementOptimizationError'
  ) {
    return (
      <Grid item xs={12}>
        <Card>
          <Error message="There has been a problem loading the data for this campaign." />
        </Card>
      </Grid>
    );
  }

  return (
    <>
      {hasNoMetrics && (
        <Grid item xs={12}>
          <Alert severity="info" title="Below data threshold">
            Feasibility has not yet been met for this section
          </Alert>
        </Grid>
      )}

      {metrics && metrics?.totalDealConversions.length > 0 && (
        <Grid item xs={12}>
          <div data-intercom-target={'measurement-optimization-overallcvr'}>
            <TableOverall
              campaignTitle={campaignName}
              data={metrics?.totalDealConversions}
              decimalPlaces={Number(decimalPlaces)}
              columnToggles={columnToggles}
            />
          </div>
        </Grid>
      )}

      {metrics &&
        metrics?.data.length > 0 &&
        metrics?.data.map((table, i) => {
          if (
            // gaurds for ff access
            !domainsFf.enabled &&
            table.title === 'Conversion rate by domain'
          ) {
            return null;
          }
          return (
            <Grid item xs={12} key={i}>
              <div data-intercom-target={table.title}>
                <OptimizationTable
                  campaignTitle={campaignName}
                  data={table.conversionRates}
                  tableTitle={table.title}
                  mainColumnName={table.columnType}
                  hasDeals={isOpenX}
                  decimalPlaces={Number(decimalPlaces)}
                  columnToggles={columnToggles}
                />
              </div>
            </Grid>
          );
        })}
    </>
  );
};

export default MeasurementOptimizationResults;
