import TextFieldSearch from 'components/TextFieldSearch/TextFieldSearch';
import { Autocomplete } from '@klover/attain-design-system';
import { CampaignBasic } from 'types/graphql/generated';
import { getMeasurementPath } from 'utils/Measurement/measurementPaths';

interface Props {
  campaigns: CampaignBasic[];
  loading: boolean;
  close: () => void;
  navigate: (path: string) => void;
}
export const CampaignQuickSelect = ({
  campaigns,
  loading,
  close,
  navigate,
}: Props) => {
  const sortedCampaigns = campaigns.sort((a, b) => b.createdAt - a.createdAt);

  return (
    <Autocomplete
      fullWidth
      sx={{ width: '250px' }}
      options={sortedCampaigns || []}
      loading={loading}
      getOptionLabel={(option) => option.title}
      onChange={(_, value) => {
        close();
        if (value) {
          navigate(getMeasurementPath(value));
        }
      }}
      openOnFocus
      onBlur={() => {
        close();
      }}
      renderInput={(params) => (
        <TextFieldSearch
          {...params}
          autoFocus
          placeholder="Search"
          variant="outlined"
        />
      )}
    />
  );
};

export default CampaignQuickSelect;
