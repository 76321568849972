import GhostLoader from 'react-ghost-loader';
import React from 'react';
import { Card, CardTypes } from '@klover/attain-design-system';
import * as Styled from './StatCard.styles';

interface StatCardProps {
  title?: string | React.ReactNode;
  detail?: string | React.ReactNode;
  type?: CardTypes;
  children?: string | React.ReactNode;
}

export const StatCard = ({ title, detail, type, children }: StatCardProps) => {
  const isInverted = type === CardTypes.Inverted;

  return (
    <Card type={type}>
      <Styled.Title $isInverted={isInverted}>{title}</Styled.Title>

      <Styled.Detail>{detail}</Styled.Detail>

      {children && <Styled.Content>{children}</Styled.Content>}
    </Card>
  );
};

export const StatCardLoading = ({ type, children }: StatCardProps) => {
  const isInverted = type === CardTypes.Inverted;

  return (
    <Card type={type}>
      <Styled.Title $isInverted={isInverted} data-testid="statcard-loading">
        <GhostLoader height="22px" heightRandom={0} width="100%" />
      </Styled.Title>

      <Styled.Detail>
        <GhostLoader height="42px" heightRandom={0} width="100%" />
      </Styled.Detail>

      {children && <Styled.Content>{children}</Styled.Content>}
    </Card>
  );
};

export default StatCard;
