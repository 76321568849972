import React from 'react';
import {
  Autocomplete,
  Grid,
  ListSubheader,
  TextField,
} from '@klover/attain-design-system';
import { AutocompleteNoOptionsText } from 'constants/strings';
import { CaretDown } from '@phosphor-icons/react';
import {
  OutcomeHqMerchantOrBrand,
  OutcomeHqMerchantsAndBrandsQuery,
} from 'types/graphql/generated';
import {
  getRecentResultsGeneric,
  setRecentResultsGeneric,
} from 'hooks/useRecentResultsGeneric/useRecentResultsGeneric';
import { outcomeHqMerchantsAndBrandsQuery } from 'operations/queries/outcomeHqMerchantsAndBrands.query';
import { useLazyQuery } from '@apollo/client';
import * as Styled from './brandOrMerchantSearch.styles';

export enum BrandOrMerchant {
  Brand = 'Brand',
  Merchant = 'Merchant',
}

interface Props {
  onSelect: (value: any) => void;
  label?: string;
  localStorageName: string;
  disabled?: boolean;
  value:
    | OutcomeHqMerchantOrBrand[]
    | OutcomeHqMerchantOrBrand
    | null
    | undefined;
  disableMultiple?: boolean;
}
export const AutocompleteBrandOrMerchant = ({
  onSelect,
  label,
  value,
  localStorageName,
  disabled,
  disableMultiple,
}: Props) => {
  const [bounce, setBounce] = React.useState<NodeJS.Timeout | null>();

  const [search, { data, loading }] =
    useLazyQuery<OutcomeHqMerchantsAndBrandsQuery>(
      outcomeHqMerchantsAndBrandsQuery
    );

  const handleInputChange = React.useCallback(
    (_, value) => {
      if (bounce) clearTimeout(bounce);

      setBounce(
        setTimeout(() => {
          setBounce(null);

          void search({
            variables: {
              input: {
                search: value,
              },
            },
          });
        }, 300)
      );
    },
    [setBounce, bounce]
  );

  const handleOnChange = React.useCallback(
    (_, value) => {
      if (value) {
        if (Array.isArray(value)) {
          value.map((brandOrMerchant, i) => {
            if (i === value.length - 1) {
              setRecentResultsGeneric(localStorageName, brandOrMerchant);
            }
          });
        } else {
          setRecentResultsGeneric(localStorageName, value);
        }
      }
      onSelect(value);
    },
    [onSelect]
  );

  const results = data?.outcomeHqMerchantsAndBrands;
  const hasResults = results && results?.length;

  return (
    <Autocomplete
      noOptionsText={AutocompleteNoOptionsText}
      onChange={handleOnChange}
      multiple={disableMultiple ? false : true}
      popupIcon={<CaretDown />}
      getOptionKey={(option) => option.b64Id}
      renderInput={(params) => (
        <TextField {...params} placeholder={label || 'Search for merchant'} />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={value}
      filterOptions={(options) => options}
      options={hasResults ? results : getRecentResultsGeneric(localStorageName)}
      onInputChange={handleInputChange}
      getOptionLabel={(option: OutcomeHqMerchantOrBrand) => option?.title || ''}
      loading={!!bounce || loading}
      disabled={disabled}
      groupBy={() => (hasResults ? '' : 'Recent')}
      renderGroup={(params) => (
        <>
          <ListSubheader>{hasResults ? '' : 'Recent'}</ListSubheader>
          {params.children}
        </>
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Styled.StyledCheckBox checked={selected} />
              {option.title}
            </Grid>
            <Grid item>
              <Styled.StyledChip
                $type={BrandOrMerchant[option.description]}
                label={option.description}
              />
            </Grid>
          </Grid>
        </li>
      )}
    />
  );
};

export default AutocompleteBrandOrMerchant;
