import OutcomeAiLogo from 'assets/outcome-ai-logo-brand-color.svg';
import jsPDF from 'jspdf';
import {
  ChatTeardropDots,
  CornersIn,
  CornersOut,
  FilePdf,
  Key,
  Lightning,
  Sparkle,
  User,
  UsersThree,
  Warning,
} from '@phosphor-icons/react';
import { useRef, useState } from 'react';
import * as Styled from './OutcomeAiInsight.styles';

import {
  BrandLiftAiInsightsSuccess,
  IncrementalityAiInsightsSuccess,
} from 'types/graphql/generated';
import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  theme,
} from '@klover/attain-design-system';

type OutcomeAiInsightProps = {
  data:
    | IncrementalityAiInsightsSuccess
    | BrandLiftAiInsightsSuccess
    | null
    | undefined;
  loading: boolean;
  error: boolean;
};

export const OutcomeAiInsight = ({
  data,
  loading,
  error,
}: OutcomeAiInsightProps) => {
  const [aiTab, setAiTab] = useState('executiveSummaryParagraphs');
  const [isAiInsightOpen, setIsAiInsightOpen] = useState(false);

  const aiTabs = {
    executiveSummaryParagraphs: {
      displayName: 'Summary',
      icon: <ChatTeardropDots />,
    },
    keyInsights: {
      displayName: 'Key',
      icon: <Key />,
    },
    personaDevelopment: {
      displayName: 'Persona',
      icon: <User />,
    },
    demographicOverview: {
      displayName: 'Demographics',
      icon: <UsersThree />,
    },
    actionableInsights: {
      displayName: 'Action',
      icon: <Lightning />,
    },
  };

  const reportTemplateRef = useRef(null);

  const downloadPDF = () => {
    if (reportTemplateRef?.current) {
      const doc = new jsPDF({
        orientation: 'landscape',
        format: 'a2',
        unit: 'px',
      });

      // Adding the fonts.
      doc.setFont('Inter-Regular', 'normal');

      doc.html(reportTemplateRef?.current || '', {
        async callback(doc) {
          await doc.save('document');
        },
      });
      // doc.addImage(data?.images[0], "JPEG", 15, 40, 180, 180);
    }
  };

  const onAiTabClick = (tab) => setAiTab(tab);

  const noData =
    !data?.keyInsights &&
    !data?.demographicOverview &&
    !data?.personaDevelopment &&
    !data?.actionableInsights;

  return (
    <Styled.AiWrapper ref={reportTemplateRef}>
      <Styled.AiCard>
        <Styled.CardHeader>
          <Grid container>
            <Grid item xs={2}>
              <Styled.CardTitle>
                <img src={OutcomeAiLogo} />
              </Styled.CardTitle>
            </Grid>
            <Grid item xs={8} />
            {!loading && !error && !noData && (
              <Grid item xs={2}>
                <Styled.Actions>
                  {aiTab === 'full' && (
                    <Styled.ToggleMoreLess onClick={downloadPDF}>
                      <Tooltip title="Download PDF">
                        <IconButton>
                          <FilePdf />
                        </IconButton>
                      </Tooltip>
                    </Styled.ToggleMoreLess>
                  )}
                  <Styled.ToggleMoreLess
                    onClick={() => {
                      setIsAiInsightOpen(!isAiInsightOpen);
                      setAiTab(
                        !isAiInsightOpen ? 'full' : 'executiveSummaryParagraphs'
                      );
                    }}
                  >
                    <Tooltip title={isAiInsightOpen ? 'Minify' : 'Expand'}>
                      <IconButton>
                        {isAiInsightOpen ? <CornersIn /> : <CornersOut />}
                      </IconButton>
                    </Tooltip>
                  </Styled.ToggleMoreLess>
                </Styled.Actions>
              </Grid>
            )}
            <Grid item xs={12}>
              {!isAiInsightOpen && !loading && !error && !noData && (
                <Styled.InsightButtonGroup
                  size="small"
                  aria-label="Small button group"
                >
                  {Object.keys(aiTabs).map((key) => {
                    return (
                      <Button
                        key={key}
                        size="small"
                        onClick={() => onAiTabClick(key)}
                        variant={aiTab === key ? 'contained' : undefined}
                        startIcon={aiTabs[key].icon}
                      >
                        {aiTabs[key].displayName}
                      </Button>
                    );
                  })}
                </Styled.InsightButtonGroup>
              )}
            </Grid>
          </Grid>
        </Styled.CardHeader>
        {(loading || error) && (
          <Styled.CardBody>
            {loading && (
              <Styled.AiLoading>
                <Sparkle weight="fill" color={theme.colors.CHART_SECONDARY} />{' '}
                Generating...
              </Styled.AiLoading>
            )}
            {error && (
              <Styled.AiError>
                <Warning color={theme.colors.errorForeground} />
                Something went wrong. Please try again later.
              </Styled.AiError>
            )}
          </Styled.CardBody>
        )}
        {!loading && !error && aiTab === 'executiveSummaryParagraphs' && (
          <Styled.CardBody>
            {data?.executiveSummaryParagraphs.map((p, i) => (
              <p key={i}>{p}</p>
            ))}
          </Styled.CardBody>
        )}
        {!loading &&
          !error &&
          !['executiveSummaryParagraphs', 'full'].includes(aiTab) && (
            <Styled.CardBody>
              <h3>{data?.[aiTab]?.title}</h3>
              {data?.[aiTab]?.insights.map(({ header, bulletPoints }, j) => (
                <div key={j}>
                  <h4>{header}</h4>
                  <ul>
                    {bulletPoints.map((p, k) => (
                      <li key={k}>{p}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </Styled.CardBody>
          )}
        {!loading && !error && aiTab === 'full' && (
          <Styled.CardBody>
            {true
              ? data?.executiveSummaryParagraphs.map((p, i) => (
                  <p key={i}>{p}</p>
                ))
              : data?.executiveSummaryParagraphs?.[0]}
            {true && (
              <Styled.AiInsightBoxContainer>
                {[
                  data?.keyInsights,
                  data?.demographicOverview,
                  data?.personaDevelopment,
                  data?.actionableInsights,
                ].map((item, i) => (
                  <Styled.AiInsightBox key={i}>
                    <h3>{item?.title}</h3>
                    {item?.insights.map(({ header, bulletPoints }, j) => (
                      <div key={j}>
                        <h4>{header}</h4>
                        <ul>
                          {bulletPoints.map((p, k) => (
                            <li key={k}>{p}</li>
                          ))}
                        </ul>
                      </div>
                    ))}
                    {i === 2 && data?.images.length && (
                      <Styled.PersonaImgContainer>
                        <a
                          href={data?.images[0]}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Styled.PersonaImg src={data?.images[0]} />
                        </a>
                      </Styled.PersonaImgContainer>
                    )}
                  </Styled.AiInsightBox>
                ))}
              </Styled.AiInsightBoxContainer>
            )}
          </Styled.CardBody>
        )}
      </Styled.AiCard>
    </Styled.AiWrapper>
  );
};

export default OutcomeAiInsight;
