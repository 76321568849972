import Glide from '@glidejs/glide';
import LogoCocaCola from 'assets/signup/logo-coca-cola.svg';
import LogoMagnite from 'assets/signup/logo-magnite.svg';
import LogoMax from 'assets/signup/logo-max.svg';
import LogoOpenX from 'assets/signup/logo-openx.svg';
import LogoOutcomeHQ from 'assets/outcome-hq-logo.svg';
import LogoPampers from 'assets/signup/logo-pampers.svg';
import LogoSnowflake from 'assets/signup/logo-snowflake.svg';
import LogoTTD from 'assets/signup/logo-ttd.svg';
import LogoTacoBell from 'assets/signup/logo-taco-bell.svg';
import LogoUber from 'assets/signup/logo-uber.svg';
import LogoUlta from 'assets/signup/logo-ulta.svg';
import LogoUnited from 'assets/signup/logo-united.svg';
import LogoWalgreens from 'assets/signup/logo-walgreens.svg';
import Marquee from 'react-fast-marquee';
import Quote from 'assets/signup/quote.svg';
import React from 'react';
import SymbolAttain from 'assets/symbol-attain.svg';
import Video from 'assets/signup/video.mp4';
import paths from 'constants/paths';
import { Grid, theme } from '@klover/attain-design-system';
import { useAuth0 } from '@auth0/auth0-react';
import { useMediaQuery } from 'react-responsive';
import * as Styled from './Signup.styles';

export const SignupV3 = () => {
  const { loginWithRedirect } = useAuth0();
  const newAccRedirect = `${window.location.origin}${paths.VERIFYEMAIL}`;
  const redirect = `${window.location.origin}${paths.LOGIN_REDIRECT}`;

  const isDesktop = useMediaQuery({
    query: theme.mq.tabletPortraitUp,
  });

  React.useEffect(() => {
    window.analytics.page('Page view: Signup V3');
    if (!isDesktop) {
      new Glide('.glide', { gap: 32, type: 'carousel' }).mount();
    }
  }, []);

  const Quote1 = (
    <Styled.Quote>
      <img src={Quote} alt="" />

      <Styled.QuoteText>
        By joining forces, Attain and Magnite are at the forefront of advancing
        measurement capabilities for video.
      </Styled.QuoteText>

      <Styled.QuoteCredit>
        <Styled.QuoteAuthor>Kristen Williams</Styled.QuoteAuthor>
        <Styled.QuoteRole>
          SVP of Partnerships
          <br />
          Magnite
        </Styled.QuoteRole>
      </Styled.QuoteCredit>
    </Styled.Quote>
  );

  const Quote2 = (
    <Styled.Quote>
      <img src={Quote} alt="" />

      <Styled.QuoteText>
        With our Attain integration, buyers can apply granular purchase data
        across their CTV, mobile, and web campaigns via OpenX’s supply-side
        targeting solution.
      </Styled.QuoteText>

      <Styled.QuoteCredit>
        <Styled.QuoteAuthor>Brian Chisholm</Styled.QuoteAuthor>
        <Styled.QuoteRole>
          SVP of Strategic Partnerships
          <br />
          OpenX
        </Styled.QuoteRole>
      </Styled.QuoteCredit>
    </Styled.Quote>
  );

  const Quote3 = (
    <Styled.Quote>
      <img src={Quote} alt="" />

      <Styled.QuoteText>
        For brands who sell their products through retailers, the ability to
        make data-driven decisions that leverage customer sales data from
        partners like Attain is important to reach the right consumers.
      </Styled.QuoteText>

      <Styled.QuoteCredit>
        <Styled.QuoteAuthor>Jay Goebel</Styled.QuoteAuthor>
        <Styled.QuoteRole>
          GM, Data Partnerships
          <br />
          The Trade Desk
        </Styled.QuoteRole>
      </Styled.QuoteCredit>
    </Styled.Quote>
  );

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Container>
          <Styled.Flex>
            <Styled.HeaderLeft>
              <a href="https://attaindata.io/">
                <Styled.Logo src={LogoOutcomeHQ} alt="Attain" />
              </a>

              {isDesktop && (
                <>
                  <a href="https://attaindata.io/measurement/">Platform</a>

                  <a href="https://attaindata.io/media-strategy/">Solutions</a>

                  <a href="https://attaindata.io/newsroom/">Resources</a>

                  <a href="https://attaindata.io/about/">Company</a>
                </>
              )}
            </Styled.HeaderLeft>

            <Styled.HeaderRight>
              {isDesktop && (
                <a href="https://attaindata.io/contact-us/">
                  <Styled.HeaderButtonTalk variant="outlined">
                    Talk with us
                  </Styled.HeaderButtonTalk>
                </a>
              )}
              <Styled.HeaderButtonSignUp
                variant="contained"
                onClick={() =>
                  loginWithRedirect({
                    authorizationParams: {
                      screen_hint: 'signup',
                      redirectUri: newAccRedirect,
                    },
                  })
                }
              >
                Sign up
              </Styled.HeaderButtonSignUp>

              {isDesktop && (
                <Styled.LoginButton
                  onClick={() => {
                    loginWithRedirect({
                      authorizationParams: {
                        redirectUri: redirect,
                      },
                    });
                  }}
                >
                  Login
                </Styled.LoginButton>
              )}
            </Styled.HeaderRight>
          </Styled.Flex>
        </Styled.Container>
      </Styled.Header>

      <Styled.HeroV3>
        <Styled.Container>
          <Grid
            container
            alignItems="center"
            spacing={isDesktop ? theme.spacing.xxxl : 0}
          >
            <Grid item xs={12} sm={5}>
              <Styled.HeroDescription>
                Real consumer data in{' '}
                <Styled.HeroDescriptionGradient>
                  real-time.
                </Styled.HeroDescriptionGradient>
              </Styled.HeroDescription>

              {!isDesktop && (
                <Styled.MoveVideoMobile>
                  <Styled.VideoSkewedMobile>
                    <video playsInline autoPlay muted loop src={Video} />
                  </Styled.VideoSkewedMobile>
                </Styled.MoveVideoMobile>
              )}

              <Styled.HeroCTAs>
                <Styled.HeroCTA
                  variant="contained"
                  onClick={() =>
                    loginWithRedirect({
                      authorizationParams: {
                        screen_hint: 'signup',
                        redirectUri: newAccRedirect,
                      },
                    })
                  }
                >
                  Try Attain for free
                </Styled.HeroCTA>
              </Styled.HeroCTAs>
            </Grid>

            {isDesktop && (
              <Grid item xs={12} sm={7}>
                <Styled.MoveVideo>
                  <Styled.VideoSkewed>
                    <video playsInline autoPlay muted loop src={Video} />
                  </Styled.VideoSkewed>
                </Styled.MoveVideo>
              </Grid>
            )}
          </Grid>
        </Styled.Container>
      </Styled.HeroV3>

      <Styled.ClientsV3>
        <Marquee>
          <Styled.ClientsLogos>
            <img src={LogoMax} alt="" />
            <img src={LogoWalgreens} alt="" />
            <img src={LogoPampers} alt="" />
            <img src={LogoUnited} alt="" />
            <img src={LogoUlta} alt="" />
            <img src={LogoUber} alt="" />
            <img src={LogoCocaCola} alt="" />
            <img src={LogoTacoBell} alt="" />
          </Styled.ClientsLogos>
        </Marquee>
      </Styled.ClientsV3>

      <Styled.Quotes>
        <Styled.Container>
          {isDesktop ? (
            <Grid
              container
              spacing={isDesktop ? theme.spacing.xxl : theme.spacing.lg}
            >
              <Grid item xs={12} sm={4}>
                {Quote1}
              </Grid>

              <Grid item xs={12} sm={4}>
                {Quote2}
              </Grid>

              <Grid item xs={12} sm={4}>
                {Quote3}
              </Grid>
            </Grid>
          ) : (
            <div className="glide">
              <div data-glide-el="track" className="glide__track">
                <ul className="glide__slides" style={{ display: 'flex' }}>
                  <li className="glide__slide">{Quote1}</li>
                  <li className="glide__slide">{Quote2}</li>
                  <li className="glide__slide">{Quote3}</li>
                </ul>
              </div>

              <div className="glide__bullets" data-glide-el="controls[nav]">
                <div className="glide__bullet" data-glide-dir="=0" />
                <div className="glide__bullet" data-glide-dir="=1" />
                <div className="glide__bullet" data-glide-dir="=2" />
              </div>
            </div>
          )}
        </Styled.Container>
      </Styled.Quotes>

      <Styled.PartnersV3>
        <Styled.Container>
          <Styled.ClientsTitleV3>
            Partnering with industry leaders to drive better outcomes
          </Styled.ClientsTitleV3>

          <Styled.PartnerLogosV3>
            <img src={LogoTTD} alt="" />
            <img src={LogoOpenX} alt="" />
            <img src={LogoMagnite} alt="" />
            <img src={LogoSnowflake} alt="" />
          </Styled.PartnerLogosV3>
        </Styled.Container>
      </Styled.PartnersV3>

      <Styled.MissionV3>
        <Styled.Container>
          <Styled.MissionDescriptionV3>
            Our mission is to provide advertisers with the data they need to
            drive better outcomes.
          </Styled.MissionDescriptionV3>

          <a href="https://attaindata.io/">
            <Styled.MissionCTA variant="contained">
              Learn more about Attain
            </Styled.MissionCTA>
          </a>

          <Styled.Attain href="https://attaindata.io/">
            <img src={SymbolAttain} alt="" />
            <span>attaindata.io</span>
          </Styled.Attain>
        </Styled.Container>
      </Styled.MissionV3>
    </Styled.Wrapper>
  );
};

export default SignupV3;
