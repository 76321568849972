import { gql } from '@apollo/client';

export const brandLiftSurveyInsightsNumResponsesQuery = gql`
  query BrandLiftSurveyInsightsNumResponses(
    $input: BrandLiftSurveyInsightsInput!
  ) {
    brandLiftSurveyInsightsNumResponses(input: $input) {
      totalResponses {
        value
        title
      }
      exposedResponses {
        value
        title
      }
      controlResponses {
        value
        title
      }
    }
  }
`;

export default brandLiftSurveyInsightsNumResponsesQuery;
