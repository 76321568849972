import React from 'react';
import SmartPersonaBody from './SmartPersonaBody';
import insightDemographicsQuery from 'operations/queries/insightDemographics.query';
import trackEvent from 'utils/trackingEvents';
import useMe from 'hooks/useMe/useMe';
import {
  InsightDemographicsQuery,
  InsightDemographicsQueryVariables,
} from 'types/graphql/generated';
import { useLazyQuery } from '@apollo/client';

interface Props {
  id: any;
  scrollDrawerToTop: () => any;
}

const SmartPersonaInsights = ({ id, scrollDrawerToTop }: Props) => {
  const me = useMe();
  const [getSmartPersonaData, { data, loading }] = useLazyQuery<
    InsightDemographicsQuery,
    InsightDemographicsQueryVariables
  >(insightDemographicsQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const persona = data && data?.insightDemographics;
  const demographics = data && persona?.demographics;
  const psychographics = data && persona?.psychographics;
  const mediaConsumption = data && persona?.mediaConsumption;

  React.useEffect(() => {
    if (id) {
      getSmartPersonaData({
        variables: {
          input: {
            id: id,
            filters: [],
          },
        },
        onCompleted(data) {
          trackEvent('Audience Insights', {
            ...me,
            audienceInput: id,
            ...data?.insightDemographics,
          });
        },
      });
    }
  }, [id]);

  return (
    <SmartPersonaBody
      demographics={{ data: demographics, loading: loading }}
      psychographics={{ data: psychographics, loading: loading }}
      mediaConsumption={{ data: mediaConsumption, loading: loading }}
      id={id}
      loading={loading}
      persona={persona}
      drawerCategory="Audience Insights"
      scrollDrawerToTop={scrollDrawerToTop}
    />
  );
};

export default SmartPersonaInsights;
