import styled from 'styled-components';
import { CSVLink } from 'react-csv';
import { MenuItem, theme } from '@klover/attain-design-system';

export const Wrapper = styled(MenuItem)`
  && {
    padding: ${theme.spacing.sm} 0;
  }
`;

export const Button = styled(CSVLink)`
  ${theme.typography.bodyLG};
  align-items: center;
  border: 0;
  background: none;
  color: ${theme.colors.buttonOutlinedTextDefault};
  cursor: pointer;
  display: flex;
  gap: ${theme.spacing.xxs};
  margin: 0 ${theme.spacing.md};
`;
