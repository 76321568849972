import React from 'react';
import { Base64ID } from 'types/models';
import {
  CampaignMeasurementBasicQuery,
  DateRangeInput,
  Deal,
  MeasurementOverviewCustomerType,
  Seat,
  ImpressionEntityFilterItem as Windows,
} from 'types/graphql/generated';
import { DateRange } from 'react-day-picker';
import { UseFormReturn, useForm } from 'react-hook-form';

export interface AllMeasurementInputs {
  attributionWindow: Base64ID;
  campaignId: Base64ID;
  customerType: MeasurementOverviewCustomerType;
  dateRange?: DateRangeInput;
  dealIds: string[];
  newCustomersWindow?: Base64ID;
  postPeriod?: number;
  useImpute?: boolean;
}

export interface MeasurementFormInterface {
  campaign?: CampaignMeasurementBasicQuery['campaign'] | null;
  seat?: Seat | null;
  deals?: Deal[] | null;
  window?: Windows | null;
  newCustomerWindow?: Windows | null;
  customerType?: MeasurementOverviewCustomerType | null;
  dateRange?: DateRange | null;
  postPeriod?: number;
  useImpute?: boolean;
}
export type MeasurementForm = UseFormReturn<MeasurementFormInterface, any>;
export const useMeasurementForm = (
  initialValues: MeasurementFormInterface = {}
) => {
  const form = useForm({
    defaultValues: {
      campaign: initialValues.campaign ?? null,
      seat: initialValues.seat ?? null,
      deals: initialValues.deals ?? null,
      window: initialValues.window ?? null,
      newCustomerWindow: initialValues.newCustomerWindow ?? null,
      customerType: initialValues.customerType ?? null,
      dateRange: initialValues.dateRange ?? null,
      postPeriod: initialValues.postPeriod ?? 30,
      useImpute: false,
    } as MeasurementFormInterface,
  });

  const getMeasurementInput = React.useCallback(() => {
    let inputObject: AllMeasurementInputs | null = null;
    const values = form.getValues();
    const ctype = values.campaign?.__typename;

    if (
      values.campaign &&
      values.window &&
      (ctype !== 'OpenXCampaign' || values.deals?.length)
    ) {
      const campaignB64ID =
        ctype === 'OpenXCampaign' || ctype === 'TtdCampaign'
          ? values.campaign?.b64Id
          : values.campaign?.id;
      inputObject = {
        campaignId: campaignB64ID,
        dealIds: values.deals?.map((deal) => deal.id) || [], //if deals doesn't exist its not an openXcampaign and doesn't require deals
        attributionWindow: values.window.id,
        customerType:
          values.customerType ?? MeasurementOverviewCustomerType.ALL_CUSTOMERS,
        postPeriod: values.postPeriod,
      };
      if (values.newCustomerWindow) {
        inputObject.newCustomersWindow = values.newCustomerWindow.id;
      }
      if (values.dateRange) {
        inputObject.dateRange = {
          startDate: values.dateRange?.from
            ? Math.floor(values.dateRange.from.getTime() / 1000)
            : 0,
          endDate: values.dateRange?.to
            ? Math.floor(values.dateRange.to.getTime() / 1000)
            : Math.floor(Date.now() / 1000),
        };
      }
      if (values.useImpute) {
        inputObject.useImpute = true;
      }
    }
    return inputObject;
  }, [form.watch()]);

  return {
    form,
    getMeasurementInput,
  };
};

export default useMeasurementForm;
