import Ball404 from 'assets/404-ball.svg';
import Header from 'components/Header/Header';
import React from 'react';
import Telescope404 from 'assets/404-telescope.svg';
import TelescopeFooter404 from 'assets/404-telescope-footer.svg';
import paths from 'constants/paths';
import useMe from 'hooks/useMe/useMe';
import { Button } from '@klover/attain-design-system';
import { Link } from 'react-router-dom';
import * as Styled from './403.styles';

export const _404 = () => {
  const me = useMe();
  const show404Version = React.useMemo(() => Math.floor(Math.random() * 4), []);

  React.useEffect(() => {
    window.analytics.page('Page view: 404', { ...me });
  }, []);

  return (
    <>
      <Header current="404: Page not found" />

      <Styled.Content>
        <Styled.Centered>
          {show404Version === 2 && (
            <Styled.Telescope src={Telescope404} alt="" />
          )}

          {show404Version === 3 && <Styled.Ball src={Ball404} alt="" />}

          <Styled.Title>
            {show404Version < 3 ? (
              <>Sorry, we couldn’t find that page!</>
            ) : (
              <>Looks like we dropped the ball.</>
            )}
          </Styled.Title>

          <Styled.Description>
            The page you are looking for is missing. Let’s get you back to the
            good stuff.
          </Styled.Description>

          <Link to={paths.HOME}>
            <Button variant="contained">View Home</Button>
          </Link>
        </Styled.Centered>

        {show404Version === 0 && (
          <Styled.TelescopeFooter>
            <Styled.TelescopeFooterImg src={TelescopeFooter404} alt="" />
          </Styled.TelescopeFooter>
        )}
      </Styled.Content>
    </>
  );
};

export default _404;
