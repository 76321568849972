import React from 'react';
import {
  Autocomplete,
  Badge,
  ListSubheader,
  TextField,
} from '@klover/attain-design-system';
import { AutocompleteNoOptionsText } from 'constants/strings';
import { CaretDown } from '@phosphor-icons/react';
import {
  OutcomeHqMerchantOrBrand,
  OutcomeHqMerchantsAndBrandsQuery,
} from 'types/graphql/generated';
import {
  getRecentResultsGeneric,
  setRecentResultsGeneric,
} from 'hooks/useRecentResultsGeneric/useRecentResultsGeneric';
import { outcomeHqMerchantsAndBrandsQuery } from 'operations/queries/outcomeHqMerchantsAndBrands.query';
import { useLazyQuery } from '@apollo/client';
import * as Styled from './AutocompleteBrandMerchant.styles';

interface AutocompleteBrandMerchantProps {
  onSelect: (value: any) => void;
  label?: string;
  localStorageName: string;
  disabled?: boolean;
  value: OutcomeHqMerchantOrBrand[];
}
export const AutocompleteBrandMerchant = ({
  onSelect,
  label,
  value,
  localStorageName,
  disabled,
}: AutocompleteBrandMerchantProps) => {
  const [bounce, setBounce] = React.useState<NodeJS.Timeout | null>();

  const [search, { data, loading }] =
    useLazyQuery<OutcomeHqMerchantsAndBrandsQuery>(
      outcomeHqMerchantsAndBrandsQuery
    );

  const handleInputChange = React.useCallback(
    (_, value) => {
      if (bounce) clearTimeout(bounce);

      setBounce(
        setTimeout(() => {
          setBounce(null);

          void search({
            variables: {
              input: {
                search: value,
              },
            },
          });
        }, 300)
      );
    },
    [setBounce, bounce]
  );

  const handleOnChange = React.useCallback(
    (_, value) => {
      if (value) {
        value.map((brandOrMerchant, i) => {
          if (i === value.length - 1) {
            setRecentResultsGeneric(localStorageName, brandOrMerchant);
          }
        });
      }
      onSelect(value);
    },
    [onSelect]
  );

  const results = data?.outcomeHqMerchantsAndBrands;
  const hasResults = results && results?.length;

  return (
    <Autocomplete
      noOptionsText={AutocompleteNoOptionsText}
      onChange={handleOnChange}
      multiple
      popupIcon={<CaretDown />}
      renderInput={(params) => (
        <TextField {...params} placeholder={label || 'Search for merchant'} />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={value}
      filterOptions={(options) => options}
      options={hasResults ? results : getRecentResultsGeneric(localStorageName)}
      onInputChange={handleInputChange}
      getOptionLabel={(option: OutcomeHqMerchantOrBrand) => option?.title || ''}
      loading={!!bounce || loading}
      disabled={disabled}
      groupBy={() => (hasResults ? '' : 'Recent')}
      renderGroup={(params) => (
        <>
          <ListSubheader>{hasResults ? '' : 'Recent'}</ListSubheader>
          {params.children}
        </>
      )}
      renderOption={(props, option) => (
        <Styled.Row {...props}>
          <Styled.RowTitle>{option.title}</Styled.RowTitle>

          <div>
            <Badge
              color={
                option.description === 'Brand'
                  ? 'brandPrimary'
                  : 'brandSecondary'
              }
            >
              {option.description}
            </Badge>
          </div>
        </Styled.Row>
      )}
    />
  );
};

export default AutocompleteBrandMerchant;
