import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

export const Wrapper = styled.div`
  align-items: center;
  background: ${theme.colors.backgroundPrimary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  width: 100vw;
`;

export const Logo = styled.img`
  display: block;
`;

export const Message = styled.p`
  ${theme.typography.bodyLG};
  max-width: 500px;
`;
