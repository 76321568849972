import styled from 'styled-components';
import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  theme,
} from '@klover/attain-design-system';

export const TitleWrapper = styled.div`
  background: ${theme.colors.backgroundPrimary};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
  margin: 0 -${theme.spacing.xl};
  padding: ${theme.spacing.lg} ${theme.spacing.xl};
  position: relative;

  @media ${theme.mq.tabletPortraitUp} {
    align-items: center;
    flex-direction: row;
  }
`;

export const Title = styled.div`
  flex-grow: 1;
`;

export const TitleText = styled.h1`
  ${theme.typography.headingLG};
  margin-inline-end: ${theme.spacing.sm};
  display: inline-block;
`;

export const CampaignType = styled.div`
  ${theme.typography.bodyLG};
  color: ${theme.colors.textTertiary};
`;

export const Measurements = styled(Grid)`
  padding-top: ${theme.spacing.lg};
`;

export const PersonaButton = styled(Button)`
  &&& {
    @media ${theme.mq.tabletPortraitUp} {
      align-self: start;
      padding-inline: ${theme.spacing.lg};
    }
  }
`;
export const StyledButtonGroup = styled(ButtonGroup)`
  &&& {
    @media ${theme.mq.tabletPortraitUp} {
      align-self: start;
      padding-inline: ${theme.spacing.lg};
    }
  }
`;

export const OutcomeAiButton = styled(Button)`
  background: linear-gradient(
    270deg,
    #67579e 0%,
    #d05b7f 62.74%,
    #df627a 71.14%,
    #ec6b74 79.76%,
    #f6756d 88.16%,
    #ff8166 100.14%
  );
  &&& {
    @media ${theme.mq.tabletPortraitUp} {
      align-self: start;
      padding-inline: ${theme.spacing.lg};
    }
  }
`;

export const AttWindow = styled.span`
  margin-inline-end: ${theme.spacing.sm};
`;

export const HeaderFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.colors.backgroundPrimary};
  margin: 0 -${theme.spacing.xl};
  padding: ${theme.spacing.sm} ${theme.spacing.xl};
  border-bottom: 1px solid ${theme.colors.borderDark};
  position: sticky;
  top: 63px; //height of header component.
  z-index: 2;
`;

export const FiltersChipContainer = styled.div`
  display: flex;
`;
export const FilterButtonsContainer = styled.div``;

export const CampaignListButton = styled(IconButton)`
  display: inline-block;
  bottom: ${theme.spacing.xxs};
`;

export const Search = styled.div`
  margin: -${theme.spacing.md};
`;

export const SearchOptionName = styled.div`
  ${theme.typography.bodyLG};
`;

export const SearchOptionType = styled.div`
  ${theme.typography.bodyMD};
  color: ${theme.colors.textTertiary};
`;

export const FilterChip = styled.div`
  ${theme.typography.bodyMD};
  background: ${theme.colors.backgroundPrimary};
  cursor: pointer;
  color: ${theme.colors.textTertiary};
  border: 1px solid ${theme.colors.borderDark};
  border-radius: ${theme.spacing.xxs};
  margin: 0 ${theme.spacing.xxs};
  padding: ${theme.spacing.xs} ${theme.spacing.xs};
  && span {
    color: ${theme.colors.text};
  }
`;

export const ResponsesTag = styled.div`
  ${theme.typography.bodyMD};
  color: ${theme.colors.textTertiary};
  padding: ${theme.spacing.xs} ${theme.spacing.xs};
  span {
    color: ${theme.colors.text};
  }
`;
