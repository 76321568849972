import paths from 'constants/paths';
import whereToGoQuery from 'operations/queries/whereToGo.query';
import { WhereToGoQuery } from 'types/graphql/generated';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

export const LoginRedirect = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();

  useQuery<WhereToGoQuery>(whereToGoQuery, {
    fetchPolicy: 'cache-first',
    onCompleted(data) {
      const whereToGo = data.whereToGo.__typename;
      let redirect = paths.HOME;
      switch (whereToGo) {
        case 'GoToOnboarding':
          redirect = '/onboarding';
          break;
        case 'GoToDashboard':
          redirect = paths.HOME;
          break;
      }
      navigate(redirect);
    },
    onError() {
      if (user?.email_verified) {
        navigate(paths.HOME);
      } else {
        navigate(paths.VERIFYEMAIL);
      }
    },
  });

  return (
    <div className="divLoader">
      <object
        className="svgLoader"
        data="favicon-light.svg"
        aria-label="loading..."
      ></object>
    </div>
  );
};

export default LoginRedirect;
