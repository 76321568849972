import { gql } from '@apollo/client';

export const streamCampaignInsightChatSubscription = gql`
  subscription StreamCampaignInsightChat(
    $input: StreamCampaignInsightChatInput!
  ) {
    streamCampaignInsightChat(input: $input) {
      ... on StreamCampaignInsightChatSuccess {
        context
        message
        messageId
        messageDone
      }
    }
  }
`;

export default streamCampaignInsightChatSubscription;
