import { gql } from '@apollo/client';

export const createSnapshotCampaignInsights = gql`
  mutation CreateSnapshotCampaignInsights(
    $input: CreateSnapshotCampaignInsightsInput!
  ) {
    createSnapshotCampaignInsights(input: $input) {
      ... on CreateSnapshotCampaignInsightsSuccess {
        id
      }
      ... on CreateSnapshotCampaignInsightsError {
        message
      }
    }
  }
`;

export default createSnapshotCampaignInsights;
