import GhostLoader from 'react-ghost-loader';
import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import { Square } from '@phosphor-icons/react';
import { format } from 'friendly-numbers';
import { theme } from '@klover/attain-design-system';
import * as Styled from './MeasurementChart.styles';

interface Props {
  data: any;
  label: string;
}

export const MeasurementChart = ({ data, label }: Props) => {
  const [ticksLine, setTicksLine] = React.useState<number[]>([]);
  const barTheme = {
    fontSize: 14,
    axis: {
      ticks: {
        text: {
          fill: theme.colors.textTertiary,
        },
      },
    },
    grid: {
      line: {
        stroke: '#D9D9D9',
      },
    },
  };

  const lineTheme = {
    fontSize: 14,
    axis: {
      ticks: {
        text: {
          fill: theme.colors.textTertiary,
        },
      },
    },
  };

  const chartRef = React.useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = React.useState<number | null>(null);
  const totalDays = data[0].data.length;
  const overallPadding = chartWidth && (1 / totalDays) * (chartWidth / 2.1);
  const overallMargin = 69;
  const dotsMargin = overallPadding && overallMargin + overallPadding;
  const ticks = 5;
  const values = [...data[0].data.map((obj) => obj.value)];
  const highestBar = Math.max(...values);
  const ticksBar = Array.from({ length: ticks + 1 }, (_, i) => {
    return (highestBar / ticks) * i;
  }).reverse();

  React.useEffect(() => {
    if (chartRef.current) {
      setChartWidth(chartRef.current.offsetWidth);
    }
  }, [chartRef]);

  React.useEffect(() => {
    if (
      data &&
      data[0] &&
      data[0].data &&
      data[0].data.length > 0 &&
      data[0].data[data[0].data.length - 1] &&
      data[0].data[data[0].data.length - 1].y !== undefined
    ) {
      setTicksLine(
        Array.from({ length: ticks + 1 }, (_, i) => {
          return (data[0].data[data[0].data.length - 1].y / ticks) * i;
        }).reverse()
      );
    }
  }, [data]);

  return (
    <Styled.Wrapper ref={chartRef}>
      <Styled.LeftAxis>
        {ticksLine?.map((value) => (
          <Styled.axisLeftItem
            key={value}
            value={format(value, {
              formattedDecimals: value >= 1000000 ? 1 : 0,
            })}
          />
        ))}
      </Styled.LeftAxis>

      <Styled.RightAxis>
        {ticksBar?.map((value) => (
          <Styled.axisRightItem
            key={value}
            value={format(value, {
              formattedDecimals: value >= 1000000 ? 1 : 0,
            })}
          />
        ))}
      </Styled.RightAxis>

      <Styled.BottomAxis>
        <span>{data[0].startDate}</span>
        <span>{data[0].midDate}</span>
        <span>{data[0].endDate}</span>
      </Styled.BottomAxis>

      <ResponsiveBar
        animate={false}
        axisBottom={null}
        axisLeft={null}
        axisTop={null}
        data={data[0].data}
        enableGridX={false}
        enableGridY={false}
        indexBy="bar"
        margin={{
          top: 6,
          right: overallMargin,
          bottom: 72,
          left: overallMargin,
        }}
        padding={0.25}
        theme={barTheme}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: false }}
        colors={theme.colors.CHART_TERTIARY}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        layout={'vertical'}
        isInteractive={true}
      />

      <Styled.GridLines>
        {ticksBar?.map((v) => (
          <span key={v}></span>
        ))}
      </Styled.GridLines>

      {overallPadding && (
        <ResponsiveLine
          animate={false}
          axisLeft={null}
          axisBottom={null}
          theme={lineTheme}
          colors={theme.colors.CHART_PRIMARY}
          data={data}
          enableGridX={false}
          enableGridY={false}
          margin={{
            top: 6,
            right: dotsMargin ? dotsMargin - 2 : overallMargin,
            bottom: 72,
            left: dotsMargin ? dotsMargin - 3 : overallMargin,
          }}
          isInteractive={true}
          enableSlices="x"
          pointSize={8}
          pointBorderWidth={0}
          sliceTooltip={(sliceData) => {
            const idx = sliceData.slice.points[0].index;
            const pointTotal = sliceData.slice.points[0];
            const pointDaily = data[0].data[idx];

            if (pointTotal) {
              return (
                <Styled.Tooltip id="info-tooltip">
                  <Styled.LabelWrapper>
                    <Square
                      size={18}
                      fill={theme.colors.backgroundPrimary}
                      weight="fill"
                    />
                    <Styled.Label>Date:</Styled.Label>
                    {pointTotal.data.x as any}
                  </Styled.LabelWrapper>

                  <Styled.LabelWrapper>
                    <Square
                      size={18}
                      fill={theme.colors.CHART_TERTIARY}
                      weight="fill"
                    />
                    <Styled.Label>Daily {label}:</Styled.Label>
                    {pointDaily.value.toLocaleString()}
                  </Styled.LabelWrapper>

                  <Styled.LabelWrapper>
                    <Square size={18} fill={pointTotal.color} weight="fill" />
                    <Styled.Label>Total {label}:</Styled.Label>
                    {pointTotal.data.y.toLocaleString()}
                  </Styled.LabelWrapper>
                </Styled.Tooltip>
              );
            } else {
              return <></>;
            }
          }}
        />
      )}
    </Styled.Wrapper>
  );
};

export const MeasurementChartLoading = () => {
  return (
    <Styled.Wrapper>
      <GhostLoader
        height="100%"
        heightRandom={0}
        width="100%"
        widthRandom={0}
      />
    </Styled.Wrapper>
  );
};

export default MeasurementChart;
