import { ImpressionEntityFilterItem } from 'types/graphql/generated';
import { MenuItem, Select } from '@klover/attain-design-system';

interface Props {
  disabled: boolean;
  value: ImpressionEntityFilterItem | null;
  onChange: (value: ImpressionEntityFilterItem | null) => void;
  attributionWindows: ImpressionEntityFilterItem[] | undefined;
}

export const AttributionWindowSelect = ({
  disabled,
  value,
  onChange,
  attributionWindows,
}: Props) => {
  return (
    <Select
      disabled={disabled}
      variant="outlined"
      onChange={(e) => {
        const val = attributionWindows?.find(
          (item) => item.title === e.target.value
        );
        onChange(val ?? null);
      }}
      value={value?.title || ''}
    >
      {attributionWindows?.map((window, i) => (
        <MenuItem value={window.title} key={i}>
          {window.title}
        </MenuItem>
      ))}
    </Select>
  );
};

export default AttributionWindowSelect;
