import React from 'react';
import * as Styled from '../MeasurementFilters.styles';

interface Props {
  title: string;
  badgeText?: string;
  defaultExpanded?: boolean;
}

export const Filter = ({
  children,
  title,
  badgeText,
  defaultExpanded,
}: React.PropsWithChildren<Props>) => {
  return (
    <Styled.Filter
      defaultExpanded={defaultExpanded}
      header={
        <Styled.FilterHeader>
          <Styled.FilterTitle>{title}</Styled.FilterTitle>

          {badgeText && <Styled.FilterBadge>{badgeText}</Styled.FilterBadge>}
        </Styled.FilterHeader>
      }
    >
      <Styled.FilterInput>{children}</Styled.FilterInput>
    </Styled.Filter>
  );
};

export default Filter;
