import * as Styled from './FallbackPageLoad.styles';

const FallbackPageLoad = () => {
  return (
    <Styled.Wrapper>
      <object
        className="svgLoader"
        data="favicon-light.svg"
        aria-label="loading..."
      ></object>
    </Styled.Wrapper>
  );
};

export default FallbackPageLoad;
