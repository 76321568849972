import { gql } from '@apollo/client';

export const brandLiftSurveyQuestionTypesQuery = gql`
  query BrandLiftSurveyInsightsQuestionTypes(
    $input: BrandLiftSurveyInsightsInput!
  ) {
    brandLiftSurveyInsightsQuestionTypes(input: $input) {
      questionTypes {
        templateId
        title
      }
      isBrand
    }
  }
`;

export default brandLiftSurveyQuestionTypesQuery;
