import { gql } from '@apollo/client';

export const whereToGoQuery = gql`
  query WhereToGo {
    whereToGo {
      ... on GoToOnboarding {
        placeholder
      }
      ... on GoToDashboard {
        placeholder
      }
      ... on GoToBlocked {
        placeholder
      }
      ... on GoToError {
        message
      }
    }
  }
`;

export default whereToGoQuery;
