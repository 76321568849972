import { gql } from '@apollo/client';

export const outcomeHqMerchantsAndBrandsQuery = gql`
  query OutcomeHqMerchantsAndBrands($input: OutcomeHqMerchantsAndBrandsInput!) {
    outcomeHqMerchantsAndBrands(input: $input) {
      id
      description
      b64Id
      title
    }
  }
`;
