import { Badge } from '@klover/attain-design-system';
import * as Styled from './ComingSoonBadge.styles';

export const ComingSoonBadge = () => {
  return (
    <Styled.Wrapper>
      <Badge color="brandSecondary">Coming soon!</Badge>
    </Styled.Wrapper>
  );
};

export default ComingSoonBadge;
