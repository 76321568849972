import GhostLoader from 'react-ghost-loader';
import { Card } from '@klover/attain-design-system';
import * as Styled from '../MeasurementOptimization/MeasurementOptimization.styles';

export const TableSkeletonLoader = () => {
  return (
    <Card>
      <Styled.TableHeader>
        <GhostLoader height="42px" heightRandom={0} width="100%" />
      </Styled.TableHeader>

      <Styled.Table>
        <thead>
          <tr>
            <Styled.HeaderFull>
              <GhostLoader height="36px" heightRandom={0} width="100%" />
            </Styled.HeaderFull>
            <Styled.HeaderCVR>
              <GhostLoader height="36px" heightRandom={0} width="100%" />
            </Styled.HeaderCVR>
            <Styled.HeaderCVR>
              <GhostLoader height="36px" heightRandom={0} width="100%" />
            </Styled.HeaderCVR>
            <Styled.HeaderROAS>
              <GhostLoader height="36px" heightRandom={0} width="100%" />
            </Styled.HeaderROAS>
          </tr>
        </thead>
        <tbody>
          {[...Array(5)].map((_, i) => (
            <tr key={i}>
              <Styled.CellTwoCol>
                <GhostLoader height="36px" heightRandom={0} width="100%" />
              </Styled.CellTwoCol>
              <td>
                <Styled.CellRate>
                  <GhostLoader height="36px" heightRandom={0} width="100%" />
                </Styled.CellRate>
              </td>
              <td>
                <Styled.CellRate>
                  <GhostLoader height="36px" heightRandom={0} width="100%" />
                </Styled.CellRate>
              </td>
              <td>
                <Styled.CellRate>
                  <GhostLoader height="36px" heightRandom={0} width="100%" />
                </Styled.CellRate>
              </td>
            </tr>
          ))}
        </tbody>
      </Styled.Table>
    </Card>
  );
};

export default TableSkeletonLoader;
