import { gql } from '@apollo/client';

export const stableSeedFragment = gql`
  fragment stableSeedFragment on StableSeed {
    sampleSize {
      title
      description
      bars
    }
    audienceSize {
      value
      title
    }
    stability {
      title
      description
      bars
    }
  }
`;

export default stableSeedFragment;
