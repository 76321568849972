import styled from 'styled-components';
import { theme } from '@klover/attain-design-system';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: clip;
`;

export const WrapperLoading = styled(Wrapper)`
  opacity: 0.25;
`;

export const CenterBoth = styled.div`
  align-items: center;
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
`;

export const Loader = styled.div`
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: ${theme.colors.backgroundBrand};
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;

  @keyframes l3 {
    to {
      transform: rotate(1turn);
    }
  }
`;

export const Center = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  animation: 2s fadeIn ease-out 1 both;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const RegionTitle = styled.div`
  ${theme.typography.headingXS};
  background: ${theme.colors.backgroundPrimary};
  border-radius: ${theme.spacing.xs};
  color: ${theme.colors.CHART_PRIMARY};
  left: 21.5%;
  padding: ${theme.spacing.xxs} ${theme.spacing.sm};
  position: absolute;
  text-transform: uppercase;
  top: 10%;
  translate: -50% -50%;
`;

export const RegionTitleMidwest = styled(RegionTitle)`
  color: ${theme.colors.CHART_TERTIARY};
  left: 58%;
  top: 17%;
`;

export const RegionTitleNortheast = styled(RegionTitle)`
  color: ${theme.colors.CHART_6};
  left: 78%;
`;

export const RegionTitleSouth = styled(RegionTitle)`
  color: ${theme.colors.CHART_4};
  left: 61%;
  top: 85%;
`;
