import { gql } from '@apollo/client';

export const measurementInsightsGeoDataQuery = gql`
  query MeasurementInsightsGeoData($input: MeasurementOverviewInput!) {
    measurementInsightsGeoData(input: $input) {
      divisions {
        division
        percent {
          value
          title
        }
      }
      dmas {
        division
        percent {
          value
          title
        }
      }
    }
  }
`;
