import { gql } from '@apollo/client';

export const campaignsQuery = gql`
  query Campaigns {
    campaigns {
      campaigns {
        agency {
          id
          name
        }
        b64Id
        createdAt
        createdBy
        dealIds
        id
        partner
        status
        title
      }
    }
  }
`;

export default campaignsQuery;
