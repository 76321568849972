import ChartAbsoluteLift from './BrandSurveyCharts/ChartAbsoluteLift';
import DataTable from 'components/DataTable/DataTable';
import { Card, Grid, theme } from '@klover/attain-design-system';
import * as Styled from './MeasurementBrandSurvey.styles';

const data = [
  {
    title: 'Under $50K',
    control: 45,
    exposed: 115,
    baselineRecall: 0.5556,
    absoluteLift: -0.0207,
    lowerCI: -0.0766,
    upperCI: 0.0353,
    pValue: -0.714,
    isSignificant: false,
    charts: [
      {
        type: 'absolute',
        values: [
          {
            name: 'Control',
            value: 0.5263,
            confidenceInterval: [0, 0.1119],
          },
          {
            name: 'Exposed',
            value: 0.5428,
            confidenceInterval: [0, 0.1119],
          },
        ],
      },
    ],
  },
  {
    title: '$50-100K',
    control: 15,
    exposed: 45,
    baselineRecall: 0.375,
    absoluteLift: 0.1875,
    lowerCI: 0.0963,
    upperCI: 0.2787,
    pValue: 4.108,
    isSignificant: true,
    charts: [
      {
        type: 'absolute',
        values: [
          {
            name: 'Control',
            value: 0.375,
            confidenceInterval: [0, 0.1824],
          },
          {
            name: 'Exposed',
            value: 0.5625,
            confidenceInterval: [0, 0.1824],
          },
        ],
      },
    ],
  },
  {
    title: '$100K+',
    control: 7,
    exposed: 21,
    baselineRecall: 0.4667,
    absoluteLift: 0.1896,
    lowerCI: 0.0515,
    upperCI: 0.3277,
    pValue: 2.648,
    isSignificant: true,
    charts: [
      {
        type: 'absolute',
        values: [
          {
            name: 'Control',
            value: 0.4667,
            confidenceInterval: [0, 0.2762],
          },
          {
            name: 'Exposed',
            value: 0.6563,
            confidenceInterval: [0, 0.2762],
          },
        ],
      },
    ],
  },
];

const columns = [
  {
    title: 'Income',
    sort: 'income',
  },
  {
    title: '# Control',
    sort: 'control',
  },
  {
    title: '# Exposed',
    sort: 'exposed',
  },
  {
    title: 'Baseline recall',
    sort: 'baselineRecall',
  },
  {
    title: 'Absolute lift',
    sort: 'absoluteLift',
  },
  {
    title: 'Lower CI',
    sort: 'lowerCI',
  },
  {
    title: 'Upper CI',
    sort: 'upperCI',
  },
  {
    title: 'P value',
    sort: 'pValue',
  },
  {
    title: 'Is significant',
    sort: 'isSignificant',
  },
];

const rows = data.map((row) => ({
  sortValues: {
    income: row.title,
    control: row.control,
    exposed: row.exposed,
    baselineRecall: row.baselineRecall,
    absoluteLift: row.absoluteLift,
    lowerCI: row.lowerCI,
    upperCI: row.upperCI,
    pValue: row.pValue,
    isSignificant: row.isSignificant,
  },
  component: () => (
    <>
      <td>{row.title}</td>
      <td>{row.control}</td>
      <td>{row.exposed}</td>
      <td>{(row.baselineRecall * 100).toFixed(2)}%</td>
      <td>{(row.absoluteLift * 100).toFixed(2)}%</td>
      <td>{(row.lowerCI * 100).toFixed(2)}%</td>
      <td>{(row.upperCI * 100).toFixed(2)}%</td>
      <td>{row.pValue}</td>
      <td>{row.isSignificant ? 'TRUE' : 'FALSE'}</td>
      <td></td>
    </>
  ),
}));

export const MeasurementBrandRecallIncome = () => {
  return (
    <Card>
      <Grid
        container
        columnSpacing={theme.spacing.xxl}
        rowSpacing={theme.spacing.lg}
      >
        <Grid item xs={12}>
          <Styled.CardHeader>Brand recall by income</Styled.CardHeader>

          <Styled.CardDescription>
            Which of the following brands of lump charcoal have you heard of?
          </Styled.CardDescription>
        </Grid>

        <Grid item xs={12} md={4}>
          <Styled.ChartHeader>Absolute lift - Under $50K</Styled.ChartHeader>
          {data[0]?.charts?.[0] && (
            <ChartAbsoluteLift chart={data[0].charts[0]} type="absolute" />
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <Styled.ChartHeader>Absolute lift - $50-100K</Styled.ChartHeader>
          {data[1]?.charts?.[0] && (
            <ChartAbsoluteLift chart={data[1].charts[0]} type="absolute" />
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <Styled.ChartHeader>Absolute lift - $100K+</Styled.ChartHeader>
          {data[2]?.charts?.[0] && (
            <ChartAbsoluteLift chart={data[2].charts[0]} type="absolute" />
          )}
        </Grid>

        <Grid item xs={4}>
          <Styled.Badges $stacked>
            <Styled.Badge>
              Absolute lift: <span>-2.07%</span>
            </Styled.Badge>
            <Styled.Badge>
              P value: <span>-0.714</span>
            </Styled.Badge>
            <Styled.Badge
              $borderColor={theme.colors.errorForeground}
              $color={theme.colors.errorBackground}
            >
              Significant lift: <span>FALSE</span>
            </Styled.Badge>
          </Styled.Badges>
        </Grid>

        <Grid item xs={4}>
          <Styled.Badges $stacked>
            <Styled.Badge>
              Absolute lift: <span>18.75%</span>
            </Styled.Badge>
            <Styled.Badge>
              P value: <span>4.108</span>
            </Styled.Badge>
            <Styled.Badge
              $borderColor={theme.colors.successForeground}
              $color={theme.colors.successBackground}
            >
              Significant lift: <span>TRUE</span>
            </Styled.Badge>
          </Styled.Badges>
        </Grid>

        <Grid item xs={4}>
          <Styled.Badges $stacked>
            <Styled.Badge>
              Absolute lift: <span>18.96%</span>
            </Styled.Badge>
            <Styled.Badge>
              P value: <span>2.648</span>
            </Styled.Badge>
            <Styled.Badge
              $borderColor={theme.colors.successForeground}
              $color={theme.colors.successBackground}
            >
              Significant lift: <span>TRUE</span>
            </Styled.Badge>
          </Styled.Badges>
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginInline: -24 }}>
            <DataTable
              id="brand-recall-age"
              columns={columns}
              rows={rows}
              noFooter
              noBorder
            />
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default MeasurementBrandRecallIncome;
