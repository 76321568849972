import { CardTypes, Grid } from '@klover/attain-design-system';
import { MeasurementChartLoading } from './MeasurementChart';
import { StatCardLoading } from 'components/StatCard/StatCard';

export const MeasurementGhostLoader = () => {
  return (
    <>
      <Grid item xs={12} sm={3} data-testid="ghostLoader">
        <StatCardLoading type={CardTypes.Inverted} />
      </Grid>

      {[...Array(3)].map((_, i) => (
        <Grid item xs={12} sm={3} key={i}>
          <StatCardLoading />
        </Grid>
      ))}

      {[...Array(2)].map((_, i) => (
        <Grid item xs={12} sm={6} key={i}>
          <StatCardLoading />
        </Grid>
      ))}

      {[...Array(2)].map((_, i) => (
        <Grid item xs={12} sm={6} key={i}>
          <StatCardLoading>
            <MeasurementChartLoading />
          </StatCardLoading>
        </Grid>
      ))}
    </>
  );
};

export default MeasurementGhostLoader;
