import IndexPointer from 'assets/index-pointer.svg';
import { ArrowDown, ArrowUp, Minus } from '@phosphor-icons/react';

import { IndexValue, IndexValueEnum } from 'types/graphql/generated';
import { theme } from '@klover/attain-design-system';
import * as Styled from './IndexScore.styles';

interface Props {
  index: IndexValue;
  maxIndex?: number | null;
  delay?: number;
  showScale?: boolean;
}

const IndexScore = ({
  index,
  maxIndex = 0,
  delay = 0,
  showScale = false,
}: Props) => {
  const getIndexStatus = (kind) => {
    return {
      isLow: kind === IndexValueEnum.LOW,
      isNeutral: kind === IndexValueEnum.NEUTRAL || kind === IndexValueEnum.NA,
      isHigh: kind === IndexValueEnum.HIGH,
    };
  };

  const calculatePointerLocation = (indexValue, maxIndex) => {
    return (indexValue / maxIndex) * 100;
  };

  const { isLow, isNeutral, isHigh } = getIndexStatus(index.kind);
  const pointerLocation = calculatePointerLocation(index.value, maxIndex);

  const safeMaxIndex = maxIndex ?? 1; // Default to 1 if maxIndex is null
  const lowWidth = (85 / safeMaxIndex) * 100;
  const neutralWidth = (20 / safeMaxIndex) * 100;
  const highWidth = 100 - lowWidth - neutralWidth;

  if (index && index.kind) {
    return (
      <Styled.Index>
        {showScale && (
          <Styled.Slider>
            <Styled.Negative $active={isLow} $delay={delay} $width={lowWidth} />
            <Styled.Neutral
              $active={isNeutral}
              $delay={delay}
              $width={neutralWidth}
            />
            <Styled.Positive
              $active={isHigh}
              $delay={delay}
              $width={highWidth}
            />

            <Styled.Pointer $left={pointerLocation} $delay={delay}>
              <img src={IndexPointer} alt="" />
            </Styled.Pointer>
          </Styled.Slider>
        )}

        {showScale && <Styled.Amount>{Math.round(index.value)}</Styled.Amount>}

        {isHigh && !showScale && (
          <Styled.CellPositive>
            <ArrowUp size={theme.icons.md} weight="bold" />
            {index.description}
          </Styled.CellPositive>
        )}
        {isLow && !showScale && (
          <Styled.CellNegative>
            <ArrowDown size={theme.icons.md} weight="bold" />
            {index.description}
          </Styled.CellNegative>
        )}
        {isNeutral && !showScale && (
          <Styled.CellNA>
            <Minus size={theme.icons.md} weight="bold" />
            {index.description}
          </Styled.CellNA>
        )}

        {!showScale && <Styled.Amount>{Math.round(index.value)}</Styled.Amount>}
      </Styled.Index>
    );
  } else {
    return (
      <Styled.Index>
        <Styled.CellNegative>Error</Styled.CellNegative>
      </Styled.Index>
    );
  }
};

export default IndexScore;
