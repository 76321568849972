import {
  AdapterDayjs,
  DesktopDatePicker,
  FormControl,
  Grid,
  LocalizationProvider,
  TextField,
} from '@klover/attain-design-system';
import { Control, Controller } from 'react-hook-form';

interface Props {
  control: Control;
}

export const CustomDateField = ({ control }: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <Controller
            name="customTimePeriodStart"
            control={control}
            rules={{ required: true, shouldUnregister: true }}
            render={({ field }) => (
              <DesktopDatePicker
                onChange={field.onChange}
                value={field.value || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Start Date',
                    }}
                  />
                )}
              />
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <Controller
            name="customTimePeriodEnd"
            control={control}
            rules={{ required: true, shouldUnregister: true }}
            render={({ field }) => (
              <DesktopDatePicker
                onChange={field.onChange}
                value={field.value || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'End Date',
                    }}
                  />
                )}
              />
            )}
          />
        </FormControl>
      </Grid>
    </LocalizationProvider>
  );
};

export default CustomDateField;
