import ComingSoonBadge from 'components/ComingSoonBadge/ComingSoonBadge';
import { Switch, Tooltip, theme } from '@klover/attain-design-system';
import * as Styled from './OptimizationsFilters.styles';

export interface OptimizationToggles {
  CVR: boolean;
  impVol: boolean;
  avgTxn: boolean;
  CPA: boolean;
  CTR: boolean;
  VCR: boolean;
  ROAS: boolean;
  attn: boolean;
}

interface Props {
  toggles: OptimizationToggles;
  setToggles: React.Dispatch<React.SetStateAction<OptimizationToggles>>;
  onClose: () => void;
  open: boolean;
}

export const OptimizationFilters = ({
  toggles,
  setToggles,
  onClose,
  open,
}: Props) => {
  return (
    <Styled.DrawerWrapper open={open} onClose={onClose} anchor="right">
      <Styled.Wrapper>
        <Styled.Main>
          <Styled.Header>
            <Styled.Title>Filters</Styled.Title>
            <Styled.Close size={theme.icons.lg} onClick={onClose} />
          </Styled.Header>
          <Styled.Filter>
            <Styled.FilterHeader>Conversion Rate</Styled.FilterHeader>
            <Switch
              checked={toggles.CVR}
              onChange={() =>
                setToggles((prev) => ({ ...prev, CVR: !prev.CVR }))
              }
            />
          </Styled.Filter>

          <Styled.Filter>
            <Styled.FilterHeader>Impression volume</Styled.FilterHeader>
            <Switch
              checked={toggles.impVol}
              onChange={() =>
                setToggles((prev) => ({ ...prev, impVol: !prev.impVol }))
              }
            />
          </Styled.Filter>

          <Styled.Filter>
            <Styled.FilterHeader>Avg. transaction amount</Styled.FilterHeader>
            <span>
              <ComingSoonBadge />
            </span>
            <Switch
              disabled
              checked={toggles.avgTxn}
              onChange={() =>
                setToggles((prev) => ({ ...prev, avgTxn: !prev.avgTxn }))
              }
            />
          </Styled.Filter>

          <Styled.Filter>
            <Styled.FilterHeader>CPA</Styled.FilterHeader>
            <Tooltip
              title={
                <div>
                  <h3>Action required to view</h3>
                  <div>
                    Send us your impession level media spend and this column
                    will come to life!
                  </div>
                </div>
              }
              placement="top"
              arrow
            >
              <span>
                <ComingSoonBadge />
              </span>
            </Tooltip>
            <Switch
              disabled
              checked={toggles.CPA}
              onChange={() =>
                setToggles((prev) => ({ ...prev, CPA: !prev.CPA }))
              }
            />
          </Styled.Filter>

          <Styled.Filter>
            <Styled.FilterHeader>CTR</Styled.FilterHeader>
            <Tooltip
              title={
                <div>
                  <h3>Action required to view</h3>
                  <div>
                    Share your campaign metrics and we can correlate them to
                    sales outcomes!
                  </div>
                </div>
              }
              placement="top"
              arrow
            >
              <span>
                <ComingSoonBadge />
              </span>
            </Tooltip>
            <Switch
              disabled
              checked={toggles.CTR}
              onChange={() =>
                setToggles((prev) => ({ ...prev, CTR: !prev.CTR }))
              }
            />
          </Styled.Filter>

          <Styled.Filter>
            <Styled.FilterHeader>VCR</Styled.FilterHeader>
            <Tooltip
              title={
                <div>
                  <h3>Action required to view</h3>
                  <div>
                    Share your campaign metrics and we can correlate them to
                    sales outcomes!
                  </div>
                </div>
              }
              placement="top"
              arrow
            >
              <span>
                <ComingSoonBadge />
              </span>
            </Tooltip>
            <Switch
              disabled
              checked={toggles.VCR}
              onChange={() =>
                setToggles((prev) => ({ ...prev, VCR: !prev.VCR }))
              }
            />
          </Styled.Filter>

          <Styled.Filter>
            <Styled.FilterHeader>ROAS</Styled.FilterHeader>
            <Tooltip
              title={
                <div>
                  <h3>Action required to view</h3>
                  <div>
                    Send us your impession level media spend and this column
                    will come to life!
                  </div>
                </div>
              }
              placement="top"
              arrow
            >
              <span>
                <ComingSoonBadge />
              </span>
            </Tooltip>
            <Switch
              checked={toggles.ROAS}
              onChange={() =>
                setToggles((prev) => ({ ...prev, ROAS: !prev.ROAS }))
              }
            />
          </Styled.Filter>

          <Styled.Filter>
            <Styled.FilterHeader>Attention</Styled.FilterHeader>
            <Tooltip
              title={
                <div>
                  <h3>Action required to view</h3>
                  <div>More details soon</div>
                </div>
              }
              placement="top"
              arrow
            >
              <span>
                <ComingSoonBadge />
              </span>
            </Tooltip>
            <Switch
              disabled
              checked={toggles.attn}
              onChange={() =>
                setToggles((prev) => ({ ...prev, attn: !prev.attn }))
              }
            />
          </Styled.Filter>
        </Styled.Main>
      </Styled.Wrapper>
    </Styled.DrawerWrapper>
  );
};

export default OptimizationFilters;
