import { gql } from '@apollo/client';

export const meQuery = gql`
  query Me {
    me {
      id
      avatar
      auth0Role
      env
      email
      featureFlags {
        ... on BooleanFeatureFlag {
          id
          boolean
        }
        ... on StringFeatureFlag {
          id
          string
        }
        ... on IntFeatureFlag {
          id
          int
        }
        ... on FloatFeatureFlag {
          id
          float
        }
      }
      firstName
      lastName
      work
      company
      brandOrMerchant
      industry
      jobRole
      jobLevel
      intercom
    }
  }
`;
