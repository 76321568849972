declare global {
  interface Window {
    analytics: any;
  }
}
import GlobalHeader from 'components/GlobalHeader/GlobalHeader';
import InternalApp from './InternalApp';
import LogRocket from 'logrocket';
import Login from 'views/Login';
import LoginRedirect from 'views/LoginRedirect';
import Mixpanel from 'mixpanel-browser';
import NavBar from 'components/NavBar';
import Onboarding from 'views/Onboarding';
import React from 'react';
import Signup from 'views/Signup';
import SignupDirect from 'views/SignupDirect';
import SignupV2 from 'views/SignupV2';
import SignupV3 from 'views/SignupV3';
import SmartPersona from 'components/SmartPersona/SmartPersonaInsights';
import SmartPersonaAudience from 'components/SmartPersona/SmartPersonaAudience';
import SmartPersonaMeasurement from 'components/SmartPersona/SmartPersonaMeasurement';
import VerifyEmail from 'views/VerifyEmail';
import _403 from 'views/403';
import _404 from 'views/404';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { getUrlParams } from 'utils/smartPersonas/urlParameters';
import { initialize } from 'react-ga';
import { paths } from './constants/paths';
import { useAuth0 } from '@auth0/auth0-react';
import { useGlobalState } from 'state';
import * as Styled from './App.styles';

const App = () => {
  const [state, actions] = useGlobalState();
  const { isLoading, error, user } = useAuth0();

  const location = useLocation();

  const positions = React.useRef(new Map());

  React.useEffect(() => {
    if (positions.current.has(location)) {
      const { x, y } = positions.current.get(location);
      window.scrollTo(x, y);
    } else {
      window.scrollTo(0, 0);
    }

    const handler = () => {
      positions.current.set(location, { x: window.scrollX, y: window.scrollY });
    };

    window.addEventListener('scroll', handler);

    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [location]);

  // google analytics
  if (import.meta.env.VITE_PROD) {
    initialize('G-X7XW5XXEJB');
  }

  React.useEffect(() => {
    const LOG_ROCKET_APP_ID = import.meta.env.VITE_LOG_ROCKET_APP_ID as string;
    const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN as string;
    const DATADOG_APPLICATION_ID = import.meta.env
      .VITE_DATADOG_APPLICATION_ID as string;
    const DATADOG_CLIENT_TOKEN = import.meta.env
      .VITE_DATADOG_CLIENT_TOKEN as string;
    const DATADOG_ENVIRONMENT = import.meta.env
      .VITE_DATADOG_ENVIRONMENT as string;

    if (LOG_ROCKET_APP_ID) {
      LogRocket.init(LOG_ROCKET_APP_ID, {
        network: {
          requestSanitizer: (request) => {
            request.headers['authorization'] = '';
            request.headers['Auth0-Client'] = '';
            return request;
          },
        },
        shouldDebugLog: !import.meta.env.VITE_PROD,
      });
    }

    if (MIXPANEL_TOKEN) {
      Mixpanel.init(MIXPANEL_TOKEN);
    }

    if (
      DATADOG_APPLICATION_ID &&
      DATADOG_CLIENT_TOKEN &&
      DATADOG_ENVIRONMENT &&
      !datadogRum.getInitConfiguration()
    ) {
      datadogRum.init({
        applicationId: DATADOG_APPLICATION_ID,
        clientToken: DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'ksaas-web',
        env: DATADOG_ENVIRONMENT,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
      datadogRum.startSessionReplayRecording();
    }

    const urlParams = getUrlParams();
    if (urlParams && urlParams.personas && urlParams.personaType) {
      actions.setSmartPersonaId(urlParams.personas, urlParams.personaType);
    }
  }, []);

  React.useEffect(() => {
    if (user && user.sub) {
      LogRocket.identify(user.sub, {
        name: user?.name ?? '',
        email: user?.email ?? '',
      });

      window.analytics.identify(user.sub, {
        name: user?.name ?? '',
        email: user?.email ?? '',
      });
    }
  }, [user]);

  const drawerRef = React.useRef(null);

  const scrollDrawerToTop = () => {
    if (drawerRef.current && (drawerRef.current as HTMLDivElement).children) {
      (
        (drawerRef.current as HTMLDivElement)?.children[2] as HTMLDivElement
      ).scroll({
        top: 0,
      });
    }
  };

  if (error) return <div>Oops... {error.message}</div>;

  if (isLoading) return <></>;

  const isMap = location.pathname === paths.MAP;

  return (
    <Styled.Wrapper>
      <Routes>
        <Route path={'/'} element={<Navigate to={paths.HOME} replace />} />
        <Route
          path="/*"
          element={
            <>
              {user && user.sub ? (
                <>
                  {isMap ? (
                    <InternalApp />
                  ) : (
                    <Styled.GlobalGrid>
                      <Styled.Header>
                        <GlobalHeader />
                      </Styled.Header>

                      <Styled.Nav>
                        <NavBar />
                      </Styled.Nav>

                      <Styled.ContentNew>
                        <InternalApp />
                      </Styled.ContentNew>
                    </Styled.GlobalGrid>
                  )}
                </>
              ) : (
                <Navigate to={paths.LOGIN} replace />
              )}
            </>
          }
        />
        <Route path={paths.LOGIN} element={<Login />} />
        <Route path={paths.SIGNUP} element={<Signup />} />
        <Route path={paths.SIGNUP_V1} element={<Signup />} />
        <Route path={paths.SIGNUP_COMPETITOR} element={<Signup />} />
        <Route path={paths.SIGNUP_DEMOGRAPHICS} element={<Signup />} />
        <Route path={paths.SIGNUP_MARKET_RESEARCH} element={<Signup />} />
        <Route path={paths.SIGNUP_MARKET_SHARE} element={<Signup />} />
        <Route path={paths.SIGNUP_CONQUESTING} element={<Signup />} />
        <Route path={paths.SIGNUP_V2} element={<SignupV2 />} />
        <Route path={paths.SIGNUP_V3} element={<SignupV3 />} />
        <Route path={paths.SIGNUP_DIRECT} element={<SignupDirect />} />
        <Route path={paths.ONBOARDING} element={<Onboarding />} />
        <Route path={paths.VERIFYEMAIL} element={<VerifyEmail />} />
        <Route path={paths.LOGIN_REDIRECT} element={<LoginRedirect />} />
        <Route path={'/403'} element={<_403 />} />
        <Route path={'*'} element={<_404 />} />
      </Routes>

      <Styled.DrawerWrapper
        ref={drawerRef}
        anchor="right"
        open={state.showDrawer}
        onClose={actions.hideDrawer}
      >
        {!state.drawerContents && state.smartPersonaType === 'insights' && (
          <SmartPersona
            id={state.smartPersonaId}
            scrollDrawerToTop={scrollDrawerToTop}
          />
        )}

        {!state.drawerContents && state.smartPersonaType === 'audience' && (
          <SmartPersonaAudience
            id={state.smartPersonaId}
            scrollDrawerToTop={scrollDrawerToTop}
          />
        )}

        {!state.drawerContents && state.smartPersonaType === 'measurement' && (
          <SmartPersonaMeasurement
            personaInput={state.smartPersonasInputData}
            scrollDrawerToTop={scrollDrawerToTop}
          />
        )}

        {state.drawerContents}
      </Styled.DrawerWrapper>

      {state.showToast && (
        <Styled.Toast
          data-test-id="global-toast"
          autoHideDuration={5000}
          open={state.showToast}
          error={state.toastError}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onClose={actions.hideToast}
          message={
            <>
              {state.toastHeader && (
                <Styled.ToastHeader>{state.toastHeader}</Styled.ToastHeader>
              )}
              {state.toastMsg}
            </>
          }
        />
      )}
    </Styled.Wrapper>
  );
};

export default App;
