import React from 'react';
import generateYTicksArray from 'utils/Measurement/generateYTicksArray';
import { ResponsiveLine, Serie } from '@nivo/line';
import { TrendsDataChart } from '../MeasurementInsightsDashboard';
import { format } from 'friendly-numbers';
import { theme } from '@klover/attain-design-system';
import * as ChartStyles from '../MeasurementChart.styles';
import * as Styled from './TrendsChart.styles';

interface Props {
  data: TrendsDataChart | null;
}
const TICKS = 4;
export const TrendsChart = ({ data }: Props) => {
  const lineTheme = {
    axis: {
      ticks: {
        text: {
          fontSize: 16,
          fill: theme.colors.textBrand,
        },
      },
    },
    grid: {
      line: {
        stroke: '#D9D9D9',
      },
    },
  };

  const chartRef = React.useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = React.useState<number | null>(null);
  const totalDays = data?.data?.length ?? 0;
  const overallPadding = chartWidth && (1 / totalDays) * (chartWidth / 2.1);
  const overallMargin = 80;

  const chartData: Serie[] = [{ id: 'Trends', data: data?.data ?? [] }];
  const yValues: number[] =
    data?.data?.map((i) => (typeof i.y === 'number' ? i.y : 0)) || [];

  const highestY = yValues.length ? Math.max(...yValues) : 0;
  const lowestY = yValues.length ? Math.min(...yValues) : 0;
  const ticksY: number[] = generateYTicksArray(lowestY, highestY, TICKS);
  React.useEffect(() => {
    if (chartRef.current) {
      setChartWidth(chartRef.current.offsetWidth);
    }
  }, [chartRef]);
  const sign = data?.sign ?? '';
  const isPostFix = sign === '%';
  return totalDays ? (
    <Styled.Wrapper ref={chartRef}>
      <ChartStyles.BottomAxis>
        <span>{<>{data?.startDate}</>}</span>
        <span>{<>{data?.midDate}</>}</span>
        <span>{<>{data?.endDate}</>}</span>
      </ChartStyles.BottomAxis>

      <Styled.ChartWrapper>
        <ResponsiveLine
          isInteractive={true}
          axisLeft={{
            tickSize: 0,
            tickPadding: 18,
            format: (val) => {
              const display = format(val, {
                formattedDecimals:
                  val < 100000 || highestY - lowestY < 100000 ? 2 : 1,
              });
              return isPostFix ? display + sign : sign + display;
            },
            tickValues: [...ticksY],
          }}
          axisBottom={null}
          theme={lineTheme}
          colors={theme.colors.CHART_PRIMARY}
          data={chartData}
          enableGridX={false}
          enableGridY={true}
          enableSlices="x"
          gridYValues={[...ticksY]}
          yScale={{
            type: 'linear',
            min: lowestY,
            max: highestY,
            stacked: false,
            reverse: false,
          }}
          margin={{
            top: 8,
            right:
              (overallPadding || 1) >= overallMargin
                ? overallPadding || 80
                : overallMargin,
            bottom: 36,
            left:
              (overallPadding || 1) >= overallMargin
                ? overallPadding || 80
                : overallMargin,
          }}
          pointSize={0}
          sliceTooltip={(data) => {
            const date = data.slice.points[0].data.xFormatted;
            const yVal = Number(data.slice.points[0].data.y).toLocaleString(
              undefined,
              { maximumFractionDigits: 2 }
            );
            return (
              <ChartStyles.Tooltip>
                <div>
                  <ChartStyles.Label>{`${
                    isPostFix ? yVal + sign : sign + yVal
                  }`}</ChartStyles.Label>
                </div>
                <div>
                  <ChartStyles.Label>{date}</ChartStyles.Label>
                </div>
              </ChartStyles.Tooltip>
            );
          }}
        />
      </Styled.ChartWrapper>
    </Styled.Wrapper>
  ) : null;
};

export default TrendsChart;
