import * as Styled from './YearOverYear.styles';

interface Props {
  internal?: boolean;
  title: string;
  amount: { value: number; title: any };
}

const YearOverYear = ({ internal, title, amount }: Props) => {
  return (
    <Styled.Wrapper $internal={internal}>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Details>
        <Styled.Amount>{amount.title}</Styled.Amount>
      </Styled.Details>
    </Styled.Wrapper>
  );
};

export default YearOverYear;
