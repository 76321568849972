import { Base64ID } from 'types/models';
import {
  DEFAULT_MEASUREMENT_ATTRIBUTION_WINDOW_ID,
  DEFAULT_MEASUREMENT_NEW_CUSTOMER_WINDOW_ID,
} from './measurementPaths';
import {
  IncrementalityQueryVariables,
  MeasurementConversionsInput,
  MeasurementNewBuyerLiftInput,
  MeasurementOptimizationInput,
  MeasurementOverviewCustomerType,
  MeasurementOverviewInput,
  MeasurementTrendedInput,
  MeasurementWeekOverWeekInput,
} from 'types/graphql/generated';
import { MeasurementSearchParams } from 'views/Measurement/MeasurementDashboard';

export const getMeasurementOverviewInputFromUrlParams = (
  campaignId: Base64ID,
  params: MeasurementSearchParams
) => {
  let inputObject: MeasurementOverviewInput | null = null;
  if (campaignId && params.attributionwindow) {
    inputObject = {
      campaignId: campaignId,
      dealIds: params.deals || [],
      attributionWindow: params.attributionwindow,
      customerType: MeasurementOverviewCustomerType.ALL_CUSTOMERS,
      dateRange: {
        startDate: params.datefrom ? Number(params.datefrom) : 0,
        endDate: params.dateto
          ? Number(params.dateto)
          : Math.floor(Date.now() / 1000),
      },
    };
  }
  return inputObject;
};

export const getMeasurementOptimizationInputFromUrlParams = (
  campaignId: Base64ID,
  params: MeasurementSearchParams
) => {
  let inputObject: MeasurementOptimizationInput | null = null;
  if (campaignId && params.attributionwindow) {
    inputObject = {
      campaignId: campaignId,
      dealIds: params.deals || [],
      attributionWindow: params.attributionwindow,
      newCustomersWindow:
        params.customertype === 'NET_NEW_CUSTOMERS'
          ? params.newcustomerwindow
          : undefined,
      customerType:
        params.customertype === 'ALL_CUSTOMERS'
          ? MeasurementOverviewCustomerType.ALL_CUSTOMERS
          : MeasurementOverviewCustomerType.NET_NEW_CUSTOMERS,
      dateRange: {
        startDate: params.datefrom ? Number(params.datefrom) : 0,
        endDate: params.dateto
          ? Number(params.dateto)
          : Math.floor(Date.now() / 1000),
      },
    };
    if (
      params.customertype ===
        MeasurementOverviewCustomerType.NET_NEW_CUSTOMERS &&
      inputObject
    ) {
      inputObject.newCustomersWindow = params.newcustomerwindow;
    }
  }
  return inputObject;
};

export const getMeasurementNewBuyerLiftInputFromSearchParams = (
  campaignId: Base64ID,
  params: MeasurementSearchParams
) => {
  let inputObject: MeasurementNewBuyerLiftInput | null = null;
  if (campaignId && params.attributionwindow) {
    inputObject = {
      campaignId: campaignId,
      dealIds: params.deals || [],
      attributionWindow: params.attributionwindow,
      newCustomersWindow:
        params.newcustomerwindow || DEFAULT_MEASUREMENT_NEW_CUSTOMER_WINDOW_ID,
      dateRange: {
        startDate: params.datefrom ? Number(params.datefrom) : 0,
        endDate: params.dateto
          ? Number(params.dateto)
          : Math.floor(Date.now() / 1000),
      },
    };
  }
  return inputObject;
};

export const getMeasurementIncrementalityInputFromSearchParams = (
  campaignId: Base64ID,
  params: MeasurementSearchParams
) => {
  let inputObject: IncrementalityQueryVariables | null = null;
  if (campaignId) {
    inputObject = {
      campaignId: campaignId,
      postPeriod:
        typeof params.postperiod === 'number' // allows for 0
          ? params.postperiod
          : parseInt(params.postperiod || '30'),
      input: {
        useImputedData: params.useImpute ?? false,
      },
    };
  }
  return inputObject;
};

export const getMeasurementTrendedMetricsInputFromSearchParams = (
  campaignId: Base64ID,
  params: MeasurementSearchParams
) => {
  const inputObject: MeasurementTrendedInput = {
    campaignId: campaignId,
    attributionWindow:
      params.attributionwindow || DEFAULT_MEASUREMENT_ATTRIBUTION_WINDOW_ID,
  };
  if (params.datefrom || params.dateto) {
    inputObject.dateRange = {
      startDate: params.datefrom ? Number(params.datefrom) : 0,
      endDate: params.dateto
        ? Number(params.dateto)
        : Math.floor(Date.now() / 1000),
    };
  }

  return inputObject;
};

export const getMeasurementConversionsInputFromSearchParams = (
  campaignId: Base64ID,
  params: MeasurementSearchParams
) => {
  let inputObject: MeasurementConversionsInput | null = null;
  if (campaignId) {
    inputObject = {
      campaignId: campaignId,
      dealIds: params.deals || [],
      attributionWindow:
        params.attributionwindow || DEFAULT_MEASUREMENT_ATTRIBUTION_WINDOW_ID,
      dateRange: {
        startDate: params.datefrom ? Number(params.datefrom) : 0,
        endDate: params.dateto
          ? Number(params.dateto)
          : Math.floor(Date.now() / 1000),
      },
    };
    if (
      params.customertype === MeasurementOverviewCustomerType.NET_NEW_CUSTOMERS
    ) {
      inputObject.newCustomersWindow =
        params.newcustomerwindow ?? DEFAULT_MEASUREMENT_NEW_CUSTOMER_WINDOW_ID;
    }
  }
  return inputObject;
};

export const getMeasurementWeekOverWeekInputFromSearchParams = (
  campaignId: Base64ID,
  params: MeasurementSearchParams
) => {
  let inputObject: MeasurementWeekOverWeekInput | null = null;
  if (campaignId) {
    inputObject = {
      campaignId: campaignId,
      dealIds: params.deals || [],
      attributionWindow:
        params.attributionwindow || DEFAULT_MEASUREMENT_ATTRIBUTION_WINDOW_ID,
      dateRange: {
        startDate: params.datefrom ? Number(params.datefrom) : 0,
        endDate: params.dateto
          ? Number(params.dateto)
          : Math.floor(Date.now() / 1000),
      },
      newCustomersWindow:
        params.newcustomerwindow || DEFAULT_MEASUREMENT_NEW_CUSTOMER_WINDOW_ID,
    };
  }
  return inputObject;
};
