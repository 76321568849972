import React from 'react';
import paths from 'constants/paths';
import { useAuth0 } from '@auth0/auth0-react';

export const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const redirect = `${window.location.origin}${paths.LOGIN_REDIRECT}`;

  React.useEffect(() => {
    window.analytics.page('Page view: Login');
    loginWithRedirect({
      authorizationParams: {
        redirectUri: redirect,
      },
    });
  }, []);

  return (
    <div className="divLoader">
      <object
        className="svgLoader"
        data="favicon-light.svg"
        aria-label="loading..."
      ></object>
    </div>
  );
};

export default Login;
