import { gql } from '@apollo/client';

export const measurementOptimizationQuery = gql`
  query MeasurementOptimizations($input: MeasurementOptimizationInput!) {
    measurementOptimizations(input: $input) {
      ... on MeasurementOptimizationSuccess {
        metrics {
          data {
            title
            columnType
            conversionRates {
              conversionTypeName
              totalConversionRate {
                value
                title
              }
              totalIndexValue {
                value
                title
                description
                kind
              }
              impressionVolume {
                value
                title
              }
              roas {
                value
                title
              }
              rank
              dealConversions {
                dealName
                dealSourceId
                brandOrMerchantName
                dealConversionRate {
                  value
                  title
                }
                dealIndex {
                  value
                  title
                  description
                  kind
                }
                dealRoas {
                  value
                  title
                }
                dealImpressionVolume {
                  value
                  title
                }
              }
            }
          }
          totalDealConversions {
            dealName
            dealSourceId
            brandOrMerchantName
            dealConversionRate {
              value
              title
            }
            dealIndex {
              value
              title
              description
              kind
            }
            dealRoas {
              value
              title
            }
            dealImpressionVolume {
              value
              title
            }
          }
        }
      }
      ... on MeasurementOptimizationError {
        message
      }
    }
  }
`;

export default measurementOptimizationQuery;
