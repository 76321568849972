import DataTable from 'components/DataTable/DataTable';
import React from 'react';
import measurementInsightsCltvQuery from 'operations/queries/measurementInsightsCltv.query';
import { Base64ID } from 'types/models';
import { Button, theme } from '@klover/attain-design-system';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { useQuery } from '@apollo/client';
import * as Styled from './CustomerLifetimeValue.styles';

interface CustomerLifetimeValueProps {
  campaignId: Base64ID;
  dealIds?: string[];
  attributionWindow?: Base64ID;
  newCustomersWindow?: Base64ID;
  startDate: number;
  endDate: number;
}

export const CustomerLifetimeValue = ({
  campaignId,
  dealIds,
  attributionWindow,
  newCustomersWindow,
  startDate,
  endDate,
}: CustomerLifetimeValueProps) => {
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [retention, setRetention] = React.useState(100);

  const input = {
    retentionFactor: 100,
    campaignId: campaignId,
    dealIds: dealIds ?? [],
    attributionWindow: attributionWindow,
    newCustomersWindow: newCustomersWindow,
    dateRange: {
      startDate: startDate,
      endDate: endDate,
    },
  };

  const { data, loading, refetch } = useQuery(measurementInsightsCltvQuery, {
    variables: {
      input: input,
    },
  });

  const formattedData = data && data.measurementInsightsCltv;

  const columns = [
    { title: 'Segment', sort: 'segment', width: '25%' },
    { title: 'Avg transaction amount (total)', sort: 'amount', width: '75%' },
  ];

  const highestValue =
    formattedData &&
    Math.max(
      formattedData?.highCltv?.value || 0,
      formattedData?.medCltv?.value || 0,
      formattedData?.lowCltv?.value || 0
    );

  const rows = formattedData
    ? [
        {
          sortValues: {
            segment: 'High',
            amount: formattedData.highCltv.value,
          },
          component: (index: number) => (
            <>
              <td>High</td>
              <td>
                <Styled.Amount>
                  <div>{formattedData.highCltv.title ?? ''}</div>

                  <Styled.DataTableBar
                    $value={formattedData.highCltv.value / highestValue}
                  />
                </Styled.Amount>
              </td>
            </>
          ),
        },
        {
          sortValues: {
            segment: 'Medium',
            amount: formattedData.medCltv.value,
          },
          component: (index: number) => (
            <>
              <td>Medium</td>
              <td>
                <Styled.Amount>
                  <div>{formattedData.medCltv.title ?? ''}</div>

                  <Styled.DataTableBar
                    $value={formattedData.medCltv.value / highestValue}
                  />
                </Styled.Amount>
              </td>
            </>
          ),
        },
        {
          sortValues: {
            segment: 'Low',
            amount: formattedData.lowCltv.value,
          },
          component: (index: number) => (
            <>
              <td>Low</td>
              <td>
                <Styled.Amount>
                  <div>{formattedData.lowCltv.title ?? ''}</div>

                  <Styled.DataTableBar
                    $value={formattedData.lowCltv.value / highestValue}
                  />
                </Styled.Amount>
              </td>
            </>
          ),
        },
      ]
    : [];

  const handleRefetch = async () => {
    setIsRefetching(true);
    try {
      await refetch({
        input: { ...input, retentionFactor: retention },
      });
    } finally {
      setIsRefetching(false);
    }
  };

  React.useEffect(() => {
    if (data) {
      setIsRefetching(false);
    }
  }, [data]);

  return (
    <DataTable
      id="customer-ltv"
      title="Customer lifetime value"
      loading={loading || isRefetching}
      columns={columns}
      rows={rows}
      customActions={
        <Styled.Retention>
          <span>Retention (%):</span>
          <Styled.RetentionInput
            value={retention}
            inputProps={{
              max: 100,
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            onChange={(event) => {
              const value = Number(event.target.value);
              if (value <= 100) {
                setRetention(value);
              }
            }}
          />

          <Button variant="contained" onClick={handleRefetch}>
            <MagnifyingGlass size={theme.icons.md} weight="bold" />
          </Button>
        </Styled.Retention>
      }
    />
  );
};

export default CustomerLifetimeValue;
