import IncrementalityMarker from 'components/Measurement/IncrementalityMarkers/IncrementalityMarkers';
import LiftDataNA from '../DataWarnings/liftDataNAThreshold';
import {
  BrandLiftBreakdown,
  BrandLiftBreakdownSegment,
} from 'types/graphql/generated';
import { BrandLiftSurveyDataWarning } from '../DataWarnings/dataWarning';
import { Datum } from '@nivo/line';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Info, Minus, Square } from '@phosphor-icons/react';
import { LiftsChart } from './LiftsChart';
import { PositiveTraiangle } from 'components/Icons/Icons';
import { Tooltip, theme } from '@klover/attain-design-system';
import * as Styled from '../LiftTable.styles';

const getBarColor = (bar) => bar.data.color;

export const chartCommonProps = {
  isInteractive: true,
  animate: true,
  enableLable: false,
  margin: { top: 0, right: 0, bottom: 0, left: 0 },
  padding: 0.3,
  layout: 'horizontal' as const,
  axisTop: null,
  axisLeft: null,
  axisBottom: null,
  rightAxis: null,
  labelSkipWidth: 12,
  labelSkipHeight: 12,
  labelTextColor: theme.colors.text,
  colors: getBarColor,
  maxValue: 100,
  minValue: 0,
};

export interface LiftChartDatum extends Datum {
  id: string;
  type: 'exposed' | 'control';
  lift: number;
  ciLower: number;
  ciUpper: number;
  color: string;
}

const getChartDataFromLift = (
  data: BrandLiftBreakdownSegment
): LiftChartDatum[] => {
  const chartData = [
    {
      id: 'exposed',
      type: 'exposed' as const,
      lift: (data.exposed.value?.value || 0) * 100,
      ciLower: (data.exposed.ci?.lower?.value || 0) * 100,
      ciUpper: (data.exposed.ci?.upper?.value || 0) * 100,
      color: theme.colors.CHART_PRIMARY,
    },
    {
      id: 'control',
      type: 'control' as const,
      lift: (data.control.value?.value || 0) * 100,
      ciLower: (data.control.ci?.lower?.value || 0) * 100,
      ciUpper: (data.control.ci?.upper?.value || 0) * 100,
      color: theme.colors.CHART_TERTIARY,
    },
  ];
  return chartData;
};

interface Props {
  liftTables: BrandLiftBreakdown[] | undefined;
  liftType: 'absoluteLift' | 'relativeLift';
}

export const LiftTable = ({ liftTables, liftType }: Props) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState<number | null>(null);

  useEffect(() => {
    const onResize = () => {
      if (chartRef.current) {
        setChartWidth(chartRef.current?.offsetWidth);
      }
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [chartRef.current?.offsetWidth]);

  const [liftsData, displayTables] = useMemo(() => {
    if (!liftTables) {
      return [null, null];
    }
    const displayTables = liftTables.toSorted((a, b) =>
      a.title < b.title ? -1 : 1
    );
    return [
      displayTables?.map((tableData) =>
        tableData.segments.map((data) => getChartDataFromLift(data))
      ),
      displayTables,
    ];
  }, [liftTables]);

  return (
    <div>
      {displayTables ? (
        <Styled.FullWidthTable>
          {displayTables.map((tableData, tableIdx) => (
            <Fragment key={tableData.id}>
              <thead>
                <tr>
                  <Styled.ThTitle>{tableData.title}</Styled.ThTitle>
                  {tableIdx === 0 ? (
                    <Styled.ThCount>Control</Styled.ThCount>
                  ) : (
                    <th></th>
                  )}
                  {tableIdx === 0 ? (
                    <Styled.ThCount>Exposed</Styled.ThCount>
                  ) : (
                    <th></th>
                  )}
                  {tableIdx === 0 ? (
                    <Styled.ThAbsoluteLiftSticky>
                      Absolute lift
                      <Tooltip
                        placement="top"
                        title="Absolute lift shows the brand lift difference between the exposed and control groups"
                      >
                        <Info />
                      </Tooltip>
                    </Styled.ThAbsoluteLiftSticky>
                  ) : (
                    <Styled.ThAbsoluteLift />
                  )}
                  <Styled.ThControlExposed />
                  {tableIdx === 0 ? (
                    <Styled.ThScaleSticky $width={chartWidth}>
                      <Styled.ScaleWrapper $width={chartWidth}>
                        <div>0%</div>
                        <div>10%</div>
                        <div>20%</div>
                        <div>30%</div>
                        <div>40%</div>
                        <div>50%</div>
                        <div>60%</div>
                        <div>70%</div>
                        <div>80%</div>
                        <div>90%</div>
                        <div>100%</div>
                      </Styled.ScaleWrapper>
                    </Styled.ThScaleSticky>
                  ) : (
                    <Styled.ThScale $width={chartWidth}>
                      <Styled.ScaleWrapper $width={chartWidth} />
                    </Styled.ThScale>
                  )}
                  <Styled.ThControlExposed />
                </tr>
              </thead>
              <tbody>
                {tableData.segments.map((segment, segIdx) => (
                  <tr key={segment.id}>
                    <td>
                      {segment.title}
                      <Styled.DescriptionWrapper>
                        {segment.description}
                      </Styled.DescriptionWrapper>
                    </td>

                    <Styled.TdCount>
                      n= {segment.control.count.title}
                    </Styled.TdCount>

                    <Styled.TdCount>
                      n= {segment.exposed.count.title}
                    </Styled.TdCount>

                    <Styled.TdAbsoluteLift>
                      <span>{segment[liftType]?.title ?? <LiftDataNA />}</span>
                      {!!segment[liftType]?.title && (
                        <IncrementalityMarker
                          significance={segment.significance}
                        />
                      )}
                    </Styled.TdAbsoluteLift>

                    <Styled.TdControllExposedLabels>
                      <Styled.ControlExposedLabels>
                        <div>Control</div>
                        <div>Exposed</div>
                      </Styled.ControlExposedLabels>
                    </Styled.TdControllExposedLabels>

                    <Styled.TdChart>
                      <Styled.ChartWrapper ref={chartRef}>
                        <LiftsChart
                          liftData={liftsData?.at(tableIdx)?.at(segIdx)}
                        />
                      </Styled.ChartWrapper>
                    </Styled.TdChart>

                    <Styled.TdControllExposed>
                      <Styled.ControlExposedWrapper>
                        <div>{segment.control.value?.title}</div>
                        <div>{segment.exposed.value?.title}</div>
                      </Styled.ControlExposedWrapper>
                    </Styled.TdControllExposed>
                  </tr>
                ))}
              </tbody>
            </Fragment>
          ))}
        </Styled.FullWidthTable>
      ) : (
        <BrandLiftSurveyDataWarning />
      )}
      <Styled.Legend>
        <Styled.LegendItem>
          Exposed
          <Square color={theme.colors.CHART_PRIMARY} weight="fill" />
        </Styled.LegendItem>
        <Styled.LegendItem>
          Control <Square color={theme.colors.CHART_TERTIARY} weight="fill" />
        </Styled.LegendItem>
        <Styled.LegendItem>
          Confidence interval range
          <Minus color={theme.colors.backgroundBrand} size={20} />
        </Styled.LegendItem>
        <Styled.LegendItem>
          Significantly positive (90% CI) <PositiveTraiangle />
        </Styled.LegendItem>
      </Styled.Legend>
    </div>
  );
};
