import ChartAbsoluteLift from './BrandSurveyCharts/ChartAbsoluteLift';
import DataTable from 'components/DataTable/DataTable';
import { Card, Grid, theme } from '@klover/attain-design-system';
import * as Styled from './MeasurementBrandSurvey.styles';

const data = [
  {
    title: 'Kingsford',
    numControl: 663,
    numExposed: 1948,
    baselineRecall: 0.4649,
    absoluteLift: 0.0631,
    pValue: 9.0305,
    isSignificant: true,
    charts: [
      {
        type: 'absolute',
        values: [
          {
            name: 'Control',
            value: 0.4649,
            confidenceInterval: [0, 0.027],
          },
          {
            name: 'Exposed',
            value: 0.528,
            confidenceInterval: [0, 0.027],
          },
        ],
      },
      {
        type: 'relative',
        values: [
          {
            name: 'Control',
            value: 0.4649,
            confidenceInterval: [0, 0.27],
          },
          {
            name: 'Exposed',
            value: 0.528,
            confidenceInterval: [0, 0.27],
          },
        ],
      },
      {
        type: 'headroom',
        values: [
          {
            name: 'Control',
            value: 0.4649,
            confidenceInterval: [0, 0.27],
          },
          {
            name: 'Exposed',
            value: 0.528,
            confidenceInterval: [0, 0.27],
          },
        ],
      },
    ],
  },
  {
    title: 'Royal Oak',
    numControl: 271,
    numExposed: 933,
    baselineRecall: 0.2724,
  },
  {
    title: 'None of these',
    numControl: 188,
    numExposed: 439,
    baselineRecall: 0.1889,
  },
  {
    title: 'Cowboy',
    numControl: 43,
    numExposed: 152,
    baselineRecall: 0.0432,
  },
  {
    title: 'Open Nature',
    numControl: 47,
    numExposed: 113,
    baselineRecall: 0.0472,
  },
];

const columns = [
  {
    title: 'Answer',
    sort: 'answer',
  },
  {
    title: '# Control',
    sort: 'control',
  },
  {
    title: '# Exposed',
    sort: 'exposed',
  },
  {
    title: 'Baseline recall',
    sort: 'baselineRecall',
  },
];

const rows = data?.map((row) => ({
  sortValues: {
    title: row.title,
    control: row.numControl,
    exposed: row.numExposed,
    baselineRecall: row.baselineRecall,
  },
  component: () => (
    <>
      <td>{row.title}</td>
      <td>{row.numControl}</td>
      <td>{row.numExposed}</td>
      <td>{(row.baselineRecall * 100).toFixed(2)}%</td>
      <td></td>
    </>
  ),
}));

export const MeasurementAdRecall = () => {
  return (
    <Card>
      <Grid
        container
        columnSpacing={theme.spacing.xxl}
        rowSpacing={theme.spacing.lg}
      >
        <Grid item xs={12}>
          <Styled.CardHeader>Ad recall</Styled.CardHeader>

          <Styled.CardDescription>
            Which of the following brands of lump charcoal have you seen
            advertised recently?
          </Styled.CardDescription>
        </Grid>

        <Grid item xs={12} md={4}>
          <Styled.ChartHeader>Absolute lift</Styled.ChartHeader>
          {data[0]?.charts?.[0] && (
            <ChartAbsoluteLift chart={data[0]?.charts?.[0]} type="absolute" />
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <Styled.ChartHeader>Relative lift</Styled.ChartHeader>
          {data[0]?.charts?.[1] && (
            <ChartAbsoluteLift chart={data[0]?.charts?.[1]} type="relative" />
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <Styled.ChartHeader>Headroom lift</Styled.ChartHeader>
          {data[0]?.charts?.[2] && (
            <ChartAbsoluteLift chart={data[0].charts[2]} type="headroom" />
          )}
        </Grid>

        <Grid item xs={12}>
          <Styled.Badges>
            <Styled.Badge>
              Absolute lift: <span>6.31%</span>
            </Styled.Badge>
            <Styled.Badge>
              P value: <span>9.0305</span>
            </Styled.Badge>
            <Styled.Badge
              $borderColor={theme.colors.successForeground}
              $color={theme.colors.successBackground}
            >
              Significant lift: <span>TRUE</span>
            </Styled.Badge>
          </Styled.Badges>
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginInline: -24 }}>
            <DataTable
              id="ad-recall"
              columns={columns}
              rows={rows}
              noFooter
              noBorder
            />
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default MeasurementAdRecall;
