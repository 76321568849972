import { gql } from '@apollo/client';

export const demoNarrationQuery = gql`
  query demoNarration($input: DemoNarrationInput!) {
    demoNarration(input: $input) {
      ... on DemoNarrationSuccess {
        message
      }
      ... on DemoNarrationError {
        message
      }
    }
  }
`;

export default demoNarrationQuery;
