import styled from 'styled-components';
import { Grid, theme } from '@klover/attain-design-system';

export const Header = styled.h3`
  ${theme.typography.headingSM};
`;

export const TableWrapper = styled.div`
  margin-block-end: ${theme.spacing.lg};
`;

export const TableCell = styled.td<{ $selected: boolean }>`
  background: ${({ $selected }) =>
    $selected && theme.colors.backgroundBrandLightest};
  cursor: pointer;

  &:first-child {
    border-inline-start: ${({ $selected }) =>
      $selected && `1px solid ${theme.colors.borderLight}`};
  }

  &:last-child {
    border-inline-end: ${({ $selected }) =>
      $selected && `1px solid ${theme.colors.borderLight}`};
  }
`;

export const AlignCenter = styled.div`
  align-items: center;
  display: inline-flex;
  gap: ${theme.spacing.xs};
  line-height: 36px;
`;

export const Logo = styled.img<{ src?: string; alt?: string }>`
  border: 1px solid ${theme.colors.borderDark};
  border-radius: ${theme.spacing.xxs};
  height: 24px;
  vertical-align: middle;
  width: 24px;
`;

export const VennWrapper = styled(Grid)`
  .highcharts-title,
  .highcharts-credits {
    display: none;
  }

  text {
    ${theme.typography.headingLG};
    font-size: 18px !important;
  }
`;

export const Label = styled(Grid)`
  ${theme.typography.label};
  text-align: center;
`;
