import { LiftChartDatum, chartCommonProps } from './LiftTable';
import { ResponsiveBarCanvas } from '@nivo/bar';
import { theme } from '@klover/attain-design-system';

interface Props {
  liftData: LiftChartDatum[] | undefined;
}

export const LiftsChart = ({ liftData }: Props) => {
  return (
    <ResponsiveBarCanvas
      {...chartCommonProps}
      enableGridX
      enableLabel={false}
      isInteractive={false}
      axisBottom={null}
      enableGridY={false}
      data={liftData || []}
      keys={['lift']}
      label={''}
      layers={[
        'grid',
        'axes',
        (ctx, { bars, width }) => {
          bars.forEach((bar) => {
            ctx.fillStyle =
              bar.data.data.type === 'exposed' ? '#67579E0D' : '#22DDD326';
            ctx.fillRect(bar.x, bar.y, width, bar.height);
          });
        },
        'bars',
        (ctx, { bars, xScale }) => {
          bars.forEach((bar) => {
            const xStart = xScale(Number(bar.data.data['ciLower']) ?? 0);
            const xEnd = xScale(Number(bar.data.data['ciUpper']) ?? 0);
            const rectWidth = xEnd - xStart;
            ctx.fillStyle = theme.colors.backgroundBrand;
            ctx.fillRect(xStart, bar.y + bar.height / 2 - 2.5, rectWidth, 5);
          });
        },
        'legends',
      ]}
    />
  );
};
