import styled from 'styled-components';
import {
  Button,
  FormControl,
  RadioGroup,
  theme,
} from '@klover/attain-design-system';

export const Block = styled.div`
  display: none;

  @media ${theme.mq.tabletPortraitUp} {
    max-width: 360px;
  }
`;

export const Step = styled.div`
  ${theme.typography.bodyMD}
  color: ${theme.colors.textTertiary};
`;

export const PreHeader = styled.div`
  ${theme.typography.headingSM}
  color: ${theme.colors.textTertiary};
  margin-top: ${theme.spacing.xs};
`;

export const Header = styled.h1`
  ${theme.typography.headingXL}
  margin-top: ${theme.spacing.xs};
`;

export const Instructions = styled.p`
  ${theme.typography.bodyLG};
  margin-top: ${theme.spacing.md};

  span {
    ${theme.typography.headingXS};
  }
`;

export const WorkFor = styled(RadioGroup)`
  margin-top: ${theme.spacing.xl};
`;

export const InputWrapper = styled(FormControl)`
  && {
    margin-top: ${theme.spacing.xl};
  }
`;

export const Submit = styled(Button)`
  && {
    margin-top: ${theme.spacing.xl};
  }
`;
