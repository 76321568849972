export const pushUrlParam = (name: string, value?: string | null) => {
  let updated = false;
  const url = window.location.href;
  const path = `${window.location.origin}${window.location.pathname}`;
  const queryString = url?.split('?')?.at(1);
  const searchParams = queryString?.split('&') ?? [];
  searchParams.forEach((param, idx) => {
    if (param.split('=')[0] === name) {
      searchParams[idx] = `${name}${value ? `=${value}` : ''}`;
      updated = true;
    }
  });
  if (!updated) {
    searchParams.push(`${name}${value ? `=${value}` : ''}`);
  }
  const newURL = `${path}?${searchParams.join('&')}`;
  history.pushState({}, '', newURL);
};

export const popUrlParam = (name: string) => {
  let value: string | null | undefined = null;
  const url = window.location.href;
  const path = `${window.location.origin}${window.location.pathname}`;
  const queryString = url?.split('?')?.at(1);
  const searchParams = queryString?.split('&') ?? [];
  const valueIdx = searchParams?.findIndex(
    (param) => param.split('=')[0] === name
  );
  if (valueIdx === 0 || (valueIdx && valueIdx > -1)) {
    value = searchParams ? searchParams.at(valueIdx)?.split('=').at(1) : null;
    searchParams && searchParams.splice(valueIdx, 1);
  }
  const newURL = searchParams?.length
    ? `${path}?${searchParams.join('&')}`
    : path;
  history.pushState({}, '', newURL);
  return value;
};

export const getUrlParams = () => {
  const url = window.location.href;
  const queryString = url.split('?')?.at(1);
  const searchParams = queryString?.split('&') ?? [];
  const searchParamObj: Record<string, unknown> = {};
  searchParams?.forEach((param) => {
    const [name, value] = param.split('=');
    searchParamObj[name] = value ?? true;
  });

  return searchParamObj;
};
