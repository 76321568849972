import React from 'react';
import inviteYourTeamMutation from 'operations/mutations/inviteYourTeam.mutation';
import trackEvent from 'utils/trackingEvents';
import useMe from 'hooks/useMe/useMe';
import { Button, Dialog, TextField } from '@klover/attain-design-system';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { InviteYourTeamInput, InvitedFrom } from 'types/graphql/generated';
import { useGlobalState } from 'state';
import { useMutation } from '@apollo/client';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  InviteLocation: InvitedFrom; // Needs OUTCOMEHQ enum added
}

const DialogInvitePeople = ({ isOpen, onClose }: Props) => {
  const [inviteTeamates] = useMutation(inviteYourTeamMutation);
  const [, actions] = useGlobalState();
  const { control, watch, handleSubmit } = useForm();
  const me = useMe();
  const onSubmit = React.useCallback(
    (data: FieldValues) => {
      if (data.teamEmails) {
        const emailsIntoArray = data.teamEmails
          .trim()
          .replace(' ', '')
          .split(',');

        inviteTeamates({
          variables: {
            input: {
              emails: emailsIntoArray,
              inviteFrom: InvitedFrom.MAIN_NAV,
            } as InviteYourTeamInput,
          },
        });
      }
      onClose();
      actions.showToast('', 'People successfully invited');
      trackEvent('Invite people', {
        ...me,
        invitedEmails: data.teamEmails,
      });
    },
    [inviteTeamates]
  );

  const validEmails = React.useMemo(() => {
    const emails = watch('teamEmails');
    let valid = true;
    if (emails) {
      const emailList = emails.replace(/\s/g, '').split(',');
      const regex =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      for (let i = 0; i < emailList.length; i++ && valid) {
        if (emailList[i] !== '' && !regex.test(emailList[i])) {
          valid = false;
        }
      }
    }
    return valid;
  }, [watch('teamEmails')]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      headerID="invitePeopleHeader"
      header="Invite people"
      bodyID="invitePeopleBody"
      body={
        <Controller
          name="teamEmails"
          control={control}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <TextField
              aria-labelledby="teamEmails-label"
              id="teamEmails"
              fullWidth
              placeholder="Enter emails (comma separated)"
              onChange={onChange}
              onBlur={onBlur}
              value={value ?? ''}
              name={name}
              inputRef={ref}
              error={!validEmails}
              helperText={!validEmails ? 'Invalid email address' : null}
            />
          )}
        />
      }
      footer={
        <>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={!validEmails}
          >
            Send invite
          </Button>
        </>
      }
    />
  );
};

export default DialogInvitePeople;
