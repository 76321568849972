import Back from './BackButton/BackButton';
import { Control, Controller } from 'react-hook-form';
import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@klover/attain-design-system';
import * as Styled from './Step.styles';

interface Props {
  step: number;
  prevPage: () => void;
  nextPage: () => void;
  watchRole: string | null;
  watchCustomRole: string | null;
  watchLevel: string | null;
  control: Control;
  roleOptions: string[] | undefined;
  levelOptions: string[] | undefined;
}

export const JobRoleStep = ({
  step,
  prevPage,
  nextPage,
  watchRole,
  watchCustomRole,
  watchLevel,
  control,
  roleOptions,
  levelOptions,
}: Props) => {
  return (
    <Styled.Block>
      <Back prevPage={prevPage} />

      <Styled.Step>Step {step} / 6</Styled.Step>

      <Styled.Header>What is your role?</Styled.Header>

      <Styled.InputWrapper fullWidth>
        <InputLabel id="role-lable" required>
          Job function
        </InputLabel>

        <Controller
          name="jobRole"
          control={control}
          rules={{ required: false }}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <Select
              variant="outlined"
              labelId="role-lable"
              id="role-title"
              fullWidth
              onChange={onChange}
              onBlur={onBlur}
              name={name}
              inputRef={ref}
              value={value ?? ''}
            >
              {roleOptions?.map((option, i) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
              <MenuItem value="other">Other</MenuItem>
            </Select>
          )}
        />
      </Styled.InputWrapper>

      {watchRole === 'other' && (
        <Styled.InputWrapper fullWidth>
          <Controller
            name="customJobRole"
            control={control}
            rules={{ required: false }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <TextField
                required
                placeholder="Enter your job function"
                fullWidth
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? ''}
                name={name}
                inputRef={ref}
              />
            )}
          />
        </Styled.InputWrapper>
      )}

      <Styled.InputWrapper fullWidth>
        <InputLabel id="level-label" required>
          Job level
        </InputLabel>

        <Controller
          name="jobLevel"
          control={control}
          rules={{ required: false }}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <Select
              variant="outlined"
              labelId="level-label"
              id="job-level"
              fullWidth
              onChange={onChange}
              onBlur={onBlur}
              name={name}
              inputRef={ref}
              value={value ?? ''}
            >
              {levelOptions?.map((option, i) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Styled.InputWrapper>

      <Styled.Submit
        variant="contained"
        fullWidth
        onClick={nextPage}
        disabled={
          (!Boolean(watchRole && watchRole !== 'other') &&
            !(watchRole === 'other' && watchCustomRole)) ||
          !Boolean(watchLevel)
        }
      >
        Next
      </Styled.Submit>
    </Styled.Block>
  );
};

export default JobRoleStep;
