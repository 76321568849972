import { gql } from '@apollo/client';

export const brandLiftSurveyInsightsAudienceQuery = gql`
  query BrandLiftSurveyInsightsAudience($input: BrandLiftSurveyInsightsInput!) {
    brandLiftSurveyInsightsAudience(input: $input) {
      audiences {
        id
        title
        segments {
          id
          title
          description
          absoluteLift {
            value
            title
          }
          exposed {
            value {
              value
              title
            }
            ci {
              upper {
                value
                title
              }
              lower {
                value
                title
              }
            }
            count {
              value
              title
            }
          }
          control {
            value {
              value
              title
            }
            ci {
              upper {
                value
                title
              }
              lower {
                value
                title
              }
            }
            count {
              value
              title
            }
          }
          significance
        }
      }
    }
  }
`;
export default brandLiftSurveyInsightsAudienceQuery;
