import LegendPoint from 'assets/legend-point-primary.svg';
import LegendSquare from 'assets/legend-square-tertiary.svg';
import MeasurementChart from 'views/Measurement/MeasurementChart';
import React from 'react';
import StatCard from 'components/StatCard/StatCard';
import useMe from 'hooks/useMe/useMe';
import { Alert, CardTypes, Grid } from '@klover/attain-design-system';
import {
  MeasurementOverviewDualAxis,
  MeasurementOverviewMetrics,
} from 'types/graphql/generated';
import { dataChart } from 'views/Measurement/MeasurementDashboard';
import * as Styled from '../Measurement.styles';

interface Props {
  showUniqueReachWarning: boolean;
  isNewCustomersSelected: boolean;
  metrics: MeasurementOverviewMetrics | undefined;
  impressions: MeasurementOverviewDualAxis | undefined;
  reach: MeasurementOverviewDualAxis | undefined;
  dataChartImpressions: dataChart[];
  dataChartReach: dataChart[];
}

export const MeasurementOverview = ({
  showUniqueReachWarning,
  isNewCustomersSelected,
  metrics,
  impressions,
  reach,
  dataChartImpressions,
  dataChartReach,
}: Props) => {
  const me = useMe();

  React.useEffect(() => {
    window.analytics.page('Page view: Measurement', {
      ...me,
    });
  }, []);

  return (
    <>
      {showUniqueReachWarning && (
        <Grid item xs={24}>
          <Alert severity="warning" title="Unique reach warning">
            The unique reach is below one million. Conversion metrics will not
            be displayed until this campaign reaches one million in unique
            reach.
          </Alert>
        </Grid>
      )}

      <Grid item xs={12} sm={3}>
        <div data-intercom-target="measurement-overview-conversion-rate-v2">
          <StatCard
            title={'Conversion rate'}
            detail={metrics?.conversionRate?.title ?? 'N/A'}
            type={CardTypes.Inverted}
          />
        </div>
      </Grid>

      <Grid item xs={12} sm={3}>
        <div
          data-intercom-target={
            isNewCustomersSelected
              ? 'measurement-overview-roas-new'
              : 'measurement-overview-roas'
          }
        >
          <StatCard title={'ROAS'} detail={metrics?.roas?.title ?? 'N/A'} />
        </div>
      </Grid>

      <Grid item xs={12} sm={3}>
        <div
          data-intercom-target={
            isNewCustomersSelected
              ? 'measurement-overview-projconversions-new'
              : 'measurement-overview-projconversions'
          }
        >
          <StatCard
            title={'Proj. conversions'}
            detail={metrics?.projectedConversions?.title ?? 'N/A'}
          />
        </div>
      </Grid>

      <Grid item xs={12} sm={3}>
        <div
          data-intercom-target={
            isNewCustomersSelected
              ? 'measurement-overview-salesdriven-new'
              : 'measurement-overview-salesdriven'
          }
        >
          <StatCard
            title={'Sales driven'}
            detail={metrics?.salesDriven?.title ?? 'N/A'}
            data-intercom-target="measurement-overview-sales-driven"
          />
        </div>
      </Grid>

      {isNewCustomersSelected && (
        <Grid item xs={12} sm={3}>
          <div data-intercom-target="measurement-overview-cpa">
            <StatCard title={'CPA'} detail={metrics?.cpa?.title ?? 'N/A'} />
          </div>
        </Grid>
      )}
      <Grid item xs={12} sm={isNewCustomersSelected ? 3 : 6}>
        <div
          data-intercom-target={
            isNewCustomersSelected
              ? 'measurement-overview-cpm-new'
              : 'measurement-overview-cpm'
          }
        >
          <StatCard title="CPM" detail={metrics?.cpm.title ?? 'N/A'} />
        </div>
      </Grid>

      <Grid item xs={12} sm={3}>
        <div
          data-intercom-target={
            isNewCustomersSelected
              ? 'measurement-overview-frequency-new'
              : 'measurement-overview-frequency'
          }
        >
          <StatCard
            title="Frequency"
            detail={metrics?.frequency.title ?? 'N/A'}
          />
        </div>
      </Grid>

      <Grid item xs={12} sm={3}>
        <div
          data-intercom-target={
            isNewCustomersSelected
              ? 'measurement-overview-mediaspend-new'
              : 'measurement-overview-mediaspend'
          }
        >
          <StatCard
            title="Media spend"
            detail={metrics?.mediaSpend?.title ?? 'N/A'}
          />
        </div>
      </Grid>

      <Grid item xs={12} sm={6}>
        <div
          data-intercom-target={
            isNewCustomersSelected
              ? 'measurement-overview-impressions-new'
              : 'measurement-overview-impressions'
          }
        >
          <StatCard
            title="Impressions"
            detail={impressions?.heroMetric?.title ?? 'N/A'}
          >
            {Number(impressions?.heroMetric?.title) !== 0 && (
              <>
                <MeasurementChart
                  data={dataChartImpressions}
                  label="impressions"
                />

                <Styled.Legend>
                  <div>
                    <Styled.Symbol>
                      <img src={LegendPoint} alt="" />
                    </Styled.Symbol>
                    <span>Running total impressions</span>
                  </div>

                  <div>
                    <Styled.Symbol>
                      <img src={LegendSquare} alt="" />
                    </Styled.Symbol>
                    <span>Daily impressions</span>
                  </div>
                </Styled.Legend>
              </>
            )}
          </StatCard>
        </div>
      </Grid>

      <Grid item xs={12} sm={6}>
        <div
          data-intercom-target={
            isNewCustomersSelected
              ? 'measurement-overview-uniquereach-new'
              : 'measurement-overview-uniquereach'
          }
        >
          <StatCard title="Unique Reach" detail={reach?.heroMetric.title}>
            {Number(reach?.heroMetric?.title) !== 0 && (
              <>
                <MeasurementChart data={dataChartReach} label="unique reach" />

                <Styled.Legend>
                  <div>
                    <Styled.Symbol>
                      <img src={LegendPoint} alt="" />
                    </Styled.Symbol>
                    <span>Running total unique reach</span>
                  </div>

                  <div>
                    <Styled.Symbol>
                      <img src={LegendSquare} alt="" />
                    </Styled.Symbol>
                    <span>Daily unique reach</span>
                  </div>
                </Styled.Legend>
              </>
            )}
          </StatCard>
        </div>
      </Grid>
    </>
  );
};
