import React from 'react';
import { MeQuery } from 'types/graphql/generated';
import { meQuery } from 'operations/queries/me.query';
import { useQuery } from '@apollo/client';

const useFeatureFlags = () => {
  const { data, loading, error } = useQuery<MeQuery>(meQuery, {
    fetchPolicy: 'cache-first',
  });

  return {
    flags: React.useMemo(
      () =>
        data?.me?.featureFlags?.reduce((obj, next) => {
          if (next.__typename === 'BooleanFeatureFlag')
            obj[next.id] = next.boolean;
          else if (next.__typename === 'StringFeatureFlag')
            obj[next.id] = next.string;
          else if (next.__typename === 'IntFeatureFlag')
            obj[next.id] = next.int;
          else if (next.__typename === 'FloatFeatureFlag')
            obj[next.id] = next.float;
          return obj;
        }, {}) || {},
      [data]
    ),
    isReady: !loading && !error,
    error,
  };
};

export default useFeatureFlags;
