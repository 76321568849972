import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

export const BreakdownCell = styled.td<{
  $collapsed?: boolean;
  colSpan?: number;
  children?: any;
}>`
  && {
    border: ${({ $collapsed }) => ($collapsed ? 'none' : 'inherit')};
    overflow: hidden;
    padding: 0;
  }
`;

export const Wrapper = styled.div<{ $collapsed?: boolean; children?: any }>`
  max-height: ${({ $collapsed }) => ($collapsed ? '0' : '100px')};
  padding: ${({ $collapsed }) =>
    $collapsed
      ? `0 ${theme.spacing.md}`
      : `0 ${theme.spacing.md} ${theme.spacing.sm}`};
  transition: ${({ $collapsed }) =>
    $collapsed ? 'all 0.25s ease-out' : 'all 0.25s ease-in'};
`;

export const BreakdownTitle = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: ${theme.spacing.xxs};
`;

export const BreakdownTitleName = styled.div`
  ${theme.typography.bodyMD};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 220px;
`;

export const FullBreakdown = styled.td`
  color: ${theme.colors.textBrand};
  cursor: pointer;
  width: 1px;
`;

export const VertCenter = styled.div`
  align-items: center;
  display: flex;
`;
