import { Buffer } from 'buffer';

export type Base64ID = string & {
  readonly Base64ID: unique symbol;
};

/**.
 * Confirm string is a Base64ID
 * @param {string} input The id to convert to Base64ID type
 */
export const isBase64ID = (str = ''): str is Base64ID => {
  if (
    str &&
    str.match(
      /`^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$`/
    )
  ) {
    // regex for valid base 64 encoded string
    return false;
  }
  const decoded = JSON.parse(Buffer.from(str, 'base64').toString('utf-8'));
  return decoded && decoded['_'] && decoded['$'];
};

/**
 *
 * @param {string} input - string to be converted to base64ID type
 * @returns {Base64ID} Error will be thrown if input is not valid base64 encoding.
 */
export const base64ID = (input: unknown): Base64ID => {
  if (typeof input !== 'string') {
    throw new Error('invalid input');
  }

  if (!isBase64ID(input)) {
    throw new Error('input is not valid base64 encoding');
  }

  return input;
};

/**.
 * Confirm string is a Base64ID and return the string as a Base64ID
 * @param {string} input The id to convert to Base64ID type
 * @returns {Base64ID | undefined} returns undefined if string is not base 64 encoded ID
 */
export const toBase64ID = (input?: string | null) => {
  if (!input) {
    return undefined;
  }
  return isBase64ID(input) ? (input as Base64ID) : undefined;
};

type StringOfLength<Min, Max> = string & {
  min: Min;
  max: Max;
  readonly StringOfLength: unique symbol; // this is the phantom type
};
// This is a type guard function which can be used to assert that a string
// is of type StringOfLength<Min,Max>
const isStringOfLength = <Min extends number, Max extends number>(
  str: string,
  min: Min,
  max: Max
): str is StringOfLength<Min, Max> => str.length >= min && str.length <= max;

export const stringOfLength = <Min extends number, Max extends number>(
  input: unknown,
  min: Min,
  max: Max
): StringOfLength<Min, Max> => {
  if (typeof input !== 'string') {
    throw new Error('invalid input');
  }

  if (!isStringOfLength(input, min, max)) {
    throw new Error('input is not between specified min and max');
  }

  return input; // the type of input here is now StringOfLength<Min,Max>
};
