import styled from 'styled-components';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { TextField, theme } from '@klover/attain-design-system';

export const Wrapper = styled(TextField)`
  && {
    .MuiInputBase-input {
      padding-inline-start: ${theme.spacing.sm};
    }
  }
`;

export const SearchIcon = styled(MagnifyingGlass)`
  && {
    color: ${theme.colors.textTertiary};
    margin-inline-end: ${theme.spacing.xs};
  }
`;
