import BrandOrAgencyStep from 'components/Onboarding/BrandOrAgencyStep';
import CompanyStep from 'components/Onboarding/CompanyStep';
import IndustryStep from 'components/Onboarding/IndustryStep';
import InviteTeamStep from 'components/Onboarding/InviteTeamStep';
import JobRoleStep from 'components/Onboarding/JobRoleStep';
import MediaQuery from 'react-responsive';
import MerchantsAndBrandsStep from 'components/Onboarding/MerchantsAndBrandsStep';
import NameStep from 'components/Onboarding/NameStep';
import PageBg from 'assets/onboarding/bg-landing-page-1.jpg';
import PageBg2 from 'assets/onboarding/bg-landing-page-2.jpg';
import PageBg3 from 'assets/onboarding/bg-landing-page-3.jpg';
import PageBg4 from 'assets/onboarding/bg-landing-page-4.jpg';
import PageBg5 from 'assets/onboarding/bg-landing-page-5.jpg';
import PageBg6 from 'assets/onboarding/bg-landing-page-6.jpg';
import PageBg7 from 'assets/onboarding/bg-landing-page-7.jpg';
import PageBgMobile from 'assets/onboarding/bg-landing-page-mobile.jpg';
import PageFeatured2Mobile from 'assets/onboarding/bg-landing-page-2-mobile.jpg';
import PageFeatured3Mobile from 'assets/onboarding/bg-landing-page-3-mobile.jpg';
import PageFeatured4Mobile from 'assets/onboarding/bg-landing-page-4-mobile.jpg';
import PageFeatured5Mobile from 'assets/onboarding/bg-landing-page-5-mobile.jpg';
import PageFeatured6Mobile from 'assets/onboarding/bg-landing-page-6-mobile.jpg';
import PageFeatured7Mobile from 'assets/onboarding/bg-landing-page-7-mobile.jpg';
import PageFeaturedMobile from 'assets/onboarding/bg-landing-page-1-mobile.jpg';
import React from 'react';
import formatOnboardingInput from 'utils/onboarding/formatInput';
import inviteYourTeamMutation from 'operations/mutations/inviteYourTeam.mutation';
import onboardingOptionsQuery from 'operations/queries/onboardingOptions.query';
import paths from 'constants/paths';
import trackEvent from 'utils/trackingEvents';
import updateOnboardingMutation from 'operations/mutations/updateOnboarding.mutation';
import useMe from 'hooks/useMe/useMe';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import {
  InviteYourTeamInput,
  InvitedFrom,
  OnboardingOptionsQuery,
  UpdateOnboardingResult,
} from 'types/graphql/generated';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import * as Styled from './Onboarding.styles';

export enum OnboardingRouteParam {
  username,
  brandoragency,
  brandsandmerchants,
  industry,
  role,
  companyname,
  invitefriends,
}

const Onboarding = () => {
  const { step = 'username' } = useParams();
  const activeStepNum: OnboardingRouteParam = OnboardingRouteParam[step];
  const navigate = useNavigate();
  const { control, getValues, handleSubmit, getFieldState, watch } = useForm();
  const watchAll = watch();
  const me = useMe();

  const brandsAndMerchantsFieldArray = useFieldArray({
    name: 'brandOrMerchant',
    control,
    rules: { minLength: 0, maxLength: 10 },
  });
  const bgImages = [
    PageBg,
    PageBg2,
    PageBg3,
    PageBg4,
    PageBg5,
    PageBg6,
    PageBg7,
  ];
  const bgImagesMobile = [
    PageFeaturedMobile,
    PageFeatured2Mobile,
    PageFeatured3Mobile,
    PageFeatured4Mobile,
    PageFeatured5Mobile,
    PageFeatured6Mobile,
    PageFeatured7Mobile,
  ];

  const { data, loading } = useQuery<OnboardingOptionsQuery>(
    onboardingOptionsQuery,
    {
      fetchPolicy: 'cache-first',
    }
  );
  const [updateOnboarding, { loading: loadingUpdateOnboarding }] =
    useMutation<UpdateOnboardingResult>(updateOnboardingMutation);
  const [inviteTeamates] = useMutation(inviteYourTeamMutation);

  const incrementStep = () => {
    onSubmitOnBoarding(getValues());
    navigate(
      generatePath(paths.ONBOARDING, {
        step: OnboardingRouteParam[activeStepNum + 1],
      })
    );
  };

  const decrementStep = () => {
    navigate(
      generatePath(paths.ONBOARDING, {
        step: OnboardingRouteParam[activeStepNum - 1],
      })
    );
  };

  const onSubmit = React.useCallback(
    (data: FieldValues) => {
      if (data.teamEmails) {
        const emailsIntoArray = data.teamEmails
          .trim()
          .replace(' ', '')
          .split(',');

        inviteTeamates({
          variables: {
            input: {
              emails: emailsIntoArray,
              inviteFrom: InvitedFrom.ONBOARDING,
            } as InviteYourTeamInput,
          },
        });
      }

      navigate(paths.HOME);
      trackEvent('Completed Onboarding', {
        ...me,
        invitedEmails: data.teamEmails,
      });
    },
    [inviteTeamates]
  );
  const onSubmitOnBoarding = React.useCallback(
    (data: FieldValues) => {
      const input = formatOnboardingInput(data);
      updateOnboarding({
        variables: {
          input: input,
        },
      });
    },
    [updateOnboarding]
  );

  const validEmails = React.useMemo(() => {
    const emails = watchAll.teamEmails;
    let valid = true;
    if (emails) {
      const emailList = emails.replace(/\s/g, '').split(',');
      const regex =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      for (let i = 0; i < emailList.length; i++ && valid) {
        if (emailList[i] !== '' && !regex.test(emailList[i])) {
          valid = false;
        }
      }
    }
    return valid;
  }, [watchAll.teamEmails]);

  React.useEffect(() => {
    window.analytics.page('Page view: Onboarding', {
      ...me,
    });
  }, []);

  return (
    <Styled.Wrapper>
      <MediaQuery maxWidth={599}>
        <Styled.WrapperBG>
          <Styled.WrapperBGImg src={PageBgMobile} alt="" />
        </Styled.WrapperBG>
      </MediaQuery>

      <MediaQuery minWidth={600}>
        <Styled.WrapperBG>
          <Styled.WrapperBGImg src={bgImages[activeStepNum]} alt="" />
        </Styled.WrapperBG>
      </MediaQuery>

      <Styled.Content $activeStep={activeStepNum + 1}>
        <NameStep
          nextStep={incrementStep}
          getFieldState={getFieldState}
          control={control}
        />

        <BrandOrAgencyStep
          step={activeStepNum}
          nextStep={incrementStep}
          watchValue={watchAll.work}
          control={control}
          firstName={getValues('firstName')}
          workOptions={
            loading || !data ? ['Loading'] : data.onboardingOptions.workOptions
          }
        />

        <MerchantsAndBrandsStep
          prevPage={decrementStep}
          nextPage={incrementStep}
          step={activeStepNum}
          fieldArray={brandsAndMerchantsFieldArray}
          control={control}
          watchValue={watchAll.brandOrMerchantSuggestion}
        />

        <IndustryStep
          step={activeStepNum}
          prevPage={decrementStep}
          nextPage={incrementStep}
          watchIndustry={watchAll.industry}
          watchCustomIndustry={watchAll.customIndustry}
          control={control}
          industryOptions={data?.onboardingOptions.industryOptions}
        />

        <JobRoleStep
          step={activeStepNum}
          prevPage={decrementStep}
          nextPage={incrementStep}
          watchRole={watchAll.jobRole}
          watchCustomRole={watchAll.customJobRole}
          watchLevel={watchAll.jobLevel}
          control={control}
          roleOptions={data?.onboardingOptions.jobRoleOptions}
          levelOptions={data?.onboardingOptions.jobLevelOptions}
        />

        <CompanyStep
          step={activeStepNum}
          prevPage={decrementStep}
          nextPage={incrementStep}
          watchValue={watchAll.company}
          control={control}
        />

        <InviteTeamStep
          step={activeStepNum}
          prevPage={decrementStep}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          validEmails={validEmails}
          control={control}
          loading={loadingUpdateOnboarding}
        />

        <MediaQuery maxWidth={599}>
          <Styled.FeaturedImg>
            <img src={bgImagesMobile[activeStepNum]} alt="" />
          </Styled.FeaturedImg>
        </MediaQuery>
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default Onboarding;
