import styled from 'styled-components';
import { Button, Grid, theme } from '@klover/attain-design-system';

import { MagicWand } from '@phosphor-icons/react';

export const Wrapper = styled.div`
  background: #c3e6ea;
  min-height: 100vh;
  padding: ${theme.spacing.xl} ${theme.spacing.md};
  width: 100vw;

  @media ${theme.mq.tabletPortraitUp} {
    background: ${theme.colors.backgroundPrimary};
    height: 100vh;
    overflow: hidden;
    padding: 0;
  }
`;

export const WrapperBG = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;

  @media ${theme.mq.tabletPortraitUp} {
    left: calc(50vw - 72px);
    position: fixed;
  }
`;

export const WrapperBGImg = styled.img`
  width: 100%;

  @media ${theme.mq.tabletPortraitUp} {
    height: 100%;
    object-fit: cover;
    object-position: 0 50%;
  }
`;

interface ContentProps {
  $activeStep?: number;
  children?: any;
}

export const Content = styled.div<ContentProps>`
  position: relative;
  z-index: 1;

  @media ${theme.mq.tabletPortraitUp} {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    align-items: center;
    left: 0;
    bottom: 0;
    width: 50vw;
  }

  ${({ $activeStep }) => `> div:nth-child(${$activeStep}) {
      display: block;
      }`};
`;

export const Block = styled.div`
  display: none;

  @media ${theme.mq.tabletPortraitUp} {
    max-width: 360px;
  }
`;

export const BlockLanding = styled(Block)`
  display: block;
`;

export const BlockSignup = styled.div`
  display: block;

  @media ${theme.mq.tabletPortraitUp} {
    max-width: 500px;
  }
`;

export const Beta = styled.div`
  ${theme.typography.headingXS};
  align-items: center;
  background: #b8f4f1;
  border-radius: ${theme.spacing.xxs};
  color: ${theme.colors.textBrand};
  display: inline-flex;
  font-size: 16px;
  padding: 0 ${theme.spacing.sm} 0 ${theme.spacing.xs};
  text-transform: uppercase;
`;

export const BetaIcon = styled(MagicWand)`
  margin-right: ${theme.spacing.xs};
`;

export const Header = styled.h1`
  ${theme.typography.headingXL};
  color: ${theme.colors.textBrand};
  margin-top: ${theme.spacing.xs};
`;

export const SubHeader = styled.h2`
  ${theme.typography.headingMD};
  color: ${theme.colors.textBrand};
  margin-top: ${theme.spacing.xl};
`;

export const Intro = styled.p`
  ${theme.typography.bodyLG}
  color: ${theme.colors.textSecondary};
  margin: ${theme.spacing.xs} 0 0;
`;

export const HeroWrapper = styled(Grid)`
  && {
    margin-top: ${theme.spacing.md};

    @media ${theme.mq.tabletPortraitUp} {
      margin-top: ${theme.spacing.lg};
    }
  }
`;

export const HeroItem = styled(Grid)`
  && {
    @media only screen and (max-width: 599px) {
      border-top: 1px solid ${theme.colors.borderDark};
      padding-block: 0 ${theme.spacing.sm};

      &:first-child {
        border-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    @media ${theme.mq.tabletPortraitUp} {
      border-left: 1px solid ${theme.colors.borderDark};
      min-width: fit-content;
      padding-inline: ${theme.spacing.md};

      &:first-child {
        border-left: 0;
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
`;

export const HeroHeader = styled.h1`
  ${theme.typography.headingLG};
  margin-top: ${theme.spacing.xs};
  margin-bottom: 0;
`;

export const ComingSoonBold = styled.span`
  ${theme.typography.headingXS}
`;

export const ComingSoon = styled.span`
  color: ${theme.colors.textTertiary};
`;

export const List = styled.ul`
  ${theme.typography.bodyLG}
  color: ${theme.colors.textSecondary};
  list-style: disc;

  && {
    margin: ${theme.spacing.sm} 0 0 ${theme.spacing.lg};

    li {
      line-height: 28px;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
  margin-top: ${theme.spacing.xl};
`;

export const Submit = styled(Button)`
  && {
    margin-top: ${theme.spacing.xl};
  }
`;

export const SignIn = styled(Button)`
  && {
    margin-top: ${theme.spacing.md};
  }
`;

export const FeaturedImg = styled.div`
  ${theme.mixins.shadowLight};
  border-radius: ${theme.spacing.xxs};
  margin-top: ${theme.spacing.xxl};
  overflow: hidden;

  img {
    display: block;
    width: 100%;
  }
`;

export const Login = styled(Button)`
  && {
    margin-top: ${theme.spacing.lg};
  }
`;

export const Instructions = styled.p`
  ${theme.typography.bodyLG};
  margin-block: ${theme.spacing.md} 0;

  span {
    ${theme.typography.headingXS};
  }

  a {
    color: ${theme.colors.textBrand};
  }
`;

export const ResendEmail = styled.p`
  ${theme.typography.bodyLG};
  margin-block: ${theme.spacing.md} 0;

  span {
    color: ${theme.colors.textBrand};

    :hover {
      cursor: pointer;
    }
  }
`;

export const PrivacyNotice = styled.p`
  ${theme.typography.bodySM};
`;
