import OutcomeHQLogo from 'assets/outcome-hq-logo.svg';
import paths from 'constants/paths';
import { Button } from '@klover/attain-design-system';
import * as Styled from './ErrorMsg.styles';
import './index.css';
import 'react-day-picker/dist/style.css';

const ErrorMsg = () => {
  return (
    <Styled.Wrapper>
      <Styled.Logo src={OutcomeHQLogo} alt="Outcome HQ" />

      <Styled.Message>
        Whoops, looks like we had an error. Please return to the homepage. If
        the error persists, please reach out to our team. Thanks!
      </Styled.Message>

      <a href={paths.HOME}>
        <Button variant="contained">Return to homepage</Button>
      </a>
    </Styled.Wrapper>
  );
};

export default ErrorMsg;
