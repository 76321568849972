import React from 'react';
import { MenuItem, Select, theme } from '@klover/attain-design-system';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import * as Styled from './Tabs.styles';

export interface Tab {
  active?: boolean; //to make smart personas work
  activePath?: string;
  disabled?: boolean;
  element?: React.ReactNode;
  onClick?: (any) => any;
  title?: string;
}

const Tab = ({
  active,
  activePath,
  disabled,
  element,
  onClick,
  title,
}: Tab) => {
  const location = useLocation();
  const hasActivePath = activePath
    ? !!location.pathname.includes(activePath)
    : false;
  return (
    <Styled.Tab
      onClick={onClick}
      $disabled={disabled}
      className={active || hasActivePath ? 'active' : ''}
    >
      {element ? element : title}
    </Styled.Tab>
  );
};

interface TabsProps {
  tabs: Tab[];
  links?: boolean;
}

export const Tabs = ({ tabs }: TabsProps) => {
  const isDesktop = useMediaQuery({
    query: theme.mq.tabletPortraitUp,
  });

  if (!isDesktop) {
    return (
      <Styled.Dropdown>
        <Select fullWidth defaultValue={tabs[0].title} variant="outlined">
          {tabs.map((tab, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                if (tab.onClick) {
                  tab.onClick;
                }
              }}
              value={tab.title}
            >
              {tab.title}
            </MenuItem>
          ))}
        </Select>
      </Styled.Dropdown>
    );
  } else {
    return (
      <Styled.Wrapper>
        {tabs.map((tab, i) => (
          <Tab key={i} {...tab} />
        ))}
      </Styled.Wrapper>
    );
  }
};

export default Tabs;
