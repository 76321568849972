import IndexScore from '../IndexScore/IndexScore';
import MetricBar100 from 'components/MetricBar100/MetricBar100';
import React from 'react';
import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import { Accordion } from '@klover/attain-design-system';
import { SmartPersona } from 'types/graphql/generated';
import { hasValidValue } from 'utils/hasValidValue';
import * as Styled from '../../SmartPersona.styles';

interface Props {
  accordionClicked: (index: number) => void;
  expandedAccordions: number[];
  index: number;
  rightColTitle?: string;
  segment: SmartPersona;
}

const TabSegment = ({
  accordionClicked,
  expandedAccordions,
  index,
  rightColTitle,
  segment,
}: Props) => {
  const [sortColumn, setSortColumn] = React.useState('none');
  const [sortOrder, setSortOrder] = React.useState('desc');
  const CB = useUiFlag('ui_census_balancing');
  const allowCB = CB.isReady && CB.enabled;

  const hasAudiencePercentage =
    segment && hasValidValue(segment, 'percentOfAudience');

  const formattedData = React.useMemo(() => {
    return segment.data
      .map((row) => ({
        ...row,
        indexValue: row.index.value,
        audienceValue: row.percentOfAudience?.value,
      }))
      .sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (sortOrder === 'desc') {
          return aValue < bValue ? -1 : 1;
        } else {
          return aValue > bValue ? -1 : 1;
        }
      });
  }, [segment, sortColumn, sortOrder]);

  const handleSort = (column) => {
    if (sortColumn === column) {
      // If the same column is clicked, toggle the sort order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // If a different column is clicked, set it as the new sort column
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  return (
    <Styled.Segment>
      <Accordion
        onChange={() => accordionClicked(index)}
        expanded={expandedAccordions.includes(index)}
        header={
          <Styled.SegmentHeader>
            <Styled.SegmentHeaderText>{segment.title}</Styled.SegmentHeaderText>
          </Styled.SegmentHeader>
        }
      >
        <Styled.IndexTable>
          <thead>
            <tr>
              <th>Segment</th>
              <th style={{ width: 1 }} onClick={() => handleSort('indexValue')}>
                {rightColTitle ? rightColTitle : 'Index vs. all shoppers'}
              </th>

              {allowCB && (
                <>
                  {hasAudiencePercentage && (
                    <th
                      style={{ width: 1 }}
                      onClick={() => handleSort('audienceValue')}
                    >
                      Percent of audience
                    </th>
                  )}
                </>
              )}
            </tr>
          </thead>

          <tbody>
            {formattedData.map((demoDetail, j) => {
              return (
                <tr key={j}>
                  <td>{demoDetail.title}</td>

                  <td>
                    <IndexScore index={demoDetail.index} />
                  </td>

                  {allowCB && (
                    <>
                      {hasAudiencePercentage && (
                        <td>
                          <MetricBar100
                            index={
                              demoDetail.percentOfAudience?.value as number
                            }
                          />
                        </td>
                      )}
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Styled.IndexTable>
      </Accordion>
    </Styled.Segment>
  );
};

export default TabSegment;
