import { gql } from '@apollo/client';

export const createSnapshotMutation = gql`
  mutation CreateSnapshot($input: CreateSnapshotInput!) {
    createSnapshot(input: $input) {
      ... on CreateSnapshotSuccess {
        id
      }
    }
  }
`;

export default createSnapshotMutation;
