import styled from 'styled-components';
import { InputAdornment, theme } from '@klover/attain-design-system';

export const MessageActions = styled.div`
  padding: ${theme.spacing.xxs} 0;
`;

export const MessageActionButtons = styled.div`
  margin-left: -4px;
  display: flex;
  align-items: center;
`;

export const MessageReviewButton = styled.div<{ $selected?: boolean }>`
  display: flex;
  justify-content: center;
  cursor: pointer;
  padding: ${theme.spacing.xxs};
  border-radius: ${theme.spacing.xxl};
  background-color: ${({ $selected }) =>
    $selected ? theme.colors.backgroundBrandLight : 'transparent'};
  &:hover {
    background-color: ${theme.colors.backgroundBrandLight};
  }
  transition: background-color 0.2s ease-in;
`;

export const ActionFeedback = styled.div<{ $primary?: boolean }>`
  ${theme.typography.label};
  padding: 0 ${theme.spacing.xs};
  color: ${({ $primary }) =>
    $primary ? theme.colors.CHART_PRIMARY : theme.colors.text};
`;

export const FeedbackForm = styled.form`
  margin-top: ${theme.spacing.sm};
`;

export const FeedbackButtonWrapper = styled(InputAdornment)`
  width: ${theme.spacing.xl} !important;
  height: ${theme.spacing.xl} !important;
  margin-right: -${theme.spacing.xs} !important;
`;

export const FeedbackButton = styled.div<{
  $disabled?: boolean;
  $loading?: boolean;
}>`
  cursor: pointer;
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
  width: ${theme.spacing.xl};
  height: ${theme.spacing.xl};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ $disabled }) =>
    $disabled ? 'none' : theme.colors.CHART_PRIMARY};
  border-radius: ${theme.spacing.xs};
  & svg {
    transition: fill 0.2s linear;
    fill: ${({ $disabled }) =>
      $disabled ? theme.colors.buttonTextDisabled : theme.colors.textInverted};
  }
  transition: background 0.2s linear;

  @keyframes fadePulse {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.33;
    }

    100% {
      opacity: 1;
    }
  }

  animation: ${({ $loading }) => {
    return $loading && `1s fadePulse linear infinite`;
  }};
`;

export const ConversationActions = styled.div`
  display: flex;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(246, 245, 249, 0) 1%,
    rgba(246, 245, 249, 1) 3%,
    rgba(246, 245, 249, 1) 97%,
    rgba(246, 245, 249, 0) 100%
  );
  padding: ${theme.spacing.xxs} ${theme.spacing.lg};
  & small {
    margin-right: ${theme.spacing.xxs};
  }
`;
