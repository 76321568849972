import { gql } from '@apollo/client';

export const smartPersonaPsychographicsQuery = gql`
  query Psychographics($psychographicsId: Base64ID!) {
    psychographics(id: $psychographicsId) {
      ... on SmartPersonaSuccess {
        id
        title
        demographics {
          title
          data {
            index {
              description
              kind
              title
              value
            }
            percentOfAudience {
              title
              value
            }
            title
          }
        }
      }
      ... on SmartPersonaError {
        message
      }
    }
  }
`;

export default smartPersonaPsychographicsQuery;
