import { theme } from '@klover/attain-design-system';
import * as Styled from './Icons.styles';

export const NegativeTraiangle = () => (
  <Styled.NegativeTriangle size={theme.icons.sm} weight="fill" />
);

export const HiddenIcon = () => (
  <Styled.NeutralCircle
    size={theme.icons.sm}
    opacity={0}
    data-testid="hidden-icon"
  />
);

export const NeutralCircle = () => (
  <Styled.NeutralCircle
    size={theme.icons.sm}
    weight="fill"
    data-testid="neutral-icon"
  />
);

export const PositiveTraiangle = () => (
  <Styled.PositiveTriangle
    size={theme.icons.sm}
    weight="fill"
    data-testid="positive-icon"
  />
);
