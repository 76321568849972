import GhostLoader from 'react-ghost-loader';
import * as Styled from './tableLoader.styles';
export const TableLoader = () => {
  return (
    <Styled.Wrapper>
      <Styled.HeadRow>
        <GhostLoader
          height="36px"
          heightRandom={0}
          widthRandom={0}
          width="30%"
        />
      </Styled.HeadRow>
      <div>
        <GhostLoader
          height="36px"
          heightRandom={0}
          widthRandom={0}
          width="100%"
        />
      </div>
      <div>
        <GhostLoader
          height="36px"
          heightRandom={0}
          widthRandom={0}
          width="100%"
        />
      </div>
      <div>
        <GhostLoader
          height="36px"
          heightRandom={0}
          widthRandom={0}
          width="100%"
        />
      </div>
      <div>
        <GhostLoader
          height="36px"
          heightRandom={0}
          widthRandom={0}
          width="100%"
        />
      </div>
    </Styled.Wrapper>
  );
};

export default TableLoader;
