import styled from 'styled-components';
import { Table, theme } from '@klover/attain-design-system';

export const Wrapper = styled(Table)`
  border-collapse: collapse;

  th {
    ${theme.typography.label};
    padding: ${theme.spacing.sm} ${theme.spacing.md};
    text-align: left;

    @media ${theme.mq.tabletPortraitUp} {
      padding-inline: ${theme.spacing.lg};
    }
  }

  td {
    ${theme.typography.bodyLG};
    border: 1px solid ${theme.colors.borderLight};
    padding: ${theme.spacing.sm} ${theme.spacing.md};

    @media ${theme.mq.tabletPortraitUp} {
      padding-inline: ${theme.spacing.lg};
    }
  }
`;
