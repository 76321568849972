import { gql } from '@apollo/client';

export const measurementInsightsPaymentChannelQuery = gql`
  query MeasurementInsightsPaymentChannel($input: MeasurementOverviewInput!) {
    measurementInsightsPaymentChannel(input: $input) {
      paymentChannels {
        paymentChannel
        percentTransactions {
          value
          title
        }
        percentSpend {
          value
          title
        }
        roas {
          value
          title
        }
      }
    }
  }
`;
