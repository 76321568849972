import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

export const Wrapper = styled.div<{ $internal?: boolean; children?: any }>`
  background: ${({ $internal }) =>
    $internal
      ? theme.colors.backgroundLightest
      : theme.colors.backgroundPrimary};
  border: ${({ $internal }) =>
    $internal ? 'none' : `1px solid ${theme.colors.borderDark}`};
  border-radius: ${theme.spacing.xs};
  flex: 1;
  padding: ${theme.spacing.sm} ${theme.spacing.md};
`;

export const Title = styled.span`
  ${theme.typography.bodySM};
  letter-spacing: -0.012em;
  text-transform: uppercase;
`;

export const Details = styled.div`
  ${theme.typography.bodyMD};
  align-items: center;
  display: flex;
  gap: ${theme.spacing.xs};
`;

export const Amount = styled.span`
  ${theme.typography.headingXS}
`;

export const Percent = styled.span`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.xxs};
  color: ${theme.colors.successForeground};
`;

export const PercentDown = styled(Percent)`
  color: ${theme.colors.errorForeground};
`;
