import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import { PercentageValue } from 'types/graphql/generated';
import * as Styled from './OverviewAudienceParticipation.styles';

interface Props {
  index: PercentageValue;
}

const OverviewAudienceParticipation = ({ index }: Props) => {
  const censusBalancing = useUiFlag('ui_census_balancing');

  return (
    <>
      {censusBalancing.isReady && censusBalancing.enabled ? (
        <Styled.Wrapper>
          <Styled.Header>
            <div>Audience participation</div>
            <div>{index.title}</div>
          </Styled.Header>

          <Styled.Bar>
            <Styled.Filled $percent={index.value} />
          </Styled.Bar>
        </Styled.Wrapper>
      ) : (
        <Styled.WrapperOld />
      )}
    </>
  );
};

export default OverviewAudienceParticipation;
