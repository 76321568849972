import React from 'react';
import { MeQuery } from 'types/graphql/generated';
import { meQuery } from 'operations/queries/me.query';
import { useQuery } from '@apollo/client';

const useMe = () => {
  const { data, loading, error } = useQuery<MeQuery>(meQuery);

  const meData = React.useMemo(() => {
    return data?.me;
  }, [data]);

  if (!loading && !error && meData) {
    return {
      firstName: meData.firstName,
      lastName: meData.lastName,
      company: meData.company,
      email: meData.email,
      industry: meData.industry,
      auth0Role: meData.auth0Role,
      brandOrMerchant: meData.brandOrMerchant,
      jobLevel: meData.jobLevel,
      jobRole: meData.jobRole,
      work: meData.work,
      id: meData.id,
      env: meData.env,
      intercom: meData.intercom,
    };
  }
};

export default useMe;
