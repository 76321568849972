import styled from 'styled-components';
import { theme } from '@klover/attain-design-system';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  background: ${theme.colors.backgroundBrand};
  height: 64px;
  padding: ${theme.spacing.md} ${theme.spacing.lg};
  width: 100vw;
`;

export const Logo = styled.img`
  display: block;
  height: 32px;
  width: 96px;
`;

export const Content = styled.main`
  align-items: center;
  background: ${theme.colors.backgroundLight};
  display: flex;
  height: calc(100% - 58px);
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const Centered = styled.div`
  text-align: center;
`;

export const Telescope = styled.img`
  margin-bottom: ${theme.spacing.lg};
`;

export const Ball = styled.img`
  margin-bottom: ${theme.spacing.lg};
`;

export const Title = styled.h1`
  ${theme.typography.headingXL};
`;

export const Description = styled.p`
  color: ${theme.colors.textSecondary};
  margin-block: ${theme.spacing.xs} ${theme.spacing.xl};
`;

export const TelescopeFooter = styled.div`
  inset-inline: -${theme.spacing.xl};
  inset-block-end: -${theme.spacing.xs};
  overflow: hidden;
  position: absolute;
`;

export const TelescopeFooterImg = styled.img`
  width: 100%;
`;
