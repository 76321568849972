import LuciLogo from 'assets/luci-logo.svg';
import Markdown from 'react-markdown';
import OutcomeAiConversationActions from './OutcomeAiConversationActions';
import OutcomeAiLogo from 'assets/outcome-hq-logo-color.svg';
import OutcomeAiMessageActions from './OutcomeAiMessageActions';
import OutcomeAiMessages from './OutcomeAiMessages';
import React, { LegacyRef } from 'react';
import remarkGfm from 'remark-gfm';
import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import {
  Badge,
  Button,
  MenuItem,
  TextField,
} from '@klover/attain-design-system';
import {
  ChartBarHorizontal,
  CurrencyDollar,
  Globe,
  UsersThree,
} from '@phosphor-icons/react';
import { MathJax } from 'better-react-mathjax';
import * as Styled from './OutcomeAiDrawer.styles';

enum ChatRole {
  AI,
  USER,
}

enum AssistantType {
  ANALYSIS = 'ANALYSIS',
  ATTAIN = 'ATTAIN',
}

export type MessageType = {
  context?: string;
  id?: string;
  content: string;
  timestamp: number;
  role: ChatRole;
  rating?: boolean;
  rated?: boolean;
  feedbackText?: string;
  conversationRating?: boolean;
  assistantType: AssistantType;
};

type PromptSuggessionType = {
  prompt: string;
  startIcon: JSX.Element;
};

interface OutcomeAiDrawerProps {
  firstPrompt?: string;
  brandName?: string;
  campaignId?: string;
  chatHistory?: MessageType[];
  onHistoryChange?: (MessageType) => void;
}

export const OutcomeAiDrawer = ({
  firstPrompt,
  brandName,
  campaignId,
  chatHistory,
  onHistoryChange,
}: OutcomeAiDrawerProps) => {
  const assistantDropdown = useUiFlag('ui_assistants_dropdown');
  const assistantDropdownEnabled =
    assistantDropdown.isReady && assistantDropdown.enabled;

  const chatHistoryMap =
    chatHistory?.reduce((acc, item) => {
      if (!acc[item.assistantType]) acc[item.assistantType] = [];
      acc[item.assistantType].push(item);
      return acc;
    }, {}) || {};

  const [assistantType, setAssistantType] = React.useState(
    AssistantType.ANALYSIS
  );
  const [chatInput, setChatInput] = React.useState(firstPrompt || '');
  const [dataContext, setDataContext] = React.useState(
    chatHistoryMap[AssistantType.ANALYSIS]?.length
      ? chatHistoryMap[AssistantType.ANALYSIS][
          chatHistoryMap[AssistantType.ANALYSIS].length - 1
        ].context || ''
      : ''
  );
  const [attainContext, setAttainContext] = React.useState(
    chatHistoryMap[AssistantType.ATTAIN]?.length
      ? chatHistoryMap[AssistantType.ATTAIN][
          chatHistoryMap[AssistantType.ATTAIN].length - 1
        ].context || ''
      : ''
  );

  const assistants = {
    [AssistantType.ANALYSIS]: {
      display: 'Analysis',
      context: dataContext,
      setContext: setDataContext,
    },
    [AssistantType.ATTAIN]: {
      display: 'Attain',
      context: attainContext,
      setContext: setAttainContext,
    },
  };

  const [chatMessages, setChatMessages] = React.useState<MessageType[]>(
    chatHistory || []
  );
  const [activePrompt, setActivePrompt] = React.useState(firstPrompt || '');
  const [loading, setLoading] = React.useState(false);

  const scroll = React.useRef<HTMLSpanElement | null>();
  const promptInput = React.useRef<HTMLInputElement | null>();

  const scrollToBottom = () => {
    setTimeout(
      () =>
        scroll.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        }),
      200
    );
  };

  const handleInputOnChange = (e) => {
    setChatInput(e.target.value);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (chatInput && chatInput.length) {
      setChatMessages([
        ...chatMessages,
        {
          context: assistants[assistantType].context,
          timestamp: Date.now(),
          content: chatInput,
          role: ChatRole.USER,
          assistantType,
        },
      ]);
      setChatInput('');
      setActivePrompt(chatInput);
      scrollToBottom();
    }
  };

  const handleSuggestionClick = (msg) => {
    setChatInput(msg);
    setTimeout(() => {
      setChatMessages([
        ...chatMessages,
        {
          context: assistants[assistantType].context,
          timestamp: Date.now(),
          content: msg,
          role: ChatRole.USER,
          assistantType,
        },
      ]);
      setActivePrompt(msg);
      setChatInput('');
      scrollToBottom();
    }, 0);
  };

  React.useEffect(() => {
    if (firstPrompt) {
      setChatMessages([
        ...chatMessages,
        {
          context: assistants[assistantType].context,
          timestamp: Date.now(),
          content: firstPrompt,
          role: ChatRole.USER,
          assistantType,
        },
      ]);
      setChatInput('');
    }
  }, [brandName, campaignId, firstPrompt]);

  React.useEffect(() => {
    if (!loading) {
      promptInput?.current?.focus();
    }
  }, [loading]);

  React.useEffect(() => {
    scrollToBottom();
  }, []);

  const handleOnMessageDone = ({ context, message, id }) => {
    const updatedHistory = [
      ...chatMessages,
      {
        context,
        content: message,
        id,
        timestamp: Date.now(),
        assistantType,
        role: ChatRole.AI,
      } as MessageType,
    ];
    onHistoryChange && onHistoryChange(updatedHistory);
    setChatMessages(updatedHistory);
    setActivePrompt('');
    assistants[assistantType].setContext(context);
    setLoading(false);
  };

  const handleOnStreaming = () => {
    if (!loading) setLoading(true);
    scrollToBottom();
  };

  const onMessageReviewed = (message, rating, feedbackText) => {
    const foundIndex = chatMessages.findIndex((item) => item.id === message.id);
    chatMessages[foundIndex].rating = rating;
    chatMessages[foundIndex].rated = true;
    chatMessages[foundIndex].feedbackText = feedbackText;
    const updatedHistory = [...chatMessages];
    onHistoryChange && onHistoryChange(updatedHistory);
    setChatMessages(updatedHistory);
  };

  const openingStatement = brandName ? (
    <>
      Hello, I am OutcomeAI, an AI assistant designed to give you unique
      audience insights.
      <br />
      <br />
      Ask me any question you can think of about {brandName} buyers...
    </>
  ) : (
    <>
      Hello, I am OutcomeAI, an AI assistant designed to help you with unique
      insights and analysis for your campaign.
      <br />
      <br />
      Ask me how I can help optimize your campaign…
    </>
  );

  const brandSuggestions = [
    {
      prompt: `How much do ${brandName} buyers spend?`,
      startIcon: <CurrencyDollar weight="bold" />,
    },
    {
      prompt: `How often do ${brandName} buyers purchase?`,
      startIcon: <ChartBarHorizontal weight="bold" />,
    },
    {
      prompt: `What are ${brandName} buyers demographics?`,
      startIcon: <UsersThree weight="bold" />,
    },
  ];

  const campaignSuggestions = [
    {
      prompt: `What were the top three domains with the highest combination of conversion rate and highest impression volume?`,
      startIcon: <Globe weight="bold" />,
    },
    {
      prompt: `What was the best combination of optimization attributes for this campaign?`,
      startIcon: <ChartBarHorizontal weight="bold" />,
    },
  ];

  let suggestions: PromptSuggessionType[] = [];
  if (brandName) suggestions = brandSuggestions;
  else if (campaignId) suggestions = campaignSuggestions;

  const handleOnAssistantChange = (e) => {
    setAssistantType(e.target.value);
  };

  const handleConversationReview = (context, rating) => {
    for (let i = 0; i < chatMessages.length; i += 1) {
      if (chatMessages[i].context === context) {
        chatMessages[i].conversationRating = rating;
      }
    }
    const updatedHistory = [...chatMessages];
    onHistoryChange && onHistoryChange(updatedHistory);
    setChatMessages(updatedHistory);
  };

  return (
    <Styled.DrawerWrapper>
      <Styled.Header>
        <img src={OutcomeAiLogo} alt="OutcomeAI" />
        <Badge color="neutral">ALPHA</Badge>
      </Styled.Header>

      <Styled.ChatBoxWrapper>
        <Styled.ChatBox>
          {!firstPrompt && (
            <Styled.MessageWrapper position="left">
              <Styled.LuciLogo src={LuciLogo} alt="" />
              <Styled.Message position="left">
                {openingStatement}
              </Styled.Message>
            </Styled.MessageWrapper>
          )}

          {chatMessages.map((message, i) =>
            message.role === ChatRole.AI ? (
              <Styled.MessageWrapper position="left" key={i}>
                <Styled.LuciLogo src={LuciLogo} alt="" />
                <Styled.Message position="left" className="outcome-ai-message">
                  <div className="luci">OutcomeAI</div>
                  <Styled.MessageContent>
                    <MathJax hideUntilTypeset="first">
                      <Markdown remarkPlugins={[remarkGfm]}>
                        {message.content}
                      </Markdown>
                    </MathJax>
                  </Styled.MessageContent>
                  <OutcomeAiMessageActions
                    message={message}
                    onMessageReviewed={onMessageReviewed}
                  />
                </Styled.Message>
              </Styled.MessageWrapper>
            ) : (
              <Styled.MessageWrapper position="right" key={i}>
                <Styled.Message position="right">
                  {message.content}
                </Styled.Message>
              </Styled.MessageWrapper>
            )
          )}

          {activePrompt && (
            <OutcomeAiMessages
              context={assistants[assistantType].context}
              message={activePrompt}
              onComplete={handleOnMessageDone}
              onStreaming={handleOnStreaming}
              brand={brandName}
              campaignId={campaignId}
              assistantType={assistantType}
            />
          )}
          {!chatMessages.length && !!suggestions.length && (
            <Styled.Suggestions>
              <p>Here are a few suggestions...</p>
              {suggestions.map(({ prompt, startIcon }, i) => (
                <div key={i}>
                  <Button
                    variant="outlined"
                    style={{ height: 'auto', textAlign: 'left' }}
                    startIcon={startIcon}
                    onClick={() => {
                      handleSuggestionClick(prompt);
                    }}
                  >
                    {prompt}
                  </Button>
                </div>
              ))}
            </Styled.Suggestions>
          )}
          <span ref={scroll as LegacyRef<HTMLSpanElement>} />
        </Styled.ChatBox>
      </Styled.ChatBoxWrapper>

      {assistants[assistantType].context && (
        <OutcomeAiConversationActions
          context={assistants[assistantType].context}
          rating={
            (
              chatMessages.find(
                (item) => item.context === assistants[assistantType].context
              ) || {}
            ).conversationRating
          }
          onConversationReviewed={handleConversationReview}
        />
      )}
      <Styled.Footer>
        <Styled.SendMessageWrapper onSubmit={handleOnSubmit}>
          {assistantDropdownEnabled && (
            <Styled.AssistantDropdown
              disabled={loading}
              onChange={handleOnAssistantChange}
              value={assistantType}
            >
              {Object.keys(assistants).map((item) => (
                <MenuItem key={item} value={item}>
                  {assistants[item].display}
                </MenuItem>
              ))}
            </Styled.AssistantDropdown>
          )}
          <TextField
            fullWidth
            inputRef={promptInput}
            disabled={loading}
            placeholder={`Ask a question about ${brandName} buyers...`}
            value={chatInput}
            onChange={handleInputOnChange}
            // onSubmit={handleOnSubmit}
          />
          <input type="submit" style={{ display: 'none' }} />
        </Styled.SendMessageWrapper>
      </Styled.Footer>
    </Styled.DrawerWrapper>
  );
};

export default OutcomeAiDrawer;
