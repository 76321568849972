import { gql } from '@apollo/client';

export const incrementalityAiInsightsQuery = gql`
  fragment insightDetail2 on InsightDetail {
    title
    insights {
      header
      bulletPoints
    }
  }
  query IncrementalityAiInsights($input: IncrementalityAiInsightsInput!) {
    incrementalityAiInsights(input: $input) {
      executiveSummaryParagraphs
      keyInsights {
        ...insightDetail2
      }
      demographicOverview {
        ...insightDetail2
      }
      personaDevelopment {
        ...insightDetail2
      }
      actionableInsights {
        ...insightDetail2
      }
      images
    }
  }
`;

export default incrementalityAiInsightsQuery;
