import OutcomeAiInsight from './OutcomeAiInsight';
import brandLiftSurveyAiInsightsQuery from 'operations/queries/brandLiftSurveyAiInsights.query';
import useSearchParams from 'hooks/useSearchParams';
import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import {
  BrandLiftSurveyAiInsightsQuery,
  BrandLiftSurveyAiInsightsQueryVariables,
} from 'types/graphql/generated';
import { MeasurementSearchParams } from 'views/Measurement/MeasurementInsightsDashboard';
import { useQuery } from '@apollo/client';

const BrandLiftSurveyAiInsight = () => {
  const [searchParams] = useSearchParams<MeasurementSearchParams>();

  const aiInsights = useUiFlag('ui_brand_lift_ai_insight');
  const allowAiInsights = aiInsights.isReady && aiInsights.enabled;

  const { data, loading, error } = useQuery<
    BrandLiftSurveyAiInsightsQuery,
    BrandLiftSurveyAiInsightsQueryVariables
  >(brandLiftSurveyAiInsightsQuery, {
    variables: { input: { campaignId: searchParams.campaignId } },
    skip: !allowAiInsights,
  });

  if (!allowAiInsights) return;
  return (
    <OutcomeAiInsight
      data={data?.brandLiftSurveyAiInsights}
      loading={loading}
      error={!!error}
    />
  );
};

export default BrandLiftSurveyAiInsight;
