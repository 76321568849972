import { gql } from '@apollo/client';

export const measurementAudienceInsightsHeroMetricsQuery = gql`
  query MeasurementAudienceInsightsHeroMetrics(
    $input: MeasurementNewBuyerLiftInput!
  ) {
    measurementNewBuyerLift(input: $input) {
      ... on MeasurementNewBuyerLiftSuccess {
        metrics {
          recurringBuyers {
            value
            title
          }
          totalConverters {
            value
            title
          }
          netNewConverters {
            value
            title
          }
        }
      }
    }
  }
`;

export default measurementAudienceInsightsHeroMetricsQuery;
