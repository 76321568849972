import submitConversationReviewMutation from 'operations/mutations/submitConversationReview.mutation';
import { ThumbsDown, ThumbsUp } from '@phosphor-icons/react';
import { Tooltip, theme } from '@klover/attain-design-system';
import { useMutation } from '@apollo/client';
import * as Styled from './OutcomeAiMessageActions.styles';

export const OutcomeAiConversationActions = ({
  context,
  rating,
  onConversationReviewed,
}) => {
  const [submitConversationReview] = useMutation(
    submitConversationReviewMutation
  );

  const handleReviewButtonClick = (newRating) => {
    onConversationReviewed(context, newRating);
    submitConversationReview({
      variables: {
        input: {
          threadId: context,
          rating: newRating,
          feedback: '',
        },
      },
    });
  };

  return (
    <Styled.ConversationActions>
      <small>Has this conversation been helpful?</small>
      <Tooltip title={'Helpful'} placement="bottom" arrow>
        <Styled.MessageReviewButton
          $selected={rating === true}
          onClick={() => handleReviewButtonClick(true)}
        >
          <ThumbsUp
            size={theme.icons.md}
            color={
              rating === true
                ? theme.colors.CHART_PRIMARY
                : theme.colors.textTertiary
            }
          />
        </Styled.MessageReviewButton>
      </Tooltip>
      <Tooltip title={'Not helpful'} placement="bottom" arrow>
        <Styled.MessageReviewButton
          $selected={rating === false}
          onClick={() => handleReviewButtonClick(false)}
        >
          <ThumbsDown
            size={theme.icons.md}
            color={
              rating === false
                ? theme.colors.CHART_PRIMARY
                : theme.colors.textTertiary
            }
          />
        </Styled.MessageReviewButton>
      </Tooltip>
    </Styled.ConversationActions>
  );
};

export default OutcomeAiConversationActions;
