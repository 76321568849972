export const hasValidValue = (obj, targetKey) => {
  // Check if the object is null or undefined
  if (obj === null || typeof obj !== 'object') {
    return false;
  }

  // Check if the target key is present in the current level
  if (
    targetKey in obj &&
    obj[targetKey] !== null &&
    obj[targetKey] !== undefined
  ) {
    return true;
  }

  // Iterate over all keys in the current level
  for (const key in obj) {
    // Recursively check in nested objects
    if (hasValidValue(obj[key], targetKey)) {
      return true;
    }
  }

  // If the target key is not found with a valid value in the entire object
  return false;
};
