import React from 'react';
import SmartPersonaBody from './SmartPersonaBody';
import mediaConsumptionQuery from 'operations/queries/mediaConsumption.query';
import smartPersonaAudienceQuery from 'operations/queries/smartPersonaAudience.query';
import smartPersonaPsychographicsQuery from 'operations/queries/smartPersonaPsychographics.query';
import trackEvent from 'utils/trackingEvents';
import useMe from 'hooks/useMe/useMe';
import {
  PsychographicsQuery,
  PsychographicsQueryVariables,
  SmartPersonaAudienceQuery,
  SmartPersonaAudienceQueryVariables,
  SmartPersonaSuccess,
} from 'types/graphql/generated';
import { useLazyQuery } from '@apollo/client';

interface Props {
  id: any;
  scrollDrawerToTop: () => any;
}

const SmartPersonaAudience = ({ id, scrollDrawerToTop }: Props) => {
  const me = useMe();
  const [getSmartPersonaData, { data: data, loading: loading }] = useLazyQuery<
    SmartPersonaAudienceQuery,
    SmartPersonaAudienceQueryVariables
  >(smartPersonaAudienceQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });
  const [getPsychographicsData, { data: psychoData, loading: loadingPsycho }] =
    useLazyQuery<PsychographicsQuery, PsychographicsQueryVariables>(
      smartPersonaPsychographicsQuery,
      { fetchPolicy: 'no-cache' }
    );
  const [getMediaConsuptionData, { data: mediaData, loading: loadingMedia }] =
    useLazyQuery(mediaConsumptionQuery, { fetchPolicy: 'no-cache' });

  const personaDemographics =
    data && (data?.smartPersonaAudience as SmartPersonaSuccess);
  const personaPsychographics =
    psychoData && (psychoData?.psychographics as SmartPersonaSuccess);
  const personaMedia =
    mediaData && (mediaData?.mediaConsumption as SmartPersonaSuccess);

  const demographics = data && personaDemographics?.demographics;
  const psychographics = psychoData && personaPsychographics?.demographics;
  const mediaConsumption = mediaData && personaMedia?.demographics;

  React.useEffect(() => {
    if (id) {
      getSmartPersonaData({
        variables: {
          smartPersonaAudienceId: id,
        },
        onCompleted(data) {
          trackEvent('Audience Insights', {
            ...me,
            audienceInput: id,
            ...data?.smartPersonaAudience,
          });
        },
      });
      getPsychographicsData({
        variables: { psychographicsId: id },
      });
      getMediaConsuptionData({
        variables: { mediaConsumptionId: id },
      });
    }
  }, [id]);

  return (
    <SmartPersonaBody
      demographics={{ data: demographics, loading: loading }}
      psychographics={{
        data: psychographics,
        loading: loadingPsycho,
      }}
      mediaConsumption={{ data: mediaConsumption, loading: loadingMedia }}
      id={id}
      loading={loading || loadingPsycho}
      persona={personaDemographics}
      drawerCategory="Audience Insights"
      scrollDrawerToTop={scrollDrawerToTop}
    />
  );
};

export default SmartPersonaAudience;
