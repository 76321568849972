import LiftDataNA from '../DataWarnings/liftDataNAThreshold';
import {
  BrandLiftBreakdownSkew,
  BrandLiftBreakdownSkewSegment,
  LiftSignificance,
} from 'types/graphql/generated';
import { BrandLiftSurveyDataWarning } from '../DataWarnings/dataWarning';
import { Datum } from '@nivo/line';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Info, Square } from '@phosphor-icons/react';
import { SkewsChart } from './SkewsChart';
import { Tooltip, theme } from '@klover/attain-design-system';
import * as Styled from '../LiftTable.styles';

export interface SkewData {
  title: string;
  description: string;
  skew: { value: number; title: string };
  significance: LiftSignificance; // might not have this field.
}

export interface SkewTableSection {
  title: string;
  skews: SkewData[];
}

export interface SkewChartDatum extends Datum {
  id: string;
  skew: number;
  color: string | null;
}

const getSkewChartDataFromLift = (
  data: BrandLiftBreakdownSkewSegment
): SkewChartDatum[] => {
  const chartData = [
    {
      id: data.title,
      skew: (data.skew?.value || 0) * 100,
      color:
        (data.skew?.value || 0) > 0
          ? theme.colors.successForeground
          : theme.colors.errorForeground,
    },
  ];
  return chartData;
};

interface Props {
  skewTables: BrandLiftBreakdownSkew[] | undefined;
  liftType: 'absoluteLift' | 'relativeLift';
}

export const SkewsTable = ({ skewTables, liftType }: Props) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState<number | null>(null);

  useEffect(() => {
    const onResize = () => {
      if (chartRef.current) {
        setChartWidth(chartRef.current?.offsetWidth);
      }
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [chartRef.current?.offsetWidth]);

  const skewsData = useMemo(() => {
    if (!skewTables) {
      return null;
    }
    return skewTables?.map((tableData) =>
      tableData.segments.map((data) => getSkewChartDataFromLift(data))
    );
  }, [skewTables]);

  return (
    <>
      {skewTables ? (
        <Styled.FullWidthTable>
          {skewTables?.map((tableData, tableIdx) => (
            <Fragment key={tableData.id}>
              <thead>
                <tr>
                  <Styled.ThTitle>{tableData.title}</Styled.ThTitle>
                  {tableIdx === 0 ? (
                    <Styled.ThAbsoluteLiftSticky>
                      Index to [KPI]
                      <Tooltip
                        placement="top"
                        title="This value represents the percentage difference between the brand metric for a given interest skew and the general population, relative to the total brand metric. We are only surfacing the top 10 / bottom 10 performing segments that contain at least 100 responses"
                      >
                        <Info />
                      </Tooltip>
                    </Styled.ThAbsoluteLiftSticky>
                  ) : (
                    <Styled.ThAbsoluteLift />
                  )}
                  {tableIdx === 0 ? (
                    <Styled.ThScaleSticky $width={chartWidth}>
                      <Styled.ScaleWrapper $width={chartWidth}>
                        <div>-25%</div>
                        <div>-20%</div>
                        <div>-15%</div>
                        <div>-10%</div>
                        <div>-5%</div>
                        <div>0%</div>
                        <div>5%</div>
                        <div>10%</div>
                        <div>15%</div>
                        <div>20%</div>
                        <div>25%</div>
                      </Styled.ScaleWrapper>
                    </Styled.ThScaleSticky>
                  ) : (
                    <Styled.ThScaleSticky $width={chartWidth}>
                      <Styled.ScaleWrapper $width={chartWidth} />
                    </Styled.ThScaleSticky>
                  )}
                  <Styled.ThControlExposed />
                </tr>
              </thead>
              <tbody>
                {tableData.segments.map((data, segIdx) => (
                  <tr key={data.id}>
                    <td>
                      <Styled.CellOneContent>
                        {data.title}
                      </Styled.CellOneContent>
                    </td>
                    <Styled.TdAbsoluteLift>
                      <span>{data.skew?.title ?? <LiftDataNA />}</span>
                    </Styled.TdAbsoluteLift>
                    <Styled.TdChart>
                      <Styled.ChartWrapperSkew ref={chartRef}>
                        <SkewsChart
                          skewData={skewsData?.at(tableIdx)?.at(segIdx) || null}
                        />
                      </Styled.ChartWrapperSkew>
                    </Styled.TdChart>
                    <Styled.TdControllExposed></Styled.TdControllExposed>
                  </tr>
                ))}
              </tbody>
            </Fragment>
          ))}
        </Styled.FullWidthTable>
      ) : (
        <BrandLiftSurveyDataWarning />
      )}
      <Styled.Legend>
        <Styled.LegendItem>
          Skews positive
          <Square color={theme.colors.successForeground} weight="fill" />
        </Styled.LegendItem>
        <Styled.LegendItem>
          Skews negative
          <Square color={theme.colors.errorForeground} weight="fill" />
        </Styled.LegendItem>
      </Styled.Legend>
    </>
  );
};

export default SkewsTable;
