import BgImage from 'assets/up-right-bg-arrow.png';
import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

export const Wrapper = styled.div`
  ${theme.typography.bodyLG};
  color: ${theme.colors.textInverted};
  border-radius: ${theme.spacing.xs};
  padding: ${theme.spacing.md} ${theme.spacing.lg};
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url(${BgImage}),
    linear-gradient(
      89.12deg,
      #4d4176 0%,
      #61457c 11.59%,
      #754881 20.4%,
      #884b84 28.8%,
      #9c4e85 37.19%,
      #ae5185 45.71%,
      #c05682 54.4%,
      #d05b7f 62.65%,
      #df627a 71.04%,
      #ec6b74 79.65%,
      #f6756d 88.04%,
      #ff8166 100%
    );
  background-size: contain;
  background-position-x: right;
  background-repeat: no-repeat;
`;
// url(${BgImage}) right top no-repeat
