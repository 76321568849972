import { DEFAULT_GF_TIME_PERIOD_ID } from 'constants/strings';
import { SmartPersonaMeasurementInput } from 'types/graphql/generated';
import { createHook, createStore } from 'react-sweet-state';
import { hideIntercom, showIntercom } from 'utils/hideOrShowIntercom';
import { popUrlParam, pushUrlParam } from 'utils/smartPersonas/urlParameters';

const StoreGlobalState = createStore({
  initialState: {
    createSnapshotMutation: undefined,
    createSnapshotMutationInput: undefined,
    drawerContents: undefined,
    globalFiltersTimePeriod: { id: DEFAULT_GF_TIME_PERIOD_ID },
    globalFiltersPaymentChannel: null,
    globalFiltersPaymentChannelName: '',
    globalFiltersDemographics: [],
    globalFiltersDemographicsNames: [],
    showDrawer: false,
    showNav: false,
    showToast: false,
    smartPersonaId: '',
    smartPersonasInputData: <SmartPersonaMeasurementInput | undefined>undefined,
    smartPersonaType: '',
    toastError: false,
    toastHeader: '',
    toastMsg: <string | React.ReactNode>undefined,
  },
  actions: {
    secCreateSnapshotMutation:
      (createSnapshotMutation) =>
      ({ setState }) => {
        setState({
          createSnapshotMutation: createSnapshotMutation,
        });
      },
    secCreateSnapshotMutationInput:
      (createSnapshotMutationInput) =>
      ({ setState }) => {
        setState({
          createSnapshotMutationInput: createSnapshotMutationInput,
        });
      },
    setPaymentChannel:
      (paymentChannel) =>
      ({ setState }) => {
        setState({
          globalFiltersPaymentChannel: paymentChannel,
        });
      },
    setTimePeriod:
      (timePeriod) =>
      ({ setState }) => {
        setState({
          globalFiltersTimePeriod: timePeriod,
        });
      },
    setGlobalFilters:
      (
        demographics,
        demographicsNames,
        paymentChannel,
        paymentChannelNames,
        timePeriod
      ) =>
      ({ setState }) => {
        setState({
          globalFiltersDemographics: demographics,
          globalFiltersDemographicsNames: demographicsNames,
          globalFiltersPaymentChannel: paymentChannel,
          globalFiltersPaymentChannelName: paymentChannelNames,
          globalFiltersTimePeriod: timePeriod,
          showDrawer: false,
        });
      },
    resetGlobalFilters:
      () =>
      ({ setState }) => {
        setState({
          globalFiltersTimePeriod: { id: DEFAULT_GF_TIME_PERIOD_ID },
          globalFiltersPaymentChannel: null,
          globalFiltersPaymentChannelName: '',
          globalFiltersDemographics: [],
          globalFiltersDemographicsNames: [],
        });
      },
    showDrawer:
      () =>
      ({ setState }) => {
        hideIntercom();
        pushUrlParam('personas', 'open');
        setState({
          showDrawer: true,
        });
      },
    hideDrawer:
      () =>
      ({ setState }) => {
        showIntercom();
        popUrlParam('personas');
        popUrlParam('personaType');
        popUrlParam('tab');
        setState({
          createSnapshotMutation: undefined,
          showDrawer: false,
          drawerContents: undefined,
        });
      },
    showNav:
      () =>
      ({ setState }) => {
        setState({
          showNav: true,
        });
      },
    hideNav:
      () =>
      ({ setState }) => {
        setState({
          showNav: false,
        });
      },
    setDrawerContents:
      (component) =>
      ({ setState }) => {
        setState({
          showDrawer: true,
          drawerContents: component,
        });
      },
    setSmartPersonaId:
      (id, type) =>
      ({ setState }) => {
        pushUrlParam('personas', id);
        pushUrlParam('personaType', type);
        setState({
          showDrawer: true,
          smartPersonaType: type,
          smartPersonaId: id,
        });
      },
    smartPersonaInputData:
      (data: SmartPersonaMeasurementInput | null, type) =>
      ({ setState }) => {
        setState({
          smartPersonasInputData: data ?? undefined,
          smartPersonaType: type,
        });
      },
    showToast:
      (header, msg) =>
      ({ setState }) => {
        setState({
          toastHeader: header,
          toastMsg: msg,
          showToast: true,
          toastError: false,
        });
      },
    showToastError:
      (header, msg) =>
      ({ setState }) => {
        setState({
          toastError: true,
          toastHeader: header,
          toastMsg: msg,
          showToast: true,
        });
      },
    hideToast:
      () =>
      ({ setState }) => {
        setState({
          showToast: false,
          toastError: false,
        });
      },
  },
});

export const useGlobalState = createHook(StoreGlobalState);
