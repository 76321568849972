import TableLoader from '../MetricComponents/SkeletonLoaders/tableLoaders';
import brandLiftSurveyInsightsOverallQuery from 'operations/queries/brandLiftSurveyInsightsOverall.query';
import { Base64ID } from 'types/models';
import {
  BrandLiftSurveyInsightsOverallQuery,
  BrandLiftSurveyInsightsOverallQueryVariables,
} from 'types/graphql/generated';
import { LiftTable } from '../MetricComponents/Lifts/LiftTable';
import { useQuery } from '@apollo/client';

interface Props {
  campaignId: Base64ID;
  liftType: 'absoluteLift' | 'relativeLift';
}
export const Overall = ({ campaignId, liftType }: Props) => {
  const { data, loading } = useQuery<
    BrandLiftSurveyInsightsOverallQuery,
    BrandLiftSurveyInsightsOverallQueryVariables
  >(brandLiftSurveyInsightsOverallQuery, {
    variables: {
      input: {
        campaignId: campaignId,
      },
    },
  });
  if (loading) {
    return <TableLoader />;
  }

  const liftData = data?.brandLiftSurveyInsightsOverall;
  //TODO: query all the data and data mapping.
  return (
    <LiftTable
      liftTables={liftData ? [liftData as any] : undefined}
      liftType={liftType}
    />
  ); //todo remove any once apollo updates
};

export default Overall;
