import Drawer from '@mui/material/Drawer';
import styled from 'styled-components';
import { Accordion, theme } from '@klover/attain-design-system';

import { X } from '@phosphor-icons/react';

export const Wrapper = styled.div`
  ${theme.typography.bodyLG};
  background: ${theme.colors.backgroundPrimary};
  display: flex;
  flex-direction: column;
  z-index: 10;
  height: 100%;
`;

export const DrawerWrapper = styled(Drawer)`
  .MuiDrawer-paper {
    background: ${theme.colors.backgroundBrandLightest};
    overflow: visible;
    width: 100%;

    @media ${theme.mq.tabletPortraitUp} {
      width: 680px;
    }
  }
`;
export const Main = styled.main`
  flex-grow: 1;
  overflow-y: scroll;
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  padding: ${theme.spacing.lg} ${theme.spacing.lg} ${theme.spacing.md};
`;

export const Title = styled.h1`
  ${theme.typography.headingXS};
  flex-grow: 1;
  text-transform: uppercase;
`;

export const Close = styled(X)`
  cursor: pointer;
`;

export const Filter = styled(Accordion)`
  &&& {
    border-bottom: 1px solid ${theme.colors.borderLight};
    border-radius: 0;
    padding: ${theme.spacing.md} ${theme.spacing.lg};

    &:before {
      content: none;
    }
  }
`;

export const FilterHeader = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

export const FilterTitle = styled.div`
  flex-grow: 1;
`;

export const FilterBadge = styled.div`
  ${theme.typography.label};
  border: 1px solid ${theme.colors.borderDark};
  border-radius: ${theme.spacing.xxs};
  line-height: 24px;
  padding-inline: ${theme.spacing.xs};
`;

export const FilterInput = styled.div`
  margin-block-start: ${theme.spacing.md};
`;

export const Footer = styled.footer`
  border-top: 1px solid ${theme.colors.borderDark};
  box-shadow: ${theme.mixins.shadowDark};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
  padding: ${theme.spacing.md};

  @media ${theme.mq.tabletPortraitUp} {
    flex-direction: row-reverse;
    padding: ${theme.spacing.md} ${theme.spacing.lg};
  }
`;
