import styled from 'styled-components';
import { Select, theme } from '@klover/attain-design-system';

export const DrawerWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  align-items: center;
  background: ${theme.colors.backgroundPrimary};
  border-block-end: 1px solid ${theme.colors.borderDark};
  display: flex;
  gap: ${theme.spacing.sm};
  padding: ${theme.spacing.lg};
`;

export const ChatBoxWrapper = styled.div`
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
`;

export const ChatBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
  height: 100%;
  padding: ${theme.spacing.lg};
  overflow: auto;

  .luci + p,
  title + p {
    margin-top: 0;
  }
`;

export const Suggestions = styled.div`
  ${theme.mixins.stack(theme.spacing.xs)}

  p {
    margin: 0;
  }

  svg {
    fill: ${theme.colors.textBrand};
  }
`;

export const MessageWrapper = styled.div<{ position: string }>`
  align-items: top;
  display: flex;
  gap: ${theme.spacing.xs};
  justify-content: ${({ position }) => (position == 'left' ? 'left' : 'right')};
`;

export const LuciLogo = styled.img`
  height: 42px;
  width: 42px;
`;

export const Message = styled.div<{ position: string; $analyzing?: boolean }>`
  background: ${({ position }) =>
    position == 'left'
      ? theme.colors.backgroundPrimary
      : `rgba(234, 232, 242, 1)`};
  border: ${({ position }) =>
    position == 'left' && `1px solid ${theme.colors.borderDark}`};
  color: ${theme.colors.text};
  padding: ${theme.spacing.xs} ${theme.spacing.md};
  border-radius: ${theme.spacing.xs};
  animation: ${({ $analyzing }) =>
    $analyzing && `1s fadePulse linear infinite`};
  word-break: break-word;

  @keyframes fadePulse {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.33;
    }

    100% {
      opacity: 1;
    }
  }

  .luci {
    ${theme.typography.label};
  }
`;

export const Analyzing = styled.div`
  ${theme.typography.bodyLG}
  background: linear-gradient(
    90deg,
    #4D4176 0%,
    #61457C 5.8%,
    #754881 10.215%,
    #884B84 14.42%,
    #9C4E85 18.62%,
    #AE5185 22.89%,
    #C05682 27.235%,
    #D05B7F 31.37%,
    #DF627A 35.57%,
    #EC6B74 39.88%,
    #F6756D 44.08%,
    #FF8166 50%,
    #F6756D 55.8%,
    #EC6B74 60.215%,
    #DF627A 64.42%,
    #D05B7F 68.62%,
    #C05682 72.89%,
    #AE5185 77.235%,
    #9C4E85 81.37%,
    #884B84 85.57%,
    #754881 89.88%,
    #61457C 94.08%,
    #4D4176 100%
  ) 200% 0 / 200% 100%;
  color: transparent;
  background-clip: text;
  align-content: center;
  @media (prefers-reduced-motion: no-preference) {
    & {
      animation: move-bg 1s linear infinite;
    }
    @keyframes move-bg {
      100% {
        background-position: 0 0;
      }
    }
  }
`;

export const AnalyzingDots = styled.span`
  background: inherit;
  position: relative;
  font-size: 3rem;
  padding: 0.5rem;
  top: -0.1rem;
  background-clip: text;
`;

export const MessageContent = styled.div`
  padding: ${theme.spacing.xxs} 0;
  ul {
    padding-left: 1em;
    list-style-position: inside;
    li {
      list-style-type: disc;
    }
  }
  img {
    width: 100%;
  }

  th {
    text-align: left;
  }
  td {
    border-top: solid 1px #eee;
    padding: ${theme.spacing.xxs};
  }
`;

export const Footer = styled.footer`
  align-items: center;
  background: ${theme.colors.backgroundPrimary};
  border-block-start: 1px solid ${theme.colors.borderDark};
  display: flex;
  gap: ${theme.spacing.sm};
  padding: ${theme.spacing.sm} ${theme.spacing.lg};
`;

export const SendMessageWrapper = styled.form`
  width: calc(100% - 68px);
  display: flex;
  flex-direction: horizontal;
`;

export const AssistantDropdown = styled(Select)`
  margin-right: ${theme.spacing.xxs};
`;
