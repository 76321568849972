import Back from './BackButton/BackButton';
import { Control, Controller } from 'react-hook-form';
import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@klover/attain-design-system';
import * as Styled from './Step.styles';

interface Props {
  step: number;
  prevPage: () => void;
  nextPage: () => void;
  watchIndustry: string | null;
  watchCustomIndustry: string | null;
  control: Control;
  industryOptions: string[] | undefined;
}

export const IndustryStep = ({
  step,
  prevPage,
  nextPage,
  watchIndustry,
  watchCustomIndustry,
  control,
  industryOptions,
}: Props) => {
  return (
    <Styled.Block>
      <Back prevPage={prevPage} />

      <Styled.Step>Step {step} / 6</Styled.Step>

      <Styled.Header>What industry are you in?</Styled.Header>

      <Styled.InputWrapper fullWidth>
        <InputLabel id="industry-label" required>
          Industry
        </InputLabel>

        <Controller
          name="industry"
          control={control}
          rules={{ required: false }}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <Select
              variant="outlined"
              labelId="industry-label"
              id="industry"
              fullWidth
              onChange={onChange}
              onBlur={onBlur}
              value={value ?? ''}
              name={name}
              inputRef={ref}
              defaultValue=""
            >
              {industryOptions?.map((option, i) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
              <MenuItem value="other">Other</MenuItem>
            </Select>
          )}
        />
      </Styled.InputWrapper>

      {watchIndustry === 'other' && (
        <Styled.InputWrapper fullWidth>
          <Controller
            name="customIndustry"
            control={control}
            rules={{ required: false }}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <TextField
                required
                placeholder="Enter your industry"
                fullWidth
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? ''}
                name={name}
                inputRef={ref}
              />
            )}
          />
        </Styled.InputWrapper>
      )}

      <Styled.Submit
        variant="contained"
        fullWidth
        onClick={nextPage}
        disabled={
          !Boolean(watchIndustry && watchIndustry !== 'other') &&
          !(watchIndustry === 'other' && watchCustomIndustry)
        }
      >
        Next
      </Styled.Submit>
    </Styled.Block>
  );
};

export default IndustryStep;
