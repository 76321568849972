import trackEvent from 'utils/trackingEvents';
import useMe from 'hooks/useMe/useMe';
import { Button } from '@klover/attain-design-system';
import { useGlobalState } from 'state';
import * as Styled from './MeasurementUpsellBanner.styles';

export const MeasurementUpsellBanner = () => {
  const me = useMe();
  const [, actions] = useGlobalState();
  return (
    <Styled.Wrapper>
      This is a demo campaign – ready to start measuring your data in real-time?
      <Button
        variant="contained"
        onClick={() => {
          actions.showToast(
            'Thank you for your interest!',
            'Your Attain Account Representative we’ll reach out to you!'
          );
          trackEvent('Measurement Upsell', {
            ...me,
            from: 'Measurement - upsell banner',
          });
        }}
      >
        Launch your first campaign
      </Button>
    </Styled.Wrapper>
  );
};

export default MeasurementUpsellBanner;
