import {
  AdapterDayjs,
  Alert,
  InputLabel,
  LocalizationProvider,
  TextField,
} from '@klover/attain-design-system';
import { Calendar } from '@phosphor-icons/react';
import { DateRange, DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import * as Styled from './DatepickerRange.styles';

interface DatepickerRangeProps {
  disclaimer?: string;
  inputLabel: string;
  dateLimit?: DateRange;
  onSelect: (value: DateRange | undefined) => void;
  selected: DateRange | undefined;
  alignLeft?: boolean;
}

export const DatepickerRange = ({
  disclaimer,
  inputLabel,
  dateLimit,
  onSelect,
  selected,
  alignLeft,
}: DatepickerRangeProps) => {
  const dateToString = (date) => {
    return format(date, 'MM/dd/yy');
  };

  const now = new Date();
  const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1);
  const endDateLimit = dateLimit?.to ? dateLimit.to : null;
  const startDateLimit = dateLimit?.from ? dateLimit.from : null;

  return (
    <Styled.DatepickerWrapper>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <InputLabel>{inputLabel}</InputLabel>

        <Styled.Click>
          <div>
            <TextField
              placeholder="Select dates"
              value={
                selected
                  ? `${
                      selected.from ? dateToString(selected.from) : 'mm/dd/yyyy'
                    } - ${
                      selected.to ? dateToString(selected?.to) : 'mm/dd/yyyy'
                    }`
                  : ''
              }
              InputProps={{
                endAdornment: <Calendar size={36} />,
              }}
            />

            <Styled.Datepicker id="dp-wrapper" $alignLeft={alignLeft}>
              <DayPicker
                defaultMonth={lastMonth}
                disabled={
                  endDateLimit && startDateLimit
                    ? [
                        { from: endDateLimit, to: new Date(9999, 12, 31) },
                        {
                          from: new Date(Date.now() / 1000),
                          to: startDateLimit,
                        },
                      ]
                    : undefined
                }
                fixedWeeks
                mode={'range'}
                numberOfMonths={2}
                onSelect={(range, selectedDay, activeModifiers) => {
                  if (range && activeModifiers.range_middle) {
                    range.from = selectedDay;
                  }
                  onSelect(range);
                }}
                selected={selected}
                showOutsideDays
                toDate={new Date()}
              />

              {disclaimer && (
                <Styled.Note>
                  <Alert severity="info">{disclaimer}</Alert>
                </Styled.Note>
              )}
            </Styled.Datepicker>
          </div>
        </Styled.Click>
      </LocalizationProvider>
    </Styled.DatepickerWrapper>
  );
};

export default DatepickerRange;
