import { ResponsiveLine } from '@nivo/line';
import { attainColorPalette } from 'constants/attainColorPalette';
import * as Styled from '../MeasurementBrandSurvey.styles';

interface Props {
  charts: any;
}

const Line = ({ series, lineGenerator, xScale, yScale }) => {
  return series.map(({ id, data }, index) => (
    <path
      key={id}
      d={lineGenerator(
        data.map((d) => ({
          x: xScale(d.data.x),
          y: yScale(d.data.y),
        }))
      )}
      fill="none"
      stroke={index === 0 ? attainColorPalette[0] : attainColorPalette[8]}
      strokeDasharray={index === 0 ? 0 : '8, 8'}
      strokeWidth={3}
    />
  ));
};

// Custom Tooltip Component
const CustomTooltip = ({ point }) => (
  <Styled.Tooltip>
    <Styled.TooltipTitle>{point.data.x}</Styled.TooltipTitle>
    <strong>{point.serieId}:</strong> {point.data.yFormatted}
  </Styled.Tooltip>
);

export const ChartLifeOverTime = ({ charts }: Props) => {
  return (
    <Styled.ChartWrapper $height={400}>
      <ResponsiveLine
        tooltip={CustomTooltip}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
        }}
        axisLeft={{
          format: (value) => `${Math.round(value * 100)}%`,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
        }}
        data={charts}
        margin={{ top: 4, right: 76, bottom: 21, left: 72 }}
        pointSize={0}
        enableTouchCrosshair={true}
        useMesh={true}
        yFormat={(value) => `${(Number(value) * 100).toFixed(2)}%`}
        layers={[
          // includes all default layers
          'grid',
          'markers',
          'axes',
          'areas',
          'crosshair',
          'slices',
          'points',
          'mesh',
          'legends',
          Line,
        ]}
      />
    </Styled.ChartWrapper>
  );
};

export default ChartLifeOverTime;
