import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import {
  FloatValue,
  MoneyValue,
  PercentageValue,
} from 'types/graphql/generated';
import * as Styled from './MetricBar100.styles';

interface Props {
  index?: number | FloatValue | MoneyValue | PercentageValue;
  highestIndex?: number | false | undefined;
  fullWidth?: boolean;
  forStorybookDontUse?: boolean; //remove once flag deprecated
}

const MetricBar100 = ({
  index,
  highestIndex = 1,
  fullWidth,
  forStorybookDontUse,
}: Props) => {
  const censusBalancing = useUiFlag('ui_census_balancing');

  const barFilled =
    (index as FloatValue | MoneyValue | PercentageValue)?.value /
    (highestIndex as number);

  const component = (
    <>
      {
        // for number values
        typeof (index as number) === 'number' ? (
          <Styled.Wrapper>
            <Styled.PercentBar>
              <Styled.PercentFilled
                $percent={index ? (index as number) * 100 : 0}
              />
            </Styled.PercentBar>
            <Styled.Text>
              {((index as number) * 100).toFixed(0) + '%'}
            </Styled.Text>
          </Styled.Wrapper>
        ) : (
          <>
            {(index as PercentageValue)?.__typename === 'PercentageValue' ? (
              <>
                {/* for percentage values*/}
                {!fullWidth ? (
                  <Styled.Wrapper>
                    <Styled.PercentBar>
                      <Styled.PercentFilled
                        $percent={index ? barFilled * 100 : 0}
                        $opacity={barFilled}
                      />
                    </Styled.PercentBar>
                    <Styled.Text>
                      {(index as PercentageValue)?.title ?? 'N/A'}
                    </Styled.Text>
                  </Styled.Wrapper>
                ) : (
                  <Styled.WrapperFullWidth>
                    <Styled.PercentBar>
                      <Styled.PercentFilled
                        $percent={index ? barFilled * 100 : 0}
                        $opacity={barFilled}
                      />
                    </Styled.PercentBar>
                    <Styled.Text>
                      {(index as PercentageValue)?.title ?? 'N/A'}
                    </Styled.Text>
                  </Styled.WrapperFullWidth>
                )}
              </>
            ) : (
              <>
                {/* for relative values*/}
                <Styled.WrapperReverse>
                  <Styled.PercentBarTransparent>
                    <Styled.PercentFilledTransparent
                      $percent={index ? barFilled * 100 : 0}
                      $opacity={barFilled}
                    />
                  </Styled.PercentBarTransparent>
                  <Styled.Text>
                    {(index as FloatValue | MoneyValue)?.title ?? 'N/A'}
                  </Styled.Text>
                </Styled.WrapperReverse>
              </>
            )}
          </>
          // for percentage values
        )
      }
    </>
  );

  return (
    <>
      {
        //remove once flag deprecated
        forStorybookDontUse && component
      }

      {censusBalancing.isReady && censusBalancing.enabled && component}

      {!forStorybookDontUse &&
        !censusBalancing.isReady &&
        !censusBalancing.enabled && <Styled.WrapperOld />}
    </>
  );
};

export default MetricBar100;
