import Back from './BackButton/BackButton';
import { Control, Controller } from 'react-hook-form';
import { InputLabel, TextField } from '@klover/attain-design-system';
import * as Styled from './Step.styles';

interface Props {
  step: number;
  prevPage: () => void;
  nextPage: () => void;
  watchValue: string | null;
  control: Control;
}

export const CompanyStep = ({
  step,
  prevPage,
  nextPage,
  watchValue,
  control,
}: Props) => {
  return (
    <Styled.Block>
      <Back prevPage={prevPage} />

      <Styled.Step>Step {step} / 6</Styled.Step>

      <Styled.Header>Where do you work?</Styled.Header>

      <Styled.InputWrapper fullWidth>
        <InputLabel id="company-label" required>
          Employer name
        </InputLabel>

        <Controller
          name="company"
          control={control}
          rules={{ required: false }}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <TextField
              aria-labelledby="company-label"
              placeholder="Enter your employer's name"
              fullWidth
              onChange={onChange}
              onBlur={onBlur}
              value={value ?? ''}
              name={name}
              inputRef={ref}
            />
          )}
        />
      </Styled.InputWrapper>

      <Styled.Submit
        variant="contained"
        fullWidth
        onClick={nextPage}
        disabled={!Boolean(watchValue)}
      >
        Next
      </Styled.Submit>
    </Styled.Block>
  );
};

export default CompanyStep;
