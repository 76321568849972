import styled from 'styled-components';
import { theme } from '@klover/attain-design-system';

export const Bubble = styled.div<{
  $name: string;
  $percent: number;
  $top: number;
  $left: number;
  $color: string;
  $delay: number;
  $minValue: number;
  $maxValue: number;
}>`
  align-items: center;
  animation: ${({ $name }) =>
    `0.5s ${$name
      .split(' ')
      .join('')} cubic-bezier(0.175, 0.885, 0.32, 1.275) 1 both`};
  animation-delay: ${({ $delay }) => `${$delay * 0.1 + 0.5}s`};
  aspect-ratio: 1;
  border: 0.3vh solid white;
  border-color: ${({ $color }) => $color};
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: clip;
  position: absolute;
  text-align: center;
  translate: -50% -50%;
  top: ${({ $top }) => $top}%;
  left: ${({ $left }) => $left}%;
  width: 17%;

  @keyframes ${({ $name }) => $name.split(' ').join('')} {
    from {
      opacity: 0;
      scale: 0;
    }
    to {
      opacity: 1;
      scale: ${({ $percent, $minValue, $maxValue }) =>
        (($percent - $minValue) / ($maxValue - $minValue)) * 0.5 + 0.5};
    }
  }
`;

export const Text = styled.div<{
  $name: string;
  $percent: number;
  $delay: number;
  $minValue: number;
  $maxValue: number;
}>`
  animation: ${({ $name }) =>
    `0.5s ${$name
      .split(' ')
      .join('')}1 cubic-bezier(0.175, 0.885, 0.32, 1.275) 1 both`};
  animation-delay: ${({ $delay }) => `${$delay * 0.1 + 0.5}s`};
  width: 40%;

  @keyframes ${({ $name }) => `${$name.split(' ').join('')}1`} {
    from {
      opacity: 0;
      scale: 0;
    }
    to {
      opacity: 1;
      scale: ${({ $percent, $minValue, $maxValue }) =>
        1 / ((($percent - $minValue) / ($maxValue - $minValue)) * 0.5 + 0.5)};
    }
  }
`;

export const Name = styled.div<{
  $color?: string;
}>`
  ${theme.typography.bodyLG};
  font-size: 100%;
  line-height: 1;
  color: ${({ $color }) => $color};
  margin-block-end: 0.1em;
  position: relative;
  z-index: 1;
`;

export const Percent = styled(Name)`
  color: ${theme.colors.text};
`;

export const BubbleBackgroundColor = styled.div<{
  $color?: string;
  $name: string;
}>`
  background: ${({ $color }) => $color};
  inset: 0;
  opacity: 0.1;
  position: absolute;
  z-index: 1;
`;

export const BubbleBackground = styled.div<{
  $name: string;
}>`
  background: ${theme.colors.backgroundPrimary};
  inset: 0;
  opacity: 0.9;
  position: absolute;
  z-index: 0;
`;
