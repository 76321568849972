import IncrementalityMarker from '../../IncrementalityMarkers/IncrementalityMarkers';
import Pagination from 'components/Pagination/Pagination';
import StatCard from 'components/StatCard/StatCard';
import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import {
  HiddenIcon,
  NeutralCircle,
  PositiveTraiangle,
} from 'components/Icons/Icons';
import {
  IncrementalityDemographicReport,
  LiftSignificance,
} from 'types/graphql/generated';
import { significanceMap } from 'components/Measurement/MeasurementIncrementality/MeasurementIncrementality';
import { useState } from 'react';
import * as Styled from './IncrementalityTable.styles';

interface Props {
  data: IncrementalityDemographicReport[] | undefined;
}

export const IncrementalityTable = ({ data }: Props) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offset, setOffset] = useState(0);
  const uiTransactionLift = useUiFlag('ui_incrementality_transaction_lift');
  const showTransactionLift =
    uiTransactionLift.isReady && uiTransactionLift.enabled;

  const displayData = data?.slice(
    offset * rowsPerPage,
    offset * rowsPerPage + rowsPerPage
  );

  return (
    <StatCard title="Incremental sales breakdown">
      <Styled.ITable>
        <thead>
          <tr>
            <th>Audience</th>
            <th></th>
            <th> {showTransactionLift ? 'Transaction lift' : null}</th>
            <th>Incremental sales lift</th>
          </tr>
        </thead>
        <tbody>
          {displayData?.map((demographic, i) => {
            const showSalesLiftValue =
              significanceMap[demographic.salesLift.significance] !==
              LiftSignificance.NO_LIFT_DETECTED;
            const showTxnLiftValue =
              significanceMap[demographic.transactionLift.significance] !==
              LiftSignificance.NO_LIFT_DETECTED;

            return (
              <tr key={i}>
                <td>{demographic.title}</td>
                <td></td>
                <td>
                  {showTransactionLift ? (
                    <>
                      {showTxnLiftValue
                        ? demographic.transactionLift.value?.title
                        : 'NL'}
                      <IncrementalityMarker
                        significance={demographic.transactionLift.significance}
                      />
                    </>
                  ) : null}
                </td>

                <td>
                  {showSalesLiftValue
                    ? demographic.salesLift.value?.title
                    : 'NL'}
                  <IncrementalityMarker
                    significance={demographic.salesLift.significance}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>

        <tfoot>
          <td>
            <span>
              <PositiveTraiangle /> Significantly positive at 90%
            </span>
            <span>
              <NeutralCircle /> Not significant
            </span>
            <span>
              <HiddenIcon />
              NL - No detected lift
            </span>
          </td>
          {!!data?.length && data?.length > 5 && (
            <td colSpan={3}>
              <Pagination
                total={data?.length ?? 0}
                setRowsPerPage={setRowsPerPage}
                rowsPerPage={rowsPerPage}
                setOffset={setOffset}
                offset={offset}
              />
            </td>
          )}
        </tfoot>
      </Styled.ITable>
    </StatCard>
  );
};

export default IncrementalityTable;
