import { gql } from '@apollo/client';

export const onboardingOptionsQuery = gql`
  query OnboardingOptions {
    onboardingOptions {
      workOptions
      industryOptions
      jobRoleOptions
      jobLevelOptions
    }
  }
`;

export default onboardingOptionsQuery;
