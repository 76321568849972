import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

export const Empty = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px - 2 * ${theme.spacing.lg});
  justify-content: center;
  margin-top: ${theme.spacing.lg};
`;

export const Header = styled.div`
  ${theme.typography.headingSM};
  color: ${theme.colors.textBrand};
  margin-top: ${theme.spacing.lg};
`;

export const Link = styled.a`
  margin-top: ${theme.spacing.lg};
  display: block;
`;
