import styled from 'styled-components';
import { theme } from '@klover/attain-design-system';

export const TrendsWrapper = styled.div`
  padding-block-start: ${theme.spacing.xl};
`;

export const LegendWrapper = styled.div`
  ${theme.typography.bodyMD};
  color: ${theme.colors.textTertiary};
  display: flex;
  align-items: center;
  border-top: 1px solid ${theme.colors.borderDark};
  margin-inline: -${theme.spacing.lg};
  padding: ${theme.spacing.md} ${theme.spacing.lg};
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-inline-end: ${theme.spacing.lg};
  margin-block-end: -${theme.spacing.lg};
  svg {
    margin-inline-start: ${theme.spacing.xxs};
  }
`;
