import { gql } from '@apollo/client';

export const submitMessageReviewMutation = gql`
  mutation SubmitMessageReview($input: SubmitMessageReviewInput!) {
    submitMessageReview(input: $input) {
      ... on SubmitMessageReviewSuccess {
        success
      }
      ... on SubmitMessageReviewError {
        title
        message
      }
    }
  }
`;

export default submitMessageReviewMutation;
