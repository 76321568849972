export const convertObjectToSearchString = (obj: Record<string, any>) => {
  return Object.keys(obj).reduce((str, key) => {
    const valueAtKey = obj[key];
    if (!valueAtKey) return str;

    return (
      str +
      `${key}=${
        Object(valueAtKey) !== valueAtKey
          ? valueAtKey
          : JSON.stringify(valueAtKey)
      }&`
    );
  }, '?');
};
