import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

export const Wrapper = styled.div`
  height: 400px;
  position: relative;

  & > div {
    position: absolute;
  }
`;

export const LeftAxis = styled.div`
  display: flex;
  flex-direction: column;
  height: 91%;
  padding: 0 0 40px;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 5px;
  width: 6px;
`;

interface AxisProps {
  value: string;
}

export const AxisLeftItem = styled.span<AxisProps>`
  position: relative;
  background: ${theme.colors.CHART_PRIMARY};
  ::before {
    left: 0px;
    position: absolute;
    right: 24px;
    bottom: -12px;
    ${theme.typography.bodyLG}
    color: ${theme.colors.textBrand};
    content: '${({ value }) => value}';
  }
`;

export const GridLines = styled(LeftAxis)`
  width: 100%;
  height: 91%;
  padding: 0px 78px 40px;

  > span {
    background: ${theme.colors.borderDark};
    display: block;
    border-radius: 50%;
    opacity: 0.3;
    height: 2px;
    width: 100%;
  }
`;

export const ChartWrapper = styled.div`
  &&& {
    position: relative;
  }

  width: 99%;
  height: 91%;
`;

export const RightAxis = styled.div`
  color: ${theme.colors.textBrand};
  display: flex;
  flex-direction: column;
  font-size: 16px;
  justify-content: space-between;
  position: absolute;
  top: -6px;
  right: 24px;
  text-align: right;
  height: 375px;
`;
