import Door from 'assets/403-door.svg';
import OutcomeHQLogo from 'assets/outcome-hq-logo.svg';
import React from 'react';
import paths from 'constants/paths';
import { Button } from '@klover/attain-design-system';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntercom } from 'react-use-intercom';
import * as Styled from './403.styles';

export const _403 = () => {
  const { logout } = useAuth0();
  const { shutdown } = useIntercom();

  React.useEffect(() => {
    window.analytics.page('Page view: 403');
  }, []);

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Link to={paths.HOME}>
          <Styled.Logo src={OutcomeHQLogo} alt="Attain" />
        </Link>
      </Styled.Header>

      <Styled.Content>
        <Styled.Centered>
          <img src={Door} alt="" />
          <Styled.Title>We trust you, but we can’t let you in.</Styled.Title>
          <Styled.Description>
            It looks like you don’t have access to this page. Contact our team
            and we’ll get your account set up.
          </Styled.Description>

          <Button
            variant="contained"
            onClick={() => {
              shutdown();
              logout({
                logoutParams: {
                  returnTo: `${window.origin}${paths.LOGIN}`,
                },
              });
            }}
          >
            Logout
          </Button>
        </Styled.Centered>
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default _403;
