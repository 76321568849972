import {
  Autocomplete,
  Checkbox,
  MenuItem,
  TextField,
} from '@klover/attain-design-system';
import { CaretDown } from '@phosphor-icons/react';
import { Deal } from 'types/graphql/generated';
import * as Styled from './DealSelectAutocomplete.styles';

interface Props {
  selected: Deal[] | null;
  loading: boolean;
  disabled: boolean;
  deals: Deal[] | null;
  setter: (value: Deal[]) => void;
}

export const DealSelectAutocomplete = ({
  deals,
  loading,
  disabled,
  setter,
  selected,
}: Props) => {
  return (
    <Autocomplete
      disablePortal
      options={deals || []}
      loading={loading}
      disabled={disabled}
      value={selected || []}
      multiple
      limitTags={1}
      getOptionLabel={(option) => option.name}
      disableCloseOnSelect
      disableClearable
      popupIcon={<CaretDown />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_, value) => {
        setter(value);
      }}
      renderOption={(props, option, { selected: isSelected }) => (
        <MenuItem {...props} key={option.id}>
          <Styled.OptionWrapper>
            <Checkbox checked={isSelected} />
            <div>
              <Styled.OptionTitle>{option.name}</Styled.OptionTitle>
              <Styled.OptionDetails>
                `${option.sourceId} | ${option.brandName}`
              </Styled.OptionDetails>
            </div>
          </Styled.OptionWrapper>
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={Boolean(selected && !selected.length)}
          placeholder={selected?.length ? '' : 'Select deal(s)'}
          variant="outlined"
        />
      )}
    />
  );
};

export default DealSelectAutocomplete;
