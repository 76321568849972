import IndexScore from '../IndexScore/IndexScore';
import { IndexValue, IndexValueEnum } from 'types/graphql/generated';
import * as Styled from './OverviewIndexScore.styles';

interface Props {
  index: IndexValue;
}

const OverviewIndexScore = ({ index }: Props) => {
  return (
    <Styled.Wrapper>
      <Styled.Header>
        <div>Index score</div>
        <IndexScore index={index} />
      </Styled.Header>

      <Styled.Bars>
        <Styled.BarNegative $active={index.kind === IndexValueEnum.LOW} />
        <Styled.BarNeutral
          $active={index.kind === (IndexValueEnum.NA || IndexValueEnum.NEUTRAL)}
        />
        <Styled.BarPositive $active={index.kind === IndexValueEnum.HIGH} />
      </Styled.Bars>
    </Styled.Wrapper>
  );
};

export default OverviewIndexScore;
