import { gql } from '@apollo/client';

export const brandLiftSurveyInsightsInterestSkewsQuery = gql`
  query BrandLiftSurveyInsightsInterestSkews(
    $input: BrandLiftSurveyInsightsInput!
  ) {
    brandLiftSurveyInsightsInterestSkews(input: $input) {
      id
      title
      segments {
        id
        title
        skew {
          value
          title
        }
      }
    }
  }
`;

export default brandLiftSurveyInsightsInterestSkewsQuery;
