export const Address = ({ address }) => {
  const { addressLine1, addressLine2, city, state, zipCode } = address;

  if (!addressLine1 && !city && !state && !zipCode) return;

  const displayLine1 = addressLine1 ? (
    <span>
      {addressLine1}
      <br />
    </span>
  ) : null;
  const displayLine2 = addressLine2 ? (
    <span>
      {addressLine2}
      <br />
    </span>
  ) : null;
  const displayCity = city ? (
    <span>
      {city}
      <br />
    </span>
  ) : null;
  const displayState = state ? (
    <span>
      {state}
      <br />
    </span>
  ) : null;
  const displayZipCode = zipCode ? (
    <span>
      {zipCode}
      <br />
    </span>
  ) : null;

  const addressComponents = [
    displayLine1,
    displayLine2,
    displayCity,
    displayState,
    displayZipCode,
  ].filter((item) => !!item);

  return <div>{addressComponents}</div>;
};
