export const setRecentResults = (
  id: string,
  newItem: { id: string; title: string; kind?: string; group?: string }
) => {
  const oldRecents = JSON.parse(localStorage.getItem(id) || '[]');
  const updatedRecents = [
    newItem,
    ...oldRecents.filter((item) => item.id !== newItem.id).slice(0, 2),
  ];
  localStorage.setItem(id, JSON.stringify(updatedRecents));
};

export const getRecentResults = (id: string) =>
  JSON.parse(localStorage.getItem(id) || '[]');
