import { gql } from '@apollo/client';

export const brandLiftSurveyAiInsightsQuery = gql`
  fragment insightDetail on InsightDetail {
    title
    insights {
      header
      bulletPoints
    }
  }
  query BrandLiftSurveyAiInsights($input: BrandLiftSurveyAiInsightsInput!) {
    brandLiftSurveyAiInsights(input: $input) {
      executiveSummaryParagraphs
      keyInsights {
        ...insightDetail
      }
      demographicOverview {
        ...insightDetail
      }
      personaDevelopment {
        ...insightDetail
      }
      actionableInsights {
        ...insightDetail
      }
      images
    }
  }
`;

export default brandLiftSurveyAiInsightsQuery;
