import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

export const Wrapper = styled.div`
  background: ${theme.colors.backgroundPrimary};
  border: 1px solid ${theme.colors.borderDark};
  border-radius: ${theme.spacing.xs};
  padding: ${theme.spacing.md};
`;

export const Header = styled.div`
  ${theme.typography.headingXS};
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Bars = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.xs};
  padding-block-start: ${theme.spacing.md};
  position: relative;
`;

export const BarNegative = styled.div<{ $active: boolean }>`
  background: ${({ $active }) =>
    $active ? theme.colors.errorForeground : theme.colors.backgroundLight};
  border-radius: 2px;
  flex: 1;
  height: 4px;
`;

export const BarNeutral = styled(BarNegative)`
  background: ${({ $active }) =>
    $active ? theme.colors.buttonTextDisabled : theme.colors.backgroundLight};
`;

export const BarPositive = styled(BarNegative)`
  background: ${({ $active }) =>
    $active ? theme.colors.successForeground : theme.colors.backgroundLight};
`;

export const Dot = styled.div`
  background: ${theme.colors.successForeground};
  border-radius: 50%;
  height: 12px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
`;
