import styled from 'styled-components';
import { IconButton, theme } from '@klover/attain-design-system';

export const ReportContainer = styled.div`
  padding: 1rem;
`;

export const ReportSection = styled.div`
  padding: 1rem;
  border: solid 1px #ccc;
  border-radius: 5px;
  margin-bottom: 1rem;
`;

export const LineItemContainer = styled.div`
  border-top: solid 1px #eee;
  display: flex;
  justify-content: center;
`;

export const LineItem = styled.div`
  ${theme.typography.bodyMD};
  width: 50%;
  padding: 0.5rem 0;
`;

export const LoaderWrapper = styled.div`
  padding: 1rem 0;
  height: 50px;
  width: 100%;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  & > * {
    width: 100%;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  margin-bottom: 1rem;
`;

export const CloseButton = styled(IconButton)`
  margin-bottom: 1rem;
`;
