import FeaturedLanding from 'assets/onboarding/bg-landing-page-1-mobile.jpg';
import MediaQuery from 'react-responsive';
import PageBg from 'assets/onboarding/bg-landing-page-1.jpg';
import PageBgMobile from 'assets/onboarding/bg-landing-page-mobile.jpg';
import React from 'react';
import paths from 'constants/paths';
import { Back } from 'components/Onboarding/BackButton/BackButton';
import { useAuth0 } from '@auth0/auth0-react';
import { useGlobalState } from 'state';
import * as Styled from './Onboarding.styles';

const VerifyEmail = () => {
  const [, actions] = useGlobalState();
  const { user, logout, getAccessTokenSilently, loginWithRedirect } =
    useAuth0();
  const redirect = `${window.location.origin}${paths.LOGIN_REDIRECT}`;
  const resendEmail = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.preventDefault();
    const url = import.meta.env.DEV
      ? `https://ksaas.attain-development.com${paths.RESEND_EMAIL_VERIFICATION}`
      : `${import.meta.env.VITE_GRAPHQL_URL}${paths.RESEND_EMAIL_VERIFICATION}`;

    if (!user?.email_verified) {
      getAccessTokenSilently()
        .then((accessToken) => {
          fetch(url, {
            method: 'get',
            headers: {
              'Content-type': `application/json`,
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then(() => {
              actions.showToast('', `New verification email sent.`);
            })
            .catch(() => {
              actions.showToastError(
                'Error',
                `Something went wrong. Please try again`
              );
            });
        })
        .catch(() => {
          actions.showToastError(
            'Error',
            `Something went wrong. Please try again`
          );
        });
    } else {
      actions.showToastError('Error', `Email has already been verified.`);
    }
  };

  const handleLogIn = () => {
    loginWithRedirect({
      authorizationParams: {
        redirectUri: redirect,
      },
    });
  };

  React.useEffect(() => {
    window.analytics.page('Page view: Verify Email');
  }, []);

  return (
    <Styled.Wrapper>
      <MediaQuery maxWidth={599}>
        <Styled.WrapperBG>
          <Styled.WrapperBGImg src={PageBgMobile} alt="" />
        </Styled.WrapperBG>
      </MediaQuery>

      <MediaQuery minWidth={600}>
        <Styled.WrapperBG>
          <Styled.WrapperBGImg src={PageBg} alt="" />
        </Styled.WrapperBG>
      </MediaQuery>

      <Styled.Content>
        <Styled.Block style={{ display: 'block' }}>
          <Back
            prevPage={() => {
              logout({
                logoutParams: {
                  returnTo: `${window.origin}${paths.LOGIN}`,
                },
              });
            }}
          />
          <Styled.Header>Check your email</Styled.Header>
          <Styled.Instructions>
            We sent an email verification to: <span>{user?.email}</span>
          </Styled.Instructions>
          <Styled.Login fullWidth onClick={handleLogIn} variant="contained">
            Login
          </Styled.Login>
          <Styled.ResendEmail>
            {`Didn't receive an email?  `}
            <span onClick={resendEmail} role="button" tabIndex={0}>
              Resend email
            </span>
          </Styled.ResendEmail>
        </Styled.Block>

        <MediaQuery maxWidth={599}>
          <Styled.FeaturedImg>
            <img src={FeaturedLanding} alt="" />
          </Styled.FeaturedImg>
        </MediaQuery>
      </Styled.Content>
    </Styled.Wrapper>
  );
};

export default VerifyEmail;
