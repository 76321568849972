import styled, { keyframes } from 'styled-components';
import { theme } from '@klover/attain-design-system';

const createSlideIn = (left) => keyframes`
  from {
    left: 0;
    opacity: 0;
  }
  to {
    left: ${left}%;
    opacity:1;
  }
`;

const fadeInBackground = (startColor, endColor) => keyframes`
  from {
    background: ${startColor};
  }
  to {
    background: ${endColor};
  }
`;

export const Index = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
  width: 100%;
`;

export const Slider = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  gap: ${theme.spacing.xxs};
  height: 24px;
  position: relative;
`;

interface BarProps {
  $active: boolean;
  $delay: number;
  $width: number;
}

export const Negative = styled.div<BarProps>`
  animation: ${({ $active }) =>
      fadeInBackground(
        theme.colors.backgroundLight,
        $active ? theme.colors.errorForeground : theme.colors.backgroundLight
      )}
    0.5s ease-out 1 both;
  animation-delay: ${({ $delay }) => `${$delay * 0.1}s`};
  background: ${theme.colors.backgroundLight};
  border-radius: 2px;
  height: 8px;
  width: ${({ $width }) => `${$width}%`};
`;

export const Neutral = styled(Negative)<BarProps>`
  animation-name: ${({ $active }) =>
    fadeInBackground(
      theme.colors.backgroundLight,
      $active ? theme.colors.borderDark : theme.colors.backgroundLight
    )};
`;

export const Positive = styled(Negative)<BarProps>`
  animation-name: ${({ $active }) =>
    fadeInBackground(
      theme.colors.backgroundLight,
      $active ? theme.colors.successForeground : theme.colors.backgroundLight
    )};
`;

export const Pointer = styled.div<{ $left: number; $delay: number }>`
  animation: ${({ $left }) => createSlideIn($left)} 0.5s ease-out 1 both;
  animation-delay: ${({ $delay }) => `${$delay * 0.1}s`};
  bottom: 17px;
  height: 14px;
  position: absolute;
  transform: translateX(-50%);
  width: 14px;
`;

export const Amount = styled.div`
  text-align: center;
  width: 27px;
`;

export const CellPositive = styled.div`
  ${theme.typography.bodyMD};
  align-items: center;
  background: ${theme.colors.successBackground};
  border-radius: ${theme.spacing.xxs};
  color: ${theme.colors.textSecondary};
  display: flex;
  justify-content: center;
  text-align: center;
  width: 84px;

  svg {
    color: ${theme.colors.successForeground};
    margin-right: ${theme.spacing.xxs};
  }
`;

export const CellNegative = styled(CellPositive)`
  background: ${theme.colors.errorBackground};

  svg {
    color: ${theme.colors.errorForeground};
  }
`;

export const CellNA = styled(CellPositive)`
  background: ${theme.colors.backgroundLight};

  svg {
    color: ${theme.colors.buttonTextDisabled};
  }
`;
