import Glide from '@glidejs/glide';
import LogoCocaCola from 'assets/signup/logo-coca-cola.svg';
import LogoMagnite from 'assets/signup/logo-magnite.svg';
import LogoMax from 'assets/signup/logo-max.svg';
import LogoOpenX from 'assets/signup/logo-openx.svg';
import LogoOutcomeHQ from 'assets/outcome-hq-logo.svg';
import LogoPampers from 'assets/signup/logo-pampers.svg';
import LogoSnowflake from 'assets/signup/logo-snowflake.svg';
import LogoTTD from 'assets/signup/logo-ttd.svg';
import LogoTacoBell from 'assets/signup/logo-taco-bell.svg';
import LogoUber from 'assets/signup/logo-uber.svg';
import LogoUlta from 'assets/signup/logo-ulta.svg';
import LogoUnited from 'assets/signup/logo-united.svg';
import LogoWalgreens from 'assets/signup/logo-walgreens.svg';
import Marquee from 'react-fast-marquee';
import Quote from 'assets/signup/quote.svg';
import React from 'react';
import SymbolAttain from 'assets/symbol-attain.svg';
import Video from 'assets/signup/video.mp4';
import paths from 'constants/paths';
import { Grid, theme } from '@klover/attain-design-system';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import * as Styled from './Signup.styles';

export const Signup = () => {
  const { loginWithRedirect } = useAuth0();
  const newAccRedirect = `${window.location.origin}${paths.VERIFYEMAIL}`;
  const redirect = `${window.location.origin}${paths.LOGIN_REDIRECT}`;

  const isDesktop = useMediaQuery({
    query: theme.mq.tabletPortraitUp,
  });

  React.useEffect(() => {
    window.analytics.page('Page view: Signup');
    if (!isDesktop) {
      new Glide('.glide', { gap: 32, type: 'carousel' }).mount();
    }
  }, []);

  const path = useLocation().pathname;

  const Quote1 = (
    <Styled.Quote>
      <img src={Quote} alt="" />

      <Styled.QuoteText>
        By joining forces, Attain and Magnite are at the forefront of advancing
        measurement capabilities for video.
      </Styled.QuoteText>

      <Styled.QuoteCredit>
        <Styled.QuoteAuthor>Kristen Williams</Styled.QuoteAuthor>
        <Styled.QuoteRole>
          SVP of Partnerships
          <br />
          Magnite
        </Styled.QuoteRole>
      </Styled.QuoteCredit>
    </Styled.Quote>
  );

  const Quote2 = (
    <Styled.Quote>
      <img src={Quote} alt="" />

      <Styled.QuoteText>
        With our Attain integration, buyers can apply granular purchase data
        across their CTV, mobile, and web campaigns via OpenX’s supply-side
        targeting solution.
      </Styled.QuoteText>

      <Styled.QuoteCredit>
        <Styled.QuoteAuthor>Brian Chisholm</Styled.QuoteAuthor>
        <Styled.QuoteRole>
          SVP of Strategic Partnerships
          <br />
          OpenX
        </Styled.QuoteRole>
      </Styled.QuoteCredit>
    </Styled.Quote>
  );

  const Quote3 = (
    <Styled.Quote>
      <img src={Quote} alt="" />

      <Styled.QuoteText>
        For brands who sell their products through retailers, the ability to
        make data-driven decisions that leverage customer sales data from
        partners like Attain is important to reach the right consumers.
      </Styled.QuoteText>

      <Styled.QuoteCredit>
        <Styled.QuoteAuthor>Jay Goebel</Styled.QuoteAuthor>
        <Styled.QuoteRole>
          GM, Data Partnerships
          <br />
          The Trade Desk
        </Styled.QuoteRole>
      </Styled.QuoteCredit>
    </Styled.Quote>
  );

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Container>
          <Styled.Flex>
            <Styled.HeaderLeft>
              <a href="https://attaindata.io/">
                <Styled.Logo src={LogoOutcomeHQ} alt="Attain" />
              </a>

              {isDesktop && (
                <>
                  <a href="https://attaindata.io/measurement/">Platform</a>

                  <a href="https://attaindata.io/media-strategy/">Solutions</a>

                  <a href="https://attaindata.io/newsroom/">Resources</a>

                  <a href="https://attaindata.io/about/">Company</a>
                </>
              )}
            </Styled.HeaderLeft>

            <Styled.HeaderRight>
              {isDesktop && (
                <a href="https://attaindata.io/contact-us/">
                  <Styled.HeaderButtonTalk variant="outlined">
                    Talk with us
                  </Styled.HeaderButtonTalk>
                </a>
              )}

              <Styled.HeaderButtonSignUp
                variant="contained"
                onClick={() =>
                  loginWithRedirect({
                    authorizationParams: {
                      screen_hint: 'signup',
                      redirectUri: newAccRedirect,
                    },
                  })
                }
              >
                Sign up
              </Styled.HeaderButtonSignUp>

              {isDesktop && (
                <Styled.LoginButton
                  onClick={() => {
                    loginWithRedirect({
                      authorizationParams: {
                        redirectUri: redirect,
                      },
                    });
                  }}
                >
                  Login
                </Styled.LoginButton>
              )}
            </Styled.HeaderRight>
          </Styled.Flex>
        </Styled.Container>
      </Styled.Header>

      <Styled.Hero>
        <Styled.Container>
          <Grid
            container
            alignItems="center"
            spacing={isDesktop ? theme.spacing.xxxl : 0}
          >
            <Grid item xs={12} sm={6}>
              <Styled.HeroDescription>
                {(path === paths.SIGNUP || path === paths.SIGNUP_V1) && (
                  <>
                    <span>Real consumer data.</span> In real-time.
                  </>
                )}

                {path === paths.SIGNUP_COMPETITOR && (
                  <>
                    <span>Is your</span> competition{' '}
                    <span>stealing your customers? Find out with Attain.</span>
                  </>
                )}

                {path === paths.SIGNUP_CONQUESTING && (
                  <>
                    Conquesting{' '}
                    <span>
                      with Attain: Because your competitor’s loss is your gain.
                    </span>
                  </>
                )}

                {path === paths.SIGNUP_DEMOGRAPHICS && (
                  <>
                    Demographic{' '}
                    <span>
                      insights at your fingertips. Try it now with Attain.
                    </span>
                  </>
                )}

                {path === paths.SIGNUP_MARKET_RESEARCH && (
                  <>
                    <span>Impress your boss with Attain’s</span> market
                    research.
                  </>
                )}

                {path === paths.SIGNUP_MARKET_SHARE && (
                  <>
                    <span>Competitive insights made easy. Increase</span> market
                    share <span>with Attain.</span>
                  </>
                )}
              </Styled.HeroDescription>

              {!isDesktop && (
                <Styled.VideoMobile>
                  <video playsInline autoPlay muted loop src={Video} />
                </Styled.VideoMobile>
              )}

              <Styled.HeroCTAs>
                <Styled.HeroCTA
                  variant="contained"
                  onClick={() =>
                    loginWithRedirect({
                      authorizationParams: {
                        screen_hint: 'signup',
                        redirectUri: newAccRedirect,
                      },
                    })
                  }
                >
                  Get started for free
                </Styled.HeroCTA>
              </Styled.HeroCTAs>

              <Styled.HeroDetails>
                100% free &nbsp;|&nbsp; No credit card required &nbsp;|&nbsp; No
                setup
              </Styled.HeroDetails>
            </Grid>

            {isDesktop && (
              <Grid item xs={12} sm={6}>
                <Styled.Video>
                  <video playsInline autoPlay muted loop src={Video} />
                </Styled.Video>
              </Grid>
            )}
          </Grid>
        </Styled.Container>
      </Styled.Hero>

      <Styled.Clients>
        <Marquee>
          <Styled.ClientsLogos>
            <img src={LogoMax} alt="" />
            <img src={LogoWalgreens} alt="" />
            <img src={LogoPampers} alt="" />
            <img src={LogoUnited} alt="" />
            <img src={LogoUlta} alt="" />
            <img src={LogoUber} alt="" />
            <img src={LogoCocaCola} alt="" />
            <img src={LogoTacoBell} alt="" />
          </Styled.ClientsLogos>
        </Marquee>
      </Styled.Clients>

      <Styled.Quotes>
        <Styled.QuoteBackground />

        <Styled.Container>
          {isDesktop ? (
            <Grid
              container
              spacing={isDesktop ? theme.spacing.xxl : theme.spacing.lg}
            >
              <Grid item xs={12} sm={4}>
                {Quote1}
              </Grid>

              <Grid item xs={12} sm={4}>
                {Quote2}
              </Grid>

              <Grid item xs={12} sm={4}>
                {Quote3}
              </Grid>
            </Grid>
          ) : (
            <div className="glide">
              <div data-glide-el="track" className="glide__track">
                <ul className="glide__slides" style={{ display: 'flex' }}>
                  <li className="glide__slide">{Quote1}</li>
                  <li className="glide__slide">{Quote2}</li>
                  <li className="glide__slide">{Quote3}</li>
                </ul>
              </div>

              <div className="glide__bullets" data-glide-el="controls[nav]">
                <div className="glide__bullet" data-glide-dir="=0" />
                <div className="glide__bullet" data-glide-dir="=1" />
                <div className="glide__bullet" data-glide-dir="=2" />
              </div>
            </div>
          )}
        </Styled.Container>
      </Styled.Quotes>

      <Styled.Partners>
        <Styled.Container>
          <Styled.ClientsTitle>
            Partnering with industry leaders to drive better outcomes
          </Styled.ClientsTitle>

          <Styled.PartnerLogos>
            <img src={LogoTTD} alt="" />
            <img src={LogoOpenX} alt="" />
            <img src={LogoMagnite} alt="" />
            <img src={LogoSnowflake} alt="" />
          </Styled.PartnerLogos>
        </Styled.Container>
      </Styled.Partners>

      <Styled.Mission>
        <Styled.Container>
          <Styled.MissionDescription>
            Our mission is to provide advertisers with the data they need to
            drive <span>better outcomes.</span>
          </Styled.MissionDescription>

          <a href="https://attaindata.io/">
            <Styled.MissionCTA variant="contained">
              Learn more about Attain
            </Styled.MissionCTA>
          </a>

          <Styled.Attain href="https://attaindata.io/">
            <img src={SymbolAttain} alt="" />
            <span>attaindata.io</span>
          </Styled.Attain>
        </Styled.Container>
      </Styled.Mission>
    </Styled.Wrapper>
  );
};

export default Signup;
