import ChartAbsoluteLift from './BrandSurveyCharts/ChartAbsoluteLift';
import DataTable from 'components/DataTable/DataTable';
import { Card, Grid, theme } from '@klover/attain-design-system';
import * as Styled from './MeasurementBrandSurvey.styles';

const data = [
  {
    title: 'Kingsford',
    control: 213,
    exposed: 730,
    baselineBrandFavorability: 0.1983,
    absoluteLift: 0.0536,
    pValue: 5.41481,
    isSignificant: true,
    charts: [
      {
        type: 'absolute',
        values: [
          {
            name: 'Control',
            value: 0.1983,
            confidenceInterval: [0.0393, 0.0679],
          },
          {
            name: 'Exposed',
            value: 0.2331,
            confidenceInterval: [0.0393, 0.0679],
          },
        ],
      },
    ],
  },
  {
    title: 'Royal Oak',
    control: 81,
    exposed: 303,
    baselineBrandFavorability: 0.0755,
  },
  {
    title: 'Cowboy',
    control: 51,
    exposed: 163,
    baselineBrandFavorability: 0.0476,
  },
  {
    title: 'Open Nature',
    control: 53,
    exposed: 162,
    baselineBrandFavorability: 0.0494,
  },
];

const columns = [
  {
    title: 'Answer',
    sort: 'answer',
  },
  {
    title: '# Control',
    sort: 'control',
  },
  {
    title: '# Exposed',
    sort: 'exposed',
  },
  {
    title: 'Baseline brand favorability',
    sort: 'baselineBrandFavorability',
  },
];

const rows = data.map((row) => ({
  sortValues: {
    answer: row.title,
    control: row.control,
    exposed: row.exposed,
    baselineBrandFavorability: row.baselineBrandFavorability,
  },
  component: () => (
    <>
      <td>{row.title}</td>
      <td>{row.control}</td>
      <td>{row.exposed}</td>
      <td>{(row.baselineBrandFavorability * 100).toFixed(2)}%</td>
      <td></td>
    </>
  ),
}));

export const MeasurementBrandFavorability = () => {
  return (
    <Card>
      <Grid
        container
        columnSpacing={theme.spacing.xxl}
        rowSpacing={theme.spacing.lg}
      >
        <Grid item xs={12}>
          <Styled.CardHeader>Brand favorability</Styled.CardHeader>

          <Styled.CardDescription>
            How favorable are you of this brand of lump charcoal?
          </Styled.CardDescription>
        </Grid>

        <Grid item xs={12}>
          {data[0]?.charts?.[0] && (
            <ChartAbsoluteLift chart={data[0].charts[0]} type="absolute" />
          )}
        </Grid>

        <Grid item xs={12}>
          <Styled.Badges>
            <Styled.Badge>
              Absolute lift: <span>5.36%</span>
            </Styled.Badge>
            <Styled.Badge>
              P value: <span>5.4148</span>
            </Styled.Badge>
            <Styled.Badge
              $borderColor={theme.colors.successForeground}
              $color={theme.colors.successBackground}
            >
              Significant lift: <span>TRUE</span>
            </Styled.Badge>
          </Styled.Badges>
        </Grid>

        <Grid item xs={12}>
          <div style={{ marginInline: -24 }}>
            <DataTable
              id="brand-favorability"
              columns={columns}
              rows={rows}
              noFooter
              noBorder
            />
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default MeasurementBrandFavorability;
