import TableIndex from 'components/TableIndex/TableIndex';
import styled from 'styled-components';
import { ArrowUp, CaretRight } from '@phosphor-icons/react';

import { Grid, theme } from '@klover/attain-design-system';

export const ShowingSkeleton = styled(Grid)`
  gap: ${theme.spacing.md};
  div {
    width: 100%;
  }
`;

export const TableHeader = styled.h2`
  ${theme.typography.headingLG};
`;

export const Table = styled(TableIndex)`
  margin: ${theme.spacing.lg} calc(-${theme.spacing.lg} - 1px)
    calc(-${theme.spacing.lg} - 1px);

  && {
    width: calc(100% + calc(${theme.spacing.lg} * 2 + 2px));
  }

  th {
    white-space: nowrap;
  }
  td {
    border-inline: none;
  }

  tr td:first-child {
    ${theme.typography.bodyLG};
    word-break: break-word;
  }
`;

export const HeaderRank = styled.th`
  cursor: pointer;
  text-align: center;
`;

export const HeaderFull = styled.th`
  width: 100%;
`;

export const HeaderCVR = styled.th`
  min-width: 150px;
  text-align: center !important;
`;

export const HeaderROAS = styled.th`
  text-align: center !important;
  min-width: 100px;
`;

export const Rank = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.xxs};
  justify-content: space-around;
`;

export const RankArrow = styled(ArrowUp)<{ $sortAsc: boolean }>`
  transform: ${({ $sortAsc }) => ($sortAsc ? 'none' : 'scaleY(-1)')};
  margin-right: -20px;
`;

export const CellRank = styled.td`
  text-align: center;
  width: 81px;
`;

export const CellOneCol = styled.td`
  width: 175px;
`;

export const CellTwoCol = styled.td`
  width: 350px;
  ${theme.typography.bodyMD};
`;

const IndexBackground = (index) => {
  if (index >= 50) {
    const opacity = ((index - 50) * 2) / 100;
    return `hsla(148, 48%, 33%, ${opacity});`;
  } else {
    const opacity = (100 - index * 2) / 100;
    return `hsla(352, 80%, 55%, ${opacity});`;
  }
};

const IndexForeground = (index) => {
  if (index >= 80 || index <= 20) {
    return theme.colors.textInverted;
  } else {
    return theme.colors.text;
  }
};

export const CellIndex = styled.td<{ $index: number; children: any }>`
  background: ${({ $index }) => IndexBackground($index)};
  color: ${({ $index }) => IndexForeground($index)};
  text-align: center;
  vertical-align: top;

  &&& {
    ${theme.typography.headingXS};
    width: 155px;
  }
`;

export const AllDeals = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: ${theme.spacing.md};
  margin: -12px -24px;
  padding: 12px 24px;
  width: calc(100% + 48px);
`;

interface DealsStatus {
  $open: boolean;
  children?: any;
}

export const Caret = styled(CaretRight)<DealsStatus>`
  transform: ${({ $open }) => ($open ? 'rotate(90deg)' : 'none')};
  transition: transform 0.25s ease;
`;

export const NestedTableWrapper = styled.div<DealsStatus>`
  background: ${theme.colors.backgroundBrandLightest};
  max-height: ${({ $open }) => ($open ? '1000px' : '0')};
  overflow: hidden;
  transition: max-height 0.25s ease;
  margin: ${theme.spacing.sm} calc(-${theme.spacing.lg} - 1px)
    calc(-${theme.spacing.sm} - 1px);
  width: calc(100% + 50px);
`;

export const NestedTable = styled.table<DealsStatus>`
  border-collapse: collapse;
  display: table;

  tr:first-child td {
    border-top: 0;
  }
`;

export const Line2 = styled.div`
  ${theme.typography.bodyLG};
  color: ${theme.colors.textTertiary};
`;

export const IndexAggregate = styled.div`
  height: 24px;
`;

export const NestedCellIndex = styled(CellIndex)<{ $index: number }>`
  height: 73px;
  vertical-align: middle;
`;

export const NestedTableHeaderRowWrapper = styled.div`
  overflow: hidden;
  transition: max-height 0.25s ease;
  margin: -13px -25px -12px;
  width: calc(100% + 50px);
`;

export const CellRate = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  gap: ${theme.spacing.xs};
`;

export const DataTableBar = styled.div<{ $value: number }>`
  background: ${({ $value }) =>
    `hsl(251, ${(1 - $value) * 73 + 19}%, ${(1 - $value) * 46 + 44}%)`};
  border-radius: ${theme.spacing.xxs};
  display: none;
  height: 24px;
  max-width: ${({ $value }) => `${$value * 134 + 8}px`};
  min-width: ${theme.spacing.xxs} * 2;
  width: 100%;

  @media ${theme.mq.tabletPortraitUp} {
    display: block;
  }
`;
