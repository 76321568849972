import React from 'react';
import paths from 'constants/paths';
import { useAuth0 } from '@auth0/auth0-react';

export const SignupDirect = () => {
  const { loginWithRedirect } = useAuth0();
  const newAccRedirect = `${window.location.origin}${paths.VERIFYEMAIL}`;

  React.useEffect(() => {
    window.analytics.page('Page view: Signup Direct');
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        redirectUri: newAccRedirect,
      },
    });
  }, []);

  return (
    <div className="divLoader">
      <object
        className="svgLoader"
        data="favicon-light.svg"
        aria-label="loading..."
      ></object>
    </div>
  );
};

export default SignupDirect;
