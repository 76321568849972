import styled from 'styled-components';

import { ListSubheader, theme } from '@klover/attain-design-system';

export const Legend = styled.div`
  ${theme.typography.bodyMD};
  align-items: center;
  bottom: 24px;
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.lg};
  left: 24px;
  position: absolute;
  width: 100%;
`;

export const Symbol = styled.div`
  display: inline-block;
  margin-right: ${theme.spacing.xs};
`;

export const SectionLabel = styled.div`
  ${theme.typography.headingMD};
`;

export const CardTitle = styled.h3`
  ${theme.typography.headingSM};
  color: ${theme.colors.textSecondary};
  text-transform: none;
  flex-grow: 1;
`;

export const StatCardBody = styled.span`
  ${theme.typography.bodySM};
  margin-top: -${theme.spacing.lg};
  display: flex;
  align-items: center;
  svg {
    margin-inline-end: ${theme.spacing.xs};
  }
`;

export const StatCardBodyMetric = styled.div<{ $inverted?: boolean }>`
  margin-top: -${theme.spacing.lg};
  ${theme.typography.bodyMD};
  color: ${({ $inverted }) =>
    $inverted ? theme.colors.textInverted : theme.colors.textTertiary};

  span {
    ${theme.typography.headingXS};
    color: ${({ $inverted }) =>
      $inverted
        ? theme.colors.successBackground
        : theme.colors.successForeground};
  }
`;

export const UnTransformText = styled.div`
  text-transform: none;
`;

export const ChartSwitchesWrapper = styled.div`
  ${theme.typography.bodySM};
  color: ${theme.colors.textTertiary};
  display: flex;
  div {
    margin-inline: ${theme.spacing.xxs};
  }
  && .MuiSwitch-root {
    marign-inline-end: -10px;
  }
`;

export const ListSubHead = styled(ListSubheader)`
  ${theme.typography.label};
`;
