import React from 'react';
import onboardingMerchantsAndBrandsQuery from 'operations/queries/onboardingMerchantsAndBrands.query';
import {
  Autocomplete,
  Chip,
  ListSubheader,
  TextField,
} from '@klover/attain-design-system';
import { AutocompleteNoOptionsText } from 'constants/strings';
import { CaretDown } from '@phosphor-icons/react';
import {
  OnboardingMerchantOrBrand,
  OnboardingMerchantsAndBrandsQuery,
  QueryRootOnboardingMerchantsAndBrandsArgs,
} from 'types/graphql/generated';
import { getRecentResults } from 'hooks/useRecentResults/useRecentResults';
import { useLazyQuery } from '@apollo/client';
import * as Styled from './Autocomplete.styles';

interface Value {
  id: string;
  title: string;
}

interface Props {
  label?: string;
  replace: (vals: Value[]) => void;
  values: Value[];
  remove: (index: number) => void;
  localStorageName: string;
}

export const OnboardingMerchantBrandMultiple = ({
  label,
  replace,
  values,
  remove,
  localStorageName,
}: Props) => {
  const [bounce, setBounce] = React.useState<NodeJS.Timeout | null>();

  const [search, { data, loading }] = useLazyQuery<
    OnboardingMerchantsAndBrandsQuery,
    QueryRootOnboardingMerchantsAndBrandsArgs
  >(onboardingMerchantsAndBrandsQuery, {
    fetchPolicy: 'cache-first',
  });

  React.useEffect(() => {
    search({
      variables: {
        input: {
          search: 'A', // load some default options for the user.
        },
      },
    });
  }, []);

  const handleInputChange = React.useCallback(
    (e) => {
      if (bounce) clearTimeout(bounce);
      setBounce(
        setTimeout(() => {
          setBounce(null);
          void search({
            variables: {
              input: {
                search: e.target.value || 'a',
              },
            },
          });
        }, 300)
      );
    },
    [setBounce, bounce]
  );

  const results: OnboardingMerchantOrBrand[] | undefined =
    data?.onboardingMerchantsAndBrands;
  const hasResults = results && results?.length;

  return (
    <Autocomplete
      noOptionsText={AutocompleteNoOptionsText}
      popupIcon={<CaretDown />}
      multiple
      filterOptions={(options) => options}
      onChange={(_, value) => {
        if (value.length < 10) {
          replace(value.map((val) => ({ realId: val.id, ...val })));
        }
      }}
      value={values as OnboardingMerchantOrBrand[]}
      disabled={values.length >= 9}
      groupBy={() => (hasResults ? '' : 'Recent')}
      renderGroup={(params) => (
        <>
          <ListSubheader>{hasResults ? '' : 'Recent'}</ListSubheader>
          {params.children}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={handleInputChange}
          placeholder={label || 'Search for merchant'}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <Styled.Wrapper>
                {values.map((data, i) => (
                  <Chip
                    key={i}
                    label={data.title}
                    onDelete={(data) => {
                      remove(values.indexOf(data));
                    }}
                  />
                ))}
              </Styled.Wrapper>
            ),
          }}
        />
      )}
      options={results?.length ? results : getRecentResults(localStorageName)}
      getOptionLabel={(option: OnboardingMerchantOrBrand) =>
        option?.title || ''
      }
      loading={!!bounce || loading}
    />
  );
};

export default OnboardingMerchantBrandMultiple;
