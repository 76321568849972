import SmartPersonaMeasurement from 'components/SmartPersona/SmartPersonaMeasurement';
import StatCard from 'components/StatCard/StatCard';
import { CaretDoubleRight, Info } from '@phosphor-icons/react';
import { DEFAULT_MEASUREMENT_NEW_CUSTOMER_WINDOW_ID } from 'utils/Measurement/measurementPaths';
import {
  MeasurementOverviewCustomerType,
  SmartPersonaMeasurementInput,
} from 'types/graphql/generated';
import { Tooltip } from '@klover/attain-design-system';
import { useGlobalState } from 'state';
import * as Styled from './AudienceInsights.styles';

interface Props {
  title: string;
  description?: string;
  metric: string;
  customerType: MeasurementOverviewCustomerType;
  buttonText?: string;
}

export const CampaignInsightCard = ({
  title,
  description,
  metric,
  customerType,
  buttonText,
}: Props) => {
  const [state, actions] = useGlobalState();
  const newCustomerWindow =
    customerType !== MeasurementOverviewCustomerType.ALL_CUSTOMERS
      ? DEFAULT_MEASUREMENT_NEW_CUSTOMER_WINDOW_ID
      : undefined;
  return (
    <StatCard
      title={
        <Styled.TitleContainer>
          {title}
          {description && (
            <Tooltip title={description} placement="right-end">
              <Info />
            </Tooltip>
          )}
        </Styled.TitleContainer>
      }
      detail={
        <>
          <div>{metric}</div>
          <Styled.ViewAudienceBtn
            onClick={() => {
              actions.setDrawerContents(
                <SmartPersonaMeasurement
                  personaInput={
                    {
                      ...state.smartPersonasInputData,
                      customerType: customerType,
                      newCustomersWindow: newCustomerWindow,
                    } as SmartPersonaMeasurementInput
                  }
                />
              );
            }}
          >
            <span>{buttonText ?? 'View audience'}</span>
            <CaretDoubleRight />
          </Styled.ViewAudienceBtn>
        </>
      }
    ></StatCard>
  );
};
