import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  display: fixed;

  .svgLoader {
    height: 80px;
    width: 80px;
    animation: ${spin} 2s ease-in-out infinite;
    margin: auto;
  }
`;
