import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

export const Back = styled.div`
  ${theme.typography.bodyMD};
  align-items: center;
  color: ${theme.colors.textBrand};
  cursor: pointer;
  display: flex;
  line-height: 48px;
  gap: ${theme.spacing.xs};
  margin-bottom: ${theme.spacing.md};
`;
