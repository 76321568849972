import { Base64ID } from 'types/models';

export const AutocompleteNoOptionsText = 'No results';

export const campaignPartnerEnumDisplayMap = {
  NEXXEN: 'Partner campaign | Nexxen',
  OPEN_X: 'Partner campaign | OpenX',
  PIXEL: 'Pixel campaign',
  TTD: 'Partner campaign | The Trade Desk',
  YAHOO: 'Partner campaign | Yahoo',
  GENERIC: 'Campaign',
};

export const campaignTypeDisplayMap = {
  NexxenCampaign: 'Partner campaign | Nexxen',
  OpenXCampaign: 'Partner campaign | OpenX',
  PixelCampaign: 'Pixel campaign',
  TtdCampaign: 'Partner campaign | The Trade Desk',
  YahooCampaign: 'Partner campaign | Yahoo',
  GenericCampaign: 'Campaign',
};

export const DEFAULT_GF_TIME_PERIOD_ID = 'eyIkIjoiVCIsIl8iOjJ9' as Base64ID; //last 6 months id

export const DEFAULT_GF_PRE_TIME_PERIOD_ID = 'eyIkIjoiVCIsIl8iOjd9' as Base64ID; // 6 months
