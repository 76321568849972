import styled from 'styled-components';
import { Slider, theme } from '@klover/attain-design-system';

export const Wrapper = styled.div`
  display: inline-block;
  width: 125px;
`;

export const Title = styled.div`
  ${theme.typography.bodyLG};
  color: theme.colors.textTertiary;
`;

interface CardProps {
  visible: boolean;
}

export const SliderWrapper = styled.div<CardProps>`
  position: absolute;
  right: 72px;
  top: 80px;
  width: 350px;
  z-index: 5;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

export const SliderPostion = styled.div`
  padding-inline-end: ${theme.spacing.md};
`;

export const StyledSlider = styled(Slider)`
  &&& .MuiSlider-mark {
    height: 5px;
    color: ${theme.colors.backgroundLightest} !important;
  }
`;
