import { gql } from '@apollo/client';

export const measurementOverviewOptionsQuery = gql`
  query MeasurementOverviewOptions($input: MeasurementOverviewOptionsInput!) {
    measurementOverviewOptions(input: $input) {
      ... on MeasurementOverviewOptionsSuccess {
        attributionWindows {
          id
          title
          description
        }
        newCustomerWindows {
          id
          title
          description
        }
      }
      ... on MeasurementOverviewOptionsDisabled {
        message
      }
    }
  }
`;

export default measurementOverviewOptionsQuery;
