import { Control, Controller, ControllerFieldState } from 'react-hook-form';
import { TextField } from '@klover/attain-design-system';
import * as Styled from './Step.styles';

interface Props {
  nextStep: () => void;
  getFieldState: (name: string) => ControllerFieldState;
  control: Control;
}

export const NameStep = ({ nextStep, getFieldState, control }: Props) => {
  return (
    <Styled.Block>
      <Styled.PreHeader>Let&apos;s get you started</Styled.PreHeader>

      <Styled.Header>Please enter your full name</Styled.Header>
      <Styled.InputWrapper fullWidth>
        <Controller
          name="firstName"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <TextField
              required
              placeholder="Enter your first name"
              fullWidth
              name={name}
              onBlur={onBlur}
              value={value ?? ''}
              onChange={onChange}
              inputRef={ref}
            />
          )}
        />
      </Styled.InputWrapper>
      <Styled.InputWrapper fullWidth>
        <Controller
          name="lastName"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <TextField
              required
              placeholder="Enter your last name"
              fullWidth
              name={name}
              onBlur={onBlur}
              value={value ?? ''}
              onChange={onChange}
              inputRef={ref}
            />
          )}
        />
      </Styled.InputWrapper>

      <Styled.Submit
        variant="contained"
        fullWidth
        onClick={nextStep}
        disabled={
          !getFieldState('firstName').isDirty ||
          !getFieldState('lastName').isDirty
        }
      >
        Next
      </Styled.Submit>
    </Styled.Block>
  );
};

export default NameStep;
