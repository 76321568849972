import { gql } from '@apollo/client';

export const measurementInsightsOptimalFrequencyQuery = gql`
  query MeasurementInsightsOptimalFrequency(
    $input: MeasurementInsightsOptimalFrequencyInput!
  ) {
    measurementInsightsOptimalFrequency(input: $input) {
      ... on MeasurementInsightsOptimalFrequencySuccess {
        id
        metrics {
          ntile {
            value
            title
          }
          conversionRate {
            value
            title
          }
        }
      }
      ... on MeasurementInsightsOptimalFrequencyError {
        message
      }
    }
  }
`;

export default measurementInsightsOptimalFrequencyQuery;
