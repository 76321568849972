import { theme } from '@klover/attain-design-system';

export const attainColorPalette = [
  theme.colors.CHART_PRIMARY,
  theme.colors.CHART_SECONDARY,
  theme.colors.CHART_TERTIARY,
  theme.colors.CHART_4,
  theme.colors.CHART_5,
  theme.colors.CHART_6,
  theme.colors.CHART_7,
  theme.colors.CHART_8,
  theme.colors.CHART_9,
  theme.colors.CHART_10,
  theme.colors.CHART_11,
  theme.colors.CHART_12,
];
