import { ArrowLeft } from '@phosphor-icons/react';
import * as Styled from './BackButton.styles';

interface Props {
  prevPage: () => void;
}

export const Back = ({ prevPage }: Props) => {
  return (
    <Styled.Back onClick={prevPage}>
      <ArrowLeft weight="bold" />
      <span>Back</span>
    </Styled.Back>
  );
};

export default Back;
