import styled from 'styled-components';
import { FormControl, theme } from '@klover/attain-design-system';

export const DatepickerWrapper = styled(FormControl)`
  max-width: 240px;
  z-index: 1;
`;

export const Datepicker = styled.div<{ $alignLeft?: boolean }>`
  background: ${theme.colors.backgroundPrimary};
  border-radius: ${theme.spacing.xs};
  box-shadow: ${theme.mixins.shadowDark};
  display: none;
  left: ${({ $alignLeft }) => ($alignLeft ? '0' : '50%')};
  padding: ${theme.spacing.lg};
  position: absolute;
  scale: ${({ $alignLeft }) => ($alignLeft ? '0.95' : '1')};
  top: 66px;
  transform: ${({ $alignLeft }) => ($alignLeft ? 'none' : 'translateX(-50%)')};
  transform-origin: ${({ $alignLeft }) =>
    $alignLeft ? 'left top' : 'center top'};

  .rdp {
    margin: 0;
  }

  .rdp-month {
    margin: 0;
    padding: 0 ${theme.spacing.lg} ${theme.spacing.lg};

    &:first-child {
      border-right: 1px solid ${theme.colors.borderDark};
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .rdp-caption_label {
    ${theme.typography.headingSM};
  }

  .rdp-head_cell {
    ${theme.typography.label};
    color: ${theme.colors.textSecondary};
    text-transform: lowercase;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  .rdp-cell {
    ${theme.typography.bodyLG};
  }

  .rdp-button {
    color: ${theme.colors.buttonTextDisabled};
    margin-top: ${theme.spacing.xxs};

    &:not([disabled]) {
      color: ${theme.colors.text};
    }

    &.rdp-day_range_start,
    &.rdp-day_range_end {
      background: ${theme.colors.backgroundBrand};
      color: ${theme.colors.textInverted};
    }

    &.rdp-day_range_middle {
      background: ${theme.colors.backgroundBrandLightest};
      color: ${theme.colors.text};
    }

    &:hover:not([disabled]):not(.rdp-day_selected) {
      background: ${theme.colors.backgroundBrandLight};
    }
  }

  .rdp-day_selected {
    background: ${theme.colors.backgroundBrand};
    color: ${theme.colors.textInverted};
  }
`;

export const Click = styled.div`
  outline: 0;

  &:focus-within ${Datepicker} {
    display: block;
  }
`;

export const Note = styled.div`
  border-top: 1px solid ${theme.colors.borderDark};
  padding-top: ${theme.spacing.lg};
`;
