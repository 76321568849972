import { gql } from '@apollo/client';

export const measurementTrendedMetricsQuery = gql`
  query MeasurementTrendedMetrics($input: MeasurementTrendedInput!) {
    measurementTrendedMetrics(input: $input) {
      ... on MeasurementTrendedSuccess {
        id
        metrics {
          metricName
          timeSeries {
            dataPoint {
              ... on FloatValue {
                value
                title
              }
              ... on PercentageValue {
                value
                title
              }
              ... on IntValue {
                value
                title
              }
              ... on MoneyValue {
                value
                title
              }
            }
            time
          }
        }
      }
      ... on MeasurementTrendedError {
        message
      }
    }
  }
`;

export default measurementTrendedMetricsQuery;
