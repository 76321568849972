import styled from 'styled-components';
import { theme } from '@klover/attain-design-system';

export const ChartWrapper = styled.div`
  aspect-ratio: 3/2;
  position: relative;
`;

export const ChartTooltip = styled.div`
  background: ${theme.colors.backgroundPrimary};
  border: 1px solid ${theme.colors.CHART_PRIMARY};
  border-radius: ${theme.spacing.xs};
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
`;

export const YTitle = styled.div`
  ${theme.typography.bodySM};
  color: ${theme.colors.textTertiary};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const XTitle = styled(YTitle)`
  top: auto;
  bottom: 0;
  left: 50%;
  translate: -50% 0;
`;
