import { gql } from '@apollo/client';

export const updateOnboardingMutation = gql`
  mutation UpdateOnboarding($input: UpdateOnboardingInput!) {
    updateOnboarding(input: $input) {
      ... on UpdateOnboardingSuccess {
        success
      }
      ... on UpdateOnboardingError {
        message
      }
    }
  }
`;

export default updateOnboardingMutation;
