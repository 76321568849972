import styled from 'styled-components';
import { Circle, Triangle } from '@phosphor-icons/react';
import { theme } from '@klover/attain-design-system';

export const NegativeTriangle = styled(Triangle)`
  // margin-inline: ${theme.spacing.xs};
  color: ${theme.colors.errorForeground};
  transform: rotate(60deg);
`;

export const PositiveTriangle = styled(Triangle)`
  // margin-inline: ${theme.spacing.xs};
  color: ${theme.colors.successForeground};
`;
export const NeutralCircle = styled(Circle)`
  // margin-inline: ${theme.spacing.xs};
  color: ${theme.colors.borderDark};
`;
