import styled from 'styled-components';
import { Table, theme } from '@klover/attain-design-system';

export const ITable = styled(Table)`
  cursor: pointer;
  margin: ${theme.spacing.lg} calc(-${theme.spacing.lg} - 1px)
    calc(-${theme.spacing.lg} - 1px);
  margin-top: -${theme.spacing.lg};
  && {
    width: calc(100% + calc(${theme.spacing.lg} * 2 + 2px));
  }

  thead tr th:first-child {
    min-width: 300px;
    text-align: left;
  }

  th {
    ${theme.typography.label};
    padding: ${theme.spacing.sm} ${theme.spacing.md};
    text-align: right;
    border-top: 0px;
    white-space: nowrap;

    @media ${theme.mq.tabletPortraitUp} {
      padding-inline: ${theme.spacing.lg};
    }
  }

  td {
    ${theme.typography.bodyMD};
    padding: ${theme.spacing.sm} ${theme.spacing.md};
    text-align: right;

    @media ${theme.mq.tabletPortraitUp} {
      padding-inline: ${theme.spacing.lg};
    }

    svg {
      margin-inline: ${theme.spacing.xs};
    }
  }

  tr td:first-child {
    ${theme.typography.bodyMD};
    text-align: left;
    word-break: break-word;
  }

  tfoot {
    max-height: 100px;
  }

  tfoot td:first-child {
    ${theme.typography.bodyMD};
    text-align: left;
    span {
      margin-block-end: ${theme.spacing.xs};
      margin-inline-end: ${theme.spacing.xs};
      display: inline-block;
      svg {
        margin-inline-end: ${theme.spacing.xxs};
      }
    }
`;
