import { gql } from '@apollo/client';

export const measurementInsightsConversionTimeQuery = gql`
  query MeasurementInsightsConversionTime(
    $input: MeasurementInsightsConversionTimeInput!
  ) {
    measurementInsightsConversionTime(input: $input) {
      ... on MeasurementInsightsConversionTimeSuccess {
        id
        metrics {
          timeToConvert {
            value
            title
          }
          percentageOfUsers {
            value
            title
          }
          avgImpressionFrequency {
            value
            title
          }
          avgTransactionFrequency {
            value
            title
          }
        }
      }
      ... on MeasurementInsightsConversionTimeError {
        message
      }
    }
  }
`;

export default measurementInsightsConversionTimeQuery;
