import DataCellHeatMap from 'components/DataCellHeatMap/DataCellHeatMap';
import React from 'react';
import useFeatureFlags from 'hooks/useFeatureFlags/useFeatureFlags';
import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import {
  DealConversion,
  IndexValue,
  MoneyValue,
} from 'types/graphql/generated';
import * as Styled from '../../MeasurementOptimization/MeasurementOptimization.styles';

interface DealsProps {
  deals: DealConversion[];
  index: any;
  indexRoas?: MoneyValue;
  indexVolume?: IndexValue;
  maxIndex: number;
  maxIndexVolume: number;
  decimalPlaces: number;
}

export const Deals = ({
  deals,
  index,
  indexRoas,
  indexVolume,
  maxIndex,
  maxIndexVolume,
  decimalPlaces,
}: DealsProps) => {
  const [dealsOpen, setDealsOpen] = React.useState(false);

  const featureflags = useFeatureFlags();
  const indexAllowed =
    featureflags.isReady &&
    featureflags.flags['MEASUREMENT_OPTIMIZATIONS_ANY_TOUCH'];
  const roasCS = useUiFlag('ui_optimizations_roas_coming_soon');
  const roasFF = useUiFlag('ui_optimizations_roas');
  const volumeFF = useUiFlag('ui_optimizations_volume');
  const allowRoas = roasFF.isReady && roasFF.enabled;
  const allowVolume = volumeFF.isReady && volumeFF.enabled;
  const showRoasCS = roasCS.isReady && roasCS.enabled;

  let dealsMaxIndex;
  if (indexAllowed) {
    dealsMaxIndex = deals
      ? deals?.reduce((acc, curr) => {
          const dealIndex = curr.dealIndex.value;
          return dealIndex > acc ? dealIndex : acc;
        }, 0)
      : 100;
  } else {
    dealsMaxIndex = deals
      ? deals?.reduce((acc, curr) => {
          const conversionRate = curr.dealConversionRate.value;
          return conversionRate > acc ? conversionRate : acc;
        }, 0)
      : 100;
  }
  const barValue = (index.value / maxIndex).toFixed(decimalPlaces);
  const barValueNumber = Number(barValue);

  const barValueVolumeNumber = Number(
    (indexVolume?.value || 0 / maxIndexVolume).toFixed(decimalPlaces)
  );

  return (
    <td colSpan={2}>
      <Styled.NestedTableHeaderRowWrapper>
        <Styled.NestedTable $open={true}>
          <td>
            <Styled.AllDeals onClick={() => setDealsOpen(!dealsOpen)}>
              <Styled.Caret $open={dealsOpen} />
              All selected deals
            </Styled.AllDeals>
          </td>
          <td>
            <Styled.CellRate>
              <DataCellHeatMap
                text={`${(index.value * 100).toFixed(decimalPlaces)}%`}
                indexValue={barValueNumber}
              />
            </Styled.CellRate>
          </td>
          {allowVolume && (
            <td>
              <Styled.CellRate>
                {indexVolume ? (
                  <DataCellHeatMap
                    text={`${(indexVolume.value * 100).toFixed(
                      decimalPlaces
                    )}%`}
                    indexValue={barValueVolumeNumber}
                  />
                ) : (
                  '--'
                )}
              </Styled.CellRate>
            </td>
          )}
          {(allowRoas || showRoasCS) && (
            <td>
              <Styled.CellRate>
                {allowRoas && indexRoas ? indexRoas.title : '--'}
              </Styled.CellRate>
            </td>
          )}
        </Styled.NestedTable>
      </Styled.NestedTableHeaderRowWrapper>

      <Styled.NestedTableWrapper $open={dealsOpen}>
        <Styled.NestedTable $open={dealsOpen}>
          <tbody>
            {deals.map((deal, j) => {
              let dealValue;
              if (indexAllowed) {
                dealValue = (deal.dealIndex?.value / dealsMaxIndex).toFixed(
                  decimalPlaces
                );
              } else {
                dealValue = (
                  deal.dealConversionRate?.value / dealsMaxIndex
                ).toFixed(decimalPlaces);
              }
              const dealValueNumber = Number(dealValue);
              const rowVolumeNumber = Number(
                (deal?.dealImpressionVolume?.value || 0) / maxIndexVolume
              );
              return (
                <tr key={j}>
                  <td>
                    <div>{deal.dealName}</div>
                    <Styled.Line2>{deal.brandOrMerchantName}</Styled.Line2>
                  </td>

                  <td>
                    <Styled.CellRate>
                      <DataCellHeatMap
                        indexValue={dealValueNumber}
                        text={
                          (indexAllowed
                            ? (deal.dealIndex?.value * 100).toFixed(
                                decimalPlaces
                              )
                            : (deal.dealConversionRate?.value * 100).toFixed(
                                decimalPlaces
                              )) + '%'
                        }
                      />
                    </Styled.CellRate>
                  </td>
                  {allowVolume && (
                    <td>
                      <Styled.CellRate>
                        {deal?.dealImpressionVolume?.value ? (
                          <DataCellHeatMap
                            text={`${(
                              deal?.dealImpressionVolume.value * 100
                            ).toFixed(decimalPlaces)}%`}
                            indexValue={rowVolumeNumber}
                          />
                        ) : (
                          '--'
                        )}
                      </Styled.CellRate>
                    </td>
                  )}
                  {(allowRoas || showRoasCS) && (
                    <td>
                      <Styled.CellRate>
                        {allowRoas && deal?.dealRoas?.title
                          ? deal.dealRoas.title
                          : '--'}
                      </Styled.CellRate>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Styled.NestedTable>
      </Styled.NestedTableWrapper>
    </td>
  );
};

export default Deals;
