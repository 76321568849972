import MeasurementEmptyImg from 'assets/measurements-empty.svg';
import * as Styled from './MeasurementsEmpty.styles';

export const MeasurementsEmpty = () => {
  return (
    <Styled.Empty>
      <img src={MeasurementEmptyImg} alt="" />

      <Styled.Header>
        Select required fields to see campaign performance
      </Styled.Header>

      <Styled.Text>
        If you need help getting started, please reach out to your Attain
        Account Manager
      </Styled.Text>
    </Styled.Empty>
  );
};

export default MeasurementsEmpty;
