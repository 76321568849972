import GhostLoader from 'react-ghost-loader';
import React from 'react';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
} from '@klover/attain-design-system';
import * as Styled from './Pagination.styles';

interface PaginationProps {
  total: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  offset: number;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
  hideDropdown?: boolean;
  rowsPerPageOptions?: number[];
}

export const Pagination = ({
  total,
  setRowsPerPage,
  rowsPerPage,
  offset,
  setOffset,
  hideDropdown,
  rowsPerPageOptions = [5, 10, 25, 50],
}: PaginationProps) => {
  const rowStart = offset * rowsPerPage + 1;
  const rowEnd =
    rowStart + rowsPerPage <= total ? rowStart + rowsPerPage - 1 : total;

  return (
    <Styled.Wrapper>
      {!hideDropdown && (
        <Styled.PaginationSelect>
          Rows per page:{' '}
          <FormControl>
            <Select
              variant="outlined"
              value={rowsPerPage}
              onChange={(event: any) => {
                setRowsPerPage(event.target.value);
              }}
            >
              {rowsPerPageOptions.map((item) => (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Styled.PaginationSelect>
      )}

      <div>
        {`${rowStart} - ${rowEnd}`}{' '}
        <Styled.PaginationTotal>{`of ${total}`}</Styled.PaginationTotal>
      </div>

      <Styled.PaginationButtons>
        <IconButton
          onClick={() => {
            setOffset((prevOffset) => prevOffset - 1);
          }}
          disabled={offset <= 0}
        >
          <CaretLeft />
        </IconButton>

        <IconButton
          onClick={() => {
            setOffset((prevOffset) => prevOffset + 1);
          }}
          disabled={rowEnd >= total}
        >
          <CaretRight />
        </IconButton>
      </Styled.PaginationButtons>
    </Styled.Wrapper>
  );
};

export const PaginationLoading = () => {
  return (
    <Styled.Wrapper>
      <div>
        <GhostLoader
          height="20px"
          heightRandom={0}
          width="60px"
          widthRandom={0}
        />
      </div>

      <Styled.PaginationButtons>
        <GhostLoader
          height="42px"
          heightRandom={0}
          width="42px"
          widthRandom={0}
        />

        <GhostLoader
          height="42px"
          heightRandom={0}
          width="42px"
          widthRandom={0}
        />
      </Styled.PaginationButtons>
    </Styled.Wrapper>
  );
};

export default Pagination;
