import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

export const Wrapper = styled.div`
  ${theme.typography.label};
  color: ${theme.colors.textSecondary};
  align-items: center;
  display: flex;
  gap: ${theme.spacing.lg};
  justify-content: flex-end;
`;

export const PaginationSelect = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacing.xs};
`;

export const PaginationButtons = styled.span`
  display: flex;
  gap: ${theme.spacing.xxs};
`;

export const PaginationTotal = styled.span`
  color: ${theme.colors.textTertiary};
`;
