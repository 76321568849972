import { gql } from '@apollo/client';

export const measurementConversionsQuery = gql`
  query MeasurementConversions($input: MeasurementConversionsInput!) {
    measurementConversions(input: $input) {
      ... on MeasurementConversionsSuccess {
        id
        metrics {
          avgTotalSpend {
            value
            title
          }
          avgTransactionAmount {
            value
            title
          }
          avgTransactionFrequency {
            value
            title
          }
          costPerTransaction {
            value
            title
          }
          avgImpressionFrequency {
            value
            title
          }
        }
      }
      ... on MeasurementConversionsError {
        message
      }
    }
  }
`;

export default measurementConversionsQuery;
