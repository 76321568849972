import styled, { keyframes } from 'styled-components';
import { theme } from '@klover/attain-design-system';

export const Wrapper = styled.div`
  align-items: center;
  display: inline-flex;
  gap: ${theme.spacing.sm};
`;

export const WrapperReverse = styled(Wrapper)`
  flex-direction: row-reverse;
`;

export const WrapperFullWidth = styled(Wrapper)`
  flex-direction: row-reverse;
  width: 100%;
`;

export const WrapperOld = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 1px;
`;

export const PercentBar = styled.div`
  background: ${theme.colors.backgroundLight};
  border-radius: 4px;
  height: 4px;
  width: 50px;

  ${WrapperFullWidth} & {
    height: 16px;
    width: 100%;
  }
`;

export const PercentBarTransparent = styled(PercentBar)`
  background: transparent;
`;

const scaleLeftToRight = ($width) => keyframes`
  from {
    opacity: 0;
    width: 0;

  }
  to {
    opacity: 1;
    width: ${$width}%;
  }
`;

export const PercentFilled = styled.div<{
  $percent: number;
  $opacity?: number;
}>`
  animation: ${({ $percent }) => scaleLeftToRight($percent)} 0.5s ease-out
    forwards;
  background: ${theme.colors.backgroundBrand};
  border-radius: 4px;
  height: 100%;
  max-width: 100%;
  width: ${({ $percent }) => `${$percent}%`};
`;

export const PercentFilledTransparent = styled(PercentFilled)`
  opacity: ${({ $opacity }) =>
    ($opacity as number) * 0.8 + 0.2} !important; // min opacity of 20%
`;

export const Text = styled.div`
  width: auto;

  ${WrapperFullWidth} & {
    text-align: right;
    width: 60px;
  }
`;
