interface hasId {
  id: string;
}
export const setRecentResultsGeneric = <T extends hasId>(
  id: string,
  newItem: T
) => {
  const oldRecents = JSON.parse(localStorage.getItem(id) || '[]');
  const updatedRecents = [
    newItem,
    ...oldRecents.filter((item) => item.id !== newItem.id).slice(0, 2),
  ];
  localStorage.setItem(id, JSON.stringify(updatedRecents));
};

export const getRecentResultsGeneric = (id: string) =>
  JSON.parse(localStorage.getItem(id) || '[]');
