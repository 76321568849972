import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ScaleBandSpec, ScaleSpec } from '@nivo/scales';
import { chartCommonProps } from '../MeasurementBrandSurvey';
import { theme } from '@klover/attain-design-system';
import * as Styled from '../MeasurementBrandSurvey.styles';

interface Props {
  chart: any;
}

export const ChartSkew = ({ chart }: Props) => {
  const formattedData = chart.values.map((row) => ({
    answer: row.name,
    color:
      row.value > 0
        ? theme.colors.successBackground
        : theme.colors.errorBackground,
    empty: 0,
    lift: (row.value * 100).toFixed(2),
  }));

  const CustomLayer = ({ bars }) => {
    // Create an array of refs, one for each bar
    const textRefs = React.useRef<(SVGTextElement | null)[]>([]);

    React.useEffect(() => {
      // Adjust label positions after render based on text width
      bars.forEach((bar, index) => {
        const textElement = textRefs.current[index];
        if (textElement) {
          const textWidth = textElement.getBBox().width;
          const isPositive = bar.data.value > 0;

          // Update the position of the label based on text width
          textElement.setAttribute(
            'x',
            isPositive
              ? bar.x + bar.width + 8 // Positive value: position outside the bar
              : bar.x - textWidth - 8 // Negative value: position offset by text width
          );
        }
      });
    }, [bars]);

    return (
      <>
        {bars.map((bar, index) => {
          if (bar.data.value) {
            const isPositive = bar.data.value > 0;
            const labelText = bar.data.indexValue;

            return (
              <text
                key={bar.key}
                ref={(el) => (textRefs.current[index] = el)} // Assign ref to each text element
                x={isPositive ? bar.x + bar.width + 8 : bar.x - 8} // Initial x position
                y={bar.y + bar.height / 2}
                dominantBaseline="central"
                fill={theme.colors.text}
                fontSize={14}
              >
                {labelText}
              </text>
            );
          }
          return null;
        })}
      </>
    );
  };

  return (
    <Styled.ChartWrapperTall>
      <ResponsiveBar
        {...chartCommonProps}
        tooltip={({ value, indexValue }) => (
          <Styled.Tooltip>
            <Styled.TooltipTitle>{indexValue}</Styled.TooltipTitle>
            <strong>Very likely buyer skew:</strong> {value}%
          </Styled.Tooltip>
        )}
        layout="horizontal"
        data={formattedData.reverse()}
        keys={['lift']}
        padding={0.25}
        margin={{ top: 0, right: 15, bottom: 21, left: 0 }}
        valueScale={{ type: 'linear' } as ScaleSpec}
        indexScale={{ type: 'band', round: true } as ScaleBandSpec}
        maxValue={100}
        minValue={-100}
        enableGridX={true}
        enableGridY={false}
        axisLeft={{
          format: () => '',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
        }}
        axisBottom={{
          format: (value) => `${Math.round(value)}%`,
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
        }}
        layers={[
          'grid',
          'axes',
          'bars',
          'markers',
          'legends',
          CustomLayer, // Add CustomLayer to your layers
        ]}
      />
    </Styled.ChartWrapperTall>
  );
};

export default ChartSkew;
