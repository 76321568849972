import Hamburger from 'assets/hamburger.svg';
import React from 'react';
import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import { Badge } from '@klover/attain-design-system';
import { Sparkle } from '@phosphor-icons/react';
import { useGlobalState } from 'state';
import * as Styled from './Header.styles';

interface Parent {
  label: string;
  to: string;
  onClick?: () => void;
}

export interface HeaderProps {
  parents?: Parent[];
  current?: string;
  transparent?: boolean;
}

const Header = ({ parents, current, transparent }: HeaderProps) => {
  const [, actions] = useGlobalState();
  const brandInsights = useUiFlag('ui_brand_insights2');
  const allowBrandInsights = brandInsights.enabled && brandInsights.isReady;

  return (
    <Styled.Wrapper $transparent={transparent}>
      {!allowBrandInsights && (
        <Styled.Hamburger onClick={() => actions.showNav()}>
          <img src={Hamburger} alt="click to open mobile menu" />
        </Styled.Hamburger>
      )}

      <Styled.Text>
        {parents &&
          parents.map((parent, i) => (
            <React.Fragment key={i}>
              <Styled.Parent
                to={parent.to}
                onClick={parent.onClick}
                $transparent={transparent}
              >
                {parent.label}
              </Styled.Parent>
              <Styled.Caret $transparent={transparent} />
            </React.Fragment>
          ))}

        {parents ? (
          <Styled.Current $transparent={transparent}>{current}</Styled.Current>
        ) : (
          <Styled.CurrentBold $transparent={transparent}>
            {current}
          </Styled.CurrentBold>
        )}

        {current == 'Brand' && (
          <div
            style={{ marginInline: 16 }}
            data-testid="beta"
            data-intercom-target="mainnav-brand-insights"
          >
            <Badge color="brandSecondary" icon={<Sparkle />}>
              BETA
            </Badge>
          </div>
        )}
      </Styled.Text>
    </Styled.Wrapper>
  );
};

export default Header;
