import * as Styled from './DataCellHeatMap.styles';

interface Props {
  indexValue: number;
  text: string;
}
export const DataCellHeatMap = ({ indexValue, text }: Props) => {
  return <Styled.DataCell $value={indexValue}>{text}</Styled.DataCell>;
};

export default DataCellHeatMap;
