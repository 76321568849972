import { gql } from '@apollo/client';

export const measurementInsightsCompetitorConversionQuery = gql`
  query MeasurementInsightsCompetitorConversion(
    $input: MeasurementInsightsCompetitorConversionInput!
  ) {
    measurementInsightsCompetitorConversion(input: $input) {
      ... on MeasurementInsightsCompetitorConversionSuccess {
        id
        metrics {
          competitor
          logo
          conversionRate {
            title
            value
          }
          netNewCvr {
            title
            value
          }
          avgTransactionAmtTotal {
            title
            value
          }
          avgTransactionAmtPerTxn {
            title
            value
          }
          avgTransactionFrequency {
            title
            value
          }
          switchingPercentage {
            title
            value
          }
        }
        venn {
          targetBrand
          targetBrandLogo
          values {
            competitor
            competitorConversions {
              title
              value
            }
            overlapConversions {
              title
              value
            }
            targetConversions {
              title
              value
            }
          }
        }
      }
    }
  }
`;

export default measurementInsightsCompetitorConversionQuery;
