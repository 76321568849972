import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

export const Dropdown = styled.div`
  margin-block: ${theme.spacing.md};
`;

export const Wrapper = styled.div`
  background: ${theme.colors.backgroundPrimary};
  border-bottom: 1px solid ${theme.colors.borderDark};
  height: calc(25px + ${theme.spacing.xs});
  margin-inline: -${theme.spacing.xl};
  padding: 0 ${theme.spacing.xl};
`;

export const Tab = styled.span<{
  className: any;
  active?: boolean;
  children: any;
  onClick: any;
  $disabled?: boolean;
}>`
  ${theme.typography.bodyMD};
  color: ${({ $disabled }) =>
    $disabled ? '#a29fae' : theme.colors.textTertiary};
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  padding: 0 0 ${theme.spacing.sm};
  position: relative;

  & + & {
    margin-left: ${theme.spacing.lg};
  }

  &.active {
    color: ${theme.colors.textBrand};
    cursor: default !important;

    &:after {
      content: '';
      border-radius: 3px 3px 0 0;
      bottom: 1px;
      height: 3px;
      left: 0;
      position: absolute;
      width: 100%;
      background: ${theme.colors.backgroundBrand};
    }
  }

  span + * {
    margin-inline-start: ${theme.spacing.xs};
  }
`;
