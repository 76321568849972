import styled from 'styled-components';
import { theme } from '@klover/attain-design-system';

export const ScaleWrapper = styled.div<{ $width: number | null }>`
  ${theme.typography.bodySM}
  color: ${theme.colors.textTertiary};
  height: 100%;
  width: ${(props) => (props.$width ? `${props.$width}px` : '100%')};
  padding-inline: 0;
  margin-inline-start: ${theme.spacing.xxs};
  justify-content: space-between;
  display: flex;
  align-items: center;
  position: relative;

  & > div {
    position: absolute;

    ${({ children }) =>
      Array.isArray(children) &&
      children?.map(
        (_, index) => `
        //nth child is 1 indexed so we add 1 to map index to get child.
        &:nth-child(${index + 1}) {
          left: ${index * 10 - 2}%;
        }
      `
      )}
  }
`;
export const ChartWrapper = styled.div`
  height: 100px;
  max-width: 450px;
  min-width: 0;
  position: relative;
`;

export const ChartWrapperSkew = styled.div`
  height: 65px;
  min-width: 0;
  max-width: 450px;
  position: relative;
`;

export const CellOneContent = styled.div`
  ${theme.typography.bodyLG};
  div {
    ${theme.typography.bodySM};
    color: ${theme.colors.textTertiary};
  }
`;

export const Legend = styled.div`
  ${theme.typography.bodySM};
  width: 100%;
  color: ${theme.colors.textTertiary};
  padding-block: ${theme.spacing.lg};
  display: flex;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-inline-end: ${theme.spacing.lg};
  margin-block-end: -${theme.spacing.lg};
  svg {
    margin-inline-start: ${theme.spacing.xxs};
  }
`;

export const FullWidthTable = styled.table`
  width: calc(100% + ${theme.spacing.lg} * 2);
  margin-inline-start: -${theme.spacing.lg};
  border-collapse: collapse;
  text-align: left;
  position: relative;
  tr {
    padding-inline: ${theme.spacing.sm};
  }
  td {
    padding-inline: ${theme.spacing.lg};
    border-block: 2px solid ${theme.colors.borderLight};
  }
  thead {
    background-color: ${theme.colors.backgroundLight};
  }
  th {
    ${theme.typography.bodyMD};
    padding: ${theme.spacing.sm} ${theme.spacing.lg};
    background-color: ${theme.colors.backgroundLight};
  }
`;

export const DescriptionWrapper = styled.div`
  ${theme.typography.bodySM};
  color: ${theme.colors.textTertiary};
  max-width: 300px;
`;

export const ThTitle = styled.th`
  position: sticky;
  top: 126px;
`;

export const ThCount = styled.th`
  position: sticky;
  top: 126px;
`;

export const ThAbsoluteLift = styled.th`
  text-align: center;
  svg {
    margin-inline-start: ${theme.spacing.xxs};
  }
`;
export const ThAbsoluteLiftSticky = styled(ThAbsoluteLift)`
  position: sticky;
  top: 126px;
`;

export const ThScale = styled.th<{ $width: number | null }>`
  ${theme.typography.bodySM}
  color: ${theme.colors.textTertiary};
  width: ${(props) => (props.$width ? `${props.$width}px` : '100%')};
  padding-inline: 0;
`;

export const ThScaleSticky = styled(ThScale)`
  position: sticky;
  top: 126px;
  z-index: 1;
`;

export const ThControlExposed = styled.th`
  min-width: 50px;
  position: sticky;
  top: 126px;
`;

export const TdAbsoluteLift = styled.td`
  text-align: center;
  span {
    margin-inline-end: ${theme.spacing.xxs};
  }
`;

export const TdChart = styled.td`
  max-width: 450px;
  min-width: 0;
  padding-inline: 0;
`;

export const TdControllExposed = styled.td`
  color: ${theme.colors.textTertiary};
  height: 1px;
  && {
    padding-inline-start: 0;
  }
`;

export const ControlExposedWrapper = styled.div`
  ${theme.typography.bodyMD};
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const TdControllExposedLabels = styled.td`
  color: ${theme.colors.textTertiary};
  height: 1px;
  && {
    padding-inline: 0;
  }
`;

export const ControlExposedLabels = styled.div`
  ${theme.typography.bodyMD};
  margin-inline-end: -${theme.spacing.sm};
  padding-inline-end: 0;
  height: 100%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const TdCount = styled.td`
  ${theme.typography.bodyLG};
  color: ${theme.colors.text};
`;
