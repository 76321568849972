import { gql } from '@apollo/client';

export const campaignNameQuery = gql`
  query CampaignName($campaignId: Base64ID!) {
    campaign(campaignId: $campaignId) {
      ... on OpenXCampaign {
        title
      }
      ... on TtdCampaign {
        title
      }
    }
  }
`;

export default campaignNameQuery;
