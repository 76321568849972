interface DataTableRowProps {
  row: React.ReactNode | ((index: number) => React.ReactNode);
  index: number;
  details?: React.ReactNode | ((index: number) => React.ReactNode);
}

export const DataTableRow = ({ row, index, details }: DataTableRowProps) => {
  return (
    <>
      <tr>{typeof row === 'function' ? row(index) : row}</tr>

      {details && (
        <tr>{typeof details === 'function' ? details(index) : details}</tr>
      )}
    </>
  );
};

export default DataTableRow;
