import { gql } from '@apollo/client';

export const campaignMeasurementBasicQuery = gql`
  query CampaignMeasurementBasic($campaignId: Base64ID!) {
    campaign(campaignId: $campaignId) {
      ... on OpenXCampaign {
        title
        doIncrementality
        deals {
          id
          dealId
          name
          sourceId
          brandName
          brandId
        }
        merchantsOrBrands {
          ... on CampaignMerchant {
            name
          }
          ... on CampaignBrand {
            name
          }
        }
        id
        b64Id
      }
      ... on TtdCampaign {
        title
        id
        b64Id
      }
      ... on PixelCampaign {
        id
        title
        doIncrementality
        merchantsOrBrands {
          ... on CampaignMerchant {
            name
          }
          ... on CampaignBrand {
            name
          }
        }
      }
      ... on YahooCampaign {
        id
        title
        doIncrementality
        merchantsOrBrands {
          ... on CampaignMerchant {
            name
          }
          ... on CampaignBrand {
            name
          }
        }
      }
      ... on NexxenCampaign {
        id
        title
        doIncrementality
        merchantsOrBrands {
          ... on CampaignMerchant {
            name
          }
          ... on CampaignBrand {
            name
          }
        }
      }
      ... on GenericCampaign {
        id
        partner
        title
        doIncrementality
        merchantsOrBrands {
          ... on CampaignMerchant {
            name
          }
          ... on CampaignBrand {
            name
          }
        }
      }
    }
  }
`;

export default campaignMeasurementBasicQuery;
