import { gql } from '@apollo/client';

export const onboardingMerchantsAndBrandsQuery = gql`
  query OnboardingMerchantsAndBrands(
    $input: OnboardingMerchantsAndBrandsInput!
  ) {
    onboardingMerchantsAndBrands(input: $input) {
      id
      title
    }
  }
`;

export default onboardingMerchantsAndBrandsQuery;
