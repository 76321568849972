import OutcomeAiInsight from './OutcomeAiInsight';
import incrementalityAiInsightsQuery from 'operations/queries/incrementalityAiInsights.query';
import useSearchParams from 'hooks/useSearchParams';
import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import {
  IncrementalityAiInsightsQuery,
  IncrementalityAiInsightsQueryVariables,
} from 'types/graphql/generated';
import { MeasurementSearchParams } from 'views/Measurement/MeasurementInsightsDashboard';
import { getMeasurementIncrementalityInputFromSearchParams } from 'utils/Measurement/measurementInputFromUrlParams';
import { useQuery } from '@apollo/client';

const IncrementalityAiInsight = () => {
  const [searchParams] = useSearchParams<MeasurementSearchParams>();

  const aiInsights = useUiFlag('ui_incrementality_ai_insight');
  const allowAiInsights = aiInsights.isReady && aiInsights.enabled;

  console.log(allowAiInsights);
  const input = getMeasurementIncrementalityInputFromSearchParams(
    searchParams.campaignId,
    searchParams
  );
  const { data, loading, error } = useQuery<
    IncrementalityAiInsightsQuery,
    IncrementalityAiInsightsQueryVariables
  >(incrementalityAiInsightsQuery, {
    variables: {
      input: {
        campaignId: searchParams.campaignId,
        postPeriod: input?.postPeriod || 30,
      },
    },
    skip: !allowAiInsights,
  });

  if (!allowAiInsights) return;
  return (
    <OutcomeAiInsight
      data={data?.incrementalityAiInsights}
      loading={loading}
      error={!!error}
    />
  );
};

export default IncrementalityAiInsight;
