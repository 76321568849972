import ComingSoonBadge from 'components/ComingSoonBadge/ComingSoonBadge';
import Error from 'components/Error/Error';
import React, { useMemo, useState } from 'react';
import StatCard, { StatCardLoading } from 'components/StatCard/StatCard';
import TrendsChart from 'views/Measurement/TrendsChart/TrendsChart';
import measurementTrendedMetricsQuery from 'operations/queries/measurementTrendedMetrics.query';
import useSearchParams from 'hooks/useSearchParams';
import {
  Alert,
  Grid,
  MenuItem,
  Select,
  Switch,
} from '@klover/attain-design-system';
import {
  MeasurementSearchParams,
  TrendsDataChart,
} from 'views/Measurement/MeasurementInsightsDashboard';
import {
  MeasurementTrendedMetrics,
  MeasurementTrendedMetricsQuery,
  MeasurementTrendedMetricsQueryVariables,
} from 'types/graphql/generated';
import { getDataChartTrends } from 'utils/Measurement/dataCharts';
import { getMeasurementTrendedMetricsInputFromSearchParams } from 'utils/Measurement/measurementInputFromUrlParams';
import { useQuery } from '@apollo/client';
import * as Styled from '../Measurement.styles';

export const CampaignTrends = () => {
  const [switchStates, setSwitches] = useState({
    wow: false,
    netNew: false,
    benchMark: false,
  });

  const [searchParams] = useSearchParams<MeasurementSearchParams>();

  const variables: MeasurementTrendedMetricsQueryVariables = React.useMemo(
    () => ({
      input: getMeasurementTrendedMetricsInputFromSearchParams(
        searchParams.campaignId,
        searchParams
      ),
    }),
    [searchParams]
  );

  const [trendedMetric, setTrendedMetric] =
    useState<MeasurementTrendedMetrics>();

  const { data, loading, error } = useQuery<
    MeasurementTrendedMetricsQuery,
    MeasurementTrendedMetricsQueryVariables
  >(measurementTrendedMetricsQuery, {
    variables: variables,
    onCompleted(data) {
      if (
        data.measurementTrendedMetrics.__typename ===
        'MeasurementTrendedSuccess'
      ) {
        setTrendedMetric(data.measurementTrendedMetrics.metrics.at(0));
      }
    },
  });

  const trendsMetrics = useMemo(() => {
    return data &&
      data.measurementTrendedMetrics.__typename === 'MeasurementTrendedSuccess'
      ? data.measurementTrendedMetrics.metrics
      : null;
  }, [data]);

  const chartData: TrendsDataChart = useMemo(() => {
    return getDataChartTrends(trendedMetric);
  }, [trendedMetric]);

  if (loading) {
    return <StatCardLoading />;
  }

  if (error) {
    return (
      <StatCard
        title={<Styled.CardTitle>Campaign Trends</Styled.CardTitle>}
        data-testid="error-card"
      >
        <Error message="Error loading campaign trends data" />
      </StatCard>
    );
  }

  const hasTrends = !!(trendsMetrics && trendsMetrics.length > 0);

  return (
    <StatCard
      title={
        <Grid container justifyContent="space-between">
          <Styled.CardTitle>Campaign Trends</Styled.CardTitle>

          {hasTrends && (
            <Styled.UnTransformText>
              <Select variant="outlined" defaultValue={0}>
                <Styled.ListSubHead>All customers</Styled.ListSubHead>

                {trendsMetrics &&
                  trendsMetrics.map((metric, i) => (
                    <MenuItem
                      value={i}
                      key={metric.metricName}
                      onClick={() => {
                        setTrendedMetric(metric);
                      }}
                    >
                      {metric.metricName}
                    </MenuItem>
                  ))}
              </Select>
            </Styled.UnTransformText>
          )}
        </Grid>
      }
      detail={
        <Styled.ChartSwitchesWrapper data-testid="trend-chart">
          {/* <div>
            <Switch
              title="Week over week change"
              onChange={(e, checked) => {
                setSwitches((prev) => ({ ...prev, wow: checked }));
              }}
              checked={switchStates.wow}
            />
            Week over week change
          </div> */}
          {/* <div>
            <Switch
              title="vs. Net new"
              onChange={(e, checked) => {
                setSwitches((prev) => ({ ...prev, netNew: checked }));
              }}
              checked={switchStates.netNew}
            />
            vs. Net new
          </div> */}
          <div>
            <Switch
              disabled
              title="benchmark"
              onChange={(e, checked) => {
                setSwitches((prev) => ({ ...prev, benchMark: checked }));
              }}
              checked={switchStates.benchMark}
            />
            Benchmark
            <ComingSoonBadge />
          </div>
        </Styled.ChartSwitchesWrapper>
      }
    >
      {hasTrends ? (
        <TrendsChart data={chartData} />
      ) : (
        <Alert severity="warning" title="Data warning" data-testid="no-trends">
          There are no campaign trends currently available for this campaign.
        </Alert>
      )}
    </StatCard>
  );
};

export default CampaignTrends;
