import GhostLoader from 'react-ghost-loader';
import React, { useEffect } from 'react';
import { Address } from './Address';
import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  TextField,
} from '@klover/attain-design-system';
import { Info, X } from '@phosphor-icons/react';
import { lookupIdentityQuery } from 'operations/queries/lookupIdentity.query';
import { useLazyQuery } from '@apollo/client';
import * as Styled from './LookupTool.styles';

export const LookupTool = ({ onClose }) => {
  const handleClose = () => {
    onClose && onClose();
  };

  const [email, setEmail] = React.useState('');
  const [ipAddress, setIpAddress] = React.useState('');

  const [lookup, { loading, error, data }] = useLazyQuery(lookupIdentityQuery, {
    fetchPolicy: 'cache-first',
  });

  const emailFromData = data?.lookupIdentity?.email;
  const ipFromData = data?.lookupIdentity?.ipInfo?.ipAddress;

  useEffect(() => {
    lookup({
      variables: {
        input: {
          email,
          ipAddress,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (ipFromData && ipFromData != ipAddress) {
      setIpAddress(ipFromData);
    }
    if (emailFromData && emailFromData != email) {
      setEmail(emailFromData);
    }
  }, [ipFromData, emailFromData]);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    lookup({
      variables: {
        input: {
          email,
          ipAddress,
        },
      },
    });
  };

  const { ipInfo, userAgentInfo, households } = data?.lookupIdentity || {};

  return (
    <Styled.ReportContainer>
      <Styled.Header>
        <Styled.CloseButton onClick={handleClose}>
          <X />
        </Styled.CloseButton>
        <Alert icon={<Info fontSize="inherit" />} severity="info">
          If email is empty, it will default to your login email. if ip address
          is empty, it will default to your request ip address. The look up tool
          then will waterfall to lookup identity by email first, then ip
          address.
        </Alert>
      </Styled.Header>
      {!loading && error && (
        <Alert severity="error">{`Error: ${error.message}`}</Alert>
      )}
      <form onSubmit={handleOnSubmit}>
        <FormControl fullWidth disabled={loading}>
          <InputLabel>Email:</InputLabel>
          <TextField
            value={email}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          />
          <br />
          <InputLabel>IP address:</InputLabel>
          <TextField
            value={ipAddress}
            type="text"
            onChange={(e) => setIpAddress(e.target.value)}
            disabled={loading}
          />
          <br />
          <Button type="submit" disabled={loading} variant="contained">
            {loading ? 'Looking' : 'Lookup'}
          </Button>
        </FormControl>
      </form>
      <Styled.LoaderWrapper>
        {loading && (
          <GhostLoader
            height="25px"
            width="100%"
            heightRandom={0}
            widthRandom={0}
          />
        )}
      </Styled.LoaderWrapper>
      <Styled.ReportSection>
        <h5>IP address</h5>
        {ipInfo ? (
          Object.keys(ipInfo).map((field) =>
            field !== '__typename' ? (
              <Styled.LineItemContainer key={field}>
                <Styled.LineItem>{field}</Styled.LineItem>
                <Styled.LineItem>{ipInfo[field]}</Styled.LineItem>
              </Styled.LineItemContainer>
            ) : null
          )
        ) : (
          <div>No data</div>
        )}
      </Styled.ReportSection>

      <Styled.ReportSection>
        <h5>User Agent</h5>
        {userAgentInfo ? (
          Object.keys(userAgentInfo).map((field) =>
            field !== '__typename' ? (
              <Styled.LineItemContainer key={field}>
                <Styled.LineItem>{field}</Styled.LineItem>
                <Styled.LineItem>{userAgentInfo[field]}</Styled.LineItem>
              </Styled.LineItemContainer>
            ) : null
          )
        ) : (
          <div>No data</div>
        )}
      </Styled.ReportSection>

      <Styled.ReportSection>
        <h5>Households</h5>
        {households ? (
          households.map((household) => (
            <>
              <Styled.LineItemContainer>
                <Styled.LineItem>Household LUID</Styled.LineItem>
                <Styled.LineItem>{household.luid}</Styled.LineItem>
              </Styled.LineItemContainer>
              <Styled.LineItemContainer>
                <Styled.LineItem>Address</Styled.LineItem>
                <Styled.LineItem>
                  <Address address={household.homeAddress} />
                </Styled.LineItem>
              </Styled.LineItemContainer>
              {household.individuals.map((individual, i) => {
                return (
                  <Styled.LineItemContainer key={i}>
                    <Styled.LineItem>Household member #{i + 1}</Styled.LineItem>
                    <Styled.LineItem>
                      {individual.firstName} {individual.lastName}
                      {individual.gender !== 'UNKNOWN' ? (
                        <span>
                          <br />
                          {individual.gender}
                        </span>
                      ) : null}
                    </Styled.LineItem>
                  </Styled.LineItemContainer>
                );
              })}
            </>
          ))
        ) : (
          <div>No data</div>
        )}
      </Styled.ReportSection>
    </Styled.ReportContainer>
  );
};

export default LookupTool;
