import { gql } from '@apollo/client';

export const inviteYourTeamMutation = gql`
  mutation InviteYourTeam($input: InviteYourTeamInput!) {
    inviteYourTeam(input: $input) {
      ... on InviteYourTeamSuccess {
        success
      }
      ... on InviteYourTeamError {
        message
      }
    }
  }
`;

export default inviteYourTeamMutation;
