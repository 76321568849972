import { gql } from '@apollo/client';

export const globalSearchQuery = gql`
  query GlobalSearch($input: String!) {
    globalSearch(input: $input) {
      brands {
        __typename
        id
        title
        logo
        timePeriod
      }
      merchants {
        __typename
        id
        insightsId
        title
        logo
        timePeriod
      }
      campaigns {
        __typename
        id
        title
        partner
        dealIds
        attributionWindow
        newCustomersWindow
      }
      audience {
        __typename
        id
        title
      }
    }
  }
`;

export default globalSearchQuery;
