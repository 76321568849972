import { gql } from '@apollo/client';

export const submitConversationReviewMutation = gql`
  mutation SubmitConversationReview($input: SubmitConversationReviewInput!) {
    submitConversationReview(input: $input) {
      ... on SubmitConversationReviewSuccess {
        success
      }
      ... on SubmitConversationReviewError {
        title
        message
      }
    }
  }
`;

export default submitConversationReviewMutation;
