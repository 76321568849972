import React, { useCallback, useState } from 'react';
import TextFieldSearch from 'components/TextFieldSearch/TextFieldSearch';
import globalSearchQuery from 'operations/queries/globalSearch.query';
import {
  AudienceSuggestion,
  BrandSuggestion,
  CampaignSuggestion,
  GlobalSearchQuery,
  GlobalSearchQueryVariables,
  MerchantSuggestion,
} from 'types/graphql/generated';
import { Autocomplete, theme } from '@klover/attain-design-system';
import { AutocompleteNoOptionsText } from 'constants/strings';
import { CaretDown, ChartLine, Tag, UsersThree } from '@phosphor-icons/react';
import {
  getRecentResults,
  setRecentResults,
} from 'hooks/useRecentResults/useRecentResults';
import { useGlobalState } from 'state';
import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import * as Styled from './GlobalSearch.styles';

type AudienceSuggestionWithGroup = AudienceSuggestion & {
  group: string;
  type: string;
};
type BrandSuggestionWithGroup = BrandSuggestion & {
  group: string;
  timePeriod: string;
  customTimePeriodEnd: string;
  type: string;
};
type CampaignSuggestionWithGroup = CampaignSuggestion & {
  group: string;
  customertype: string;
  attributionwindow: string;
  type: string;
};
type MerchantSuggestionWithGroup = MerchantSuggestion & {
  group: string;
  timePeriod: string;
  customTimePeriodEnd: string;
  type: string;
};

type SuggestionWithGroup =
  | AudienceSuggestionWithGroup
  | BrandSuggestionWithGroup
  | CampaignSuggestionWithGroup
  | MerchantSuggestionWithGroup;

const hasLogo = (
  option: any
): option is MerchantSuggestion | BrandSuggestion => {
  return option && typeof option.logo === 'string';
};

const hasTimePeriod = (
  option: any
): option is BrandSuggestion | MerchantSuggestion => {
  return option && typeof option.timePeriod === 'string';
};

const hasCustomertypeAndAttributionwindow = (
  option: any
): option is CampaignSuggestion => {
  return (
    option &&
    typeof option.customertype === 'string' &&
    typeof option.attributionwindow === 'string'
  );
};

const formatCampaignType = (str: string) => {
  return str
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const SearchAutoComplete = () => {
  const [bounce, setBounce] = useState<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();
  const [, actions] = useGlobalState();

  const [search, { data, loading }] = useLazyQuery<
    GlobalSearchQuery,
    GlobalSearchQueryVariables
  >(globalSearchQuery);

  const handleInputChange = useCallback(
    (_, brandName) => {
      if (bounce) clearTimeout(bounce);

      setBounce(
        setTimeout(() => {
          setBounce(null);

          void search({
            variables: {
              input: brandName,
            },
          });
        }, 500)
      );
    },
    [bounce, search]
  );

  const audiences = data?.globalSearch.audience ?? [];
  const brands = data?.globalSearch.brands ?? [];
  const campaigns = data?.globalSearch.campaigns ?? [];
  const merchants = data?.globalSearch.merchants ?? [];

  const results: SuggestionWithGroup[] = [
    ...audiences.map((item) => ({
      ...item,
      group: 'Audiences',
      type: 'Audience',
    })),
    ...brands.map((item) => ({
      ...item,
      group: 'Brands',
      timePeriod: item.timePeriod,
      customTimePeriodEnd: '',
      type: 'Brand',
    })),
    ...campaigns.map((item) => ({
      ...item,
      group: 'Campaigns',
      customertype: 'ALL_CUSTOMERS',
      attributionwindow: item.attributionWindow,
      type: `${formatCampaignType(item.partner)} campaign`,
    })),
    ...merchants.map((item) => ({
      ...item,
      group: 'Merchants',
      timePeriod: item.timePeriod,
      customTimePeriodEnd: '',
      type: 'Merchant',
    })),
  ];

  const hasResults = results.length !== 0;

  const handleOptionSelected = (_, value: SuggestionWithGroup | null) => {
    if (value) {
      let url = '';
      switch (value.type) {
        case 'Audience':
          url = `/audiences/attain?personas=${value.id}&personaType=audience`;
          actions.setSmartPersonaId(value.id, 'audience');
          break;
        case 'Brand':
          if (hasTimePeriod(value)) {
            url = `/insights-brands/${value.id}/${value.timePeriod}/${value.customTimePeriodEnd}?`;
          }
          break;
        case 'Merchant':
          if ('insightsId' in value && hasTimePeriod(value)) {
            url = `/insights-merchants/${value.id}/${value.timePeriod}/${value.customTimePeriodEnd}?`;
          }
          break;
        default:
          if (hasCustomertypeAndAttributionwindow(value)) {
            url = `/measurement/dashboard/overview?campaignId=${value.id}&customertype=${value.customertype}&attributionwindow=${value.attributionwindow}&`;
          }
          break;
      }
      setRecentResults('globalSearch', { ...value, group: 'Recent' });
      navigate(url);
    }
  };

  const renderOption = (props, option: SuggestionWithGroup) => (
    <Styled.Result {...props} key={option.id}>
      {hasLogo(option) ? (
        <Styled.ResultLogo>
          <img src={option.logo ?? ''} alt="" />
        </Styled.ResultLogo>
      ) : option.type === 'Audience' ? (
        <Styled.ResultIcon>
          <UsersThree size={theme.icons.lg} weight="bold" />
        </Styled.ResultIcon>
      ) : option.type === 'Brand' ? (
        <Styled.ResultIcon>
          <Tag size={theme.icons.lg} weight="bold" />
        </Styled.ResultIcon>
      ) : (
        <Styled.ResultIcon>
          <ChartLine size={theme.icons.lg} weight="bold" />
        </Styled.ResultIcon>
      )}
      <div>
        <Styled.ResultTitle>{option.title}</Styled.ResultTitle>
        <Styled.ResultType>{option.type}</Styled.ResultType>
      </div>
    </Styled.Result>
  );

  return (
    <Autocomplete
      noOptionsText={AutocompleteNoOptionsText}
      onChange={handleOptionSelected}
      popupIcon={<CaretDown />}
      renderInput={(params) => (
        <TextFieldSearch
          {...params}
          placeholder={'Enter a brand, merchant, audience or campaign'}
        />
      )}
      isOptionEqualToValue={(option: SuggestionWithGroup, value) =>
        option.id === value.id
      }
      value={data?.globalSearch.audience[0] as SuggestionWithGroup}
      filterOptions={(options) => options}
      options={
        loading ? [] : hasResults ? results : getRecentResults('globalSearch')
      }
      onInputChange={handleInputChange}
      getOptionLabel={(option: SuggestionWithGroup) => option?.title || ''}
      loading={!!bounce || loading}
      loadingText="Loading results..."
      groupBy={(option: SuggestionWithGroup) => option.group}
      renderGroup={(params) => (
        <React.Fragment key={params.key}>
          <Styled.Category>{params.group}</Styled.Category>
          {params.children}
        </React.Fragment>
      )}
      renderOption={renderOption}
    />
  );
};

export default SearchAutoComplete;
