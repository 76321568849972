import styled from 'styled-components';
import { Checkbox, Chip, theme } from '@klover/attain-design-system';

export const StyledChip = styled(Chip)<{ $type: 'Brand' | 'Merchant' }>`
  && {
    background-color: ${({ $type }) =>
      $type === 'Brand'
        ? theme.colors.CHART_PRIMARY
        : theme.colors.CHART_TERTIARY};
    color: ${({ $type }) =>
      $type === 'Brand' ? theme.colors.textInverted : theme.colors.text};
  }
`;

export const StyledCheckBox = styled(Checkbox)`
  && {
    margin-inline: 0 ${theme.spacing.xs};
    color: ${theme.colors.CHART_PRIMARY};
  }
  &&.Mui-checked {
    color: ${theme.colors.CHART_PRIMARY};
  }
`;
