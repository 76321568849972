import { gql } from '@apollo/client';

export const insightRfmOptionsQuery = gql`
  query InsightRfmOptions {
    insightRfmOptions {
      id
      timePeriods {
        kind
        id
        title
      }
    }
  }
`;

export default insightRfmOptionsQuery;
