import { gql } from '@apollo/client';

export const smartPersonaMeasurementQuery = gql`
  query SmartPersonaMeasurement($input: SmartPersonaMeasurementInput!) {
    smartPersonaMeasurement(input: $input) {
      ... on SmartPersonaMeasurementSuccess {
        id
        merchantOrBrandNames
        merchantOrBrandType
        title
        demographics {
          title
          data {
            index {
              description
              kind
              title
              value
            }
            percentOfAudience {
              title
              value
            }
            title
          }
        }
        mediaConsumption {
          title
          data {
            title
            index {
              value
              title
              description
              kind
            }
          }
        }
        psychographics {
          title
          data {
            title
            index {
              value
              title
              description
              kind
            }
          }
        }
      }
      ... on SmartPersonaMeasurementError {
        message
      }
    }
  }
`;

export default smartPersonaMeasurementQuery;
