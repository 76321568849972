import React from 'react';
import SmartPersonaBody from './SmartPersonaBody';
import campaignNameQuery from 'operations/queries/campaignName.query';
import smartPersonaMeasurementQuery from 'operations/queries/smartPersonaMeasurement.query';
import trackEvent from 'utils/trackingEvents';
import useMe from 'hooks/useMe/useMe';
import {
  CampaignQuery,
  MeasurementOverviewCustomerType,
  SmartPersonaMeasurementInput,
  SmartPersonaMeasurementQuery,
  SmartPersonaMeasurementQueryVariables,
  SmartPersonaMeasurementSuccess,
} from 'types/graphql/generated';
import { useLazyQuery } from '@apollo/client';

const customerTypeTitles = {
  [MeasurementOverviewCustomerType.ALL_CUSTOMERS]: 'All Converters',
  [MeasurementOverviewCustomerType.NET_NEW_CUSTOMERS]: 'Net New Converters',
  [MeasurementOverviewCustomerType.RECURRING_CUSTOMERS]: 'Recurring Buyers',
};

interface Props {
  personaInput: SmartPersonaMeasurementInput | undefined;
  scrollDrawerToTop?: () => any;
}

const SmartPersonaMeasurement = ({
  personaInput,
  scrollDrawerToTop,
}: Props) => {
  const [campaignName, setCampaignName] = React.useState<string | undefined>(
    ''
  );
  const me = useMe();

  const [fetchPersonaData, { data, loading }] = useLazyQuery<
    SmartPersonaMeasurementQuery,
    SmartPersonaMeasurementQueryVariables
  >(smartPersonaMeasurementQuery, {
    fetchPolicy: 'no-cache',
  });

  const [fetchCampaign] = useLazyQuery<CampaignQuery>(campaignNameQuery, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setCampaignName(data.campaign?.title);
    },
  });

  React.useEffect(() => {
    if (personaInput && personaInput.campaignId) {
      fetchPersonaData({ variables: { input: personaInput } });
      fetchCampaign({ variables: { campaignId: personaInput.campaignId } });
    }
  }, [personaInput]);

  const persona =
    data && (data.smartPersonaMeasurement as SmartPersonaMeasurementSuccess);
  const merchantOrBrandNames = persona?.merchantOrBrandNames;
  const merchantOrBrandType = data && persona?.merchantOrBrandType;
  const demographics = data && persona?.demographics;
  const psychographics = data && persona?.psychographics;
  const mediaConsumption = data && persona?.mediaConsumption;

  const title = personaInput?.customerType
    ? customerTypeTitles[personaInput.customerType]
    : '';

  React.useEffect(() => {
    trackEvent('Converter Insights', {
      ...me,
      audienceInsightTitle: title,
      ...personaInput,
    });
  }, []);

  return (
    <SmartPersonaBody
      merchantOrBrandNames={merchantOrBrandNames}
      merchantOrBrandType={merchantOrBrandType}
      campaign={personaInput}
      campaign_id={personaInput?.campaignId}
      campaignName={campaignName}
      demographics={{ data: demographics, loading: loading }}
      psychographics={{ data: psychographics, loading: loading }}
      mediaConsumption={{ data: mediaConsumption, loading: loading }}
      loading={loading}
      persona={persona}
      personaTitle={title}
      rightColTitle="Index vs. all impressions"
      drawerCategory="Converter Insights"
      scrollDrawerToTop={scrollDrawerToTop}
    />
  );
};

export default SmartPersonaMeasurement;
