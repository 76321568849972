import { Control, Controller } from 'react-hook-form';
import * as Styled from './Step.styles';

interface Props {
  step: number;
  nextStep: () => void;
  watchValue: string | null;
  control: Control;
  firstName: string;
  workOptions: string[];
}

export const BrandOrAgencyStep = ({
  step,
  nextStep,
  watchValue,
  control,
  firstName,
  workOptions,
}: Props) => {
  return (
    <Styled.Block>
      <Styled.Step>Step {step} / 6</Styled.Step>
      <Styled.PreHeader>Nice to meet you, {firstName}!</Styled.PreHeader>

      <Styled.Header>Do you work for a brand or agency?</Styled.Header>

      <Controller
        name="work"
        control={control}
        rules={{ required: false }}
        render={({ field: { onChange, onBlur, value, name } }) => (
          <Styled.WorkFor
            items={workOptions}
            onChange={onChange}
            onBlur={onBlur}
            value={value ?? ''}
            name={name}
          />
        )}
      />

      <Styled.Submit
        variant="contained"
        fullWidth
        onClick={nextStep}
        disabled={!Boolean(watchValue)}
      >
        Next
      </Styled.Submit>
    </Styled.Block>
  );
};

export default BrandOrAgencyStep;
