import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

export const Empty = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 210px - 2 * ${theme.spacing.lg});
  width: 100%;
  justify-content: center;
`;

export const Header = styled.div`
  ${theme.typography.headingSM};
  color: ${theme.colors.textBrand};
  margin-top: ${theme.spacing.lg};
`;

export const Text = styled.div`
  ${theme.typography.bodyLG};
  color: ${theme.colors.textTertiary};
  margin-top: ${theme.spacing.sm};
`;
