import GhostLoader from 'react-ghost-loader';
import TabSegment from './TabSegment/TabSegment';
import { Alert } from '@klover/attain-design-system';
import { AudienceBuilderOperand, SmartPersona } from 'types/graphql/generated';
import * as Styled from '../SmartPersona.styles';

interface Props {
  segments: {
    data?: AudienceBuilderOperand[] | SmartPersona[] | null;
    loading: boolean;
  };
  accordionClicked: (index: number) => void;
  expandedAccordions: number[];
  rightColTitle?: string;
  showComingSoon?: boolean;
}

const Tab = ({
  segments,
  accordionClicked,
  expandedAccordions,
  rightColTitle,
  showComingSoon,
}: Props) => {
  return segments.loading ? (
    <>
      {[...Array(5)].map((_, i) => (
        <Styled.Loader key={i}>
          <GhostLoader
            height="24px"
            heightRandom={0}
            width="24px"
            widthRandom={0}
          />
          <GhostLoader height="32px" width="40%" heightRandom={0} />
        </Styled.Loader>
      ))}
    </>
  ) : (
    <>
      {!segments.data || segments.data?.length === 0 ? (
        showComingSoon ? (
          <Styled.AlertWrapper>
            <Alert severity="info" title="Coming soon!">
              We are in the process of double &amp; triple checking our brand
              data to make sure we’re giving you the quality you deserve.
            </Alert>
          </Styled.AlertWrapper>
        ) : (
          <Styled.AlertWrapper>
            <Alert severity="info" title="Below data threshold">
              There is not enough data to display Converter Insights. Adjust
              your filters to see key insights on these shoppers
            </Alert>
          </Styled.AlertWrapper>
        )
      ) : (
        <>
          {segments.data &&
            segments.data.map((segment, i) => (
              <TabSegment
                accordionClicked={accordionClicked}
                expandedAccordions={expandedAccordions}
                index={i}
                key={segment.title}
                rightColTitle={rightColTitle}
                segment={segment}
              />
            ))}
        </>
      )}
    </>
  );
};

export default Tab;
