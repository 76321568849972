import React from 'react';
import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import { IncrementalityDataChart } from './MeasurementDashboard';
import { Layer, ResponsiveLine, Serie } from '@nivo/line';
import { Square } from '@phosphor-icons/react';
import { generateYTicksCustomInterval } from 'utils/Measurement/generateYTicksArray';
import { theme } from '@klover/attain-design-system';
import * as ChartStyles from './MeasurementChart.styles';
import * as Styled from './MeasurmentIncrementalityChart.styles';
import * as d3 from 'd3-shape';

const LOWYAXIS = -2;
const HIGHYAXIS = 2;
const AXISINTERVAL = 2;

interface Props {
  data: IncrementalityDataChart | null;
  ciBounds: [IncrementalityDataChart | null, IncrementalityDataChart | null];
}

export const MeasurementIncrementalityChart = ({ data, ciBounds }: Props) => {
  const lineTheme = {
    axis: {
      ticks: {
        text: {
          fontSize: 16,
          fill: theme.colors.textBrand,
        },
      },
    },
    grid: {
      line: {
        stroke: '#D9D9D9',
      },
    },
  };
  const hasCiCharting = useUiFlag('ui_incrementality_chart_ci');
  const showCiChart = hasCiCharting.isReady && hasCiCharting.enabled;
  const chartRef = React.useRef<HTMLDivElement>(null);

  const upperData =
    showCiChart && ciBounds?.at(0)?.data ? ciBounds?.at(0)?.data : [];
  const lowerData =
    showCiChart && ciBounds?.at(1)?.data ? ciBounds?.at(1)?.data : [];
  const chartData: Serie[] = [
    { id: 'Incrementality', data: data?.data ?? [] },
    {
      id: 'Upper ci',
      data: upperData ?? [],
    },
    { id: 'Lower ci', data: lowerData ?? [] },
  ];
  const highYvals: number[] =
    ciBounds?.at(0)?.data?.map((i) => (typeof i.y === 'number' ? i.y : 0)) ||
    [];
  const lowYvals: number[] =
    ciBounds?.at(1)?.data?.map((i) => (typeof i.y === 'number' ? i.y : 0)) ||
    [];

  const highestY = highYvals.length ? Math.max(...highYvals) : 0;
  const lowestY = lowYvals.length ? Math.min(...lowYvals) : 0;
  const ticksY: number[] = generateYTicksCustomInterval(
    AXISINTERVAL,
    lowestY,
    highestY,
    LOWYAXIS,
    HIGHYAXIS,
    0.1
  );

  const ObfuscatedLayer = ({ xScale, innerHeight }) => {
    const x = data?.obfuscateToData ? xScale(data.obfuscateToData) : 0; // Get the scaled x position

    return (
      <rect
        x={0}
        y={0}
        width={x}
        height={innerHeight}
        fillOpacity={0.3}
        fill={theme.colors.backgroundBrand}
      />
    );
  };

  const CiLayer = ({ series, yScale }) => {
    const line1 = series.find((s) => s.id === 'Upper ci');
    const line2 = series.find((s) => s.id === 'Lower ci');
    const obfuscateIdx = line1.data.findIndex(
      (lineDat) => lineDat.data.x === data?.obfuscateToData
    );

    line1.data = line1.data.slice(obfuscateIdx > 0 ? obfuscateIdx : 0);
    line2.data = line2.data.slice(obfuscateIdx > 0 ? obfuscateIdx : 0);

    if (!line1 || !line2 || !showCiChart) {
      return null;
    }
    const areaGenerator = d3
      .area()
      .x((d: any) => d.position.x)
      .y0((d: any) => yScale(d.yBottom))
      .y1((d: any) => yScale(d.yTop))
      .curve(d3.curveMonotoneX);
    const areaData = line1.data.map((d, i) => ({
      ...d,
      yBottom: line2.data[i].data.y,
      yTop: line1.data[i].data.y,
    }));

    const d = areaGenerator(areaData);
    return (
      <path d={d ?? undefined} fill="hsla(254, 29%, 48%, 0.15)" stroke="none" />
    );
  };

  const dataLen = (data?.data?.length || 0) - 1;
  const fowIdx =
    data?.data?.findIndex((datum) => datum.x === data.obfuscateToData) || 0;
  const isPoint = dataLen <= fowIdx; // if data is only a point we will enable points.

  return (
    <Styled.Wrapper ref={chartRef}>
      <ChartStyles.BottomAxisIncrementality>
        <span>{<>{data?.startDate}</>}</span>
        <span>{<>{data?.midDate}</>}</span>
        <span>{<>{data?.endDate}</>}</span>
      </ChartStyles.BottomAxisIncrementality>

      <Styled.ChartWrapper>
        <ResponsiveLine
          isInteractive={true}
          axisLeft={{
            tickSize: 0,
            tickPadding: 18,
            format: (val) => val + '%',
            tickValues: [...ticksY],
          }}
          axisBottom={null}
          theme={lineTheme}
          colors={[
            theme.colors.CHART_PRIMARY,
            'hsla(254, 29%, 48%, 0.1)',
            'hsla(254, 29%, 48%, 0.1)',
          ]}
          data={chartData}
          enableGridX={false}
          enableGridY={true}
          enableSlices="x"
          gridYValues={[...ticksY]}
          curve="monotoneX"
          yScale={{
            type: 'linear',
            min:
              lowestY < (ticksY.at(-1) || LOWYAXIS)
                ? lowestY
                : ticksY.at(-1) || LOWYAXIS,
            max:
              highestY > (ticksY.at(0) || HIGHYAXIS)
                ? highestY
                : ticksY.at(0) || highestY,
            stacked: false,
            reverse: false,
          }}
          margin={{
            top: 7,
            right: 75,
            bottom: 7,
            left: 65,
          }}
          pointSize={isPoint ? 8 : 0}
          sliceTooltip={(data) => {
            const upper = showCiChart ? data.slice.points[1] : undefined;
            const inc = showCiChart
              ? data.slice.points[2]
              : data.slice.points[0];
            const date = data.slice.points[0].data.xFormatted;
            const yVal = Number(inc.data.y);
            const percentage = yVal.toFixed(2);
            // const isSig = data.slice.points[0].data['isSig'];
            const ci = inc.data['ci'];
            return (
              <ChartStyles.Tooltip>
                <ChartStyles.LabelWrapper>
                  <Square size={18} fill={inc.color} weight="fill" />
                  Lift
                  <ChartStyles.Label>{`${percentage}%`}</ChartStyles.Label>
                </ChartStyles.LabelWrapper>
                {showCiChart && (
                  <ChartStyles.LabelWrapper>
                    <Square size={18} fill={upper?.color} weight="fill" />
                    CI delta
                    <ChartStyles.Label>{`+/- ${ci?.toFixed(
                      2
                    )}%`}</ChartStyles.Label>
                  </ChartStyles.LabelWrapper>
                )}
                <ChartStyles.LabelWrapper>
                  <ChartStyles.Label>{date}</ChartStyles.Label>
                </ChartStyles.LabelWrapper>
              </ChartStyles.Tooltip>
            );
          }}
          layers={[
            'grid',
            'markers',
            'axes',
            'areas',
            'crosshair',
            CiLayer as Layer,
            'lines',
            'slices',
            'points',
            'mesh',
            'legends',
            ObfuscatedLayer as Layer,
          ]}
        />
      </Styled.ChartWrapper>
    </Styled.Wrapper>
  );
};

export default MeasurementIncrementalityChart;
