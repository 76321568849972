import { Alert } from '@klover/attain-design-system';
import * as Styled from './dataWarning.styled';

export const BrandLiftSurveyDataWarning = () => (
  <Styled.Wrapper>
    <Alert title="Sample threshold warning" severity="warning">
      The sample threshold of 50 control / 50 exposed has not yet been reached.
      Brand Lift metrics will not be displayed until we have collected more
      sample
    </Alert>
  </Styled.Wrapper>
);
