import {
  HiddenIcon,
  NeutralCircle,
  PositiveTraiangle,
} from 'components/Icons/Icons';
import { LiftSignificance } from 'types/graphql/generated';

interface Props {
  significance?: LiftSignificance;
  withText?: boolean;
}
export const IncrementalityMarker = ({ significance, withText }: Props) => {
  switch (significance) {
    case LiftSignificance.WITHIN_CONFIDENCE_INTERVAL_AND_POSITIVE: //deprecated
    case LiftSignificance.SIGNIFICANTLY_POSITIVE:
      return withText ? (
        <>
          <PositiveTraiangle /> Significantly positive at 90%
        </>
      ) : (
        <PositiveTraiangle />
      );

    case LiftSignificance.WITHIN_CONFIDENCE_INTERVAL_AND_NEGATIVE: // deprecated
    case LiftSignificance.NO_LIFT_DETECTED:
      return withText ? <>NL</> : <HiddenIcon />;

    case LiftSignificance.NOT_WITHIN_CONFIDENCE_INTERVAL: // deprecated
    case LiftSignificance.NOT_SIGNIFICANT:
      return withText ? (
        <>
          <NeutralCircle /> Not significant
        </>
      ) : (
        <NeutralCircle />
      );
    default:
      return <HiddenIcon />;
  }
};

export default IncrementalityMarker;
