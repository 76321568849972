import { Alert } from '@klover/attain-design-system';

export interface GenericError {
  message?: string;
}

// Place holder
const Error = ({ message }: GenericError) => (
  <Alert severity="error">{message ?? 'Error loading data'}</Alert>
);

export default Error;
