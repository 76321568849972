import styled from 'styled-components';

import { ButtonGroup, theme } from '@klover/attain-design-system';

export const CardTitle = styled.h3`
  ${theme.typography.headingSM};
  color: ${theme.colors.textSecondary};
  text-transform: none;
  flex-grow: 1;
`;

export const Wrapper = styled.div`
  margin: 0 0 0 ${theme.spacing.md};
  width: 100%;
  max-width: 1600px;
`;

export const AiWrapper = styled(Wrapper)`
  margin-top: ${theme.spacing.sm};
`;

export const AiCard = styled.div`
  padding: ${theme.spacing.lg};
  gap: ${theme.spacing.xxs};
  background: rgba(255, 255, 255, 0.9);
  border-radius: ${theme.spacing.xs};
  margin-block-end: ${theme.spacing.md};
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 129, 102, 0);
  box-sizing: border-box;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-radius: calc(${theme.spacing.xs} + 1px);
    z-index: -1;
    background: linear-gradient(
      270deg,
      rgba(77, 65, 118, 1) 0%,
      rgba(97, 69, 124, 0.8) 11.6%,
      rgba(117, 72, 129, 0.7) 20.43%,
      rgba(136, 75, 132, 0.5) 28.84%,
      rgba(156, 78, 133, 0.5) 37.24%,
      rgba(174, 81, 133, 0.7) 45.78%,
      rgba(192, 86, 130, 0.8) 54.47%,
      rgba(208, 91, 127, 1) 62.74%,
      rgba(223, 98, 122, 1) 71.14%,
      rgba(236, 107, 116, 1) 79.76%,
      rgba(246, 117, 109, 1) 88.16%,
      rgba(255, 129, 102, 1) 100.14%
    );
  }
  & h4,
  & h3 {
    font-weight: normal;
  }
  & h4 {
    margin-top: ${theme.spacing.sm};
  }
  & li {
    margin-left: ${theme.spacing.lg};
    list-style: disc;
  }
`;

export const AiInsightBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const AiInsightBox = styled.div`
  width: 32%;
  background: #ffffff99;
  padding: ${theme.spacing.md};
  border-radius: 8px;
  &:last-child {
    width: 100%;
    margin-top: ${theme.spacing.md};
  }
`;

export const ToggleMoreLess = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.CHART_PRIMARY};
  cursor: pointer;
  & > * {
    margin-right: ${theme.spacing.sm};
  }
`;

export const AiError = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: ${theme.spacing.sm};
  }
`;

export const AiLoading = styled.div`
  display: flex;
  align-items: center;
  animation: blinker 2s linear infinite;
  & > * {
    margin-right: ${theme.spacing.sm};
  }
  @keyframes blinker {
    50% {
      opacity: 0.2;
    }
  }
`;

export const PersonaImgContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: ${theme.spacing.xl};
  justify-content: center;
`;

export const PersonaImg = styled.img`
  width: 200px;
  height: 200px;
  border-radius: ${theme.spacing.md};
  margin: auto;
`;

export const InsightButtonGroup = styled(ButtonGroup)`
  margin-top: ${theme.spacing.md};
  & > button {
    height: ${theme.spacing.xl} !important;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block-end: ${theme.spacing.md};
`;

export const CardBody = styled.div``;
