import stableSeedFragment from 'operations/fragments/stableSeed.fragment';
import { gql } from '@apollo/client';

export const insightDemographicsQuery = gql`
  query InsightDemographics($input: InsightDemographicsInput!) {
    insightDemographics(input: $input) {
      ... on InsightDemographicsSuccess {
        breadcrumb
        charts {
          id
          title
          description
          icon {
            iconName
            svg
          }
          chartType
          data {
            rows {
              id
              title
              value {
                value
                title
              }
            }
            buckets {
              title
              value {
                value
                title
              }
            }
          }
        }
        demographics {
          data {
            index {
              description
              kind
              title
              value
            }
            percentOfAudience {
              title
              value
            }
            title
          }
          title
        }
        psychographics {
          title
          data {
            title
            index {
              value
              title
              description
              kind
            }
          }
        }
        mediaConsumption {
          title
          data {
            title
            index {
              value
              title
              description
              kind
            }
          }
        }
        details {
          id
          title
          description
        }
        id
        seed {
          ...stableSeedFragment
        }
        title
      }
    }
  }

  ${stableSeedFragment}
`;

export default insightDemographicsQuery;
