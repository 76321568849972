export const findHighestValue = (data, key) => {
  let highestValue = Number.NEGATIVE_INFINITY; // Initialize to the smallest possible number

  data.forEach((row) => {
    if (row[key] && 'value' in row[key]) {
      if (row[key]['value'] > highestValue) {
        highestValue = row[key]['value'];
      }
    }
  });

  return highestValue;
};
