import { Info } from '@phosphor-icons/react';
import { Tooltip } from '@klover/attain-design-system';

export const LiftDataNA = () => {
  return (
    <Tooltip
      placement="top"
      title="The sample threshold of 30 control / 30 exposed has not yet been reached for this datapoint."
    >
      <div>
        N/A <Info />
      </div>
    </Tooltip>
  );
};

export default LiftDataNA;
