import brandLiftSurveyCampaignTrendsQuery from 'operations/queries/brandLiftSurveyCampaignTrends.query';
import { Base64ID } from 'types/models';
import {
  BlquestionType,
  BrandLiftSurveyInsightsCampaignTrends,
  BrandLiftSurveyInsightsCampaignTrendsQuery,
  BrandLiftSurveyInsightsCampaignTrendsQueryVariables,
  SurveyQuestionTemplate,
} from 'types/graphql/generated';
import { BrandLiftSurveyDataWarning } from '../MetricComponents/DataWarnings/dataWarning';
import { GraphLoader } from '../MetricComponents/SkeletonLoaders/graphLoader';
import { Minus, Square } from '@phosphor-icons/react';
import { TrendsChart } from '../MetricComponents/Trends/TrendsChart';
import { getDataChartBrandLiftSurveyTrends } from 'utils/Measurement/dataCharts';
import { theme } from '@klover/attain-design-system';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import * as Styled from './Trends.styles';

interface Props {
  questionType: BlquestionType | null;
  campaignId: Base64ID;
  isBrand: boolean;
}

export const Trends = ({ questionType, campaignId, isBrand }: Props) => {
  const { data, loading } = useQuery<
    BrandLiftSurveyInsightsCampaignTrendsQuery,
    BrandLiftSurveyInsightsCampaignTrendsQueryVariables
  >(brandLiftSurveyCampaignTrendsQuery, {
    variables: {
      input: {
        campaignId: campaignId,
        questionType: isBrand
          ? ({ brand: questionType?.templateId } as SurveyQuestionTemplate)
          : ({ merchant: questionType?.templateId } as SurveyQuestionTemplate), //TODO: change this.
      },
    },
  });
  const chartData = data?.brandLiftSurveyInsightsCampaignTrends;

  const { trendLine, upperCiLine, lowerCiLine } = useMemo(() => {
    return getDataChartBrandLiftSurveyTrends(
      chartData as BrandLiftSurveyInsightsCampaignTrends
    );
  }, [chartData]);
  //TODO: remove cast once apollo type is updated

  if (loading) {
    return (
      <div>
        <GraphLoader />
      </div>
    );
  }
  return (
    <Styled.TrendsWrapper>
      {chartData && trendLine && upperCiLine && lowerCiLine ? (
        <TrendsChart data={trendLine} ciBounds={[upperCiLine, lowerCiLine]} />
      ) : (
        <BrandLiftSurveyDataWarning />
      )}
      <Styled.LegendWrapper>
        <Styled.LegendItem>
          Absolute lift
          <Minus color={theme.colors.CHART_PRIMARY} size={20} />
        </Styled.LegendItem>
        <Styled.LegendItem>
          Confidence interval range
          <Square color="hsla(254, 29%, 48%, 0.1)" weight="fill" />
        </Styled.LegendItem>
      </Styled.LegendWrapper>
    </Styled.TrendsWrapper>
  );
};

export default Trends;
