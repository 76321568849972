import * as Styled from './RegionBubble.styles';

interface Props {
  name: string;
  percent: number;
  top: number;
  left: number;
  color: string;
  delay: number;
  minValue: number;
  maxValue: number;
}

export const RegionBubble = ({
  name,
  percent,
  top,
  left,
  color,
  delay,
  minValue,
  maxValue,
}: Props) => {
  return (
    <Styled.Bubble
      $name={name}
      $percent={percent}
      $top={top}
      $left={left}
      $color={color}
      $delay={delay}
      $minValue={minValue}
      $maxValue={maxValue}
    >
      <Styled.BubbleBackgroundColor $color={color} $name={name} />

      <Styled.BubbleBackground $name={name} />

      <Styled.Text
        $name={name}
        $percent={percent}
        $delay={delay}
        $minValue={minValue}
        $maxValue={maxValue}
      >
        <Styled.Name $color={color}>{name}</Styled.Name>

        <Styled.Percent>{percent}%</Styled.Percent>
      </Styled.Text>
    </Styled.Bubble>
  );
};

export default RegionBubble;
