import GhostLoader from 'react-ghost-loader';
import LuciLogo from 'assets/luci-logo.svg';
import Markdown from 'react-markdown';
import React from 'react';
import remarkGfm from 'remark-gfm';
import streamBrandInsightChatSubscription from 'operations/subscriptions/streamBrandInsightChat.subscription';
import streamCampaignInsightChatSubscription from 'operations/subscriptions/streamCampaignInsightChat.subscription';
import { Base64ID } from 'types/models';
import { MathJax } from 'better-react-mathjax';
import {
  StreamBrandInsightChatSubscription,
  StreamCampaignInsightChatSubscription,
} from 'types/graphql/generated';
import { useSubscription } from '@apollo/client';
import * as Styled from './OutcomeAiDrawer.styles';

const OutcomeAiCampaignMessages = ({
  context,
  message,
  onComplete,
  onStreaming,
  campaignId,
  assistantType,
}) => {
  const [displayMsg, setDisplayMsg] = React.useState('');
  const [showGhostLoader, setShowGhostLoader] = React.useState(false);
  const { data, loading, error } =
    useSubscription<StreamCampaignInsightChatSubscription>(
      streamCampaignInsightChatSubscription,
      {
        variables: {
          input: {
            context: (context || '') as Base64ID,
            message: message,
            campaignId: campaignId as Base64ID,
            assistantType,
          },
        },
        skip: !message,
      }
    );

  const messageDone = !!data?.streamCampaignInsightChat.messageDone;

  React.useEffect(() => {
    onStreaming();
    if (data?.streamCampaignInsightChat.message) {
      const { value, loadingImage } = sanitizeMessage(
        data?.streamCampaignInsightChat.message
      );
      if (value != displayMsg) setDisplayMsg(value);
      if (loadingImage != showGhostLoader) setShowGhostLoader(loadingImage);
    }
  }, [data?.streamCampaignInsightChat.message]);

  React.useEffect(() => {
    if (messageDone || error) {
      onComplete({
        context: data?.streamCampaignInsightChat.context || context,
        message: sanitizeMessage(data?.streamCampaignInsightChat.message).value,
        id: data?.streamCampaignInsightChat.messageId || '',
      });
    }
  }, [messageDone, error]);

  return (
    <>
      {(loading ||
        (!data?.streamCampaignInsightChat.message && !messageDone)) && (
        <Styled.MessageWrapper position="left">
          <Styled.LuciLogo src={LuciLogo} alt="" />

          <Styled.Analyzing>
            <Styled.AnalyzingDots>...</Styled.AnalyzingDots>Analyzing...
          </Styled.Analyzing>
        </Styled.MessageWrapper>
      )}
      {!loading && data?.streamCampaignInsightChat.message && (
        <Styled.MessageWrapper position="left">
          <Styled.LuciLogo src={LuciLogo} alt="" />
          <Styled.Message position="left" className="outcome-ai-message">
            <div className="luci">OutcomeAI</div>
            <MathJax dynamic hideUntilTypeset="every">
              <Markdown remarkPlugins={[remarkGfm]}>
                {error
                  ? 'I have encountered an issue, please try again later.'
                  : displayMsg}
              </Markdown>
            </MathJax>
            {showGhostLoader && (
              <>
                <br />
                <GhostLoader
                  height="300px"
                  width="100%"
                  widthRandom={0}
                  heightRandom={0}
                />
              </>
            )}
          </Styled.Message>
        </Styled.MessageWrapper>
      )}
    </>
  );
};

const OutcomeAiBrandMessages = ({
  context,
  message,
  onComplete,
  onStreaming,
  brandId,
  assistantType,
}) => {
  const [displayMsg, setDisplayMsg] = React.useState('');
  const [showGhostLoader, setShowGhostLoader] = React.useState(false);

  const { data, loading, error } =
    useSubscription<StreamBrandInsightChatSubscription>(
      streamBrandInsightChatSubscription,
      {
        variables: {
          input: {
            context: (context || '') as Base64ID,
            message: message,
            brandId: brandId as Base64ID,
            assistantType,
          },
        },
        skip: !message,
      }
    );

  const messageDone = !!data?.streamBrandInsightChat.messageDone;

  React.useEffect(() => {
    onStreaming();
    if (data?.streamBrandInsightChat.message) {
      const { value, loadingImage } = sanitizeMessage(
        data?.streamBrandInsightChat.message
      );
      if (value != displayMsg) setDisplayMsg(value);
      if (loadingImage != showGhostLoader) setShowGhostLoader(loadingImage);
    }
  }, [data?.streamBrandInsightChat.message]);

  React.useEffect(() => {
    if (messageDone || error) {
      onComplete({
        context: data?.streamBrandInsightChat.context || context,
        message: sanitizeMessage(data?.streamBrandInsightChat.message).value,
        id: data?.streamBrandInsightChat.messageId || '',
      });
    }
  }, [messageDone, error]);

  return (
    <>
      {(loading || (!data?.streamBrandInsightChat.message && !messageDone)) && (
        <Styled.MessageWrapper position="left">
          <Styled.LuciLogo src={LuciLogo} alt="" />

          <Styled.Message position="left" $analyzing>
            <div className="luci">OutcomeAI</div>
            Analyzing...
          </Styled.Message>
        </Styled.MessageWrapper>
      )}
      {!loading && data?.streamBrandInsightChat.message && (
        <Styled.MessageWrapper position="left">
          <Styled.LuciLogo src={LuciLogo} alt="" />

          <Styled.Message position="left" className="outcome-ai-message">
            <div className="luci">OutcomeAI</div>
            <MathJax dynamic hideUntilTypeset="every" renderMode="pre">
              <Markdown remarkPlugins={[remarkGfm]}>
                {error
                  ? 'I have encountered an issue, please try again later.'
                  : displayMsg}
              </Markdown>
            </MathJax>
            {showGhostLoader && (
              <>
                <br />
                <GhostLoader
                  height="300px"
                  width="100%"
                  widthRandom={0}
                  heightRandom={0}
                />
              </>
            )}
          </Styled.Message>
        </Styled.MessageWrapper>
      )}
    </>
  );
};

const sanitizeMessage = (raw) => {
  let loadingImage = false;
  if (!raw) return { message: '', loadingImage };
  const pattern =
    /(<h[12][^>]*>.*?<\/h[12]>)|(<[^>]*>([^<]*(Source:|Data extracted from:|Citation:).*$))/gs;
  let cleanedMessage = raw.replace(pattern, '</body></html>');
  cleanedMessage = cleanedMessage.replace(/【.*】\.*/g, '').trim();

  const incomepleteImageMarkdownPattern = /^(?!.+\).*$)^.*(!\[.*]\(.+)/gm;
  const incompImageMarkdownMatches = cleanedMessage.match(
    incomepleteImageMarkdownPattern
  );

  if (incompImageMarkdownMatches?.length) {
    loadingImage = true;
    cleanedMessage = cleanedMessage.replace(incompImageMarkdownMatches[0], '');
  }

  return {
    value: cleanedMessage,
    loadingImage,
  };
};

const OutcomeAiMessages = (props) => {
  if (props.campaignId) {
    return <OutcomeAiCampaignMessages {...props} />;
  } else {
    return <OutcomeAiBrandMessages {...props} />;
  }
};

export default OutcomeAiMessages;
