import * as Styled from './MenuItemExport.styles';

interface MenuItemExportProps {
  data: any[];
  filename: string;
}

export const MenuItemExport = ({
  data,
  filename,
  ...props
}: MenuItemExportProps) => {
  return (
    <Styled.Wrapper>
      <Styled.Button data={data} filename={`${filename}.csv`} {...props}>
        Export CSV
      </Styled.Button>
    </Styled.Wrapper>
  );
};

export default MenuItemExport;
