import styled from 'styled-components';

import { theme } from '@klover/attain-design-system';

export const OptionWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  width: 100%;
  padding-left: calc(${theme.spacing.xs} + 1px);
`;

export const OptionTitle = styled.div`
  ${theme.typography.bodyLG};
`;

export const OptionDetails = styled.div`
  ${theme.typography.bodyMD};
  color: ${theme.colors.textTertiary};
`;
