import { gql } from '@apollo/client';

export const measurementInsightsTopRetailerQuery = gql`
  query MeasurementInsightsTopRetailer($input: MeasurementOverviewInput!) {
    measurementInsightsTopRetailer(input: $input) {
      topRetailers {
        merchant {
          id
          title
          logo
        }
        percentSpend {
          title
          value
        }
        percentTransactions {
          title
          value
        }
        roas {
          title
          value
        }
        pfmCategory
      }
    }
  }
`;
