import Error from 'components/Error/Error';
import GhostLoader from 'react-ghost-loader';
import Header from 'components/Header/Header';
import MeasurementBrandSurvey from 'components/Measurement/MeasurementBrandSurvey/MeasurementBrandSurvey';
import MeasurementBrandSurveyV2 from 'components/Measurement/MeasurementBrandSurveyV2/MeasurementBrandSurveyV2';
import MeasurementCampaignInsights from 'components/Measurement/MeasurementCampaignInsights/MeasurementCampaignInsights';
import MeasurementFilters from 'components/Measurement/MeasurementFilters/FiltersDrawer/MeasurementFilters';
import MeasurementGhostLoader from './MeasurementGhostLoader';
import MeasurementIncrementality from 'components/Measurement/MeasurementIncrementality/MeasurementIncrementality';
import MeasurementNewBuyerLift from 'components/Measurement/MeasurementNewBuyerLift/MeasurementNewBuyerLift';
import MeasurementNotPermissioned from './MeasurementNotPermissioned';
import MeasurementOptimizationResults from 'components/Measurement/MeasurementOptimization/MeasurementOptimization';
import MeasurementUpsellBanner from 'components/Measurement/MeasurementUpsellBanner/MeasurementUpsellBanner';
import MeasurementsEmpty from './MeasurementsEmpty';
import OptimizationFilters, {
  OptimizationToggles,
} from 'components/Measurement/OptimizationsFilters/OptimizationsFilters';
import OutcomeAiDrawer, {
  MessageType,
} from 'views/InsightsNew/OutcomeAi/OutcomeAiDrawer';
import OutcomeAiIconLogo from 'assets/outcome-hq-icon-logo.svg';
import SmartPersonaMeasurement from 'components/SmartPersona/SmartPersonaMeasurement';
import Tabs, { Tab } from 'components/Tabs/Tabs';
import brandLiftSurveyInsightsNumResponsesQuery from 'operations/queries/brandLiftSurveyInsightsNumResponses.query';
import campaignMeasurementBasicQuery from 'operations/queries/campaignMeasurementBasic.query';
import campaignsQuery from 'operations/queries/campaigns.query';
import getAppliedFilters from 'utils/Measurement/getAppliedFilters';
import measurementOverviewOptionsQuery from 'operations/queries/measurementOverviewOptions.query';
import measurementOverviewQuery from 'operations/queries/measurementOverview.query';
import paths from 'constants/paths';
import useFeatureFlags from 'hooks/useFeatureFlags/useFeatureFlags';
import useMeasurementForm from 'hooks/useMeasurementForm/useMeasurementForm';
import useSearchParams from 'hooks/useSearchParams';
import useUiFlag from 'hooks/useFeatureFlags/useUiFlag';
import {
  Badge,
  Button,
  Card,
  Grid,
  IconButton,
  Tooltip,
  theme,
} from '@klover/attain-design-system';
import { Base64ID } from 'types/models';
import {
  BrandLiftSurveyInsightsNumResponsesQuery,
  BrandLiftSurveyInsightsNumResponsesQueryVariables,
  CampaignBasic,
  CampaignMeasurementBasicQuery,
  CampaignStatusEnum,
  CampaignsQuery,
  ImpressionEntityFilterItem,
  MeasurementOverviewCustomerType,
  MeasurementOverviewOptionsQuery,
  MeasurementOverviewOptionsSuccess,
  MeasurementOverviewQuery,
  MeasurementOverviewQueryVariables,
  PartnerEnum,
  SmartPersonaMeasurementInput,
} from 'types/graphql/generated';
import { CampaignQuickSelect } from 'components/Measurement/CampaignQuickSelect/CampaignQuickSelect';
import {
  CaretDoubleRight,
  CaretDown,
  CaretLeft,
  CirclesThreePlus,
  FunnelSimple,
} from '@phosphor-icons/react';
import {
  DEFAULT_MEASUREMENT_ATTRIBUTION_WINDOW_ID,
  DEFAULT_MEASUREMENT_NEW_CUSTOMER_WINDOW_ID,
} from 'utils/Measurement/measurementPaths';
import { DateRange } from 'react-day-picker';
import { Datum } from '@nivo/line';
import { MeasurementOverview as MeasurementOverviewComponent } from 'components/Measurement/MeasurementOverview/MeasurementOverview';
import { Popper } from '@mui/material';
import { Route, Routes, generatePath, useNavigate } from 'react-router-dom';
import { addDays, intlFormat, subDays } from 'date-fns';
import { campaignTypeDisplayMap } from 'constants/strings';
import { convertObjectToSearchString } from 'utils/convertObjectToSearchString';
import {
  getDataChartImpressions,
  getDataChartReach,
} from 'utils/Measurement/dataCharts';
import {
  getMeasurementOptimizationInputFromUrlParams,
  getMeasurementOverviewInputFromUrlParams,
} from 'utils/Measurement/measurementInputFromUrlParams';
import { hideIntercom, showIntercom } from 'utils/hideOrShowIntercom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGlobalState } from 'state';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import * as Styled from './MeasurementInsightsDashboard.styles';

export interface dataChart {
  id: string;
  color: string;
  startDate: string | undefined;
  midDate: string | undefined;
  endDate: string | undefined;
  data:
    | {
        bar: number;
        value: number;
        x: string;
        y: number;
      }[]
    | undefined;
}

export interface TrendsDataChart {
  id: string;
  sign: string;
  color: string;
  startDate: string | null;
  midDate: string | null;
  endDate: string | null;
  data: Datum[] | null;
}

export interface IncrementalityDataChart {
  id: string;
  color: string;
  startDate: string | null;
  midDate: string | null;
  endDate: string | null;
  data: Datum[] | null;
  obfuscateToData: string;
}

export enum locationEnum {
  OVERVIEW,
  OPTIMIZATION,
  NEWBUYERLIFT,
  INCREMENTALITY,
  INSIGHTS,
  BRANDLIFT,
}

export const locations = {
  [paths.MEASUREMENT_DASHBOARD + paths.MEASUREMENT_DASHBOARD_OVERVIEW]:
    locationEnum.OVERVIEW,
  [paths.MEASUREMENT_DASHBOARD + paths.MEASUREMENT_DASHBOARD_OPTIMIZATION]:
    locationEnum.OPTIMIZATION,
  [paths.MEASUREMENT_DASHBOARD + paths.MEASUREMENT_DASHBOARD_NEW_BUYER_LIFT]:
    locationEnum.NEWBUYERLIFT,
  [paths.MEASUREMENT_DASHBOARD + paths.MEASUREMENT_DASHBOARD_INCREMENTALITY]:
    locationEnum.INCREMENTALITY,
  [paths.MEASUREMENT_DASHBOARD + paths.MEASUREMENT_DASHBOARD_CAMPAIGN_INSIGHTS]:
    locationEnum.INSIGHTS,
  [paths.MEASUREMENT_DASHBOARD + paths.MEASUREMENT_DASHBOARD_BRAND_SURVEY]:
    locationEnum.BRANDLIFT,
};

export interface MeasurementSearchParams {
  campaignId: Base64ID;
  type?: PartnerEnum;
  seat?: string;
  deals?: string[];
  attributionwindow?: Base64ID;
  datefrom?: string;
  dateto?: string;
  customertype?: MeasurementOverviewCustomerType;
  newcustomerwindow?: Base64ID;
  personas?: string;
  postperiod?: string;
  useImpute?: boolean;
}

export const MeasurementInsightsDashboard = () => {
  const [state, actions] = useGlobalState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams<MeasurementSearchParams>();
  const featureFlags = useFeatureFlags();
  const isDesktop = useMediaQuery({ query: '(min: 600px)' });

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [optimizationFiltersOpen, setOptimizationFiltersOpen] = useState(false);
  const [decimalPlaces, setDecimalPlaces] = useState('2');
  const [optmizationToggles, setOptimizationToggles] =
    useState<OptimizationToggles>({
      CVR: true,
      impVol: true,
      avgTxn: false,
      CPA: false,
      CTR: false,
      VCR: false,
      ROAS: true,
      attn: false,
    });

  const activeLocation = locations[window.location.pathname];
  const [dateLimit, setDateLimit] = useState<DateRange | undefined>();
  const [chatHistory, setChatHistory] = useState<MessageType[]>([]);

  const measurementInsightsFf = useUiFlag('ui_measurement_insights');
  const campaignInsights = useUiFlag('ui_campaign_insights');
  const hasIncrementalityAccess = useUiFlag(
    'ui_incrementality_measurement_dashboard'
  );
  const hasOptimizationToggles = useUiFlag('ui_optimizations_column_toggles');
  const outcomeAiMesaurement = useUiFlag('ui_outcome_ai_measurement');
  const hasImputeToggle = useUiFlag('ui_incrementality_impute_toggle');
  const hasBrandSurveyAccess = useUiFlag('ui_brand_survey');
  const hasBrandSurveyV2Access = useUiFlag('ui_brand_lift_survey_v2');

  const allowColumnToggles =
    hasOptimizationToggles.isReady && hasOptimizationToggles.enabled;
  const allowCampaignInsights =
    campaignInsights.isReady && campaignInsights.enabled;
  const allowIC =
    hasIncrementalityAccess.isReady && hasIncrementalityAccess.enabled;
  const allowInsightsUI =
    measurementInsightsFf.isReady && measurementInsightsFf.enabled;
  const allowImputeToggle = hasImputeToggle.isReady && hasImputeToggle.enabled;
  const allowBrandSurvey =
    hasBrandSurveyAccess.isReady && hasBrandSurveyAccess.enabled;
  const allowBrandSurveyV2 =
    hasBrandSurveyV2Access.isReady && hasBrandSurveyV2Access.enabled;
  const allowOutcomeAiButton =
    outcomeAiMesaurement.isReady && outcomeAiMesaurement.enabled;

  const { form, getMeasurementInput } = useMeasurementForm({
    customerType: MeasurementOverviewCustomerType.ALL_CUSTOMERS,
  });

  const windowsOnDataLoad = (
    successData: MeasurementOverviewOptionsSuccess
  ) => {
    let attributionValue: ImpressionEntityFilterItem | undefined;
    let customerValue: ImpressionEntityFilterItem | undefined;
    const savedNewCustomerWindow = localStorage.getItem(
      `ncw|${searchParams.campaignId}`
    );
    if (searchParams.attributionwindow) {
      attributionValue = successData.attributionWindows.find(
        (val) => val.id === searchParams.attributionwindow
      );
    }
    if (searchParams.newcustomerwindow) {
      customerValue = successData.newCustomerWindows.find(
        (val) => val.id === searchParams.newcustomerwindow
      );
    } else if (savedNewCustomerWindow) {
      customerValue = successData.newCustomerWindows.find(
        (val) => val.id === savedNewCustomerWindow
      );
    }
    form.setValue(
      'window',
      attributionValue ??
        successData.attributionWindows.find(
          (window) => window.id === DEFAULT_MEASUREMENT_ATTRIBUTION_WINDOW_ID
        )
    );
    form.setValue(
      'newCustomerWindow',
      customerValue ??
        successData.newCustomerWindows.find(
          (window) => window.id === DEFAULT_MEASUREMENT_NEW_CUSTOMER_WINDOW_ID
        )
    );
    form.setValue('postPeriod', parseInt(searchParams?.postperiod || '30'));
  };

  const { data: campaignData, loading: loadingCampaign } =
    useQuery<CampaignMeasurementBasicQuery>(campaignMeasurementBasicQuery, {
      variables: { campaignId: searchParams.campaignId },
      onCompleted(data) {
        const campaign = data.campaign;
        form.setValue('campaign', campaign);
        if (campaign?.__typename === 'OpenXCampaign') {
          const deals = campaign.deals?.filter((deal) =>
            searchParams.deals?.includes(deal.id)
          );
          const badOrNoDealIds = !deals?.length;
          form.setValue('deals', deals?.length ? deals : campaign.deals);
          if (badOrNoDealIds) {
            form.setValue('dateRange', undefined);
            handleApplyFilters();
          }
        }
      },
    });

  const { data: windows } = useQuery<MeasurementOverviewOptionsQuery>(
    measurementOverviewOptionsQuery,
    {
      variables: { input: {} },
      initialFetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      onCompleted(data) {
        if (
          data.measurementOverviewOptions.__typename ===
          'MeasurementOverviewOptionsSuccess'
        ) {
          windowsOnDataLoad(
            data.measurementOverviewOptions as MeasurementOverviewOptionsSuccess
          );
        }
      },
    }
  );

  const [getOverviewData, { data: overviewData, loading: overviewLoading }] =
    useLazyQuery<MeasurementOverviewQuery, MeasurementOverviewQueryVariables>(
      measurementOverviewQuery,
      {
        notifyOnNetworkStatusChange: true,
      }
    );
  const [getQuestionResponseCount, { data: responseCount }] = useLazyQuery<
    BrandLiftSurveyInsightsNumResponsesQuery,
    BrandLiftSurveyInsightsNumResponsesQueryVariables
  >(brandLiftSurveyInsightsNumResponsesQuery);

  const hasIncrementality = !!(
    campaignData?.campaign && campaignData?.campaign['doIncrementality']
  );

  const windowOptions = useMemo(
    () =>
      windows?.measurementOverviewOptions.__typename ===
      'MeasurementOverviewOptionsSuccess'
        ? (windows.measurementOverviewOptions as MeasurementOverviewOptionsSuccess)
        : null,
    [windows]
  );

  const overview = useMemo(
    () =>
      overviewData?.measurementOverview.__typename ===
      'MeasurementOverviewSuccess'
        ? overviewData.measurementOverview
        : null,
    [overviewData]
  );

  const dataChartImpressions = useMemo(
    () => getDataChartImpressions(overview),
    [overview]
  );
  const dataChartReach = useMemo(() => getDataChartReach(overview), [overview]);

  const getMeasurementData = () => {
    const overviewInput = getMeasurementOverviewInputFromUrlParams(
      searchParams.campaignId,
      searchParams
    );

    if (overviewInput) {
      getOverviewData({
        variables: { input: overviewInput },
        onCompleted(data) {
          if (
            data.measurementOverview.__typename === 'MeasurementOverviewSuccess'
          ) {
            const dateRangeData = data.measurementOverview.campaignDateRange;
            const dateFrom = searchParams.datefrom
              ? Number(searchParams.datefrom)
              : dateRangeData?.startDate;
            const endDate = searchParams.dateto
              ? Number(searchParams.dateto)
              : dateRangeData?.endDate;
            form.setValue('dateRange', {
              from: dateFrom
                ? new Date(
                    intlFormat(dateFrom * 1000, {
                      timeZone: 'GMT',
                    })
                  )
                : undefined,
              to: endDate
                ? new Date(
                    intlFormat(endDate * 1000, {
                      timeZone: 'GMT',
                    })
                  )
                : undefined,
            });
            const dateLimits = data.measurementOverview.campaignDateRange;
            if (dateLimits) {
              setDateLimit({
                from: new Date(
                  intlFormat((dateLimits.startDate - 86400) * 1000, {
                    timeZone: 'GMT',
                  })
                ),
                to: new Date(
                  intlFormat((dateLimits.endDate + 86400) * 1000, {
                    timeZone: 'GMT',
                  })
                ),
              });
            }
          }
        },
      });
    }

    const personaInput = getMeasurementOptimizationInputFromUrlParams(
      searchParams.campaignId,
      searchParams
    );
    if (personaInput) {
      actions.smartPersonaInputData(
        personaInput as SmartPersonaMeasurementInput,
        'measurement'
      );
    }
  };

  const { data: campaignsData, loading: campaignsLoading } =
    useQuery<CampaignsQuery>(campaignsQuery, {
      variables: { seatId: null },
    });

  const campaigns = campaignsData?.campaigns.campaigns.filter((campaign) => {
    return campaign.partner === PartnerEnum.TTD
      ? false
      : campaign.status === CampaignStatusEnum.ACTIVE;
  });

  const handleResetFilters = useCallback(() => {
    if (form.getValues('campaign.__typename') === 'OpenXCampaign') {
      form.setValue('deals', form.getValues('campaign.deals'));
    }
    form.setValue(
      'window',
      windows?.measurementOverviewOptions.__typename ===
        'MeasurementOverviewOptionsSuccess'
        ? windows.measurementOverviewOptions.attributionWindows.find(
            (window) => window.id === DEFAULT_MEASUREMENT_ATTRIBUTION_WINDOW_ID
          )
        : null
    );
    form.setValue(
      'customerType',
      activeLocation === locationEnum.NEWBUYERLIFT
        ? MeasurementOverviewCustomerType.NET_NEW_CUSTOMERS
        : MeasurementOverviewCustomerType.ALL_CUSTOMERS
    );
    form.setValue('dateRange', {
      from: addDays(dateLimit?.from ?? 0, 1),
      to: subDays(dateLimit?.to ?? new Date(), 1),
    });
    form.setValue(
      'newCustomerWindow',
      windows?.measurementOverviewOptions.__typename ===
        'MeasurementOverviewOptionsSuccess'
        ? windows.measurementOverviewOptions.newCustomerWindows.find(
            (window) => window.id === DEFAULT_MEASUREMENT_NEW_CUSTOMER_WINDOW_ID
          )
        : null
    );
  }, [windows, dateLimit, form, activeLocation]);

  const handleApplyFilters = () => {
    const input = getMeasurementInput();
    const params: MeasurementSearchParams = {
      campaignId: searchParams.campaignId,
      type: searchParams.type,
    };
    if (input?.dealIds) {
      params.deals = encodeURIComponent(JSON.stringify(input.dealIds)) as any;
    }
    if (input?.customerType) {
      params.customertype = input.customerType;
    }
    if (input?.attributionWindow) {
      params.attributionwindow = input.attributionWindow;
      localStorage.setItem(
        `aw|${searchParams.campaignId}`,
        params.attributionwindow || ''
      );
    }
    if (input?.dateRange?.startDate) {
      params.datefrom = input.dateRange.startDate.toString();
      params.dateto = input.dateRange.endDate.toString();
    }
    if (input?.newCustomersWindow) {
      params.newcustomerwindow = input.newCustomersWindow;
      localStorage.setItem(
        `ncw|${searchParams.campaignId}`,
        params.newcustomerwindow || ''
      );
    }
    if (
      allowIC &&
      hasIncrementality &&
      input &&
      typeof input.postPeriod === 'number'
    ) {
      params.postperiod = input.postPeriod.toString();
      if (allowImputeToggle && input.useImpute) {
        params.useImpute = true;
      }
    }
    if (!allowInsightsUI && activeLocation === locationEnum.OVERVIEW) {
      params.newcustomerwindow = undefined;
      params.customertype = MeasurementOverviewCustomerType.ALL_CUSTOMERS;
    } else if (activeLocation === locationEnum.NEWBUYERLIFT) {
      params.customertype = MeasurementOverviewCustomerType.NET_NEW_CUSTOMERS;
      params.newcustomerwindow =
        params.newcustomerwindow ?? DEFAULT_MEASUREMENT_NEW_CUSTOMER_WINDOW_ID;
    }
    if (activeLocation === locationEnum.INCREMENTALITY) {
      params.newcustomerwindow = undefined;
      params.customertype = MeasurementOverviewCustomerType.ALL_CUSTOMERS;
    }
    const search = convertObjectToSearchString(params);
    navigate({ search: search });
    setFiltersOpen(false);
    showIntercom();
  };

  const tabs: Tab[] = [
    {
      activePath:
        paths.MEASUREMENT_DASHBOARD + paths.MEASUREMENT_DASHBOARD_OVERVIEW,
      onClick: () =>
        navigate(
          `${generatePath(
            paths.MEASUREMENT_DASHBOARD + paths.MEASUREMENT_DASHBOARD_OVERVIEW
          )}${convertObjectToSearchString({
            ...searchParams,
            customertype: MeasurementOverviewCustomerType.ALL_CUSTOMERS,
            newcustomerwindow: undefined,
          })}`
        ),
      title: 'Attribution',
    },
    {
      activePath:
        paths.MEASUREMENT_DASHBOARD + paths.MEASUREMENT_DASHBOARD_OPTIMIZATION,
      onClick: () =>
        navigate(
          `${generatePath(
            paths.MEASUREMENT_DASHBOARD +
              paths.MEASUREMENT_DASHBOARD_OPTIMIZATION
          )}${convertObjectToSearchString({
            ...searchParams,
            customertype: form.getValues('customerType'),
            newcustomerwindow:
              searchParams.customertype ===
              MeasurementOverviewCustomerType.ALL_CUSTOMERS
                ? undefined
                : form.getValues('newCustomerWindow.id') ??
                  DEFAULT_MEASUREMENT_NEW_CUSTOMER_WINDOW_ID,
          })}`
        ),
      title: 'Optimization',
    },
  ];

  if (featureFlags.flags['MEASUREMENT_INCREMENTALITY']) {
    tabs.push({
      activePath:
        paths.MEASUREMENT_DASHBOARD +
        paths.MEASUREMENT_DASHBOARD_NEW_BUYER_LIFT,
      onClick: () =>
        navigate(
          `${generatePath(
            paths.MEASUREMENT_DASHBOARD +
              paths.MEASUREMENT_DASHBOARD_NEW_BUYER_LIFT
          )}${convertObjectToSearchString({
            ...searchParams,
            customertype: MeasurementOverviewCustomerType.NET_NEW_CUSTOMERS,
            newcustomerwindow:
              form.getValues('newCustomerWindow.id') ??
              DEFAULT_MEASUREMENT_NEW_CUSTOMER_WINDOW_ID,
          })}`
        ),
      title: 'New buyer lift',
    });
  }

  if (allowCampaignInsights) {
    tabs.push({
      activePath:
        paths.MEASUREMENT_DASHBOARD +
        paths.MEASUREMENT_DASHBOARD_CAMPAIGN_INSIGHTS,
      onClick: () =>
        navigate(
          `${generatePath(
            paths.MEASUREMENT_DASHBOARD +
              paths.MEASUREMENT_DASHBOARD_CAMPAIGN_INSIGHTS
          )}${convertObjectToSearchString({
            ...searchParams,
            customertype: MeasurementOverviewCustomerType.ALL_CUSTOMERS,
            newcustomerwindow: undefined,
          })}`
        ),
      element: (
        <span>
          Campaign insights <Badge color="brandSecondary">BETA</Badge>
        </span>
      ),
    });
  }

  tabs.push({
    disabled: !allowIC || !hasIncrementality,
    activePath:
      paths.MEASUREMENT_DASHBOARD + paths.MEASUREMENT_DASHBOARD_INCREMENTALITY,
    onClick: () =>
      allowIC && hasIncrementality
        ? navigate(
            `${generatePath(
              paths.MEASUREMENT_DASHBOARD +
                paths.MEASUREMENT_DASHBOARD_INCREMENTALITY
            )}${convertObjectToSearchString({
              ...searchParams,
              customertype: MeasurementOverviewCustomerType.ALL_CUSTOMERS,
              newcustomerwindow: undefined,
            })}`
          )
        : null,
    element:
      allowIC && hasIncrementality ? (
        <span>
          Incrementality <Badge color="brandSecondary">New!</Badge>
        </span>
      ) : (
        <Tooltip
          title="This feature is currently in development & will be available soon!"
          placement="top"
          arrow
        >
          <span>
            Incrementality <Badge color="brandSecondary">New!</Badge>
          </span>
        </Tooltip>
      ),
  });

  if (allowBrandSurvey) {
    tabs.push({
      activePath:
        paths.MEASUREMENT_DASHBOARD + paths.MEASUREMENT_DASHBOARD_BRAND_SURVEY,
      onClick: () => {
        const path =
          paths.MEASUREMENT_DASHBOARD +
          paths.MEASUREMENT_DASHBOARD_BRAND_SURVEY;
        navigate(
          `${generatePath(path)}${convertObjectToSearchString({
            ...searchParams,
            customertype: MeasurementOverviewCustomerType.ALL_CUSTOMERS,
            newcustomerwindow: undefined,
          })}`
        );
      },
      element: (
        <span>
          Brand survey <Badge color="neutral">ALPHA</Badge>
        </span>
      ),
    });
  }

  const hasEnoughUniqueReach =
    overview?.reach.heroMetric.value &&
    overview.reach.heroMetric.value >= 1000000;
  // const measurementEmpty = !overviewLoading && !overview;
  const isDemo =
    campaignData?.campaign?.title === 'Attain Market Demo Campaign';
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (
      allowBrandSurveyV2 &&
      searchParams.campaignId &&
      activeLocation === locationEnum.BRANDLIFT
    ) {
      getQuestionResponseCount({
        variables: { input: { campaignId: searchParams.campaignId } },
      });
    }
  }, [activeLocation, allowBrandSurveyV2]);

  useEffect(() => {
    getMeasurementData();
    return () => {
      showIntercom();
    };
  }, [searchParams]);

  const appliedFilters = getAppliedFilters(activeLocation, form.getValues(), {
    allowImpute:
      !!allowImputeToggle &&
      campaignData?.campaign?.__typename !== 'TtdCampaign' &&
      campaignData?.campaign?.merchantsOrBrands?.at(0)?.__typename ===
        'CampaignBrand',
  });

  const handleOnHistoryChange = (updatedHistory) => {
    setChatHistory(updatedHistory);
  };

  if (featureFlags.isReady) {
    if (!featureFlags.flags['NAVIGATION_MEASUREMENT']) {
      return <MeasurementNotPermissioned />;
    } else {
      return (
        <>
          <Header
            parents={[{ label: 'Measurement', to: paths.MEASUREMENT }]}
            current={
              loadingCampaign ? 'Loading...' : campaignData?.campaign?.title
            }
          />
          <Styled.TitleWrapper>
            {isDesktop && (
              <IconButton onClick={() => navigate(paths.MEASUREMENT)}>
                <CaretLeft size={theme.icons.lg} />
              </IconButton>
            )}
            <Styled.Title>
              {loadingCampaign ? (
                <GhostLoader
                  height="48px"
                  heightRandom={0}
                  width="250px"
                  widthRandom={0}
                />
              ) : (
                <Styled.TitleText>
                  {campaignData?.campaign?.title}
                </Styled.TitleText>
              )}
              {!loadingCampaign && (
                <>
                  <Styled.CampaignListButton
                    onClick={(e) => {
                      setAnchorEl((currState) =>
                        currState ? null : e.currentTarget
                      );
                    }}
                  >
                    <CaretDown size={theme.icons.md} />
                  </Styled.CampaignListButton>
                  <Popper
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    placement={'bottom-end'}
                  >
                    <CampaignQuickSelect
                      campaigns={(campaigns as CampaignBasic[]) || []}
                      loading={campaignsLoading}
                      close={() => {
                        setAnchorEl(null);
                      }}
                      navigate={(path: string) => {
                        navigate(path);
                      }}
                    />
                  </Popper>
                </>
              )}
              <Styled.CampaignType>
                {!loadingCampaign && campaignData?.campaign
                  ? !isDemo
                    ? campaignTypeDisplayMap[campaignData.campaign.__typename]
                    : 'Demo Partner'
                  : ''}
              </Styled.CampaignType>
            </Styled.Title>

            {allowOutcomeAiButton && (
              <Styled.OutcomeAiButton
                startIcon={<img src={OutcomeAiIconLogo} alt="OutcomeAI" />}
                endIcon={<CaretDoubleRight size={theme.icons.lg} />}
                variant="contained"
                onClick={() => {
                  actions.setDrawerContents(
                    <OutcomeAiDrawer
                      campaignId={searchParams.campaignId}
                      chatHistory={chatHistory}
                      onHistoryChange={handleOnHistoryChange}
                    />
                  );
                }}
              >
                OutcomeAI
              </Styled.OutcomeAiButton>
            )}

            {activeLocation !== locationEnum.INCREMENTALITY && (
              <Styled.PersonaButton
                data-test-id="converter-insights"
                data-intercom-target="measurement-dashboard-smart-persona-open"
                endIcon={<CaretDoubleRight size={theme.icons.lg} />}
                onClick={() => {
                  actions.setDrawerContents(
                    <SmartPersonaMeasurement
                      personaInput={state.smartPersonasInputData}
                    />
                  );
                }}
                variant="contained"
              >
                Converter insights
              </Styled.PersonaButton>
            )}
          </Styled.TitleWrapper>

          <Tabs tabs={tabs} />

          <Styled.HeaderFilters>
            {allowBrandSurveyV2 && activeLocation === locationEnum.BRANDLIFT ? (
              <Styled.ResponsesTag>
                Showing:{' '}
                <span>
                  {responseCount?.brandLiftSurveyInsightsNumResponses
                    .totalResponses.title || '-'}{' '}
                  responses
                </span>
              </Styled.ResponsesTag>
            ) : (
              <Styled.FiltersChipContainer>
                {appliedFilters?.map((filter, i) => (
                  <Styled.FilterChip
                    key={i}
                    onClick={() => {
                      setFiltersOpen(true);
                    }}
                  >
                    {filter.title}: <span>{filter.value}</span>
                  </Styled.FilterChip>
                ))}
              </Styled.FiltersChipContainer>
            )}
            <Styled.FilterButtonsContainer>
              {activeLocation === locationEnum.OPTIMIZATION &&
                allowColumnToggles && (
                  <Button
                    variant="text"
                    disabled={overviewLoading}
                    onClick={() => {
                      hideIntercom();
                      setOptimizationFiltersOpen(true);
                    }}
                    startIcon={<CirclesThreePlus size={theme.icons.lg} />}
                  >
                    Customize
                  </Button>
                )}
              {activeLocation !== locationEnum.BRANDLIFT && (
                <Button
                  variant="text"
                  disabled={overviewLoading}
                  onClick={() => {
                    hideIntercom();
                    setFiltersOpen(true);
                  }}
                  startIcon={<FunnelSimple size={theme.icons.lg} />}
                >
                  More filters
                </Button>
              )}
            </Styled.FilterButtonsContainer>
          </Styled.HeaderFilters>

          <Styled.Measurements
            container
            spacing={isDesktop ? theme.spacing.lg : theme.spacing.md}
          >
            {isDemo && (
              <Grid item xs={12}>
                <MeasurementUpsellBanner />
              </Grid>
            )}
            {!campaignData ? (
              <MeasurementsEmpty />
            ) : (
              <Routes>
                <Route
                  path={paths.MEASUREMENT_DASHBOARD_OVERVIEW}
                  element={
                    !overviewLoading ? (
                      <>
                        {overview ? (
                          <MeasurementOverviewComponent
                            showUniqueReachWarning={!hasEnoughUniqueReach}
                            isNewCustomersSelected={false}
                            metrics={overview?.metrics}
                            impressions={overview?.impressions}
                            reach={overview?.reach}
                            dataChartImpressions={dataChartImpressions}
                            dataChartReach={dataChartReach}
                          />
                        ) : (
                          <Grid item xs={12}>
                            <Card>
                              <Error message="There has been a problem loading the data for this campaign." />
                            </Card>
                          </Grid>
                        )}
                      </>
                    ) : (
                      <MeasurementGhostLoader />
                    )
                  }
                />

                <Route
                  path={paths.MEASUREMENT_DASHBOARD_OPTIMIZATION}
                  element={
                    campaignData?.campaign?.title && (
                      <MeasurementOptimizationResults
                        campaignName={campaignData?.campaign?.title}
                        decimalPlaces={decimalPlaces}
                        columnToggles={optmizationToggles}
                      />
                    )
                  }
                />

                <Route
                  path={paths.MEASUREMENT_DASHBOARD_NEW_BUYER_LIFT}
                  element={
                    campaignData?.campaign?.title && (
                      <MeasurementNewBuyerLift
                        overviewLoading={overviewLoading}
                        impressions={overview?.impressions}
                        reach={overview?.reach}
                        dataChartImpressions={dataChartImpressions}
                        dataChartReach={dataChartReach}
                      />
                    )
                  }
                />

                <Route
                  path={paths.MEASUREMENT_DASHBOARD_CAMPAIGN_INSIGHTS}
                  element={
                    campaignData?.campaign?.title && (
                      <>
                        <MeasurementCampaignInsights />
                      </>
                    )
                  }
                />

                <Route
                  path={paths.MEASUREMENT_DASHBOARD_INCREMENTALITY}
                  element={
                    allowIC && (
                      <MeasurementIncrementality
                        overviewLoading={overviewLoading}
                        metrics={overview?.metrics}
                        impressions={overview?.impressions}
                        reach={overview?.reach}
                        dataChartImpressions={dataChartImpressions}
                        dataChartReach={dataChartReach}
                      />
                    )
                  }
                />

                <Route
                  path={paths.MEASUREMENT_DASHBOARD_BRAND_SURVEY}
                  element={
                    allowBrandSurveyV2
                      ? campaignData?.campaign?.title && (
                          <MeasurementBrandSurveyV2 />
                        )
                      : campaignData?.campaign?.title && (
                          <MeasurementBrandSurvey />
                        )
                  }
                />
              </Routes>
            )}
          </Styled.Measurements>

          <MeasurementFilters
            form={form}
            deals={form.watch('campaign.deals')}
            windows={windowOptions}
            dateLimit={dateLimit}
            handleApplyFilters={handleApplyFilters}
            handleResetFilters={handleResetFilters}
            onClose={() => {
              showIntercom();
              setFiltersOpen(false);
            }}
            decimalPlaces={decimalPlaces}
            setDecimalPlaces={setDecimalPlaces}
            open={filtersOpen}
          />

          <OptimizationFilters
            setToggles={setOptimizationToggles}
            toggles={optmizationToggles}
            open={optimizationFiltersOpen}
            onClose={() => {
              setOptimizationFiltersOpen(false);
            }}
          />
        </>
      );
    }
  } else {
    return <></>;
  }
};

export default MeasurementInsightsDashboard;
