import Background from 'assets/global-search-bg.svg';
import SearchAutoComplete from './SearchAutoComplete';
import * as Styled from './GlobalSearch.styles';

interface GlobalSearchProps {
  tiny?: boolean; // for being displayed in tiny spaces, like the global header
}

export const GlobalSearch = ({ tiny }: GlobalSearchProps) => {
  return (
    <>
      {tiny ? (
        <Styled.SearchTiny>
          <SearchAutoComplete />
        </Styled.SearchTiny>
      ) : (
        <Styled.Wrapper>
          <Styled.Background src={Background} />
          <Styled.Center>
            <Styled.Intro>
              Get consumer insights on any brand, merchant, audience or campaign
              in real-time.
            </Styled.Intro>

            <Styled.Search>
              <Styled.FormWrapper>
                <SearchAutoComplete />
              </Styled.FormWrapper>

              <Styled.SupportingInfo>
                <div>
                  Search Attain’s purchase data platform with over{' '}
                  <Styled.Amount>10 billion</Styled.Amount> fully permissioned
                  transactions
                </div>
              </Styled.SupportingInfo>
            </Styled.Search>
          </Styled.Center>
        </Styled.Wrapper>
      )}
    </>
  );
};

export default GlobalSearch;
